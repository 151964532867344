import { clearLocalStorage, fetchWrapper } from "../_helpers/index";

let inMemoryToken;

export const accountService = {
  register,
  forgotPassword,
  forgotPasswordVerify,
  resetPassword,
  getProfile,
  getProfilePicture,
  verifyPassword,
  changePassword,
  toggleMfa,
  updateProfile,
  uploadProfilePicture,
  changeFontSize,
  updateColorContrast,
  updateHealthTrackerPreference,
  getSecret,
  getPermissions,
  getTenantFeatures,
  getFeaturePermissionMap,
  updateNotificationPreferences,
  acceptTerms
};

async function getSecret(accessToken) {
  return fetchWrapper
    .post(`/api/account/usersecret?accessToken=${accessToken}`)
    .then((userSecret) => {
      inMemoryToken = userSecret;
      return userSecret;
    });
}

function register(fields) {
  if (!fields.phoneNumber) fields.phoneNumber = null;
  clearLocalStorage();

  return fetchWrapper.post(`/api/account/register`, fields);
}

function forgotPassword(email) {
  return fetchWrapper.post(`/api/account/forgot_password`, { email });
}

function forgotPasswordVerify(email, code) {
  return fetchWrapper.post(`/api/account/forgot_password/verify`, {
    email,
    code
  });
}

function resetPassword({ email, code, password, confirmPassword }) {
  return fetchWrapper.post(`/api/account/reset_password`, {
    email,
    code,
    password,
    confirmPassword
  });
}

function getProfile(smartSession) {
  return fetchWrapper.get(`/api/profile`);
}

function getProfilePicture() {
  return fetchWrapper.get(`/api/profile/profile_picture`);
}

function getPermissions() {
  return fetchWrapper.get("/api/profile/permissions");
}

function getTenantFeatures() {
  return fetchWrapper.get("/api/profile/tenant/features");
}

function getFeaturePermissionMap() {
  return fetchWrapper.get("/api/featurepermissionmap");
}

function verifyPassword(password) {
  return fetchWrapper.post(`/api/profile/verify_password`, { password });
}

function changePassword({ oldPassword, newPassword, confirmPassword }) {
  return fetchWrapper.post(`/api/profile/change_password`, {
    oldPassword,
    newPassword,
    confirmPassword
  });
}

function toggleMfa(enable) {
  return fetchWrapper.post(`/api/profile/mfa?enable=${enable}`);
}

function updateProfile(fields) {
  fields.function = fields.function.value !== 0 ? fields.function.value : null;
  return fetchWrapper.put(`/api/profile`, fields);
}

function uploadProfilePicture(file) {
  let formData = new FormData();
  formData.append("file", file, "file.blob");
  return fetchWrapper.post(`/api/profile/profile_picture`, formData);
}

function changeFontSize(fontSize) {
  return fetchWrapper.post(
    `/api/profile/accessibility/font?fontSize=${fontSize}`
  );
}

function updateColorContrast(isEnabled) {
  return fetchWrapper.post(
    `/api/profile/accessibility/color_contrast?isEnabled=${isEnabled}`
  );
}

function updateHealthTrackerPreference(unit, type) {
  return fetchWrapper.put(
    `/api/profile/healthtracker/unit?healthTrackerUnit=${unit}&healthTrackerType=${type}`
  );
}

function updateNotificationPreferences(fields) {
  return fetchWrapper.post(`/api/profile/notificationpreferences`, fields);
}

function acceptTerms() {
  return fetchWrapper.put("/api/profile/accept/terms");
}

export { inMemoryToken };
