import React from "react";
import { ReactComponent as ChevronDown } from "../images/chevron_down_small.svg";
import { ReactComponent as ChevronUp } from "../images/chevron_up_small.svg";
const RowExpander = ({ ...row }) => {
  return (
    <span className="d-flex align-items-center p-2" {...row.getToggleRowExpandedProps()}>
      {row.isExpanded ? (
        <ChevronUp  className="glyph-dark" aria-label="up" />
      ) : (
        <ChevronDown className="glyph-dark" aria-label="down" />
      )}
    </span>
  );
};

export { RowExpander };
