import React from "react";
import { CustomInput } from "reactstrap";
import { useField } from "formik";
import PropTypes from "prop-types";

const MyCheckbox = ({ children, ...props }) => {
  // We need to tell useField what type of input this is
  // since React treats radios and checkboxes differently
  // than inputs/select/textarea.
  const [field, meta] = useField({ ...props, type: "checkbox" });
  return (
    <>
      <CustomInput
        type="checkbox"
        {...field}
        {...props}
      />
      {children}
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};
MyCheckbox.propTypes={
children: PropTypes.node
}

export { MyCheckbox };
