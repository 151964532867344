import { fetchWrapper } from "../_helpers";
export const smartService = {
  getExistingPatients,
  smartAuthorize,
  getSmartToken,
  requestSmartAccess
};

const baseUrl = "/api/smart";

function getExistingPatients(supportId) {
  return fetchWrapper.get(
    `${baseUrl}/invitations/existing-patients?supportId=${supportId}`
  );
}

async function smartAuthorize(search, isMpages) {
  const urlParams = new URLSearchParams(search);
  const code = encodeURIComponent(urlParams.get("code")?.replace(/\s/g, "+"));
  const state = encodeURIComponent(urlParams.get("state")?.replace(/\s/g, "+"));
  return fetchWrapper
    .post(
      `/api/smart/redirecting?code=${code}&state=${state}&isMpage=${isMpages}`
    )
    .then((response) => {
      localStorage.setItem("smartToken", response.accessToken);
      localStorage.setItem("refreshToken", response.smartRefreshToken);
      localStorage.setItem(
        "refreshTokenExpiresAt",
        response.smartTokenExpiresAt
      );
      return response;
    });
}

async function getSmartToken(refreshToken) {
  return fetchWrapper.post(
    `/api/smart/refresh_access_token?refreshToken=${refreshToken}`
  );
}

function requestSmartAccess(fields) {
  return fetchWrapper.post(`/api/smart/requestaccess`, fields);
}
