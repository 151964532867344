import classnames from "classnames";
import { ErrorMessage, Field, Form, Formik } from "formik";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import * as Yup from "yup";
import {
  CloseButton,
  Criteria,
  MyCheckbox,
  MyMultiSelect
} from "../_components";
import {
  createCriteriaValidationMessage,
  createCriteriaValidatonTest,
  formatFilterValue
} from "../_helpers";
import { criteriaOptions } from "../_helpers/constants";
import {
  UseMobileMediaQuery,
  UseTabletMediaQuery
} from "../_helpers/media-queries";
import {
  adminService,
  patientlistService,
  riskprofileService
} from "../_services";
import { useAlertContext } from "../context/alertContext";
import { useUserContext } from "../context/userContext";

const SmartListModal = ({
  fetchData,
  addModal,
  toggleAdd,
  type,
  selectedItem,
  setSelectedItem
}) => {
  const [groups, setGroups] = useState([]);
  const is768Width = UseTabletMediaQuery();
  const isMobileWidth = UseMobileMediaQuery();
  const [isDeleting, setIsDeleting] = useState(false);
  const { userState } = useUserContext();
  const { user, tenantFeatures } = userState;
  const { alertMethods } = useAlertContext();
  const [riskProfileOptions, setRiskProfileOptions] = useState([]);

  const mounted = React.useRef(false);

  const [initialValues, setInitialValues] = useState({
    listName: "",
    description: "",
    andor: [],
    criteria: [{ id: "", label: "" }],
    compare: [],
    choice: [],
    groups: []
  });
  const fetchRiskProfiles = useCallback(
    async (setIsLoading) => {
      if (setIsLoading) setIsLoading(true);
      try {
        const response = await riskprofileService.getRiskProfiles();
        const options = response.data.map((item) => ({
          label: item.name,
          value: item.id
        }));
        setRiskProfileOptions(options);
        return options;
      } catch {
        alertMethods.error("Could not fetch risk profiles");
      } finally {
        if (setIsLoading) setIsLoading(false);
      }
    },
    [alertMethods]
  );

  useEffect(() => {
    fetchRiskProfiles();
  }, [fetchRiskProfiles, user?.userRole]);

  useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => {
      mounted.current = false;
    }; // ... and to false on unmount
  }, []);

  useEffect(() => {
    if (tenantFeatures?.["Groups"] && type === "admin") {
      adminService
        .getGroups("", 20, 1)
        .then((res) => {
          if (mounted.current) {
            if (res.data.length > 0) {
              let groupOptions = [];
              res.data.map((row) => {
                return groupOptions.push({
                  value: row.id,
                  label:
                    row.name.length > 40
                      ? row.name.slice(0, 40) + "..."
                      : row.name
                });
              });
              setGroups(groupOptions);
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [tenantFeatures, type]);

  const newListValidationSchema = Yup.object().shape({
    listName: Yup.string().required("Name is required"),
    description: Yup.string().required("Description is required"),
    listCriteria: Yup.array()
      .of(
        Yup.object().shape({
          andor: Yup.object().shape({
            value: Yup.number().required("AND/OR is required"),
            label: Yup.string().required("AND/OR is required")
          }),
          criteria: Yup.object().shape({
            value: Yup.number().required("Criteria is required"),
            label: Yup.string().required("Criteria is required")
          }),
          compare: Yup.object().shape({
            value: Yup.number().required("Comparison is required"),
            label: Yup.string().required("Comparison is required")
          }),
          choice: Yup.mixed().test({
            name: "choice-validation",
            message: ({ path, value }) =>
              createCriteriaValidationMessage(value),
            test: (value) => createCriteriaValidatonTest(value)
          })
        })
      )
      .min(1, "At least one criteria is required")
      .required()
  });

  function onDelete() {
    setIsDeleting(true);
    patientlistService
      .deletePatientlist(selectedItem.id)
      .then((res) => {
        alertMethods.success("List has been deleted");
        setIsDeleting(false);
        toggleAdd();
        if (fetchData) fetchData();
      })
      .catch((e) => alertMethods.error(e.message));
  }

  function formatBody(type, user, selectedItem, fields, filters) {
    return {
      userId: type === "admin" ? null : user?.userId,
      id: selectedItem?.id || null,
      name: fields.listName,
      description: fields.description,
      filters: filters,
      groups: fields.groups,
      isDefault: type === "admin" ? fields.default : true
    };
  }

  function onAdd(fields, { setSubmitting, resetForm }) {
    setSubmitting(true);
    let formComplete = true;
    let filters = [];
    fields.listCriteria.forEach((item, idx) => {
      filters.push({
        filterOperator: item.andor.value,
        filterType: item.criteria.value,
        operationType: item.compare.value,
        filterValue: formatFilterValue(item.choice),
        seqNumber: item.id + 1
      });
    });

    const body = formatBody(type, user, selectedItem, fields, filters);
    if (formComplete) {
      if (selectedItem?.id) {
        patientlistService
          .editPatientlist(selectedItem.id, body)
          .then((res) => {
            if (mounted.current) {
              alertMethods.success("Smart list has been edited");
              setSubmitting(false);
              resetForm();
              toggleAdd();
              if (fetchData) fetchData();
              setSelectedItem(undefined);
            }
          })
          .catch((error) => {
            if (mounted.current) {
              alertMethods.error("Smart list could not be edited");
              setSubmitting(false);
            }
          });
      } else {
        patientlistService
          .addPatientlist(body)
          .then((res) => {
            alertMethods.success("Smart list has been created");
            setSubmitting(false);
            resetForm();
            toggleAdd();
            if (fetchData) fetchData();
            setSelectedItem(undefined);
          })
          .catch((error) => {
            alertMethods.error("Smart list could not be created");
            setSubmitting(false);
          });
      }
    }
  }

  return (
    <Modal
      isOpen={addModal}
      toggle={toggleAdd}
      className="center-modal scroll-modal"
    >
      <ModalHeader
        toggle={toggleAdd}
        close={<CloseButton toggle={toggleAdd} />}
      >
        {selectedItem?.id ? "Edit Smart List" : "New Smart List"}
      </ModalHeader>
      <ModalBody>
        <Formik
          initialValues={initialValues}
          validationSchema={newListValidationSchema}
          onSubmit={onAdd}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            isValid,
            dirty,
            setFieldValue,
            setFieldTouched,
            resetForm
          }) => {
            return (
              <Form data-testid="create-new-group-form">
                <div>
                  <div className="form-group col-11 pl-0">
                    <label htmlFor="listName">
                      List Name <span className="">*</span>
                    </label>
                    <Field
                      id="listName"
                      name="listName"
                      type="text"
                      className={classnames(
                        { "is-invalid": errors.listName && touched.listName },
                        "form-control"
                      )}
                    />
                    <ErrorMessage
                      name="listName"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group col-11 pl-0">
                    <label htmlFor="description">
                      Description <span className="">*</span>
                    </label>
                    <Field
                      id="description"
                      name="description"
                      placeholder="​Description of Smart List"
                      component="textarea"
                      rows="4"
                      type="text"
                      className={classnames(
                        {
                          "is-invalid":
                            errors.description && touched.description
                        },
                        "form-control"
                      )}
                    />
                  </div>
                  {type === "admin" && tenantFeatures?.["Groups"] && (
                    <>
                      <div className="form-group col-11 pl-0">
                        <MyMultiSelect
                          placeholder="Please select groups"
                          name="groups"
                          label="Groups"
                          value={values?.groups.map((grp) => {
                            return { value: grp.id, label: grp.name };
                          })}
                          options={groups}
                          onChange={(name, value) => {
                            let val;
                            if (value && Array.isArray(value)) {
                              val = value.map((v) => {
                                return { id: v.value, name: v.label };
                              });
                              setFieldValue(name, val);
                            } else setFieldValue(name, []);
                          }}
                          onBlur={setFieldTouched}
                          error={errors.function}
                          touched={touched.function}
                          isClearable={true}
                        ></MyMultiSelect>
                        <ErrorMessage
                          name="groups"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group">
                        <div className="d-flex">
                          <MyCheckbox
                            // checked={values.default}
                            id="default"
                            name="default"
                            className="dark-checkmark"
                          ></MyCheckbox>
                          <label htmlFor="default" className="pl-3">
                            Subscribe by Default
                          </label>
                        </div>
                      </div>
                    </>
                  )}

                  <Criteria
                    values={values}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    criteriaOptions={criteriaOptions}
                    fetchRiskProfiles={fetchRiskProfiles}
                    riskProfileOptions={riskProfileOptions}
                    selectedItem={selectedItem}
                    setInitialValues={setInitialValues}
                    touched={touched}
                    errors={errors}
                  />

                  <div className="form-group pt-6 d-flex flex-lg-row flex-column align-items-center">
                    <button
                      type="submit"
                      disabled={isSubmitting || !isValid || !dirty}
                      className="btn btn-primary mr-lg-3 mr-0 mb-lg-0 mb-4"
                    >
                      {isSubmitting && (
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                      )}
                      Save
                    </button>
                    {selectedItem?.id ? (
                      <button
                        className="btn btn-danger "
                        type="button"
                        onClick={onDelete}
                      >
                        {isDeleting && (
                          <span className="spinner-border spinner-border-sm mr-1"></span>
                        )}
                        Delete List
                      </button>
                    ) : (
                      <button
                        type="button"
                        className={classnames(
                          {
                            "btn-outline-secondary":
                              !is768Width && !isMobileWidth
                          },
                          {
                            "btn-link text-secondary":
                              is768Width || isMobileWidth
                          },
                          "btn mr-lg-5 mr-0 mb-lg-0 mb-4"
                        )}
                        onClick={toggleAdd}
                      >
                        Cancel
                      </button>
                    )}
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

SmartListModal.propTypes = {
  fetchData: PropTypes.func,
  addModal: PropTypes.bool,
  toggleAdd: PropTypes.func,
  type: PropTypes.string,
  selectedItem: PropTypes.object,
  setSelectedItem: PropTypes.func
};

export { SmartListModal };
