import classnames from "classnames";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { MyDropdown, RemovePatientModal } from "../../_components";
import { patientService } from "../../_services";
import { useAlertContext } from "../../context/alertContext";
import { useSmartContext } from "../../context/smartContext";
import { useUserContext } from "../../context/userContext";
import { ReactComponent as Menu } from "../../images/glyph_menu_2.svg";

const DeletePatientMenu = ({
  toggleRemovePatient,
  reportsVisible,
  setDeleteVersion
}) => {
  return (
    <div
      className={classnames({ "ml-auto": !reportsVisible }, "mr-4")}
      data-testid="delete-patient-menu"
    >
      <MyDropdown
        details={{
          name: "row menu",
          glyph: <Menu aria-label="menu" className="glyph-gray w-10px" />,
          items: [
            {
              name: "Disconnect Mobile App",
              link: "",
              onClick: () => {
                toggleRemovePatient();
                setDeleteVersion(false);
              }
            },
            {
              name: "Delete Patient",
              link: "",
              onClick: () => {
                setDeleteVersion(true);
                toggleRemovePatient();
              }
            }
          ]
        }}
        container="body"
        // direction="right"
      />
    </div>
  );
};

export const DeletePatient = ({
  patient,
  setPatientNotFound,
  reportsVisible
}) => {
  const { userState } = useUserContext();
  const { user } = userState;
  const { alertMethods } = useAlertContext();

  const navigate = useNavigate();
  const location = useLocation();
  const [removePatientModal, setRemovePatientModal] = useState(false);
  const [deleteVersion, setDeleteVersion] = useState(false);
  const { setPatientInviteStatus, patientId } = useSmartContext();

  const toggleRemovePatient = React.useCallback(() => {
    setRemovePatientModal(!removePatientModal);
  }, [removePatientModal]);
  function onRemovePatient(fields, { setSubmitting }) {
    patientService
      .deletePatient(patient?.patientId, deleteVersion)
      .then(() => {
        if (deleteVersion) {
          alertMethods.success("Patient deleted successfully", {
            keepAfterRouteChange: true
          });
        } else {
          alertMethods.success("Patient disconnected successfully", {
            keepAfterRouteChange: true
          });
        }
        setSubmitting(false);
        toggleRemovePatient();
        if (user?.isSmartSession) {
          if (deleteVersion) {
            setPatientInviteStatus(7);
            sessionStorage.setItem("invitationStatus", JSON.stringify(7));
            navigate(`/smart/400`);
            setPatientNotFound(true);
          } else {
            navigate(`/smart/invite/${patientId}`);
            setPatientInviteStatus(0);
            sessionStorage.setItem("invitationStatus", JSON.stringify(0));
          }
        } else {
          const doesAnyHistoryEntryExist = location.key !== "default";
          if (doesAnyHistoryEntryExist) navigate(-1);
          else navigate("/");
        }
      })
      .catch(() => {
        alertMethods.error(
          `Error ${deleteVersion ? "deleting" : "disconnecting"} patient`
        );
        setSubmitting(false);
      });
  }
  return (
    <>
      <DeletePatientMenu
        toggleRemovePatient={toggleRemovePatient}
        reportsVisible={reportsVisible}
        setDeleteVersion={setDeleteVersion}
      />
      <RemovePatientModal
        removePatientModal={removePatientModal}
        toggleRemovePatient={toggleRemovePatient}
        onRemovePatient={onRemovePatient}
        selectedPatient={patient}
        deleteVersion={deleteVersion}
      />
    </>
  );
};
