import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../context/themeContext";
import PropTypes from "prop-types";
import classnames from "classnames";

export const RiskProfileCircle = ({ level, name, textOnly }) => {
  const { theme } = useContext(ThemeContext);
  const [color, setColor] = useState("");
  const [text, setText] = useState("");

  useEffect(() => {
    if (level === 2) {
      setText("High");
      setColor(theme !== "high-contrast" ? "danger" : "dark-danger");
    } else if (level === 1) {
      setText("Medium");
      setColor(theme !== "high-contrast" ? "warning" : "dark-warning");
    } else if (level === 0) {
      setText("Low");
      setColor(theme !== "high-contrast" ? "success" : "dark-success");
    } else {
      setColor("gray");
      setText("Not at risk");
    }
  }, [level, theme]);
  return (
    <span className="text-nowrap">
      {!textOnly && (
        <div
          className={classnames(
            `d-inline-block mr-1 h-10px w-10px rounded-circle bg-${color}`
          )}
          data-testid={`${color}-circle`}
          //style={{ background: color }}
        ></div>
      )}
      {text} {textOnly && "Risk"}
      <span className="ml-1 text-nowrap"></span>
      {name && <>({name})</>}
    </span>
  );
};

RiskProfileCircle.propTypes = {
  level: PropTypes.number,
  name: PropTypes.string,
  textOnly: PropTypes.bool
};
