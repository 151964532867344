import React from "react";
import { RiskProfileCircle } from "../_components";
import { ReactComponent as GlyphPopup } from "../images/glyph_popup.svg";
import { MyPopover } from "./MyPopover";

export const createRiskProfiles = (data) => {
  const RPs = data.value;
  const atRiskProfiles = RPs.filter((item) => item.atRisk);
  //  find risk level by looking for highest severity in atRiskProfiles
  const highestRisk = Math.max(...atRiskProfiles.map((item) => item.severity));

  return (
    <div>
      {RPs.length && atRiskProfiles.length ? (
        <div className="d-flex align-items-center">
          <RiskProfileCircle level={highestRisk} />
          <button
            className={"btn btn-link text-dark px-1 pb-2 cursor-style"}
            id={`riskProfilePopover-${data.row.id}`}
          >
            <GlyphPopup className="glyph-dark h-16px ml-1" aria-label="popup" />
          </button>
          <MyPopover
            id="risk-popover"
            target={`riskProfilePopover-${data.row.id}`}
            title="Risk Profile(s)"
            placement="bottom-end"
            text={
              <div>
                {atRiskProfiles
                  .sort((item, item2) =>
                    // sort on severity, highest first
                    item.severity > item2.severity ? -1 : 1
                  )
                  .map((item, id) => (
                    <div key={`${id}-${item.name}`} className="d-flex align-items-center">
                      <RiskProfileCircle
                        name={item.name}
                        level={item.severity}
                      />
                    </div>
                  ))}
              </div>
            }
          />
        </div>
      ) : (
        <span>--</span>
      )}
    </div>
  );
};
