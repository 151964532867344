import classnames from "classnames";
import React, { useState } from "react";
import { Popover, PopoverBody, PopoverHeader, Table } from "reactstrap";
import { ReactComponent as GlyphPopup } from "../images/glyph_popup.svg";
import { CloseButton } from "../_components";
import { UseMobileOrTabletMediaQuery } from "../_helpers/media-queries";
import PropTypes from "prop-types";

const GroupsPopover = (props) => {
  const { id, groups, blocked } = props;
  const [popoverOpen, setPopoverOpen] = useState(false);
  const is_touch_device = "ontouchstart" in window;
  const isMobileOrTabletWidth = UseMobileOrTabletMediaQuery();
  const toggle = () => setPopoverOpen(!popoverOpen);

  return (
    <div>
      {!groups.length ? (
        <span></span>
      ) : (
        <div className="">
          <button
            className="mr-1 btn focusable-text"
            color=""
            id={"Popover-" + id}
            aria-describedby="groups-popover"
          >
            <span className="groups-popover gray-glyph-possible">
              <GlyphPopup
                aria-label="popup"
                className={classnames({ "glyph-light-gray": blocked }, "mr-3")}
              />
              {groups.map((group, i) => {
                return groups.length === i + 1 ? group.name : group.name + ", ";
              })}
            </span>
          </button>
          <Popover
            trigger={is_touch_device ? "click" : "hover focus"}
            placement="bottom"
            isOpen={popoverOpen}
            target={"Popover-" + id}
            toggle={toggle}
          >
            {isMobileOrTabletWidth && (
              <PopoverHeader>
                Groups
                <CloseButton
                  toggle={() => setPopoverOpen(!popoverOpen)}
                  color="glyph-white"
                  popoverVersion={true}
                />
              </PopoverHeader>
            )}
            {/* <PopoverHeader>Group</PopoverHeader> */}
            <PopoverBody className="group-popover">
              <Table className="mb-0">
                <tbody>
                  {groups.map((group) => {
                    return (
                      <tr key={group.id}>
                        <td className="">{group.name}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </PopoverBody>
          </Popover>
        </div>
      )}
    </div>
  );
};

GroupsPopover.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  ),
  blocked: PropTypes.bool
};

export { GroupsPopover };
