import React from "react";
import { ReactComponent as BackIcon } from "../images/chevron_left.svg";
import PropTypes from "prop-types";

const BackButton = ({ onClick, backText }) => {
  return (
    <button
      className="btn btn-link text-secondary py-lg-3 pt-2 pb-1 pl-lg-0 pl-4"
      onClick={onClick}
    >
      <BackIcon className="mr-3 glyph-gray width-8" aria-label="back" />
      Back {backText}
    </button>
  );
};

BackButton.propTypes = {
  onClick: PropTypes.func,
  backText: PropTypes.string
};

export { BackButton };
