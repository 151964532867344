import React, { useEffect, useState } from "react";
import { patientService } from "../../../_services";
import { useAlertContext } from "../../../context/alertContext";
import PropTypes from "prop-types";

const BulkActions = ({
  multiSelect,
  deleteBulkAlerts,
  selectedRows,
  setMultiSelect,
  setBulkUpdate,
  setDeleteBulkAlerts,
  toggleAlertModal,
  fetchMedications,
  setSelectedRow
}) => {
  const { alertMethods } = useAlertContext();
  const [submitting, setSubmitting] = useState(false);
  const mounted = React.useRef(false);

  useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => {
      mounted.current = false;
    }; // ... and to false on unmount
  }, []);

  const bulkDeleteAlerts = () => {
    setSubmitting(true);
    let fields = selectedRows
      .filter((item) => item.alert?.alertScheduleType > -1)
      .map((row) => {
        return {
          medicationStrengthId: row.medicationStrength.id,
          alertScheduleType: -1,
          alertScheduleTime: null
        };
      });
    patientService
      .addAlert(fields)
      .then(() => {
        alertMethods.success(
          "<div class=''>Success!</div>Alerts have been deleted"
        );
        if (mounted.current) {
          setSubmitting(false);
          fetchMedications();
          setDeleteBulkAlerts(false);
          setMultiSelect(false);
        }
      })
      .catch(() => {
        alertMethods.error(
          "<div class=''>Something went wrong</div>Please try again"
        );
      });
  };
  return (
    <>
      {multiSelect && !deleteBulkAlerts && (
        <div className="d-md-flex mb-5 justify-content-center flex-md-row flex-column align-items-center">
          <button
            className="btn btn-primary mr-md-5 mr-0 mb-md-0 mb-4"
            disabled={!selectedRows.length}
            onClick={() => {
              toggleAlertModal();
              setBulkUpdate(true);
              setSelectedRow({});
            }}
          >
            Confirm Selection
          </button>
          <button
            className="btn btn-outline-primary"
            onClick={() => {
              setMultiSelect(false);
              setSelectedRow({});
            }}
          >
            Cancel Bulk Update
          </button>
        </div>
      )}
      {deleteBulkAlerts && (
        <div className="justify-content-center mb-5 d-flex flex-md-row flex-column align-items-center">
          <button
            className="btn btn-danger mr-md-5 mr-0 mb-md-0 mb-4 "
            disabled={!selectedRows.length || submitting}
            onClick={bulkDeleteAlerts}
          >
            {submitting && (
              <span className="spinner-border spinner-border-sm mr-1"></span>
            )}
            Delete Selected Alerts
          </button>
          <button
            className="btn btn-outline-danger"
            onClick={() => {
              setDeleteBulkAlerts(false);
              setMultiSelect(false);
            }}
          >
            Cancel
          </button>
        </div>
      )}
    </>
  );
};

BulkActions.propTypes = {
  multiSelect: PropTypes.bool,
  deleteBulkAlerts: PropTypes.bool,
  selectedRows: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  setMultiSelect: PropTypes.func,
  setBulkUpdate: PropTypes.func,
  setDeleteBulkAlerts: PropTypes.func,
  toggleAlertModal: PropTypes.func,
  fetchMedications: PropTypes.func,
  setSelectedRow: PropTypes.func
};

export { BulkActions };
