import React from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useAuthContext } from "../context/auth0Context";
import { ReactComponent as Exclamation } from "../images/circle-exclaimation.svg";
import { AuthenticationCheck } from "./AuthenticationCheck";
import { year } from "../_helpers";

const AccessDenied = (props) => {
  const { shouldUseAuth0 } = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const isMpage = state?.isMpage;
  const goBack = () => {
    navigate(-1);
  };

  return (
    <div
      id="access-denied"
      className="col-6 offset-3 col-lg-4 offset-lg-4 d-flex flex-column justify-content-between"
    >
      {" "}
      <div className="d-flex">
        <Exclamation
          className="mr-2 glyph-gray h-2rem w-4-5rem"
          aria-label="exclamation"
        />
        <div>
          <h4>Access Denied/Forbidden</h4>
          <h6 className="mb-4">Error 403: Forbidden</h6>
          <p>
            You do not have the permission to access this page. For more details
            contact your system administrator.
          </p>
          {shouldUseAuth0 ? (
            <AuthenticationCheck />
          ) : (
            !isMpage && (
              <button className="btn btn-link pl-0" onClick={goBack}>
                Back to Home
              </button>
            )
          )}
        </div>
      </div>
      <div className="text-secondary text-center">
        <Link to="/support" className="text-primary">
          Contact Support
        </Link>
        <div className="pt-4">© {year} EveryDose. All rights reserved. </div>
      </div>
    </div>
  );
};

export { AccessDenied };
