import React, { useEffect, useState } from "react";
import { NavLink } from "reactstrap";
import { ReactComponent as ChevronRight } from "../images/chevron_right.svg";
import classnames from "classnames";
import PropTypes from "prop-types";

const Carousel = ({
  items,
  handleTabChange,
  activeTab,
  toggleTab,
  numberToDisplay
}) => {
  const [visibleTabs, setVisibleTabs] = useState(
    items.slice(0, numberToDisplay).map((item, idx) => idx)
  );

  useEffect(() => {
    const updateTabNumber = () => {
      setVisibleTabs(items.slice(0, numberToDisplay).map((item, idx) => idx));
    };
    window.addEventListener("resize", updateTabNumber);
    return () => window.removeEventListener("resize", updateTabNumber);
  }, [items, numberToDisplay]);

  const next = () => {
    let nextNumber = visibleTabs[numberToDisplay - 1] + 1;
    if (nextNumber >= items.length) nextNumber = 0;
    setVisibleTabs((state) => state.slice(1).concat(nextNumber));
  };

  return (
    <div className="d-flex w-100 align-items-center" data-testid="carousel">
      {visibleTabs.map((tab, i) => {
        return (
          <NavLink
            key={tab}
            tabIndex="0"
            className={classnames(
              {
                active: activeTab === tab
              },
              { "ml-4": i === 0 },
              "table-header"
            )}
            // onKeyDown={(e) => {
            //   if (e.key === "Enter") {
            //     if (handleTabChange) handleTabChange(items[tab].id, tab);
            //     else toggleTab(tab);
            //   }
            // }}
            onClick={() => {
              if (handleTabChange) handleTabChange(items[tab].id, tab);
              else toggleTab(tab);
            }}
          >
            {items[tab]?.name || items[tab]?.text}
          </NavLink>
        );
      })}
      {items.length > 3 && (
        <button
          className="btn btn-link ml-auto mr-4"
          onClick={next}
          data-testid="go-right"
        >
          <ChevronRight aria-label="right" className="glyph-dark" />
        </button>
      )}
    </div>
  );
};

Carousel.propTypes = {
  handleTabChange: PropTypes.func,
  activeTab: PropTypes.number,
  toggleTab: PropTypes.func,
  numberToDisplay: PropTypes.number,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      text: PropTypes.string
    })
  )
};

export { Carousel };
