import { fetchWrapper } from "../_helpers/index";

export const supportService = {
  support,
  beforeLogin
};

function support(email, subject, description, tag) {
  return fetchWrapper.post(`/api/support`, {
    email,
    subject,
    description,
    tag
  });
}

function beforeLogin(email, subject, description, tag) {
  return fetchWrapper.post(`/api/support/beforelogin`, {
    email,
    subject,
    description,
    tag
  });
}
