import classnames from "classnames";
import React, { useEffect } from "react";
import { Card, Nav, NavItem, NavLink } from "reactstrap";
import { isIE, toTitleCase } from "../_helpers";
import { Loader } from "./Loader";
import PropTypes from "prop-types";

const NO_LEAFLETS_AVAILABLE_TEXT = {
  0: "No leaflets available",
  1: "No hay folletos disponibles"
};

const END_OF_REPORT_TEXT = {
  0: "**END OF REPORT**",
  1: "**FIN DEL INFORME**"
};

const LeafletComponent = ({
  leafletTab,
  toggle,
  submitting,
  componentRef,
  leaflets,
  loading,
  drugImageVersion
}) => {
  const isFirefox = navigator.userAgent.toLowerCase().includes("firefox");
  useEffect(() => {
    let leafletTitles = document.getElementsByClassName("title");
    for (let item of leafletTitles) {
      item.children[0].textContent = toTitleCase(item.children[0].textContent);
    }
  }, []);
  return (
    <div
      className={classnames(
        { "px-4": drugImageVersion },
        { "pt-5": !drugImageVersion }
      )}
    >
      <Nav tabs className="border-bottom border-dark">
        <NavItem>
          <NavLink
            className={classnames(
              {
                active: leafletTab === 0
              },
              "tab"
            )}
            onClick={() => {
              toggle(0);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") toggle(0);
            }}
            tabIndex={0}
          >
            {submitting && leafletTab === 0 && (
              <span className="spinner-border spinner-border-sm mr-1"></span>
            )}
            English
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames(
              {
                active: leafletTab === 1
              },
              "tab"
            )}
            onClick={() => {
              toggle(1);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") toggle(1);
            }}
            tabIndex={0}
          >
            {submitting && leafletTab === 1 && (
              <span className="spinner-border spinner-border-sm mr-1"></span>
            )}
            Spanish
          </NavLink>
        </NavItem>
      </Nav>

      <Card
        className="bg-white border-0"
        innerRef={componentRef}
        id="print-leaflets"
      >
        {!loading ? (
          <>
            {leaflets.length ? (
              <>
                {leaflets.map((med, idx) => (
                  <React.Fragment key={`${idx}-${med?.name}`}>
                    {(isIE || isFirefox) && idx ? (
                      <div className="page-break"></div>
                    ) : (
                      <></>
                    )}
                    <div
                      className={classnames(
                        { "pt-5": !drugImageVersion },
                        { "force-page-break": !isIE && !isFirefox },
                        "d-flex flex-column"
                      )}
                      key={med.name}
                    >
                      {!drugImageVersion && (
                        <div className="ml-auto text-secondary text-right mb-neg-3rem">
                          {leafletTab === 0 ? (
                            <>
                              Leaflet {idx + 1} of {leaflets.length}
                            </>
                          ) : (
                            <>
                              Folleto {idx + 1} de {leaflets.length}
                            </>
                          )}
                        </div>
                      )}
                      <div
                        className="primary-underline pt-5 w-100"
                        dangerouslySetInnerHTML={{
                          __html: med.leaflet
                        }}
                      />
                    </div>
                  </React.Fragment>
                ))}
              </>
            ) : (
              <div className="d-flex justify-content-center py-4">
                {NO_LEAFLETS_AVAILABLE_TEXT[leafletTab]}
              </div>
            )}
          </>
        ) : (
          <Loader />
        )}
      </Card>
      {!loading ? (
        <div className="d-flex justify-content-center py-5">
          {END_OF_REPORT_TEXT[leafletTab]}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

LeafletComponent.propTypes = {
  leafletTab: PropTypes.number,
  toggle: PropTypes.func,
  submitting: PropTypes.bool,
  componentRef: PropTypes.object,
  leaflets: PropTypes.array,
  loading: PropTypes.bool,
  drugImageVersion: PropTypes.bool
};

export { LeafletComponent };
