import { Field } from "formik";
import React from "react";

export const Cycle = () => {
  return (
    <>
      <h6>Cycle Details</h6>

      <div className="bg-gray border border-graydark1 rounded text-nowrap overflow-hidden">
        <div className="d-flex align-items-baseline p-3">
        <span className="text-danger">*&nbsp;</span>
          <label htmlFor="daysOn">Days On:</label>
          <div className="mx-2">
            <Field
              name="daysOn"
              id="daysOn"
              type="number"
              min={1}
              max={100}
              step={1}
              placeholder="__"
              className="form-control-no-border extra-small bg-gray"
            ></Field>
          </div>

          <span className="text-danger">*&nbsp;</span>
          <label htmlFor="daysOff">Days Off:</label>
          <div className="mx-2">
            <Field
              name="daysOff"
              id="daysOff"
              type="number"
              min={1}
              max={100}
              step={1}
              placeholder="__"
              className="form-control-no-border extra-small bg-gray"
            ></Field>
          </div>
          <label htmlFor="totalNumberOfCycles">End After:</label>
          <div className="mx-2">
            <Field
              name="totalNumberOfCycles"
              id="totalNumberOfCycles"
              type="number"
              min={1}
              max={100}
              step={1}
              placeholder="__"
              className="form-control-no-border extra-small bg-gray"
            ></Field>
            cycles
          </div>
        </div>
      </div>
    </>
  );
};
