import PropTypes from "prop-types";
import React, { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAlertContext, AlertType } from "../context/alertContext";
import { ReactComponent as Exclamation } from "../images/glyph-exclamation.svg";
import classnames from "classnames";

const propTypes = {
  id: PropTypes.string,
  fade: PropTypes.bool,
};

const defaultProps = {
  id: "default-alert",
  fade: true,
};

function Alert({ id, fade }) {
  const { alertMethods, alerts } = useAlertContext();
  const { clear } = alertMethods;
  const location = useLocation();
  const mounted = React.useRef(false);
  const prevPathname = React.useRef(location.pathname);
  const timeoutRef = React.useRef(null);

  React.useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => {
      mounted.current = false;
    }; // ... and to false on unmount
  }, []);

  const removeAlert = useCallback(
    (alert) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
      // fade out alert
      clear(alert.id);
    },
    [clear]
  );

  useEffect(() => {
    let alert;
    if (alerts && alerts.length > 0) {
      alert = alerts[alerts.length - 1];
    }

    if (alert && mounted.current) {
      // clear alerts when an empty alert is received
      if (!alert.message) {
        // filter out alerts without 'keepAfterRouteChange' flag
        const filteredAlerts = alerts.filter((x) => x.keepAfterRouteChange);
        // remove 'keepAfterRouteChange' flag on the rest
        filteredAlerts.forEach((x) => {
          removeAlert(x);
        });

        // });
      } else if (alert.autoClose) {
        timeoutRef.current = setTimeout(() => {
          removeAlert(alert);
        }, 10000);
        // auto close alert if required
      }
    }

    // clear alerts on location change
    const historyUnlisten = () => {
      // don't clear if pathname has trailing slash because this will be auto redirected again
      if (location.pathname?.endsWith("/")) return;
      if (prevPathname !== location.pathname) {
        const filteredAlerts = alerts.filter((x) => !x.keepAfterRouteChange);
        // remove 'keepAfterRouteChange' flag on the rest
        filteredAlerts.forEach((x) => {
          removeAlert(x);
        });
      }
    };

    // clean up function that runs when the component unmounts
    return () => {
      // unsubscribe & unlisten to avoid memory leaks
      // subscription.unsubscribe();
      historyUnlisten();
    };
  }, [alerts, location.pathname, removeAlert]);

  function cssClasses(alert) {
    if (!alert) return;
    const classes = ["alert", "alert-dismissable"];

    const alertTypeClass = {
      [AlertType.Success]: "alert alert-success",
      [AlertType.Error]: "alert alert-danger",
      [AlertType.Info]: "alert alert-info",
      [AlertType.Warning]: "alert alert-warning",
    };

    classes.push(alertTypeClass[alert.type]);

    if (alert.fade) {
      classes.push("fade");
    }
    return classes.join(" ");
  }

  function cssClassesGlyph(alert) {
    if (!alert) return;
    const classes = ["mr-3"];

    const alertTypeClass = {
      [AlertType.Success]: "glyph-success",
      [AlertType.Error]: "glyph-danger",
      [AlertType.Info]: "glyph-info",
      [AlertType.Warning]: "glyph-warning",
    };
    classes.push(alertTypeClass[alert.type]);
    return classes.join(" ");
  }

  if (!alerts.length) return null;
  return (
    <div className="w-100">
      <div className="mb-3">
        {alerts.map((alert, index) => (
          <div
            key={`${alert.id} ${alert.message} ${index}`}
            className={cssClasses(alert)}
          >
            <Exclamation
              aria-label="exclamation"
              className={classnames(cssClassesGlyph(alert), "h22-w22-style")}
            />
            <span
              className="mx-3"
              dangerouslySetInnerHTML={{ __html: alert.message }}
            ></span>
          </div>
        ))}
      </div>
    </div>
  );
}

Alert.propTypes = propTypes;
Alert.defaultProps = defaultProps;
export { Alert };
