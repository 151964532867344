import { fetchWrapper } from "../_helpers/index";
export const patientService = {
  getPatient,
  favorite,
  unfavorite,
  getMedications,
  getAdhrence,
  getCalendar,
  getNotEhrMediactions,
  getClinicalInteractions,
  getSuggestedMeds,
  resendSuggestedMed,
  editMedication,
  addMedication,
  addAlert,
  overrideRiskProfile,
  resetRiskProfile,
  getTasks,
  createTask,
  getOutreach,
  createOutreach,
  editOutreach,
  deleteOutreach,
  bulkInvite,
  //getStreaks,
  getMedicationsEHRSync,
  getHealthTrackers,
  getHealthTracker,
  createMedicationReport,
  createOutreachReport,
  createTaskReport,
  createHealthTrackerReport,
  createAdherenceReport,
  deletePatient,
};

const baseUrl = "/api/patients";

function getPatient(patientId) {
  const url = `${baseUrl}/${patientId}`;
  return fetchWrapper.get(url);
}

function bulkInvite(fields) {
  return fetchWrapper.post(`${baseUrl}/invitations/create/bulk`, fields);
}
function getAdhrence(patientId, startDate, endDate) {
  let url = `${baseUrl}/${patientId}/overall_adherence`;
  url = `${url}?startDate=${encodeURIComponent(
    startDate
  )}&endDate=${encodeURIComponent(endDate)}`;
  return fetchWrapper.get(url);
}

function getCalendar(patientId, startDate, endDate) {
  let url = `${baseUrl}/${patientId}/calendar`;
  url = `${url}?startDate=${encodeURIComponent(
    startDate
  )}&endDate=${encodeURIComponent(endDate)}`;

  return fetchWrapper.get(url);
}

function favorite(id) {
  return fetchWrapper.post(`${baseUrl}/${id}/favorite`);
}

function unfavorite(id) {
  return fetchWrapper.post(`${baseUrl}/${id}/unfavorite`);
}

function getMedications(patientId) {
  const url = `${baseUrl}/${patientId}/medications`;
  return fetchWrapper.get(url);
}

function addMedication(patientId, med) {
  const url = `${baseUrl}/${patientId}/medications`;
  return fetchWrapper.post(url, med);
}

function editMedication(patientId, med) {
  const url = `${baseUrl}/${patientId}/medications`;
  return fetchWrapper.put(url, med);
}

function getNotEhrMediactions(patientId) {
  const url = `${baseUrl}/${patientId}/medications/not_in_ehr`;
  return fetchWrapper.get(url);
}

function getClinicalInteractions(patientId) {
  const url = `${baseUrl}/${patientId}/medications/clinical_interactions`;
  return fetchWrapper.get(url);
}

function getSuggestedMeds(
  patientId,
  pageSize = 10,
  pageNum = 1,
  changeStatus = ""
) {
  const url = `${baseUrl}/${patientId}/medications/suggested?pageSize=${pageSize}&pageNum=${pageNum}&changeStatus=${changeStatus}`;
  return fetchWrapper.get(url);
}

function resendSuggestedMed(patientId, medId) {
  const url = `${baseUrl}/${patientId}/medications/suggested/resend/${medId}`;
  return fetchWrapper.post(url);
}

function addAlert(fields) {
  const url = `${baseUrl}/medications/alerts`;
  return fetchWrapper.post(url, fields);
}

function overrideRiskProfile(patientId, riskProfileId, fields) {
  const url = `${baseUrl}/${patientId}/riskprofile/${riskProfileId}/override`;
  return fetchWrapper.put(url, fields);
}

function resetRiskProfile(patientId, riskProfileId) {
  const url = `${baseUrl}/${patientId}/riskprofile/${riskProfileId}/reset`;
  return fetchWrapper.put(url);
}

function getTasks(patientId, filters, sortStr, pageSize = 50, page = 1) {
  if (!sortStr) sortStr = "dueDate.DESC";
  let url = `${baseUrl}/${patientId}/tasks?pageSize=${pageSize}&page=${page}&SortBy=${sortStr}`;
  if (filters) {
    for (const key in filters) {
      let urlFilters = "";
      filters[key].forEach((item) => (urlFilters += `&${key}=${item}`));
      url = `${url}${urlFilters}`;
    }
  }
  return fetchWrapper.get(url);
}

function createTask(patientId, fields) {
  const url = `${baseUrl}/${patientId}/tasks`;
  return fetchWrapper.post(url, fields);
}

function getOutreach(patientId, sortStr, pageSize = 50, page = 1) {
  if (!sortStr) sortStr = "dueDate.DESC";
  let url = `${baseUrl}/${patientId}/outreach?pageSize=${pageSize}&page=${page}&SortBy=${sortStr}`;

  return fetchWrapper.get(url);
}

function createOutreach(patientId, fields) {
  const url = `${baseUrl}/${patientId}/outreach`;
  return fetchWrapper.post(url, fields);
}

function editOutreach(patientId, outreachId, fields) {
  const url = `${baseUrl}/${patientId}/outreach/${outreachId}`;
  return fetchWrapper.put(url, fields);
}

function deleteOutreach(patientId, outreachId) {
  const url = `${baseUrl}/${patientId}/outreach/${outreachId}`;
  return fetchWrapper.delete(url);
}

function getMedicationsEHRSync(patientId, integrationId) {
  const url = `${baseUrl}/${patientId}/${integrationId}/sync/medications`;
  return fetchWrapper.get(url);
}

function getHealthTrackers(id, startDate, endDate, pageSize) {
  let url = `${baseUrl}/${id}/healthtrackers?`;
  if (startDate && endDate)
    url = `${url}startDate=${encodeURIComponent(
      startDate
    )}&endDate=${encodeURIComponent(endDate)}`;
  if (pageSize) url = `${url}pageSize=${pageSize}`;
  return fetchWrapper.get(url);
}

function getHealthTracker(
  patientId,
  healthTrackerId,
  startDate,
  endDate,
  pageSize = 500
) {
  let url = `${baseUrl}/${patientId}/healthtrackers/${healthTrackerId}?pageSize=${pageSize}`;
  if (startDate && endDate)
    url = `${url}&startDate=${encodeURIComponent(
      startDate
    )}&endDate=${encodeURIComponent(endDate)}`;

  return fetchWrapper.get(url);
}

function createMedicationReport(id, reportType, reportDocumentType, fields) {
  const url = `${baseUrl}/${id}/medication/report?reportType=${reportType}&reportDocumentType=${reportDocumentType}`;
  return fetchWrapper.post(url, fields);
}

function createOutreachReport(id, reportType, reportDocumentType, fields) {
  const url = `${baseUrl}/${id}/outreach/report?reportType=${reportType}&reportDocumentType=${reportDocumentType}`;
  return fetchWrapper.post(url, fields);
}

function createTaskReport(id, reportType, reportDocumentType, fields) {
  const url = `${baseUrl}/${id}/tasks/report?reportType=${reportType}&reportDocumentType=${reportDocumentType}`;
  return fetchWrapper.post(url, fields);
}

function createHealthTrackerReport(id, reportType, reportDocumentType, fields) {
  const url = `${baseUrl}/${id}/healthtrackers/report?reportType=${reportType}&reportDocumentType=${reportDocumentType}`;
  return fetchWrapper.post(url, fields);
}

function createAdherenceReport(id, reportType, reportDocumentType, fields) {
  const url = `${baseUrl}/${id}/medication/adherence/report?reportType=${reportType}&reportDocumentType=${reportDocumentType}`;
  return fetchWrapper.post(url, fields);
}

function deletePatient(id, deleteAll = false) {
  return fetchWrapper.delete(`${baseUrl}/${id}?deleteAll=${deleteAll}`);
}
