import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { integrations as ints } from "../_helpers";
import { patientService } from "../_services";
import { useAlertContext } from "../context/alertContext";
import { PatientContext } from "../context/patientContext";
import { ReactComponent as Sync } from "../images/sync_24dp_8C8C8C_FILL0_wght400_GRAD0_opsz24.svg";
import { CloseButton } from "./CloseButton";
import { MySelect } from "./MySelect";

const EHRSync = ({ setDataChange, dataChange }) => {
  const { state } = useContext(PatientContext);
  const mounted = React.useRef(false);
  useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => {
      mounted.current = false;
    }; // ... and to false on unmount
  }, []);

  const { alertMethods } = useAlertContext();
  const { patient } = state;
  const { integrations, patientId } = patient;

  const [loadingSyncEHR, setLoadingSyncEHR] = useState(false);
  const [modal, setModal] = useState(false);
  const [integrationOptions, setIntegrationOptions] = useState([]);
  const [selectedIntegration, setSelectedIntegration] = useState("");

  useEffect(() => {
    if (integrations?.length) {
      // for each value in integrations, find it in ints and push to integrationOptions
      let options = [];
      integrations.forEach((integration) => {
        let option = ints.find((int) => int.value === integration);
        if (option) options.push(option);
      });
      setIntegrationOptions(options);
      if (options.length === 1) setSelectedIntegration(options[0]);
      else setSelectedIntegration("");
    }
  }, [integrations]);

  const toggleModal = () => setModal(!modal);

  const handleSuccess = () => {
    setLoadingSyncEHR(false);

    toggleModal();
    alertMethods.success("EHR Sync initiated successfully");
    setTimeout(() => {
      if (mounted.current) {
        setDataChange(!dataChange);
      }
    }, 10000);
  };

  const handleFailure = () => {
    setLoadingSyncEHR(false);
    alertMethods.error(
      "Something went wrong with the EHR Sync. Please try again."
    );
  };

  const ehrSync = () => {
    setLoadingSyncEHR(true);
    // if (!user?.isSmartSession) {
    patientService
      .getMedicationsEHRSync(patientId, selectedIntegration.value)
      .then((res) => {
        handleSuccess();
      })
      .catch((err) => {
        handleFailure();
      });
    // } else {
    //   patientInviteService
    //     .getSmartMedications(patientId)
    //     .then((res) => {
    //       handleSuccess();
    //     })
    //     .catch((err) => {
    //       handleFailure();
    //     });
    // }
  };

  return (
    <div>
      {integrations?.length ? (
        <div className="ehr-sync">
          <button
            onClick={toggleModal}
            className="btn btn-small bg-white ml-3 btn-outline-graydark1 text-dark d-flex align-items-center"
          >
            <Sync className="mr-2" />
            EHR Sync
          </button>
          <Modal isOpen={modal} toggle={toggleModal}>
            <ModalHeader
              toggle={toggleModal}
              close={<CloseButton toggle={toggleModal} />}
            >
              EHR Sync
            </ModalHeader>
            <ModalBody>
              <h4>
                <Sync className="mr-2 h30-w30-style" /> Resync this patient's
                meds from the EHR?
              </h4>
              <p>
                Resyncing this patient's medications will pull their latest
                medication list from the EHR, compare it with the patient's
                mobile app med list, and suggest any changes to the patient for
                review. Once complete, you can review the changes in the
                Suggested Med Changes section of the Medications tab.
              </p>
              <MySelect
                options={integrationOptions}
                defaultValue={
                  integrations.length === 1 ? integrationOptions[0] : ""
                }
                onChange={(e, val) => setSelectedIntegration(val)}
                name="ehr"
                placeholder="Select EHR"
                label="EHR Integration"
              />
              <div className="d-flex flex-md-row flex-column mt-3">
                <button
                  onClick={ehrSync}
                  disabled={loadingSyncEHR || !selectedIntegration}
                  className="btn btn-primary mr-md-4 mr-0 mb-md-0 mb-3"
                >
                  {loadingSyncEHR && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  Sync
                </button>
                <button
                  onClick={toggleModal}
                  className="btn btn-outline-secondary"
                >
                  Cancel
                </button>
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
EHRSync.propTypes = {
  setDataChange: PropTypes.func,
  dataChange: PropTypes.bool,
};

export { EHRSync };
