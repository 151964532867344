import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useAlertContext } from "../context/alertContext";

const PrintPDF = ({ pdfUrl, onClick }) => {
  const { alertMethods } = useAlertContext();
  const iframeRef = useRef(null);

  const handlePrint = () => {
    onClick();
    try {
      window.open(pdfUrl, "_blank");
    } catch (e) {
      alertMethods.error(
        "Popup blocked by your browser. If the issue persists, please refresh the page and try again"
      );
    }
  };

  return (
    <div>
      <button
        onClick={handlePrint}
        className="btn btn-outline-primary min-width-9-5rem"
      >
        Print
      </button>
      <iframe
        id="iframe-savings-card"
        src={pdfUrl}
        ref={iframeRef}
        title="pdfFrame"
      ></iframe>
    </div>
  );
};

PrintPDF.propTypes = {
  pdfUrl: PropTypes.string,
  onClick: PropTypes.func
};

export { PrintPDF };
