import { fetchWrapper } from "../_helpers/index";
export const notificationService = {
  getNotifications,
  deleteNotification,
  deleteNotifications,
  markAllAsRead,
  markAsRead,
  markAllAsUnread,
  markAsUnread,
  getIsNew
};

const baseUrl = "/api/notifications";

function getNotifications(patientId = "", pageSize = 20, pageIndex = 1) {
  return fetchWrapper.get(
    `${baseUrl}?patientId=${patientId}&pageSize=${pageSize}&page=${pageIndex}`
  );
}

function deleteNotification(id) {
  return fetchWrapper.delete(`${baseUrl}/clear/${id}`);
}

function deleteNotifications(patientId = "") {
  return fetchWrapper.delete(`${baseUrl}/clear?patientId=${patientId}`);
}

function markAllAsRead(patientId = "") {
  return fetchWrapper.post(
    `${baseUrl}/mark_all_as_read?patientId=${patientId}`
  );
}

function markAsRead(id, patientId = "") {
  return fetchWrapper.post(
    `${baseUrl}/mark_as_read?notificationId=${id}&patientId=${patientId}`
  );
}

function markAllAsUnread(patientId = "") {
  return fetchWrapper.post(
    `${baseUrl}/mark_all_as_unread?patientId=${patientId}`
  );
}

function markAsUnread(id, patientId = "") {
  return fetchWrapper.post(
    `${baseUrl}/mark_as_unread?notificationId=${id}&patientId=${patientId}`
  );
}

function getIsNew(patientId = "") {
  return fetchWrapper.get(
    `${baseUrl}/is_new_notification_present?patientId=${patientId}`
  );
}
