import { useMediaQuery } from "react-responsive";
import PropTypes from "prop-types";

export const mobile = { maxWidth: 767 };
export const mobileOrSmallTablet = { maxWidth: 991 };
export const mobileOrTablet = { minWidth: 0, maxWidth: 1279 };
export const tablet = { minWidth: 768, maxWidth: 1279 };
export const is1280 = { minWidth: 0, maxWidth: 1439 };
export const is1480 = { minWidth: 0, maxWidth: 1480 };
export const is1440 = { minWidth: 1440, maxWidth: 1599 };
export const is1599 = { minWidth: 0, maxWidth: 1599 };
export const is1600 = { minWidth: 1600 };
export const is1650 = { minWidth: 1600, maxWidth: 1699 };
export const is1750 = { maxWidth: 1750 };
export const desktop = { minWidth: 1280 };
export const se = { maxWidth: 374 };

export const UseMobileMediaQuery = () => {
  return useMediaQuery(mobile);
};

export const UseTabletMediaQuery = () => useMediaQuery(tablet);
export const UseMobileOrTabletMediaQuery = () => useMediaQuery(mobileOrTablet);
export const UseMobileOrSmallTabletMediaQuery = () =>
  useMediaQuery(mobileOrSmallTablet);
export const Use1280MediaQuery = () => useMediaQuery(is1280);
export const Use1480MediaQuery = () => useMediaQuery(is1480);
export const Use1440MediaQuery = () => useMediaQuery(is1440);
export const Use1599MediaQuery = () => useMediaQuery(is1599);
export const Use1600MediaQuery = () => useMediaQuery(is1600);
export const Use1650MediaQuery = () => useMediaQuery(is1650);
export const Use1750MediaQuery = () => useMediaQuery(is1750);
export const UseDesktopMediaQuery = () => useMediaQuery(desktop);
export const UseSEMediaQuery = () => useMediaQuery(se);

export const Mobile = ({ children }) => {
  const isMobile = UseMobileMediaQuery();
  return isMobile ? children : null;
};
Mobile.propTypes = {
  children: PropTypes.node,
};

export const Tablet = ({ children }) => {
  const isTablet = UseTabletMediaQuery();
  return isTablet ? children : null;
};
Tablet.propTypes = {
  children: PropTypes.node,
};

export const MobileOrTablet = ({ children }) => {
  const isMobileOrTablet = UseMobileOrTabletMediaQuery();
  return isMobileOrTablet ? children : null;
};
MobileOrTablet.propTypes = {
  children: PropTypes.node,
};

export const MobileOrSmallTablet = ({ children }) => {
  const isMobileOrSmallTablet = UseMobileOrSmallTabletMediaQuery();
  return isMobileOrSmallTablet ? children : null;
};
MobileOrSmallTablet.propTypes = {
  children: PropTypes.node,
};

export const Medium = ({ children }) => {
  const is1280 = Use1280MediaQuery();
  return is1280 ? children : null;
};
Medium.propTypes = {
  children: PropTypes.node,
};

export const MedLarge = ({ children }) => {
  const is1440 = Use1440MediaQuery();
  return is1440 ? children : null;
};
MedLarge.propTypes = {
  children: PropTypes.node,
};

export const MedXLarge = ({ children }) => {
  const is1599 = Use1599MediaQuery();
  return is1599 ? children : null;
};

MedXLarge.propTypes = {
  children: PropTypes.node,
};

export const XMedLarge = ({ children }) => {
  const is1650 = Use1650MediaQuery();
  return is1650 ? children : null;
};
XMedLarge.propTypes = {
  children: PropTypes.node,
};

export const Large = ({ children }) => {
  const is1750 = Use1750MediaQuery();
  return is1750 ? children : null;
};
Large.propTypes = {
  children: PropTypes.node,
};

export const XLarge = ({ children }) => {
  const is1600 = Use1600MediaQuery();
  return is1600 ? children : null;
};
XLarge.propTypes = {
  children: PropTypes.node,
};

export const Desktop = ({ children }) => {
  const isDesktop = UseDesktopMediaQuery();
  return isDesktop ? children : null;
};
Desktop.propTypes = {
  children: PropTypes.node,
};

export const SE = ({ children }) => {
  const isSE = UseSEMediaQuery();
  return isSE ? children : null;
};
SE.propTypes = {
  children: PropTypes.node,
};
