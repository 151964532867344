import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Popover,
  PopoverBody,
  PopoverHeader,
  Table
} from "reactstrap";
import { OutsideAlerter } from "./index";
import { ReactComponent as GlyphMedsAdd } from "../../../images/glyph_plus_med.svg";
import { ReactComponent as Arrow } from "../../../images/glyph_right_extended_short.svg";
import { CloseButton } from "../../../_components";
import { UseMobileMediaQuery } from "../../../_helpers/media-queries";
import PropTypes from "prop-types";
import dayjs from "dayjs";

const MedEditTable = ({ rows }) => {
  const sortedRows = rows.sort((a, b) => {
    return new Date(a.date) - new Date(b.date);
  });
  return (
    <Table borderless>
      <tbody>
        {sortedRows.map((row, i) => {
          return (
            <tr key={row.date + row.medicationId}>
              <td className="w-50">{dayjs(row.date).format("h:mm A")}</td>
              <td className="">
                {row.medicationName}
                {row.data?.strength && `, ${row.data.strength}`}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
MedEditTable.propTypes = {
  rows: PropTypes.array
};

function findUniqueTimes(arr) {
  const uniqueTimes = new Set();

  arr?.forEach((dateTime) => {
    const time = dayjs(dateTime).format("h:mm A");
    uniqueTimes.add(time);
  });

  return Array.from(uniqueTimes);
}

const ModifyTable = ({ rows }) => {
  return (
    <Table borderless>
      <thead className="border-bottom">
        <tr>
          <th className="bg-white px-0">{rows[0].title}</th>
          <th className="bg-white"></th>
          {rows.length === 1 && (
            <th className="text-secondary bg-white">
              {rows[0].eventTriggeredBy}
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, i) => {
          return (
            <tr key={`${row.date}-${row.medicationId}`}>
              <td className="pl-0 w-40">
                <div className="">{row.medicationName}</div>
                {row.eventType === 8 && (
                  <div className="text-secondary">
                    <div>{row.data.old.sigCode}</div>
                    {row.data.old.sigCode !== "PRN" && (
                      <>
                        {findUniqueTimes(row.data.old?.times).map((item) => (
                          <div key={item}>{item}</div>
                        ))}
                      </>
                    )}
                  </div>
                )}
                {row.eventType === 7 && (
                  <div className="text-secondary">{row.data.old} per dose</div>
                )}

                {row.eventType === 6 && (
                  <div className="text-secondary">
                    {row.data.old?.strength} {row.data.old?.form?.toLowerCase()}
                  </div>
                )}
              </td>
              <td className="w-10">
                <Arrow className="w12-h10-style" aria-label="arrow" />
              </td>
              <td className="">
                <div className="">{row.medicationName}</div>

                {row.eventType === 8 && (
                  <div className="text-secondary">
                    <div>{row.data.new.sigCode}</div>
                    {row.data.new.sigCode !== "PRN" && (
                      <>
                        {findUniqueTimes(row.data.new?.times).map((item) => {
                          return <div key={item}>{item}</div>;
                        })}
                      </>
                    )}
                  </div>
                )}
                {row.eventType === 7 && (
                  <div className="text-secondary">{row.data.new} per dose</div>
                )}

                {row.eventType === 6 && (
                  <div className="text-secondary">
                    {row.data.new?.strength} {row.data.new?.form?.toLowerCase()}
                  </div>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
ModifyTable.propTypes = {
  rows: PropTypes.array
};

const BodyInfo = ({ createdDate }) => {
  return (
    <>
      {createdDate && (
        <p className="text-secondary">
          <GlyphMedsAdd aria-label="meds changed" /> User account created at{" "}
          <span>{dayjs(createdDate).format("h:mm A")}</span>
        </p>
      )}

      {/* {info.extendedProps.history.length > 1 && (
        <p className="pb-2 border-bottom">
          {info.extendedProps.history.filter((ev) => ev.eventType).length} Meds
          Changed
        </p>
      )}
      {addedMeds.length > 0 && (
        <div className="">
          <p className="mb-1 border-bottom">
            {addedMeds.length === 1 && "Med "}
            Added
          </p>
          <MedEditTable rows={addedMeds} />
        </div>
      )}
      {removedMeds.length > 0 && (
        <div className="">
          <p className="mb-1 border-bottom">
            {removedMeds.length === 1 && "Med "}
            Removed
          </p>
          <MedEditTable rows={removedMeds} />
        </div>
      )}
      {pausedMeds.length > 0 && (
        <div className="">
          <p className="mb-1 border-bottom">
            {pausedMeds.length === 1 && "Med "}
            Paused
          </p>
          <MedEditTable rows={pausedMeds} />
        </div>
      )}

      {strengthMeds.length > 0 && <ModifyTable rows={strengthMeds} />}
      {frequencyMeds.length > 0 && <ModifyTable rows={frequencyMeds} />}
      {quantityMeds.length > 0 && <ModifyTable rows={quantityMeds} />} */}
    </>
  );
};
BodyInfo.propTypes = {
  createdDate: PropTypes.string
};

export const UserCreatedEvent = ({ adhState, adhDispatch }) => {
  const { eventInfo } = adhState;
  const info = eventInfo.event;
  const [popoverOpen, setPopoverOpen] = useState(true);
  // const [addedMeds, setAddedMeds] = useState([]);
  // const [removedMeds, setRemovedMeds] = useState([]);
  // const [pausedMeds, setPausedMeds] = useState([]);
  // const [strengthMeds, setStrengthMeds] = useState([]);
  // const [frequencyMeds, setFrequencyMeds] = useState([]);
  // const [quantityMeds, setQuantityMeds] = useState([]);
  const [createdDate, setCreatedDate] = useState(null);

  const isMobileWidth = UseMobileMediaQuery();
  const toggle = () => setPopoverOpen(!popoverOpen);

  React.useEffect(() => {
    info.extendedProps.history?.forEach((element) => {
      // if (element.eventTypeDescription === "MEDICATION_ADDED") {
      //   setAddedMeds((state) => [...state, element]);
      // } else if (element.eventTypeDescription === "MEDICATION_DELETED") {
      //   setRemovedMeds((state) => [...state, element]);
      // } else if (element.eventTypeDescription === "MEDICATION_PAUSED") {
      //   setPausedMeds((state) => [...state, element]);
      // } else if (
      //   element.eventTypeDescription === "MEDICATION_STRENGTH_CHANGED"
      // ) {
      //   setStrengthMeds((state) => [...state, element]);
      // } else if (
      //   element.eventTypeDescription === "MEDICATION_FREQUENCY_CHANGED"
      // ) {
      //   setFrequencyMeds((state) => [...state, element]);
      // } else if (
      //   element.eventTypeDescription === "MEDICATION_QUANTITY_CHANGED"
      // ) {
      //   setQuantityMeds((state) => [...state, element]);
      // } else
      if (element.eventTypeDescription === "USER_CREATED") {
        setCreatedDate(element.date);
      }
    });
  }, [info]);

  return (
    <>
      {isMobileWidth ? (
        <Modal
          isOpen={popoverOpen}
          toggle={toggle}
          className="center-modal med-event-modal"
        >
          <ModalHeader
            className="py-1 bg-light"
            toggle={toggle}
            close={<CloseButton toggle={toggle} color="" />}
          >
            <div className="mb-0 mt-2">
              {dayjs(info.start).format("dddd, MMMM D")}
            </div>
          </ModalHeader>
          <OutsideAlerter adhDispatch={adhDispatch} selectedEvent={info}>
            <ModalBody className="px-5">
              <BodyInfo
                // info={info}
                // addedMeds={addedMeds}
                // removedMeds={removedMeds}
                // pausedMeds={pausedMeds}
                // strengthMeds={strengthMeds}
                // quantityMeds={quantityMeds}
                // frequencyMeds={frequencyMeds}
                createdDate={createdDate}
              />
            </ModalBody>
          </OutsideAlerter>
        </Modal>
      ) : (
        <Popover
          trigger="click"
          placement="bottom"
          isOpen={popoverOpen}
          target={eventInfo.el}
          container={document.getElementById("calendar")}
          boundariesElement={document.getElementById("calendar")}
          toggle={toggle}
          className="width-275"
        >
          <PopoverHeader className="d-flex align-items-end">
            {dayjs(info.start).format("dddd, MMMM D")}

            <CloseButton toggle={toggle} color="glyph-white mb-0" />
          </PopoverHeader>
          <OutsideAlerter adhDispatch={adhDispatch}>
            <PopoverBody>
              <BodyInfo
                // info={info}
                // addedMeds={addedMeds}
                // removedMeds={removedMeds}
                // pausedMeds={pausedMeds}
                // strengthMeds={strengthMeds}
                // quantityMeds={quantityMeds}
                // frequencyMeds={frequencyMeds}
                createdDate={createdDate}
              />
            </PopoverBody>
          </OutsideAlerter>
        </Popover>
      )}
    </>
  );
};
UserCreatedEvent.propTypes = {
  adhState: PropTypes.object,
  adhDispatch: PropTypes.func
};
