import classnames from "classnames";
import React from "react";
import { Nav, NavItem, NavLink, Row } from "reactstrap";
import PropTypes from 'prop-types';

export const ClaimsNav = ({ activeTab, toggleTab }) => {
  return (
    <Row className="justify-content-center mb-5">
      <Nav pills className="bg-dark br-5px">
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => {
              toggleTab("2");
            }}
          >
            Claims
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              toggleTab("1");
            }}
          >
            Mobile App
          </NavLink>
        </NavItem>
      </Nav>
    </Row>
  );
};

ClaimsNav.propTypes = {
  activeTab: PropTypes.string,
  toggleTab: PropTypes.func
};
