import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import { inMemoryToken } from "../_services/account.service";

dayjs.extend(utc);
dayjs.extend(timezone);

let testError;
let baseUrl = process.env.REACT_APP_URL;

export const fetchWrapper = {
  get,
  post,
  put,
  delete: _delete
};

const sharedHeaders = {
  key: "Strict-Transport-Security",
  value: "max-age=65540 ; includeSubDomains",
  "X-Content-Type-Options": "nosniff",
  "X-Frame-Options": "DENY",
  timezone: dayjs.tz.guess()
};

function get(url) {
  const requestOptions = {
    method: "GET",
    headers: {
      pragma: "no-cache",
      ...authHeader(url),
      Cache: "no-cache",
      timezone: dayjs.tz.guess()
    }
  };
  return fetch(baseUrl + url, requestOptions).then(handleResponse);
}

function post(url, body) {
  let requestOptions;
  if (
    url === "/api/profile/profile_picture" ||
    url.startsWith("/api/admin/tenantpreference/image")
  ) {
    requestOptions = {
      method: "POST",
      headers: {
        Cache: "no-cache",
        Accept: "application/json",
        ...sharedHeaders,

        ...authHeader(url)
      },
      credentials: "include",
      body: body
    };
  } else
    requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...sharedHeaders,
        ...authHeader(url)
      },
      credentials: "include",
      body: JSON.stringify(body)
    };
  return fetch(baseUrl + url, requestOptions).then(handleResponse);
}

function put(url, body) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...sharedHeaders,
      ...authHeader(url)
    },
    body: JSON.stringify(body)
  };
  return fetch(baseUrl + url, requestOptions).then(handleResponse);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(url) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      ...authHeader(url),
      ...sharedHeaders
    }
  };
  return fetch(baseUrl + url, requestOptions).then(handleResponse);
}

// helper functions

function authHeader(url) {
  // return auth header with jwt if user is logged in and request is to the api url
  // const user = JSON.parse(sessionStorage.getItem("user"));
  const isLoggedIn =
    inMemoryToken?.accessToken || localStorage.getItem("smartToken");
  let token = inMemoryToken?.accessToken || localStorage.getItem("smartToken");
  const isApiUrl =
    (url.startsWith("/api") &&
      !url.startsWith("/api/account") &&
      !url.startsWith("/api/smart/validating")) ||
    url.startsWith("/api/account/logout");

  if (isLoggedIn && isApiUrl) {
    return { Authorization: `Bearer ${token}` };
  } else {
    return {};
  }
}

async function handleResponse(response) {
  const contentType = response.headers.get("content-type");
  if (
    contentType &&
    [
      "application/pdf",
      "application/octet-stream",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ].some((type) => contentType.includes(type))
  ) {
    return response.blob().then((blob) => {
      return blob;
    });
  } else if (contentType && contentType === "text/plain; charset=utf-8") {
    return response.text().then((text) => {
      return text;
    });
  } else
    return response.text().then(async (text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        // if ([401].includes(response.status)) {
        //   console.log("401 error");
        //   // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        // } else if ([403].includes(response.status)) {
        //   console.log("403 error");
        // } else
        if ([500].includes(response.status)) {
          testError = 500;
          setTimeout(() => {
            testError = false;
          }, 1000);
        }
        const error = data || response.statusText;
        return Promise.reject(error);
      }

      return data;
    });
}

export { testError };
