import React, { useEffect, useState, Suspense } from "react";
import { UseTabletMediaQuery } from "../_helpers";
import PropTypes from "prop-types";
import classnames from "classnames";
import { ChartWrapper } from "./ChartWrapper";

const LoadingAdhChart = () => {
  return (
    <div className="p-2">
      {[...Array(5)].map((e, i) => {
        if (i % 2 === 0) {
          return (
            <div className="ph-item" key={`ph-item-${i + 1}`}>
              <div className="ph-col-12">
                <div className="ph-row">
                  <div className="ph-col-real3 big"></div>
                  <div className="ph-col-3 empty"></div>
                  <div className="ph-col-8 big"></div>
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div className="ph-item" key={`ph-item-${i + 1}`}>
              <div className="ph-col-12">
                <div className="ph-row">
                  <div className="ph-col-2 big"></div>
                  <div className="ph-col-14 empty"></div>
                  <div className="ph-col-8 big"></div>
                </div>
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};
function CreateArrowAnnotation() {
  return <div className="text-secondary mt-3 font-size-normal">▼</div>;
}

function GradientStripLine() {
  return (
    <div className="gradient-style">
      <div className="d-flex justify-content-between text-secondary pt-3 small font-weight-bold">
        <span>0%</span>
        <span>100%</span>
      </div>
    </div>
  );
}

const AdherenceChart = ({ data, average }) => {
  const [chartData, setChartData] = useState([]);
  const [chartHeight, setChartHeight] = useState(0);

  useEffect(() => {
    const root = document.documentElement;

    if (average < 80) {
      // Set a CSS variable
      root.style.setProperty("--left-margin", average + "%");
    } else {
      root.style.setProperty("--left-margin", "80%");
    }
  }, [average]);
  const is768Width = UseTabletMediaQuery();

  function chartTemplate(args) {
    let className = "";
    if (args.point.colorValue > 95) {
      className = "ml-neg-42px";
    } else if (args.point.y < 6 && args.point.y >= 1) {
      className = "padding-0-0-0-30px";
    } else if (parseInt(args.point.y) > average) {
      className = "padding-0-0-0-42px";
    } else {
      className = "padding-0-45-0-5px";
    }

    return (
      <div id="templateWrap" className={className}>
        <div>{parseInt(args.point.y)}%</div>
      </div>
    );
  }
  const template = chartTemplate;
  const marker = {
    width: 10,
    height: 10,
    visible: true,
    // fill: "Red",
    dataLabel: {
      visible: true,

      template: template,
      position: "Middle",
      font: {
        size: "14px",
        fontFamily: "Effra",
        color: "#1f2f36"
      }
    }
  };

  // Ignore Sonar Cloud recommendations about this. This needs to be like this in order to show the text annotation properly.
  // With your change it was not showing the average.
  let arrowAnnotation = CreateArrowAnnotation;
  let gradient = GradientStripLine;

  const containsMoreThanOneObjectWithLabel = (array, label) => {
    let count = 0;
    for (const object of array) {
      if (object.name === label) {
        count++;
      }
    }
    return count > 1;
  };
  useEffect(() => {
    setChartHeight(parseInt(data.length * 70));
  }, [data]);

  useEffect(() => {
    setChartData([]);
    data
      .sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return 1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      })
      .forEach((element, idx) => {
        //Preparing text for chart - setting length and also adding asterisk for paused/deleted meds
        let text;
        if (is768Width && element.name.length > 12)
          text = element.name.substring(0, 12) + "...";
        else if (element.name.length > 22)
          text = element.name.substring(0, 22) + "...";
        else text = element.name;
        // if text is already a label in the chartData array, add element.strength
        if (
          containsMoreThanOneObjectWithLabel(data, text) &&
          element.strength
        ) {
          text += ", " + element.strength?.substring(0, 8);
        }

        if (!element.isActive) {
          text += "*";
        }

        const adhChartValue = Math.round(
          (element.medicationsTaken /
            (element.medicationsTaken +
              element.medicationsSkipped +
              element.medicationsMissed)) *
            100
        );
        //Updating state array with the averages and index for each med
        setChartData((state) => [
          ...state,
          { value: adhChartValue || 0.001, label: text }
        ]);
      });
  }, [data, is768Width]);

  const seriesProps = {
    dataSource: [...chartData, { value: undefined, label: "-" }],
    xName: "label",
    type: "Scatter",
    yName: "value",
    name: "adh-chart",
    marker: marker
  };

  const annotationProps1 = {
    content: gradient,
    id: "gradient",
    coordinateUnits: "Point",
    x: "-",
    y: 0
  };

  const annotationProps2 = {
    content: arrowAnnotation,
    id: "avg-adh",
    coordinateUnits: "Point",
    x: "-",
    region: "Series",
    y: average
  };

  const rangeColorSettingProps1 = {
    start: 0,
    end: 50,
    label: "0-50%",
    colors: ["#d73838"]
  };

  const rangeColorSettingProps2 = {
    start: 51,
    end: 80,
    label: "51-80%",
    colors: ["#fcb422"]
  };

  const rangeColorSettingProps3 = {
    start: 81,
    label: "81-100%",
    end: 100,
    colors: ["#8bce00"]
  };

  const chartProps = {
    width: "98%",
    height: `${chartHeight}`,
    titleStyle: { fontFamily: "Effra" },
    id: "adh-chart",
    primaryYAxis: {
      valueType: "Double",
      labelType: "n0",
      labelPlacement: "OnTicks",
      minimum: 0,
      maximum: 100,
      interval: 100,
      visible: false,
      stripLines: [
        {
          start: average,
          end: average,
          size: 2,
          sizeType: "Pixel",
          dashArray: "10,5",
          color: "#8c8c8c"
        }
      ]
    },
    selectionMode: "Point",
    primaryXAxis: {
      valueType: "Category",
      labelPlacement: "OnTicks",
      labelStyle: {
        size: "14px",
        fontFamily: "Effra"
      },
      majorTickLines: {
        width: 0
      },

      majorGridLines: {
        width: 1
      },
      lineStyle: { width: 0 }
    },
    isTransposed: true,
    chartArea: { border: { width: 0, color: "transparent" } },
    legendSettings: {
      mode: "Range",
      visible: false,
      toggleVisibility: false
    }
  };

  return (
    <div className="pt-4 pl-6">
      <Suspense fallback={<LoadingAdhChart />}>
        <div
          className={classnames(
            {},
            "text-secondary small mb-neg-10px dynamic-margin"
          )}
        >
          {average}% Average Adherence
        </div>
        <ChartWrapper
          chartProps={chartProps}
          seriesProps={seriesProps}
          annotationProps1={annotationProps1}
          annotationProps2={annotationProps2}
          rangeColorSettingProps1={rangeColorSettingProps1}
          rangeColorSettingProps2={rangeColorSettingProps2}
          rangeColorSettingProps3={rangeColorSettingProps3}
        />
      </Suspense>
    </div>
  );
};

AdherenceChart.propTypes = {
  data: PropTypes.array.isRequired,
  average: PropTypes.number.isRequired
};

export { AdherenceChart, LoadingAdhChart };
