import classnames from "classnames";
import { Field } from "formik";
import React, { useState } from "react";
import { ReactComponent as Invisible } from "../images/glyph-invisible.svg";
import { ReactComponent as Visible } from "../images/glyph-visible.svg";
import PropTypes from "prop-types";

const PasswordField = ({
  id,
  autoComplete,
  svgClass,
  errors,
  touched,
  innerRef
}) => {
  const [type, setType] = useState("password");

  const toggleType = (version, originalType) => {
    originalType === "input" ? version("password") : version("input");
  };

  return (
    <>
      <Field
        innerRef={innerRef}
        id={id}
        name={id}
        autoComplete={autoComplete}
        type={type}
        className={classnames(
          { "is-invalid": errors && touched },
          "form-control mb-1"
        )}
      />
      {type === "password" ? (
        <Visible
          aria-label="show-password"
          className={svgClass}
          tabIndex="0"
          onKeyPress={() => toggleType(setType, type)}
          onClick={() => toggleType(setType, type)}
        />
      ) : (
        <Invisible
          aria-label="hide-password"
          className={svgClass}
          tabIndex="0"
          onKeyPress={() => toggleType(setType, type)}
          onClick={() => toggleType(setType, type)}
        />
      )}
    </>
  );
};

PasswordField.propTypes = {
  id: PropTypes.string,
  autoComplete: PropTypes.string,
  svgClass: PropTypes.string,
  errors: PropTypes.string,
  touched: PropTypes.bool,
  innerRef: PropTypes.object
};

export { PasswordField };
