import classnames from "classnames";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import PropTypes from "prop-types";
import React, { Suspense, useEffect } from "react";
import { Col } from "reactstrap";
import {
  FullCalendarWrapper,
  MedDropdown,
  NewPatientAlert
} from "../../../_components";
import { patientService } from "../../../_services";
import { ReactComponent as GlyphCircleCheck } from "../../../images/glyph-circle-check.svg";
import { ReactComponent as GlyphCircleSkip } from "../../../images/glyph-circle-skip.svg";
import { ReactComponent as GlyphCircleX } from "../../../images/glyph-circle-x.svg";
import { ReactComponent as GlyphMedsChange } from "../../../images/glyph_change.svg";
import { ReactComponent as GlyphMedsRemove } from "../../../images/glyph_minus_med.svg";
import { ReactComponent as GlyphMedsAdd } from "../../../images/glyph_plus_med.svg";

dayjs.extend(utc);

const LoadingCalendar = () => {
  return (
    <div id="overlay" className="w-100 d-block" data-testid="loading-cal">
      <div className="ph-item border-0 mt-7 px-5">
        <div className="ph-col-12 mb-4">
          <div className="ph-row">
            <div className="ph-col-real3 medium"></div>
            <div className="ph-col-quarter empty"></div>
            <div className="ph-col-1 fifteenpx mt-3"></div>
            <div className="ph-col-quarter empty"></div>
            <div className="ph-col-1 fifteenpx mt-3"></div>
            <div className="ph-col-real3 empty"></div>
            <div className="ph-col-quarter empty"></div>
            <div className="ph-col-15 medium"></div>
            <div className="ph-col-quarter empty"></div>
            <div className="ph-col-15 medium"></div>
          </div>
        </div>
        <div className="ph-col-12 mb-3">
          <div className="ph-row">
            <div className="ph-col-3 big"></div>
            <div className="ph-col-1 empty"></div>
            <div className="ph-col-3 big"></div>
            <div className="ph-col-1 empty"></div>
            <div className="ph-col-3 big"></div>
            <div className="ph-col-1 empty"></div>
            <div className="ph-col-3 big"></div>
            <div className="ph-col-1 empty"></div>
            <div className="ph-col-3 big"></div>
            <div className="ph-col-1 empty"></div>
            <div className="ph-col-3 big"></div>
            <div className="ph-col-1 empty"></div>
            <div className="ph-col-3 big"></div>
          </div>
        </div>
        {[...Array(5)].map((e, i) => {
          return (
            <div className="ph-col-12 mb-6" key={i}>
              <div className="ph-row">
                <div className="ph-col-15 big"></div>
                <div className="ph-col-quarter empty"></div>
                <div className="ph-col-15 big"></div>
                <div className="ph-col-quarter empty"></div>
                <div className="ph-col-15 big"></div>
                <div className="ph-col-quarter empty"></div>
                <div className="ph-col-15 big"></div>
                <div className="ph-col-quarter empty"></div>
                <div className="ph-col-15 big"></div>
                <div className="ph-col-quarter empty"></div>
                <div className="ph-col-15 big"></div>
                <div className="ph-col-quarter empty"></div>
                <div className="ph-col-15 big"></div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
const EventContentComponent = ({ event, isMobileWidth }) => {
  if (event.title === "User Created") {
    return (
      <>
        <GlyphMedsAdd aria-label="meds changed" />
        <> {!isMobileWidth && event.title}</>
      </>
    );
  }
  if (event.title === "Meds Modified") {
    return (
      <>
        <GlyphMedsChange aria-label="meds changed" />
        <> {!isMobileWidth && event.title}</>
      </>
    );
  } else if (event.title === "Med Added") {
    return (
      <>
        <GlyphMedsAdd aria-label="meds added" />
        <> {!isMobileWidth && event.title}</>
      </>
    );
  } else if (event.title === "Med Removed") {
    return (
      <>
        <GlyphMedsRemove aria-label="meds removed" />
        <> {!isMobileWidth && event.title}</>
      </>
    );
  } else if (event.classNames.length) {
    return (
      <>
        {event.classNames[0] === "missed" && (
          <GlyphCircleX aria-label="missed" className="mr-1" />
        )}

        {event.classNames[0] === "taken" && (
          <GlyphCircleCheck aria-label="taken" className="mr-1" />
        )}

        {event.classNames[0] === "skipped" && (
          <GlyphCircleSkip aria-label="skipped" className="mr-1" />
        )}
        {event.title.length > 9 && !event.allDay
          ? event.title.slice(0, 9) + "..."
          : event.title}
      </>
    );
  }
};
EventContentComponent.propTypes = {
  event: PropTypes.object,
  isMobileWidth: PropTypes.bool
};

export const Calendar = ({
  adhState,
  calendarRef,
  adhDispatch,
  patientId,
  isMobileWidth,
  isNewPatient,
  handleSelectAllRadioChange,
  handleMedClick
}) => {
  const {
    calendar,
    eventDetails,
    loadingCalendar,
    calendarView,
    days,
    availableMedications,
    eventinfo
  } = adhState;

  const fetchIdRef = React.useRef(0);
  const mounted = React.useRef(false);

  React.useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => {
      mounted.current = false;
    }; // ... and to false on unmount
  }, []);

  const handleFilterChange = (medlist) => {
    adhDispatch({
      type: "SET_SELECTED_MEDICATION_IDS",
      payload: [medlist.value]
    });
  };

  const handleTodayClick = (ev, element) => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.today();
    if (calendarApi.view.type === "timeGridWeek") {
      adhDispatch({
        type: "SET_DAYS",
        payload: {
          currentDate: calendarApi.currentDataManager.data.currentDate,
          view: "week"
        }
      });
    } else {
      adhDispatch({
        type: "SET_DAYS",
        payload: {
          currentDate: calendarApi.currentDataManager.data.currentDate,
          view: "month"
        }
      });
    }
    adhDispatch({
      type: "SET_EVENTS",
      payload: calendar
    });
  };

  const handleChangeViewMonthly = (ev, element) => {
    let calendarApi = calendarRef.current.getApi();
    adhDispatch({
      type: "SET_CALENDAR_VIEW",
      payload: "dayGridMonth"
    });
    calendarApi.changeView("dayGridMonth");
    adhDispatch({
      type: "SET_DAYS",
      payload: {
        currentDate: calendarApi.currentDataManager.data.currentDate,
        view: "month"
      }
    });
  };

  const handleChangeViewWeekly = (ev, element) => {
    let calendarApi = calendarRef.current.getApi();
    adhDispatch({
      type: "SET_CALENDAR_VIEW",
      payload: "timeGridWeek"
    });

    calendarApi.changeView("timeGridWeek");
    adhDispatch({
      type: "SET_DAYS",
      payload: {
        currentDate: calendarApi.currentDataManager.data.currentDate,
        view: "week"
      }
    });
  };
  const datesSet = () => {
    if (calendarRef.current) {
      //When navigating between months/weeks, call the new data
      let calendarApi = calendarRef.current.getApi();

      if (calendarView === "dayGridMonth") {
        adhDispatch({
          type: "SET_DAYS",
          payload: {
            currentDate: calendarApi.currentDataManager.data.currentDate,
            view: "month"
          }
        });
      } else {
        adhDispatch({
          type: "SET_EVENT_INFO",
          payload: null
        });

        adhDispatch({
          type: "SET_DAYS",
          payload: {
            currentDate: calendarApi.currentDataManager.data.currentDate,
            view: "week"
          }
        });
      }
    }
  };

  const handleEventClick = (info) => {
    adhDispatch({
      type: "SET_EVENT_INFO",
      payload: info
    });
    adhDispatch({
      type: "SET_SELECTED_EVENT",
      payload: info.event
    });
  };

  useEffect(() => {
    if (calendarRef.current) {
      let calendarApi = calendarRef.current.getApi();
      if (!eventinfo) {
        calendarApi.scrollToTime(eventDetails.firstTime);
      }
    }
  }, [calendarRef, eventDetails, eventinfo]);
  // Fecth calendar events for display

  const fetchCalendar = React.useCallback(() => {
    let ed;
    if (days.endDay > dayjs().utc().format()) {
      ed = dayjs().utc().format("YYYY-MM-DDTHH:mm:ss");
    }
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;
    // Set the loading state
    adhDispatch({
      type: "SET_LOADING_CALENDAR",
      payload: true
    });

    // Clear existing data

    if (fetchId === fetchIdRef.current) {
      adhDispatch({
        type: "SET_CALENDAR",
        payload: []
      });

      patientService
        .getCalendar(patientId, days.startDay, ed || days.endDay)
        .then((res) => {
          if (mounted.current) {
            adhDispatch({
              type: "SET_CALENDAR",
              payload: res
            });
            adhDispatch({
              type: "SET_LOADING_CALENDAR",
              payload: false
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [adhDispatch, days, patientId]);

  useEffect(() => {
    fetchCalendar();
  }, [fetchCalendar]);

  const calendarProps = {
    customButtons: {
      todayButton: {
        text: "Today",
        click: handleTodayClick
      },
      timeGridWeek: {
        text: "Weekly",
        click: handleChangeViewWeekly
      },
      dayGridMonth: {
        text: "Monthly",
        click: handleChangeViewMonthly
      },
      customFilter: {
        text: (
          <MedDropdown
            availableMedications={availableMedications}
            handleMedClick={handleMedClick}
            handleFilterChange={handleFilterChange}
            handleSelectAllRadioChange={handleSelectAllRadioChange}
            isClearable={true}
          />
        )
      }
    },
    headerToolbar: {
      left: isMobileWidth ? "prev,next,title" : "todayButton,prev,next,title",
      center: "",
      right: isMobileWidth ? "customFilter" : "dayGridMonth,timeGridWeek"
    },
    initialDate: Date.now(),
    initialView: calendarView,
    scrollTime: eventDetails.firstTime,
    datesSet: datesSet,
    showNonCurrentDates: false,
    height: 600,
    events: eventDetails.events,
    views: {
      dayGridMonth: {
        displayEventTime: false
      },
      timeGridWeek: {
        slotDuration: "00:15",
        slotLabelInterval: "01:00",
        eventMaxStack: "1"
      }
    },
    allDayContent: !loadingCalendar && "Events",
    allDaySlot: !loadingCalendar,
    eventClick: handleEventClick,
    eventContent: (info) => (
      <EventContentComponent event={info.event} isMobileWidth={isMobileWidth} />
    )
  };

  return (
    <Col xl="8" sm="12">
      <div
        className={classnames(
          { "alert-border": isNewPatient },
          "card bg-white "
        )}
      >
        {<NewPatientAlert id={"tabO-2"} isNewPatient={isNewPatient} />}
        <div className="card-body" id="calendar">
          {loadingCalendar ? <LoadingCalendar /> : <div></div>}
          <Suspense fallback={<LoadingCalendar />}>
            <FullCalendarWrapper
              calendarRef={calendarRef}
              calendarProps={calendarProps}
            />
          </Suspense>
        </div>
      </div>
    </Col>
  );
};

Calendar.propTypes = {
  adhState: PropTypes.object,
  calendarRef: PropTypes.object,
  adhDispatch: PropTypes.func,
  patientId: PropTypes.string,
  isMobileWidth: PropTypes.bool,
  isNewPatient: PropTypes.bool,
  handleSelectAllRadioChange: PropTypes.func,
  handleMedClick: PropTypes.func
};
