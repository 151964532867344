import React from "react";
import { MyDropdown, MyStaticTable } from "../../../_components";
import { ReactComponent as GylphMenu } from "../../../images/glyph_menu_2.svg";
import dayjs from "dayjs";

const dummyClaimsData = [
  {
    medicationName: "Glipizide, 5 mg Tablet",
    lastFillDate: "2023-05-27",
    quantity: 30,
    daysSupply: 30,
    daysOnHand: 0,
    prescriber: "D. Johnson"
  },
  {
    medicationName: "Metformin, 500 mg Tablet",
    lastFillDate: "2023-03-27",
    quantity: 180,
    daysSupply: 90,
    daysOnHand: 0,
    prescriber: "D. Johnson"
  },
  {
    medicationName: "Warfarin, 2 mg Tablet",
    lastFillDate: "2023-06-15",
    quantity: 30,
    daysSupply: 30,
    daysOnHand: 15,
    prescriber: "F. Jones"
  }
];

export const MedClaimsTable = (props) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Medication",
        accessor: "medicationName"
      },
      {
        Header: "Last Fill",
        accessor: "lastFillDate",
        Cell: ({ value }) => {
          return (
            <span className="white-space-nowrap">
              {dayjs(value).format("M/D/YYYY")}
            </span>
          );
        }
      },
      {
        Header: "Qty",
        accessor: "quantity"
      },
      {
        Header: "Days Supply",
        accessor: "daysSupply"
      },
      {
        Header: "Days On Hand",
        accessor: "daysOnHand",
        Cell: ({ value }) => {
          return value > 0 ? (
            <span>{value}</span>
          ) : (
            <span>
              {value}
              <div className="d-inline-block ml-1 h-10px w-10px rounded-circle bg-danger"></div>
            </span>
          );
        }
      },
      {
        Header: "Prescriber",
        accessor: "prescriber"
      },
      {
        Header: "",
        id: "actions",
        accessor: (row) => (
          <div className="my-neg-8px text-right">
            <MyDropdown
              details={{
                name: "row menu",
                glyph: <GylphMenu className="width-8" aria-label="menu" />,
                items: []
              }}
              // container="body"
              direction="right"
            />
          </div>
        )
      }
    ],
    []
  );

  return <MyStaticTable columns={columns} data={dummyClaimsData} />;
};
