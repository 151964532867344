import React from "react";
import logo from "../images/ED-Hortizontal_2-color_Logo.png";
import { Link } from "react-router-dom";
import { clearLocalStorage } from "../_helpers";
import { useAuth0 } from "@auth0/auth0-react";

const BlockedUser = (props) => {
  const { logout } = useAuth0();
  const handleReturnToLogin = () => {
    clearLocalStorage();
    logout({
      logoutParams: { returnTo: window.location.origin }
    });
  };
  const user =
    sessionStorage.getItem("user") &&
    JSON.parse(sessionStorage.getItem("user"));
  return (
    <div className="d-flex flex-column align-items-center smart-container blocked-user">
      <div className="d-flex justify-content-center mt-7 mb-5">
        <img src={logo} alt="logo" className="w-12rem" />
      </div>
      <div className="card bg-white align-self-center">
        <div className="card-body">
          <h3 className="text-center mb-6 mt-4">Your account is blocked</h3>
          <p className="pb-4 mx-6 text-center">
            Your account has been temporarily blocked. Please contact your
            organization’s administrator.
          </p>
          <p className="pb-4 mx-6 text-center">
            Questions? Contact EveryDose support below.
          </p>
          <div className="d-flex flex-column align-items-center">
            <Link to="/support" className="btn btn-primary w-16rem mb-4">
              Contact Support
            </Link>
            {!user?.isSmartSession && (
              <button
                to="/account/login"
                className="btn btn-link"
                onClick={handleReturnToLogin}
              >
                Return to Login
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { BlockedUser };
