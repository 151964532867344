import classnames from "classnames";
import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState
} from "react";
import {
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap";
import {
  healthTrackersEnum,
  UseMobileMediaQuery,
  getTrackerInfo,
  valuesByType
} from "../../_helpers";
import { patientService } from "../../_services";
import { PatientContext } from "../../context/patientContext";
import { useUserContext } from "../../context/userContext";
import { ReactComponent as Lock } from "../../images/glyph_lock.svg";
import { ReactComponent as GlyphSmile } from "../../images/smile.svg";
import { ReactComponent as ChevronDown } from "../../images/chevron_down_small.svg";
import {
  ChartCard,
  FilterDropdown,
  TrackingLoader
} from "./healthTrackerComponents";
import PropTypes from "prop-types";

function filterDataByNumberOfRecords(data, x) {
  const filteredData = data.map((item) => {
    const modifiedItem = { ...item };
    modifiedItem.healthTrackersLog = modifiedItem.healthTrackersLog.slice(0, x);
    return modifiedItem;
  });
  return filteredData;
}
filterDataByNumberOfRecords.propTypes = {
  data: PropTypes.array,
  x: PropTypes.number
};

// Function to filter the data based on a given startDate
function filterByStartDate(data, startDate) {
  const filteredData = data.map((item) => {
    const modifiedItem = { ...item };
    modifiedItem.healthTrackersLog = modifiedItem.healthTrackersLog.filter(
      (log) => new Date(log.logDate) >= new Date(startDate)
    );
    return modifiedItem;
  });
  return filteredData;
}

const initialHealthTrackerState = {
  activeHealthTrackerData: [],
  inactiveHealthTrackerData: [],
  specificTrackerData: [],
  trackerId: 0,
  loading: true,
  loadingTrackers: false,
  selectedFilter: {
    value: 25,
    label: "Last 25 records",
    type: "records"
  },
  biggestFilter: {
    value: 25,
    label: "Last 25 records",
    activeTab: 0,
    type: "records"
  },
  makeAPICall: true,
  filteredData: []
};

const healthTrackerReducer = (state, action) => {
  switch (action.type) {
    case "SET_ACTIVE_TRACKER_DATA":
      return {
        ...state,
        activeHealthTrackerData: action.payload
      };
    case "SET_INACTIVE_TRACKER_DATA":
      return {
        ...state,
        inactiveHealthTrackerData: action.payload
      };
    case "SET_SPECIFIC_TRACKER_DATA":
      return {
        ...state,
        specificTrackerData: action.payload
      };
    case "SET_TRACKER_ID":
      return {
        ...state,
        trackerId: action.payload
      };

    case "SET_LOADING_TRACKERS":
      return {
        ...state,
        loadingTrackers: action.payload
      };
    case "SET_SELECTED_FILTER":
      return {
        ...state,
        selectedFilter: action.payload
      };
    case "SET_FILTERED_DATA":
      return {
        ...state,
        filteredData: action.payload
      };

    case "SET_FILTERED_DATA_BY_NUMBER_OF_RECORDS":
      return {
        ...state,
        filteredData: filterDataByNumberOfRecords(
          state.activeHealthTrackerData,
          action.payload
        )
      };
    case "SET_FILTERED_DATA_BY_START_DATE":
      return {
        ...state,
        filteredData: filterByStartDate(
          state.activeHealthTrackerData,
          action.payload
        )
      };
    case "SET_SPECIFIC_TRACKER_DATA_WITH_FILTERS":
      const filteredData = state.filteredData.find(
        (item) => item.id === action.payload
      );
      return {
        ...state,
        specificTrackerData: filteredData?.healthTrackersLog || null
      };

    case "SET_MAKE_API_CALL":
      return {
        ...state,
        makeAPICall: action.payload
      };
    case "SET_BIGGEST_FILTER":
      return {
        ...state,
        biggestFilter: action.payload
      };
    default:
      return state;
  }
};

const TabHealthTrackers = ({ patientId, patientExists }) => {
  const { setHealthTrackers, setLoadingHealthTrackers, state } =
    useContext(PatientContext);
  const { loadingHealthTrackers, healthTrackers } = state;
  const [activeTab, setActiveTab] = useState(0);
  const [trackerState, dispatchTrackerState] = useReducer(
    healthTrackerReducer,
    initialHealthTrackerState
  );
  const {
    activeHealthTrackerData,
    inactiveHealthTrackerData,
    specificTrackerData,
    trackerId,
    loadingTrackers,
    selectedFilter,
    biggestFilter,
    makeAPICall,
    filteredData
  } = trackerState;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const isMobileWidth = UseMobileMediaQuery();
  const toggle = () => setDropdownOpen(!dropdownOpen);

  const { userState } = useUserContext();
  const { user } = userState;
  const mounted = React.useRef(false);

  React.useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => {
      mounted.current = false;
    }; // ... and to false on unmount
  }, []);
  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const filterData = useCallback(
    (startDate) => {
      if (selectedFilter.type === "records") {
        dispatchTrackerState({
          type: "SET_FILTERED_DATA_BY_NUMBER_OF_RECORDS",
          payload: selectedFilter.value
        });
      } else if (selectedFilter.type === "time") {
        dispatchTrackerState({
          type: "SET_FILTERED_DATA_BY_START_DATE",
          payload: startDate
        });
      }
      dispatchTrackerState({ type: "SET_LOADING_TRACKERS", payload: false });
    },
    [selectedFilter]
  );

  useEffect(() => {
    dispatchTrackerState({ type: "SET_LOADING_TRACKERS", payload: true });
    let startDate;
    let endDate;
    let pageSize;
    if (selectedFilter.type === "records") {
      pageSize = selectedFilter.value;
    } else if (selectedFilter.type === "time") {
      const today = new Date();
      const daysBefore = selectedFilter.value;
      endDate = today.toISOString();
      startDate = new Date(
        today.getTime() - daysBefore * 24 * 60 * 60 * 1000
      ).toISOString();
    }
    if (patientExists) {
      if (!healthTrackers?.length && !inactiveHealthTrackerData.length)
        setLoadingHealthTrackers(true);
      if (makeAPICall) {
        if (trackerId)
          patientService
            .getHealthTracker(
              patientId,
              trackerId,
              startDate,
              endDate,
              pageSize
            )
            .then((res) => {
              if (mounted.current) {
                dispatchTrackerState({
                  type: "SET_LOADING_TRACKERS",
                  payload: false
                });
                dispatchTrackerState({
                  type: "SET_SPECIFIC_TRACKER_DATA",
                  payload: res
                });
              }
            })
            .catch((e) => console.log(e));
        else {
          patientService
            .getHealthTrackers(patientId, startDate, endDate, pageSize)
            .then((res) => {
              if (mounted.current) {
                dispatchTrackerState({
                  type: "SET_LOADING_TRACKERS",
                  payload: false
                });
                dispatchTrackerState({
                  type: "SET_ACTIVE_TRACKER_DATA",
                  payload: res.active
                });
                dispatchTrackerState({
                  type: "SET_INACTIVE_TRACKER_DATA",
                  payload: healthTrackersEnum.filter((item) => {
                    // Check if there is no item with a matching healthTrackerType id in the active array
                    return !res.active.some(
                      (activeItem) => activeItem.healthTrackerType === item.id
                    );
                  })
                });
                dispatchTrackerState({
                  type: "SET_FILTERED_DATA",

                  payload: res.active
                });
                setHealthTrackers(res.active);

                setLoadingHealthTrackers(false);
              }
            })
            .catch((e) => console.log(e));
        }
      } else {
        // filter all the data
        filterData(startDate);
        if (trackerId) {
          // then if there is a trackerID, filter the filtered data by trackerID
          dispatchTrackerState({
            type: "SET_SPECIFIC_TRACKER_DATA_WITH_FILTERS",
            payload: trackerId
          });
          dispatchTrackerState({
            type: "SET_LOADING_TRACKERS",
            payload: false
          });
        }
      }
    }
  }, [
    makeAPICall,
    patientId,
    selectedFilter,
    trackerId,
    filterData,
    setHealthTrackers,
    setLoadingHealthTrackers,
    patientExists,
    healthTrackers?.length
  ]);

  function getAllMetrics() {
    toggleTab(0);
    dispatchTrackerState({
      type: "SET_TRACKER_ID",
      payload: 0
    });
    if (biggestFilter.activeTab !== 0) {
      // if the biggest filter is not the all metrics, set it to all metrics
      dispatchTrackerState({
        type: "SET_BIGGEST_FILTER",
        payload: { ...biggestFilter, activeTab: 0 }
      });
    }
  }
  function getMetric(item) {
    dispatchTrackerState({
      type: "SET_SPECIFIC_TRACKER_DATA",
      payload: []
    });
    toggleTab(item.healthTrackerType);
    if (
      biggestFilter.activeTab === 0 ||
      biggestFilter.activeTab === item.healthTrackerType
    ) {
      // don't call the api if the biggest filter includes all metrics or is already for the selected tracker
      dispatchTrackerState({
        type: "SET_MAKE_API_CALL",
        payload: false
      });
    } else {
      // otherwise call the api and update the biggest filter
      dispatchTrackerState({
        type: "SET_MAKE_API_CALL",
        payload: true
      });
      dispatchTrackerState({
        type: "SET_BIGGEST_FILTER",
        payload: {
          ...selectedFilter,
          activeTab: item.healthTrackerType
        }
      });
    }

    dispatchTrackerState({
      type: "SET_TRACKER_ID",
      payload: item.id
    });
  }
  return (
    <Row>
      <Col xl="3" lg="3" md="4">
        {isMobileWidth ? (
          <Nav>
            <Dropdown
              nav
              isOpen={dropdownOpen}
              toggle={toggle}
              data-testid="health-tracker-dropdown"
            >
              <DropdownToggle nav>
                {activeTab ? getTrackerInfo(activeTab).name : "All Metrics"}
                <ChevronDown
                  className="ml-3 glyph-dark"
                  aria-label="chevron down"
                />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={getAllMetrics}
                  className={classnames(
                    { active: activeTab === 0 },
                    "health-trackers"
                  )}
                >
                  All Metrics
                </DropdownItem>
                {activeHealthTrackerData.map((item) => {
                  return (
                    <DropdownItem
                      data-testid={`dd-${item.healthTrackerType}`}
                      key={item.healthTrackerType}
                      className={classnames(
                        {
                          active: activeTab === item.healthTrackerType
                        },
                        "health-trackers"
                      )}
                      onClick={() => {
                        if (activeTab !== item.healthTrackerType)
                          getMetric(item);
                      }}
                    >
                      {getTrackerInfo(item.healthTrackerType).name}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
          </Nav>
        ) : (
          <Nav pills>
            <Card className="bg-white w-100">
              <CardBody className="p-0">
                <div className="">
                  <div className="m-5 ">
                    <NavItem className="w-100">
                      <NavLink
                        tabIndex={0}
                        onClick={getAllMetrics}
                        className={classnames(
                          { active: activeTab === 0 },
                          "font-weight-bold light-blue"
                        )}
                        onKeyDown={(e) => e.key === "Enter" && getAllMetrics()}
                      >
                        All Metrics
                      </NavLink>
                    </NavItem>
                    {loadingHealthTrackers ? (
                      <div className="mt-4">
                        {[...Array(8)].map((e, i) => {
                          if (i % 2 === 0) {
                            return (
                              <div className="ph-item" key={i}>
                                <div className="ph-col-12">
                                  <div className="ph-row">
                                    <div className="ph-col-1 big"></div>
                                    <div className="ph-col-1 empty"></div>
                                    <div className="ph-col-6 big"></div>
                                  </div>
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div className="ph-item" key={i}>
                                <div className="ph-col-12">
                                  <div className="ph-row">
                                    <div className="ph-col-1 big"></div>
                                    <div className="ph-col-1 empty"></div>
                                    <div className="ph-col-5 big"></div>
                                    <div className="ph-col-real3 empty"></div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    ) : (
                      <>
                        {activeHealthTrackerData.map((item) => (
                          <NavItem
                            key={item.healthTrackerType}
                            className="w-100"
                          >
                            <NavLink
                              tabIndex={0}
                              data-testid={`tab-${item.healthTrackerType}`}
                              onClick={() => {
                                if (activeTab !== item.healthTrackerType)
                                  getMetric(item);
                              }}
                              className={classnames(
                                {
                                  active: activeTab === item.healthTrackerType
                                },
                                "font-weight-bold light-blue"
                              )}
                              onKeyDown={(e) =>
                                e.key === "Enter" &&
                                activeTab !== item.healthTrackerType &&
                                getMetric(item)
                              }
                            >
                              {getTrackerInfo(item.healthTrackerType).name}
                            </NavLink>
                          </NavItem>
                        ))}
                      </>
                    )}
                  </div>
                </div>
                {inactiveHealthTrackerData.length ? (
                  <div className="border-top">
                    <div className="m-5 w-100">
                      {inactiveHealthTrackerData.map((item, i) => (
                        <div key={item.id}>
                          <NavItem className="w-100">
                            <NavLink
                              disabled
                              className={classnames("font-weight-bold")}
                            >
                              <Lock
                                className="glyph-gray mr-2 mb-1"
                                aria-label="locked"
                              />
                              {item.name}
                            </NavLink>
                          </NavItem>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </CardBody>
            </Card>
          </Nav>
        )}
      </Col>
      <Col xl="9" lg="9" md="8">
        <TabContent activeTab={activeTab}>
          <div className="d-flex justify-content-end pr-4">
            <FilterDropdown
              selectedFilter={selectedFilter}
              dispatch={dispatchTrackerState}
              activeTab={activeTab}
              biggestFilter={biggestFilter}
              makeAPICall={makeAPICall}
            />
          </div>
          <TabPane tabId={0}>
            {loadingTrackers ? (
              <TrackingLoader />
            ) : (
              <div className="row d-flex flex-wrap">
                {activeTab === 0 ? (
                  <>
                    {filteredData.map((item, i) => {
                      let trackerInfo = getTrackerInfo(item.healthTrackerType);
                      let sortedValues;
                      let latestReading;

                      if (item.healthTrackersLog?.length) {
                        sortedValues = valuesByType(
                          item.healthTrackersLog,
                          trackerInfo.name
                        );
                        latestReading = valuesByType(
                          [item.healthTrackersLog[0]],
                          trackerInfo.name
                        );
                      }
                      return (
                        <React.Fragment key={item.healthTrackerType}>
                          <ChartCard
                            trackerInfo={trackerInfo}
                            sortedValues={sortedValues}
                            latestReading={latestReading}
                            activeTab={activeTab}
                            item={item}
                            chartId={trackerInfo?.type}
                            userPreference={
                              user?.userPreference[trackerInfo.type]
                            }
                          />
                        </React.Fragment>
                      );
                    })}

                    <Col xl={filteredData.length ? 6 : 12} lg="12">
                      <div className="py-7 dashed-border d-flex flex-column justify-content-center align-items-center h-93">
                        <GlyphSmile aria-label="smile" />
                        <h4 className="text-secondary text-center">
                          That's All Folks!
                        </h4>
                        <div className="text-secondary text-center">
                          This patient isn't tracking any{" "}
                          {filteredData.length ? "other" : ""} metrics
                        </div>
                      </div>
                    </Col>
                  </>
                ) : (
                  <></>
                )}
              </div>
            )}
          </TabPane>

          {activeHealthTrackerData.map((item, i) => {
            if (activeTab === item.healthTrackerType) {
              let trackerInfo = getTrackerInfo(activeTab);
              let sortedValues;
              let latestReading;
              if (specificTrackerData.length) {
                sortedValues = valuesByType(
                  specificTrackerData,
                  trackerInfo.name
                );
                latestReading = valuesByType(
                  [specificTrackerData[0]],
                  trackerInfo.name
                );
              }
              return (
                <TabPane
                  key={item.healthTrackerType}
                  tabId={item.healthTrackerType}
                >
                  {loadingTrackers ? (
                    <TrackingLoader />
                  ) : (
                    <div className="row d-flex flex-wrap">
                      <ChartCard
                        key={item.healthTrackerType}
                        trackerInfo={trackerInfo}
                        sortedValues={sortedValues}
                        latestReading={latestReading}
                        activeTab={activeTab}
                        item={{
                          id: trackerId,
                          healthTrackerType: activeTab,
                          healthTrackersLog: specificTrackerData
                        }}
                        chartId={`individual-${trackerInfo.type}`}
                        userPreference={user?.userPreference[trackerInfo.type]}
                      />
                    </div>
                  )}
                </TabPane>
              );
            } else
              return (
                <React.Fragment key={item.healthTrackerType}></React.Fragment>
              );
          })}
        </TabContent>
      </Col>
    </Row>
  );
};

TabHealthTrackers.propTypes = {
  patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  patientExists: PropTypes.bool
};

export { TabHealthTrackers };
