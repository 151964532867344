import classnames from "classnames";
import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { ReactComponent as Filter } from "../../../images/filter.svg";
import PropTypes from "prop-types";

const recordsOptions = [
  { label: "Last 10 records", value: 10 },
  { label: "Last 25 records", value: 25 },
  { label: "Last 50 records", value: 50 },
];

const timeOptions = [
  { label: "Last 7 Days", value: 7 },
  { label: "Last 30 Days", value: 30 },
  { label: "Last 90 Days", value: 90 },
  { label: "Last Year", value: 365 },
];

export const FilterDropdown = (props) => {
  const { dispatch, selectedFilter, activeTab, biggestFilter } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filterTimeOptions, setFilterTimeOptions] = useState(
    activeTab > 0 ? timeOptions : timeOptions.slice(0, 3)
  );
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleClick = (type, option) => {
    if (
      option.value <= biggestFilter.value &&
      biggestFilter.type === type &&
      (biggestFilter.activeTab === activeTab || biggestFilter.activeTab === 0)
    ) {
      // don't call the api and keep the biggest filter the same
      dispatch({
        type: "SET_MAKE_API_CALL",
        payload: false,
      });
    } else {
      // else set makeAPICall to true
      dispatch({
        type: "SET_MAKE_API_CALL",
        payload: true,
      });
      // change the biggest filter to the selected filter
      dispatch({
        type: "SET_BIGGEST_FILTER",
        payload: {
          ...option,
          type: type,
          activeTab: activeTab,
        },
      });
    }
    // set the filter so data can be filtered whether or not api is called
    dispatch({
      type: "SET_SELECTED_FILTER",
      payload: { ...option, type: type },
    });
  };

  useEffect(() => {
    if (activeTab === 0) {
      // if selected filter is Last Year, set selected filter to Last 25 records
      if (selectedFilter.value === 365) {
        dispatch({
          type: "SET_SELECTED_FILTER",
          payload: {
            value: 25,
            label: "Last 25 records",
            type: "records",
          },
        });
        dispatch({
          type: "SET_BIGGEST_FILTER",
          payload: {
            value: 25,
            label: "Last 25 records",
            type: "records",
            activeTab: 0,
          },
        });
      }
      setFilterTimeOptions(timeOptions.slice(0, 3));
    } else setFilterTimeOptions(timeOptions);
  }, [activeTab, selectedFilter.value, dispatch]);
  // dropdown with records options and time options divided with divider
  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
        tag="span"
      >
        <button
          className="btn btn-link"
          onKeyDown={(e) => e.key === "Enter" && toggle()}
        >
          <Filter
            className="mr-2 glyph-primary-fill h16-w16-style"
            aria-label="filter"
          />
          {selectedFilter.label}
        </button>
      </DropdownToggle>
      <DropdownMenu className="p-4 mt-2 date-filter">
        {recordsOptions.map((option) => (
          <div key={option.value}>
            <DropdownItem
              className={classnames(
                {
                  "text-primary": selectedFilter.value === option.value,
                },
                "px-3"
              )}
              onClick={() => {
                handleClick("records", option);
              }}
            >
              {option.label}
            </DropdownItem>
          </div>
        ))}
        <div className="dropdown-divider"></div>
        {filterTimeOptions.map((option) => (
          <div key={option.value}>
            <DropdownItem
              className={classnames(
                {
                  "text-primary": selectedFilter.value === option.value,
                },
                "px-3"
              )}
              onClick={() => {
                handleClick("time", option);
              }}
            >
              {option.label}
            </DropdownItem>
          </div>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

FilterDropdown.propTypes = {
  dispatch: PropTypes.func,
  selectedFilter: PropTypes.object,
  activeTab: PropTypes.number,
  biggestFilter: PropTypes.object,
};
