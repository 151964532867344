import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  InputGroup,
  CustomInput
} from "reactstrap";
import { CloseButton } from "./CloseButton";
import PropTypes from "prop-types";

const AlertTypeModal = ({
  modal,
  toggleModal,
  setBulkAlertType,
  setMultiSelect,
  bulkAlertType,
  prnMeds,
  scheduledMeds
}) => {
  return (
    <Modal
      isOpen={modal}
      toggle={toggleModal}
      className="center-modal"
    >
      <ModalHeader
        toggle={toggleModal}
        close={<CloseButton toggle={toggleModal} />}
      >
        Select Alert Type
      </ModalHeader>
      <ModalBody>
        <InputGroup className="d-flex flex-column">
          <CustomInput
            id="missed-med-alert"
            name="alert-type"
            type="radio"
            label="Missed Medication Alert (for scheduled meds)"
            onChange={() => {
              setBulkAlertType("missed");
            }}
            disabled={!scheduledMeds.length}
          />
          <CustomInput
            id="taken-med-alert"
            name="alert-type"
            type="radio"
            label="Taken Medication Alert (for as needed meds)"
            onChange={() => {
              setBulkAlertType("taken");
            }}
            disabled={!prnMeds.length}
          />
        </InputGroup>
        <div className="d-flex justify-content-center mt-3">
          <button
            className="btn btn-primary"
            onClick={() => {
              setMultiSelect(true);
              toggleModal();
            }}
            disabled={!bulkAlertType}
          >
            Set Alerts
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

AlertTypeModal.propTypes = {
  modal: PropTypes.bool,
  toggleModal: PropTypes.func,
  setBulkAlertType: PropTypes.func,
  setMultiSelect: PropTypes.func,
  bulkAlertType: PropTypes.string,
  prnMeds: PropTypes.array,
  scheduledMeds: PropTypes.array
};

export { AlertTypeModal };
