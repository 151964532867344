import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

function useOutsideAlerter(ref, adhDispatch, setCalendarOpen) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      const key = event.keyCode || event.which;
      //For keyboard accsesibility - if you hit enter
      if (adhDispatch) {
        if (ref.current && !ref.current.contains(event.target) && key !== 13) {
          adhDispatch({ type: "SET_SELECTED_EVENT", payload: false });
        }
      }
      if (setCalendarOpen) {
        if (ref.current && !ref.current.contains(event.target) && key !== 13) {
          setCalendarOpen(false);
        }
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleClickOutside);
    };
  }, [ref, adhDispatch, setCalendarOpen]);
}
useOutsideAlerter.propTypes = {
  adhDispatch: PropTypes.func,
  setCalendarOpen: PropTypes.func,
  ref: PropTypes.object,
};

export function OutsideAlerter(props) {
  const { adhDispatch, setCalendarOpen } = props;
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, adhDispatch, setCalendarOpen);
  return <div ref={wrapperRef}>{props.children}</div>;
}
OutsideAlerter.propTypes = {
  children: PropTypes.element,
  adhDispatch: PropTypes.func,
  setCalendarOpen: PropTypes.func,
};
