import React from "react";
import { Card, CardBody, CardHeader, Table } from "reactstrap";
import PropTypes from "prop-types";
import { getTrackerInfo, unitsEnum, valuesByType } from "../../../_helpers";
import { useUserContext } from "../../../context/userContext";
import { LastReading } from "../healthTrackerComponents";

export const HealthTrackerCard = ({ healthTrackers }) => {
  const { userState } = useUserContext();
  const { user } = userState;
  const userPreference = user?.userPreference;

  const isValidUnit = (trackerInfo) => {
    if (userPreference) {
      let up = userPreference[trackerInfo.type];
      return trackerInfo.units.some((obj) => obj.value === unitsEnum[up]);
    }
  };
  return (
    <div>
      <Card className="bg-white">
        <CardHeader>
          <h6>Health Tracker Summary</h6>
        </CardHeader>
        <CardBody className="p-3">
          <Table className="g-table static">
            <tbody>
              {healthTrackers.map((tracker) => {
                let trackerInfo = getTrackerInfo(tracker.healthTrackerType);
                const defaultUnitValue = trackerInfo.units[0].label;

                let selectedUnit;
                let latestReading;
                if (isValidUnit(trackerInfo)) {
                  let up = userPreference[trackerInfo.type];

                  selectedUnit = unitsEnum[up];
                } else selectedUnit = defaultUnitValue;

                if (tracker.healthTrackersLog?.length) {
                  latestReading = valuesByType(
                    [tracker.healthTrackersLog[0]],
                    trackerInfo.name
                  );
                }
                return (
                  <tr key={tracker.healthTrackerType}>
                    <td>
                      <h6 className="word-break-normal">{trackerInfo.name} </h6>
                    </td>
                    <td className="">
                      {latestReading ? (
                        <LastReading
                          sortedValues={latestReading}
                          trackerInfo={trackerInfo}
                          selectedUnit={selectedUnit}
                          alternateUnitSelected={
                            defaultUnitValue !== selectedUnit
                          }
                          item={tracker}
                          version="summary"
                        />
                      ) : (
                        <span>No records found</span>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </div>
  );
};

HealthTrackerCard.propTypes = {
  healthTrackers: PropTypes.array
};
