import React from "react";
import { MySelectBtnLink } from "./MySelect";
import { isIE, languageOptions } from "../_helpers";
import classnames from "classnames";
import PropTypes from "prop-types";

export const LanguageField = ({ onChange, value, centered, stateChange }) => {
  return (
    <div
      className={classnames(
        { "justify-content-center": centered },
        "d-flex mt-4 ",
        { "align-items-center": !isIE }
      )}
    >
      <label
        htmlFor="language"
        className="text-secondary mr-1 mb-0 font-weight-normal"
      >
        <span>Invitation Language: </span>
      </label>
      <MySelectBtnLink
        options={languageOptions.filter((option) => option !== value)}
        name="language"
        value={value}
        onChange={(name, val) => {
          if (stateChange) onChange(val);
          else onChange(name, val);
        }}
        menuPlacement={stateChange ? "auto" : "top"}
      />
    </div>
  );
};

LanguageField.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  centered: PropTypes.bool,
  stateChange: PropTypes.bool
};
