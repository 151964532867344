import React, { useState } from "react";
import { Popover, PopoverBody, PopoverHeader, Table } from "reactstrap";
import { ReactComponent as GlyphPopup } from "../images/glyph_popup.svg";
import { CloseButton } from "../_components";
import { UseMobileOrTabletMediaQuery } from "../_helpers/media-queries";
import PropTypes from "prop-types";

const GroupsPhysiciansPopover = (props) => {
  const { id, groups } = props;
  const [popoverOpen, setPopoverOpen] = useState(false);
  const is_touch_device = "ontouchstart" in window;
  const isMobileOrTabletWidth = UseMobileOrTabletMediaQuery();
  const toggle = () => setPopoverOpen(!popoverOpen);

  return (
    <div>
      {groups.length === 0 ? (
        <span></span>
      ) : (
        <div>
          <button
            className="mr-1 btn focusable-text"
            color=""
            id={"Popover-" + id}
            aria-describedby="groups-popover"
          >
            <span className="groups-popover">
              <GlyphPopup aria-label="popup" className="mr-3" />
              {groups.map((group, i) => {
                return groups.length === i + 1 ? group.name : group.name + ", ";
              })}
            </span>
          </button>
          <Popover
            trigger={is_touch_device ? "click" : "hover focus"}
            placement="bottom"
            isOpen={popoverOpen}
            target={"Popover-" + id}
            toggle={toggle}
            style={{ minWidth: "301px" }}
          >
            <PopoverHeader>
              Group Clinicians{" "}
              {isMobileOrTabletWidth && (
                <CloseButton
                  toggle={() => setPopoverOpen(!popoverOpen)}
                  color="glyph-white"
                  popoverVersion={true}
                />
              )}
            </PopoverHeader>
            <PopoverBody className="group-popover">
              <Table className="mb-0">
                <tbody>
                  {groups.map((group, i) => {
                    return (
                      <tr key={group.id}>
                        <td>{group.name}</td>
                        <td className="text-right vertical-align-middle">
                          {group.primaryPhysician
                            ? group.primaryPhysician.name
                            : "Not assigned"}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </PopoverBody>
          </Popover>
        </div>
      )}
    </div>
  );
};

GroupsPhysiciansPopover.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      primaryPhysician: PropTypes.shape({
        name: PropTypes.string
      })
    })
  )
};

export { GroupsPhysiciansPopover };
