import PropTypes from "prop-types";
import React, { Suspense, useContext, useEffect, useState } from "react";
import { ReactComponent as Bell } from "../../../images/bell.svg";
import { ReactComponent as Menu } from "../../../images/menu_24dp_1F2F36_FILL0_wght400_GRAD0_opsz24.svg";
import { ReactComponent as GlyphMenu } from "../../../images/glyph_menu_2.svg";
import { ReactComponent as DocumentIcon } from "../../../images/document-new.svg";
import { ReactComponent as Warning } from "../../../images/warning-new.svg";
import classnames from "classnames";
import {
  AlertTypeModal,
  CustomMedPopover,
  DDITableExpandable,
  DosesCell,
  FrequencyCell,
  MedicationCell,
  MyDropdown,
  MyStaticTable,
  ReviewSidebar,
  NoEditModal,
  SimpleMedicationCell,
  PrintButton
} from "../../../_components";
import {
  Use1600MediaQuery,
  allAccess,
  permissionsEnum,
  isPRN,
  isValidDate,
  isIOS
} from "../../../_helpers";
import { PatientContext } from "../../../context/patientContext";
import { useUserContext } from "../../../context/userContext";
import { AlertModal } from "./AlertModal";
import { BulkActions } from "./BulkActions";
import { ReactComponent as PrintIcon } from "../../../images/print_24dp_1F2F36_FILL0_wght400_GRAD0_opsz24.svg";

import dayjs from "dayjs";

const medSelectFilterOptions = [
  { id: "ACTIVE", name: "Active Medications" },
  { id: "PAUSED", name: "Paused Medications" },
  { id: "DELETED", name: "Deleted Medications" }
];

const mobileMedSelectFilterOptions = [
  { id: "ACTIVE", name: "Active" },
  { id: "PAUSED", name: "Paused" },
  { id: "DELETED", name: "Deleted" }
];

const IndeterminateCheckbox = React.forwardRef(
  ({ paddingBottom, id, indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <div>
        <label className={`g-table-checkbox ${paddingBottom}`} id={id}>
          <input type="checkbox" ref={resolvedRef} {...rest} data-testid={id} />
          <span className="checkmark"></span>
        </label>
      </div>
    );
  }
);
IndeterminateCheckbox.propTypes = {
  paddingBottom: PropTypes.string,
  id: PropTypes.string,
  indeterminate: PropTypes.bool
};

const MedTitle = ({ multiSelect, getToggleAllRowsSelectedProps }) => {
  if (!multiSelect) {
    return null;
  }
  MedTitle.propTypes = {
    multiSelect: PropTypes.bool,
    getToggleAllRowsSelectedProps: PropTypes.func
  };

  return (
    <span className="checkbox-style">
      {/* <MyTooltip target="select-all" text="Select All" />
      <button className="btn focusable-text" aria-describedby="select all"> */}
      <IndeterminateCheckbox
        id="select-all"
        paddingBottom="mb-3"
        {...getToggleAllRowsSelectedProps()}
      />
      {/* </button> */}
    </span>
  );
};

const MedNameCell = ({
  multiSelect,
  row,
  value,

  bulkAlertType,
  bulkDeleteAlerts
}) => {
  const [selectable, setSelectable] = useState(false);

  // find index of first space in value
  let spaceIdx;
  if (value.indexOf(" ") > -1) {
    spaceIdx = value.indexOf(" ");
  } else spaceIdx = value.length;
  useEffect(() => {
    if (
      (isPRN(row.original.medicationSchedule) && bulkAlertType === "taken") ||
      (!isPRN(row.original.medicationSchedule) && bulkAlertType === "missed") ||
      (bulkDeleteAlerts && row.original.alert?.alertScheduleType > -1)
    ) {
      setSelectable(true);
    } else setSelectable(false);
  }, [bulkAlertType, bulkDeleteAlerts, row.original]);
  return (
    <div className="pl-3 pl-lg-0">
      <div
        className={classnames("d-flex align-items-center", {
          "overflow-anywhere": spaceIdx > 15
        })}
      >
        {multiSelect && selectable ? (
          <div className="checkbox-style">
            <IndeterminateCheckbox
              id={row.original.id}
              {...row.getToggleRowSelectedProps()}
            />
          </div>
        ) : (
          <></>
        )}
        {!multiSelect && row.original.isCustomMedication && (
          <div className="checkbox-style">
            <CustomMedPopover id={`med-list${row.original.id}`} />
          </div>
        )}
        <span>
          <MedicationCell row={row} value={value} version="med-list" />
        </span>
      </div>
    </div>
  );
};
MedNameCell.propTypes = {
  multiSelect: PropTypes.bool,
  row: PropTypes.object,
  value: PropTypes.string,
  isMobileWidth: PropTypes.bool,
  bulkAlertType: PropTypes.string,
  bulkDeleteAlerts: PropTypes.bool
};

const StartEndCell = ({ row, isMobileWidth }) => {
  return (
    <>
      <div>
        {isValidDate(row.medicationSchedule.startDate) ? (
          <span>
            {dayjs(row.medicationSchedule.startDate).format("M/D/YY")}
          </span>
        ) : (
          <>N/A</>
        )}{" "}
        -{" "}
        {row.medicationSchedule.endDate &&
          isValidDate(row.medicationSchedule.endDate) && (
            <span>
              {dayjs(row.medicationSchedule.endDate).format("M/D/YY")}
            </span>
          )}
      </div>
      {isMobileWidth ? (
        <div>
          <DosesCell medicationSchedule={row.medicationSchedule} />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
StartEndCell.propTypes = {
  row: PropTypes.object,
  isMobileWidth: PropTypes.bool
};

const EHRCell = ({
  cellprops,
  setSelectedRow,
  toggleAlertModal,
  setName,
  setRowId,
  setSelectedMed,
  toggleAdd,
  editDisabled,
  toggleNoEditModal
}) => {
  const createItems = React.useCallback(
    (props) => {
      let items = [
        {
          name: "Set Alert",
          link: "",
          onClick: () => {
            setSelectedRow(props.row);
            toggleAlertModal();
          }
        },
        {
          name: "Edit Medication",
          link: "",
          onClick: () => {
            if (editDisabled) toggleNoEditModal();
            else {
              const { row } = props;
              const { original, cells, index } = row;
              setName(cells[0].value);
              setRowId(index);
              setSelectedRow(row);
              setSelectedMed(original);
              toggleAdd();
            }
          }
        }
      ];

      if (props.row.original.status) return items.slice(1, 2); //active meds
      else if (!props.row.original.isInEhr) return items; //paused meds
      else return items.slice(0, 1); //deleted meds
    },
    [
      editDisabled,
      setName,
      setRowId,
      setSelectedMed,
      setSelectedRow,
      toggleAdd,
      toggleAlertModal,
      toggleNoEditModal
    ]
  );

  const formatAlertInfo = React.useCallback(
    (props) => {
      const { alert, status } = props.row.original;
      if (alert?.alertScheduleType > -1 && !status) {
        return (
          <button
            className="btn btn-link"
            onClick={() => {
              setSelectedRow(props.row);
              toggleAlertModal();
            }}
          >
            <Bell
              aria-label="alert-bell"
              className="glyph-dark mr-3 w-1rem h-1rem"
            />
          </button>
        );
      }
    },
    [setSelectedRow, toggleAlertModal]
  );

  return (
    <div className="d-flex justify-content-end align-items-center">
      {formatAlertInfo(cellprops)}
      {cellprops.row.original.status !== 1 && (
        <MyDropdown
          details={{
            name: "row menu",
            glyph: <GlyphMenu className="w-10px" aria-label="menu" />,
            items: createItems(cellprops)
          }}
          // container="body"
          direction="right"
        />
      )}
    </div>
  );
};
EHRCell.propTypes = {
  cellprops: PropTypes.object,
  setSelectedRow: PropTypes.func,
  toggleAlertModal: PropTypes.func,
  setName: PropTypes.func,
  setRowId: PropTypes.func,
  setSelectedMed: PropTypes.func,
  toggleAdd: PropTypes.func,
  editDisabled: PropTypes.bool,
  toggleNoEditModal: PropTypes.func
};

const InfoCell = ({
  row,
  toggleSidebar,
  setSidebarType,
  setSelectedSidebarRow
}) => {
  const [selectedLeaflet, setSelectedLeaflet] = useState(null);
  const [drugInteractions, setDrugInteractions] = useState([]);
  const { state } = useContext(PatientContext);

  const { leaflets, allInteractions } = state;

  useEffect(() => {
    const results = allInteractions.filter((i) =>
      i.drugName.includes(row.medicationName)
    );
    setDrugInteractions(results);
  }, [allInteractions, row.medicationName]);

  useEffect(() => {
    const leaflet = leaflets.find((l) => l.name === row.medicationName);
    setSelectedLeaflet(leaflet);
  }, [leaflets, row.medicationName]);

  return (
    <div className="d-flex align-items-center">
      {selectedLeaflet ? (
        <button
          className="btn btn-link mr-2"
          onClick={() => {
            setSelectedSidebarRow({ ...row, leaflet: selectedLeaflet });
            setSidebarType("leaflet");
            toggleSidebar(true);
          }}
        >
          <DocumentIcon
            className="glyph-link-document"
            aria-label="document-new"
          />
        </button>
      ) : (
        <></>
      )}
      {drugInteractions.length ? (
        <button
          className="btn btn-link"
          onClick={() => {
            setSidebarType("ddi");
            setSelectedSidebarRow({ ...row, drugInteractions });
            // find all interactions where the drugName field includes the row.medicationName (e.g. "Warfarin + Anjeso" would show up for Warfarin or Anjeso)

            toggleSidebar(true);
          }}
        >
          <Warning className="glyph-warning" aria-label="warning" />
        </button>
      ) : (
        <> </>
      )}
    </div>
  );
};
InfoCell.propTypes = {
  row: PropTypes.object,
  toggleSidebar: PropTypes.func,
  setSidebarType: PropTypes.func,
  setSelectedSidebarRow: PropTypes.func
};

const MedlistTable = ({
  setMedlist,
  loadingMedications,
  setBulkUpdate,
  selectedRows,
  fetchMedications,
  setMultiSelect,
  setDeleteBulkAlerts,
  bulkUpdate,
  toggleAdd,
  medlist,
  setSelectedRows,
  setName,
  setRowId,
  setSelectedMed,
  setSelectedRow,
  toggleAlertModal,
  medications,
  multiSelect,
  isMobileWidth,
  deleteBulkAlerts,
  selectedRow,
  alertModal,
  isIE,
  scrollPosition,
  setScrollPosition
}) => {
  const [updatePatientFeatureEnabled, setUpdatePatientFeatureEnabled] =
    useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const [modal, setModal] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [sidebarType, setSidebarType] = useState(null);
  const [selectedSidebarRow, setSelectedSidebarRow] = useState({});
  const [noEditModal, setNoEditModal] = useState(false);
  const [editDisabled, setEditDisabled] = useState(false);

  const is1600Width = Use1600MediaQuery();

  const [bulkAlertType, setBulkAlertType] = useState(null);

  const { userState } = useUserContext();
  const { user, userPermissions } = userState;
  const { state } = useContext(PatientContext);
  const { patient } = state;
  const [columns, setColumns] = useState([]);
  const [loadingMedPDF, setLoadingMedPDF] = useState(false);
  const componentRef = React.useRef();

  const toggleSidebar = React.useCallback(
    (arg) => {
      setScrollPosition(document.body.scrollTop);
      return setIsSidebarOpen(arg);
    },
    [setScrollPosition]
  );
  const toggleModal = () => {
    setModal(!modal);
  };

  const toggleNoEditModal = React.useCallback(() => {
    setNoEditModal(!noEditModal);
  }, [noEditModal]);

  const beforePrint = () => {
    setLoadingMedPDF(true);
    // if user is smart session, remove the d-none class from the hidden card
    if (user?.isSmartSession && !isIE) {
      componentRef.current.classList.remove("d-none");
    }
  };

  const afterPrint = () => {
    setLoadingMedPDF(false);
    // add the d-none class back to the hidden card
    if (user?.isSmartSession && !isIE) {
      componentRef.current.classList.add("d-none");
    }
  };

  useEffect(() => {
    if (
      userPermissions?.indexOf(permissionsEnum.PatientDetailsUpdate) > -1 ||
      allAccess(userPermissions)
    )
      setUpdatePatientFeatureEnabled(true);
    if (
      userPermissions?.indexOf(permissionsEnum.SmartMedicationsUpdateOnInvite) <
        0 &&
      user?.isSmartSession
    ) {
      setEditDisabled(true);
    }
  }, [user?.isSmartSession, userPermissions]);

  const printedMedColumns = React.useMemo(
    () => [
      {
        Header: (props) => {
          return (
            <div className="">
              <span>Medication</span>
            </div>
          );
        },
        accessor: "medicationName",
        Cell: (props) => {
          return <SimpleMedicationCell row={props.row} value={props.value} />;
        }
      },
      {
        Header: "Frequency",
        accessor: "medicationSchedule.sigCode",
        Cell: (props) => (
          <FrequencyCell value={props.value} row={props.row.original} />
        )
      },
      {
        Header: "Start/End",
        accessor: (row) => {
          return <StartEndCell row={row} isMobileWidth={false} />;
        }
      },

      {
        Header: "Scheduled Doses",
        accessor: "medicationSchedule",
        Cell: (props) => {
          return <DosesCell medicationSchedule={props.value} />;
        }
      }
    ],
    []
  );

  const medicationsColumns = React.useMemo(
    () => [
      {
        Header: (props) => {
          return (
            <div className="d-flex pl-3 pl-lg-0">
              <MedTitle multiSelect={multiSelect} {...props} />
              <span>Medication</span>
            </div>
          );
        },
        accessor: "medicationName",
        Cell: (props) => {
          return (
            <MedNameCell
              multiSelect={multiSelect}
              row={props.row}
              value={props.value}
              isMobileWidth={isMobileWidth}
              user={user}
              bulkAlertType={bulkAlertType}
              bulkDeleteAlerts={deleteBulkAlerts}
            />
          );
        }
      },
      {
        Header: "Frequency",
        accessor: "medicationSchedule.sigCode",
        Cell: (props) => (
          <FrequencyCell value={props.value} row={props.row.original} />
        )
      },
      {
        Header: "Start/End",
        accessor: (row) => {
          return <StartEndCell row={row} isMobileWidth={isMobileWidth} />;
        }
      },

      {
        Header: "Scheduled Doses",
        accessor: "medicationSchedule",
        Cell: (props) => {
          return <DosesCell medicationSchedule={props.value} />;
        }
      },
      {
        Header: () => {
          // if active meds show "Info" else show "Leaflet"
          return <span>{activeTab === 0 ? "Info" : "Leaflet"}</span>;
        },
        id: "info",
        // accessor: "id",
        accessor: (row) => {
          return (
            <InfoCell
              row={row}
              setScrollPosition={setScrollPosition}
              setSidebarType={setSidebarType}
              setSelectedSidebarRow={setSelectedSidebarRow}
              toggleSidebar={toggleSidebar}
            />
          );
        }
      },
      // {
      //   Header: "In EHR?",
      //   accessor: "isInEhr",
      //   Cell: (props) => {
      //     return props.value ? "Yes" : "No";
      //   }
      // },

      {
        accessor: "alert",
        Cell: (props) => {
          return (
            <EHRCell
              setSelectedRow={setSelectedRow}
              cellprops={props}
              toggleAlertModal={toggleAlertModal}
              setName={setName}
              setRowId={setRowId}
              setSelectedMed={setSelectedMed}
              toggleAdd={toggleAdd}
              editDisabled={editDisabled}
              toggleNoEditModal={toggleNoEditModal}
            />
          );
        }
      }
    ],
    [
      activeTab,
      bulkAlertType,
      deleteBulkAlerts,
      editDisabled,
      isMobileWidth,
      multiSelect,
      setName,
      setRowId,
      setScrollPosition,
      setSelectedMed,
      setSelectedRow,
      toggleAdd,
      toggleAlertModal,
      toggleNoEditModal,
      toggleSidebar,
      user
    ]
  );

  useEffect(() => {
    if (user?.isSmartSession) {
      setColumns(medicationsColumns);
    } else
      setColumns(
        medicationsColumns.filter((col) => col.accessor !== "isInEhr")
      );
  }, [medicationsColumns, user]);

  useEffect(() => {
    if (!multiSelect || deleteBulkAlerts) {
      setBulkAlertType(null);
    }
  }, [multiSelect, deleteBulkAlerts]);

  const isIOSDevice = isIOS();

  return (
    <div>
      <div
        className={classnames(
          { "border-danger": deleteBulkAlerts },
          { "border-primary": multiSelect && !deleteBulkAlerts },
          "card bg-white"
        )}
      >
        <div className="card-body p-0">
          <div className="small-med-table alerts-table">
            <MyStaticTable
              mobileColumnsToHide={["medicationSchedule", "medicationStrength"]}
              headerTabs={
                isMobileWidth
                  ? mobileMedSelectFilterOptions
                  : medSelectFilterOptions
              }
              headerTabsDisabled={multiSelect}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              columns={columns}
              data={medlist}
              medications={medications}
              setMedlist={setMedlist}
              loading={loadingMedications}
              isMobileWidth={isMobileWidth}
              bulkUpdate={bulkUpdate}
              print={
                !isIOSDevice && (
                  <Suspense fallback={<div></div>}>
                    <PrintButton
                      componentRef={componentRef}
                      buttonComponent={
                        loadingMedPDF ? (
                          <span className="spinner-border spinner-border-sm mr-1"></span>
                        ) : (
                          <PrintIcon
                            aria-label="print"
                            className="glyph-dark-fill"
                          />
                        )
                      }
                      classes="btn btn-link ml-auto"
                      onBeforeGetContent={() => beforePrint()}
                      onAfterPrint={() => afterPrint()}
                    />
                  </Suspense>
                )
              }
              setBulkUpdate={setBulkUpdate}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              multiSelect={multiSelect}
              fetchMedications={fetchMedications}
              tableAction={
                <div className="ml-auto mr-5">
                  {medlist.length && medlist[0].status === 0 ? (
                    <MyDropdown
                      details={{
                        name: "row menu",
                        glyph: (
                          <Menu
                            data-testid="bulk-dropdown"
                            className="glyph-dark-fill ml-3"
                            aria-label="menu"
                          />
                        ),
                        items: [
                          {
                            name: "Bulk Set Alerts",
                            link: "",
                            onClick: () => {
                              setDeleteBulkAlerts(false);
                              setSelectedRows([]);
                              setMultiSelect(false);
                              setBulkAlertType(null);
                              toggleModal();
                            },
                            class: ""
                          },
                          {
                            name: "Bulk Delete Alerts",
                            link: "",
                            onClick: () => {
                              setMultiSelect(true);
                              setBulkAlertType(null);

                              setDeleteBulkAlerts(true);
                            },
                            class: ""
                          }
                        ]
                      }}
                      // container="body"
                      direction="right"
                    />
                  ) : (
                    <></>
                  )}
                </div>
              }
            />
          </div>
          {!user?.isSmartSession &&
            !bulkUpdate &&
            updatePatientFeatureEnabled && (
              <div className="d-flex justify-content-center">
                <button
                  tabIndex="0"
                  className="btn btn-link  pt-1 pb-5"
                  data-testid="add-med-med-tab"
                  id="add-med-med-tab"
                  onClick={() => {
                    setSelectedMed({});
                    toggleAdd();
                    // const btn = document.getElementById("add-med-med-tab");
                    // btn.blur();
                  }}
                >
                  + Add New Med
                </button>
              </div>
            )}
        </div>
        <AlertTypeModal
          modal={modal}
          toggleModal={toggleModal}
          setBulkAlertType={setBulkAlertType}
          setMultiSelect={setMultiSelect}
          bulkAlertType={bulkAlertType}
          prnMeds={medlist.filter(
            (med) =>
              med.medicationSchedule?.prnReason ||
              med.medicationSchedule?.prnReasonOther ||
              med.medicationSchedule?.prnReason === 0
          )}
          scheduledMeds={medlist.filter(
            (med) =>
              !med.medicationSchedule?.prnReason &&
              !med.medicationSchedule?.prnReasonOther &&
              med.medicationSchedule?.prnReason !== 0
          )}
        />
      </div>{" "}
      <BulkActions
        multiSelect={multiSelect}
        deleteBulkAlerts={deleteBulkAlerts}
        selectedRows={selectedRows}
        setMultiSelect={setMultiSelect}
        setBulkUpdate={setBulkUpdate}
        fetchMedications={fetchMedications}
        setDeleteBulkAlerts={setDeleteBulkAlerts}
        toggleAlertModal={toggleAlertModal}
        setSelectedRow={setSelectedRow}
        bulkAlertType={bulkAlertType}
      />
      <AlertModal
        alertModal={alertModal}
        toggleAlertModal={toggleAlertModal}
        selectedRow={selectedRow}
        bulkUpdate={bulkUpdate}
        fetchMedications={fetchMedications}
        setBulkUpdate={setBulkUpdate}
        selectedRows={selectedRows}
        bulkAlertType={bulkAlertType}
        setMultiSelect={setMultiSelect}
      />
      <NoEditModal toggle={toggleNoEditModal} isOpen={noEditModal} />
      <ReviewSidebar
        user={user}
        isSidebarOpen={isSidebarOpen}
        scrollPosition={scrollPosition}
        isIE={isIE}
        is1600Width={is1600Width}
        toggleSidebar={toggleSidebar}
        sidebarType={sidebarType}
        leaflet={selectedSidebarRow.leaflet}
        medicationName={selectedSidebarRow.medicationName}
        clinicalInteractions={selectedSidebarRow.drugInteractions}
        allInteractions={true}
        table={
          <DDITableExpandable
            isIE={isIE}
            loading={false}
            clinicalInteractions={selectedSidebarRow.drugInteractions}
          />
        }
      />
      {/* make a hidden card that includes three static med tables */}
      {/* one for active meds, one for paused, and one for deleted */}
      {/* don't include info or alert columns */}
      {/* no table actions, just a basic header that says Active, Paused, or Deleted */}
      <div className="card bg-white d-none" ref={componentRef}>
        <div className="card-body p-0">
          <div className="px-5">
            <h2>My Medication List</h2>
            <h4>{patient?.name}</h4>
            <p>
              This medication list was created on{" "}
              {
                // today's date in MM/DD/YYYY format
                dayjs().format("M/D/YYYY")
              }
            </p>
          </div>
          {medications.filter((med) => !med.status).length > 0 && (
            <>
              <h4 className="text-primary px-5 py-4">Active Medications</h4>
              <MyStaticTable
                columns={printedMedColumns}
                data={medications.filter((med) => !med.status)}
              />{" "}
            </>
          )}
          {medications.filter((med) => med.status === 2).length > 0 && (
            <>
              <h4 className="text-primary px-5 py-4">Paused Medications</h4>
              <MyStaticTable
                columns={printedMedColumns}
                data={medications.filter((med) => med.status === 2)}
              />
            </>
          )}
          {medications.filter((med) => med.status === 1).length > 0 && (
            <>
              <h4 className="text-primary px-5 py-4">Deleted Medications</h4>
              <MyStaticTable
                columns={printedMedColumns}
                data={medications.filter((med) => med.status === 1)}
              />
            </>
          )}
          {medications.filter((med) => !med.drugDetailId).length > 0 && (
            <div className="px-5">
              <span>
                *Custom Med: This med was added by the mobile app user but was
                not found in our drug database.
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
MedlistTable.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number,
    original: PropTypes.object
  }),
  original: PropTypes.shape({
    id: PropTypes.number,
    isInEhr: PropTypes.bool,
    status: PropTypes.number
  }),
  cellprops: PropTypes.shape(
    {
      row: {
        id: PropTypes.number,
        original: PropTypes.object,
        cells: PropTypes.array
      }
    },
    {
      original: {
        id: PropTypes.number,
        medicationName: PropTypes.string,
        medicationStrength: PropTypes.object,
        medicationSchedule: PropTypes.object,
        isInEhr: PropTypes.bool,
        status: PropTypes.number,
        alert: PropTypes.object
      }
    }
  ),

  setMedlist: PropTypes.func,
  loadingMedications: PropTypes.bool,
  setBulkUpdate: PropTypes.func,
  selectedRows: PropTypes.array,
  fetchMedications: PropTypes.func,
  setMultiSelect: PropTypes.func,
  setDeleteBulkAlerts: PropTypes.func,
  bulkUpdate: PropTypes.bool,
  toggleAdd: PropTypes.func,
  medlist: PropTypes.array,
  setSelectedRows: PropTypes.func,
  setName: PropTypes.func,
  setRowId: PropTypes.func,
  setSelectedMed: PropTypes.func,
  setSelectedRow: PropTypes.func,
  toggleAlertModal: PropTypes.func,
  medications: PropTypes.array,
  multiSelect: PropTypes.bool,
  isMobileWidth: PropTypes.bool,
  deleteBulkAlerts: PropTypes.bool,
  selectedRow: PropTypes.object,
  alertModal: PropTypes.bool,
  isIE: PropTypes.bool,
  scrollPosition: PropTypes.number,
  setScrollPosition: PropTypes.func
};

export { MedlistTable };
