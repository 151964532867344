import React, { useState, useEffect } from "react";
import { MyAsyncCreatableSelect } from "../../_components";
import PropTypes from "prop-types";

export const AsyncMedDropdown = ({
  meds,
  setStrengthOptions,
  values,
  setFieldValue,
  setCustomMed,
  loadOptions,
  id,
  options,
  setOptions,
  disableFields,
  setNumberOfChars
}) => {
  const [currentSelections, setCurrentSelections] = useState([]);

  const disableOptions = React.useCallback(
    (vals, input) => {
      // If you're editing a med and is already a strength selected - add to current selections array so it is not disabled
      if (
        vals.strengthAndForm?.value &&
        currentSelections.indexOf(vals.strengthAndForm.value) < 0
      ) {
        setCurrentSelections((cs) => cs.concat(vals.strengthAndForm.value));
      }
      //If adding a med as opposed to editing it, there is no vals.medName yet so you have to use input in order to disable options
      //Since we're using input below, in the case of editing set input to the vals.medName
      if (!input) input = vals?.medName;
      const medDetails = [];
      const medStrengths = [];
      //for each med add the name and strength and form to arrays
      meds
        .filter((med) => med.status !== 1)
        .forEach((med) => {
          medDetails.push(med.medName?.label || med.medicationName);
          medStrengths.push(med.drugDetailId || med.strengthAndForm?.value);
        });

      //if the selected med is in the med details array, find every idx where that med is and store it
      // if (medDetails.indexOf(input.label) > -1) {
      let indexes = [];
      medDetails.forEach((med, idx) => {
        if (med === input.label) indexes.push(idx);
      });
      //store the already selected strengths using the indexes where the med was found
      let selectedStrengths = [];
      indexes.forEach((item) => selectedStrengths.push(medStrengths[item]));
      const alreadySelectedValues = [];
      //find strengths in list that are the same as the selected strengths
      selectedStrengths.forEach((item) => {
        alreadySelectedValues.push(
          input.strengths?.find(
            (value) => value.id === item
            // || value.label === item
          )
        );
      });
      alreadySelectedValues?.forEach((option) => {
        if (option) {
          if (currentSelections.indexOf(option.id) < 0) {
            //don't disable option if it is the current selection, otherwise disable (this is for when med is edited)
            option.disabled = "yes";
          } else option.disabled = "no";
        }
      });
      // }
      if (input)
        setStrengthOptions(
          input.strengths.map((strength) => {
            return {
              ...strength,
              value: strength.id,
              label: strength.strength + " " + strength.form
            };
          })
        );
      // }
      //use state to keep track of strength options and which ones to disable
    },
    [currentSelections, meds, setStrengthOptions]
  );

  useEffect(() => {
    //If this is a custom med you do not need to disable options - check for strength options
    if (values?.medName?.strengths?.length) {
      setCustomMed(false);
      if (
        meds?.length &&
        meds.find((med) => med.medicationName === values?.medName?.label)
      ) {
        disableOptions({
          medName: values.medName,
          strengthAndForm: values.strengthAndForm
        });
      }
    } else if (values?.medName) {
      setCustomMed(true);
    }
  }, [
    disableOptions,
    meds,
    setCustomMed,
    values.medName,
    values.strengthAndForm
  ]);

  return (
    <MyAsyncCreatableSelect
      name={id || "medName"}
      noOptionsMessage={() =>
        "Please type at least three characters to search our medication database"
      }
      label={
        <h6 className="mb-0">
          Medication Name <span className="text-danger">*</span>
        </h6>
      }
      placeholder={"Name of Med"}
      // cacheOptions
      value={values.medName}
      onChange={(val, input) => {
        setFieldValue("medName", input);
        if (input?.strengths)
          setStrengthOptions(
            input?.strengths
              .filter((strength) => !strength.isDeleted)
              .map((strength) => {
                return {
                  ...strength,
                  value: strength.id,
                  label: strength.strength + " " + strength.form
                };
              })
          );
        else setStrengthOptions([]);
        setOptions([]);
        setNumberOfChars(0);
      }}
      onInputChange={(val) => {
        if (val && values.medName && values.medName.label !== val) {
          setFieldValue("strengthAndForm", "");
          setFieldValue("strength", "");
          setFieldValue("form", "");
          setFieldValue("unit", "");
        }
      }
      }
      isDisabled={disableFields}
      className="basic-single"
      classNamePrefix="select"
      loadOptions={(value, callback) =>
        loadOptions(value, callback, setOptions, options)
      }
    />
  );
};
AsyncMedDropdown.propTypes = {
  meds: PropTypes.array,
  setStrengthOptions: PropTypes.func,
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  setCustomMed: PropTypes.func,
  loadOptions: PropTypes.func,
  id: PropTypes.string,
  options: PropTypes.array,
  setOptions: PropTypes.func,
  disableFields: PropTypes.bool,
  setNumberOfChars: PropTypes.func
};
