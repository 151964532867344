import React, { createContext, useCallback, useMemo, useReducer } from "react";
import PropTypes from "prop-types";

const PatientContext = createContext();

const initialState = {
  patientId: null,
  tasks: [],
  loadingTasks: false,
  outreach: [],
  loadingOutreach: false,
  medications: [],
  adherence: [],
  healthTrackers: [],
  loadingHealthTrackers: false,
  patient: {},
  adhSummary: {},
  clinicalInteractions: [],
  leaflets: [],
  englishLeaflets: [],
  spanishLeaflets: [],
  foodInteractions: [],
  allTasks: [],
  suggestedMeds: [],
  suggestedMedCount: 0,
  suggestedMedPages: 0,
  loadingSuggestedChanges: false,
  medNameList: [],
  activeMedNameList: [],
  allInteractions: []
};

const patientReducer = (state, action) => {
  switch (action.type) {
    case "SET_TASKS":
      return { ...state, tasks: action.payload };
    case "UPDATE_TASK":
      return {
        ...state,
        tasks: state.tasks.map((task) => {
          if (task.id === action.payload.id) {
            // update the task with action.payload.status
            return {
              ...task,
              status: action.payload.status
            };
          }
          return task;
        })
      };

    case "CANCEL_TASK":
      // remove task from state
      // Filter out the task with the specified ID
      return {
        ...state,
        tasks: state.tasks.filter((task) => task.id !== action.payload)
      };

    case "SET_OUTREACH":
      return { ...state, outreach: action.payload };

    case "SET_HEALTH_TRACKERS":
      return { ...state, healthTrackers: action.payload };

    // case "SET_INACTIVE_HEALTH_TRACKERS":
    //   return { ...state, inactiveHealthTrackers: action.payload };
    case "SET_MEDICATIONS":
      return { ...state, medications: action.payload };
    case "SET_MED_NAMES":
      return { ...state, medNameList: action.payload };
    case "SET_ACTIVE_MED_NAMES":
      return { ...state, activeMedNameList: action.payload };
    case "SET_ADHERENCE":
      return { ...state, adherence: action.payload };
    case "SET_PATIENT":
      return { ...state, patient: action.payload };
    case "SET_ADH_SUMMARY":
      return { ...state, adhSummary: action.payload };
    case "SET_CLINICAL_INTERACTIONS":
      return { ...state, clinicalInteractions: action.payload };
    case "SET_LEAFLETS":
      return { ...state, leaflets: action.payload };
    case "SET_LEAFLETS_SPANISH":
      return { ...state, spanishLeaflets: action.payload };
    case "SET_LEAFLETS_ENGLISH":
      return { ...state, englishLeaflets: action.payload };
    case "SET_FOOD_INTERACTIONS":
      return { ...state, foodInteractions: action.payload };
    case "SET_ALL_TASKS":
      return { ...state, allTasks: action.payload };
    case "SET_LOADING_TASKS":
      return { ...state, loadingTasks: action.payload };
    case "SET_LOADING_OUTREACH":
      return { ...state, loadingOutreach: action.payload };
    case "SET_LOADING_HEALTH_TRACKERS":
      return { ...state, loadingHealthTrackers: action.payload };
    case "SET_SUGGESTED_MEDS":
      return { ...state, suggestedMeds: action.payload };
    case "SET_LOADING_SUGGESTED_CHANGES":
      return { ...state, loadingSuggestedChanges: action.payload };
    case "SET_SUGGESTED_MED_COUNT":
      return { ...state, suggestedMedCount: action.payload };
    case "SET_SUGGESTED_MED_PAGES":
      return { ...state, suggestedMedPages: action.payload };

    case "SET_PATIENT_ID":
      return { ...state, patientId: action.payload };
    case "SET_ALL_INTERACTIONS":
      return { ...state, allInteractions: action.payload };

    default:
      return state;
  }
};

const PatientContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(patientReducer, initialState);

  const setPatientId = useCallback((patientId) => {
    dispatch({ type: "SET_PATIENT_ID", payload: patientId });
  }, []);

  const setTasks = useCallback((tasks) => {
    dispatch({ type: "SET_TASKS", payload: tasks });
  }, []);

  const updateTask = useCallback((task) => {
    dispatch({ type: "UPDATE_TASK", payload: task });
  }, []);

  const cancelTask = useCallback((taskId) => {
    dispatch({ type: "CANCEL_TASK", payload: taskId });
  }, []);

  const setOutreach = useCallback((outreach) => {
    dispatch({ type: "SET_OUTREACH", payload: outreach });
  }, []);

  const setHealthTrackers = useCallback((healthTrackers) => {
    dispatch({ type: "SET_HEALTH_TRACKERS", payload: healthTrackers });
  }, []);

  const setMedications = useCallback((medications) => {
    dispatch({ type: "SET_MEDICATIONS", payload: medications });
  }, []);

  const setAdherence = useCallback((adherence) => {
    dispatch({ type: "SET_ADHERENCE", payload: adherence });
  }, []);

  const setPatient = useCallback((patient) => {
    dispatch({ type: "SET_PATIENT", payload: patient });
  }, []);

  const setAdhSummary = useCallback((adhSummary) => {
    dispatch({ type: "SET_ADH_SUMMARY", payload: adhSummary });
  }, []);

  const setClinicalInteractions = useCallback((clinicalInteractions) => {
    dispatch({
      type: "SET_CLINICAL_INTERACTIONS",
      payload: clinicalInteractions
    });
  }, []);

  const setLeaflets = useCallback((leaflets) => {
    dispatch({ type: "SET_LEAFLETS", payload: leaflets });
  }, []);

  const setSpanishLeaflets = useCallback((spanishLeaflets) => {
    dispatch({ type: "SET_LEAFLETS_SPANISH", payload: spanishLeaflets });
  }, []);

  const setEnglishLeaflets = useCallback((englishLeaflets) => {
    dispatch({ type: "SET_LEAFLETS_ENGLISH", payload: englishLeaflets });
  }, []);

  const setFoodInteractions = useCallback((foodInteractions) => {
    dispatch({ type: "SET_FOOD_INTERACTIONS", payload: foodInteractions });
  }, []);

  const setAllTasks = useCallback((allTasks) => {
    dispatch({ type: "SET_ALL_TASKS", payload: allTasks });
  }, []);

  const setLoadingTasks = useCallback((loadingTasks) => {
    dispatch({ type: "SET_LOADING_TASKS", payload: loadingTasks });
  }, []);

  const setLoadingOutreach = useCallback((loadingOutreach) => {
    dispatch({ type: "SET_LOADING_OUTREACH", payload: loadingOutreach });
  }, []);

  const setLoadingHealthTrackers = useCallback((loadingHealthTrackers) => {
    dispatch({
      type: "SET_LOADING_HEALTH_TRACKERS",
      payload: loadingHealthTrackers
    });
  }, []);

  const setSuggestedMeds = useCallback((suggestedMeds) => {
    dispatch({
      type: "SET_SUGGESTED_MEDS",
      payload: suggestedMeds
    });
  }, []);

  const setSuggestedMedCount = useCallback((suggestedMedCount) => {
    dispatch({
      type: "SET_SUGGESTED_MED_COUNT",
      payload: suggestedMedCount
    });
  }, []);

  const setSuggestedMedPages = useCallback((suggestedMedPages) => {
    dispatch({
      type: "SET_SUGGESTED_MED_PAGES",
      payload: suggestedMedPages
    });
  }, []);

  const setLoadingSuggestedChanges = useCallback((loadingSuggestedChanges) => {
    dispatch({
      type: "SET_LOADING_SUGGESTED_CHANGES",
      payload: loadingSuggestedChanges
    });
  }, []);

  const setMedNameList = useCallback((medNameList) => {
    dispatch({
      type: "SET_MED_NAMES",
      payload: medNameList
    });
  }, []);

  const setActiveMedNames = useCallback((activeMedNameList) => {
    dispatch({
      type: "SET_ACTIVE_MED_NAMES",
      payload: activeMedNameList
    });
  }, []);

  const setAllInteractions = useCallback((allInteractions) => {
    dispatch({
      type: "SET_ALL_INTERACTIONS",
      payload: allInteractions
    });
  }, []);
  const contextValue = useMemo(() => {
    return {
      state,
      setTasks,
      updateTask,
      cancelTask,
      setOutreach,
      setHealthTrackers,
      // setInactiveHealthTrackers,
      setMedications,
      setAdherence,
      setPatient,
      setAdhSummary,
      setClinicalInteractions,
      setLeaflets,
      setSpanishLeaflets,
      setEnglishLeaflets,
      setFoodInteractions,
      setAllTasks,
      setLoadingTasks,
      setLoadingOutreach,
      setLoadingHealthTrackers,
      setSuggestedMeds,
      setLoadingSuggestedChanges,
      setPatientId,
      setSuggestedMedCount,
      setSuggestedMedPages,
      setMedNameList,
      setActiveMedNames,
      setAllInteractions
    };
  }, [
    state,
    setTasks,
    updateTask,
    cancelTask,
    setOutreach,
    setHealthTrackers,
    // setInactiveHealthTrackers,
    setMedications,
    setAdherence,
    setPatient,
    setAdhSummary,
    setClinicalInteractions,
    setLeaflets,
    setSpanishLeaflets,
    setEnglishLeaflets,
    setFoodInteractions,
    setAllTasks,
    setLoadingTasks,
    setLoadingOutreach,
    setLoadingHealthTrackers,
    setSuggestedMeds,
    setLoadingSuggestedChanges,
    setPatientId,
    setSuggestedMedCount,
    setSuggestedMedPages,
    setMedNameList,
    setActiveMedNames,
    setAllInteractions
  ]);

  return (
    <PatientContext.Provider value={contextValue}>
      {children}
    </PatientContext.Provider>
  );
};

const usePatientContext = () => {
  const context = React.useContext(PatientContext);
  if (context === undefined) {
    return initialState;
  }
  return context;
};

export { PatientContext, PatientContextProvider, usePatientContext };

PatientContextProvider.propTypes = {
  children: PropTypes.node
};
