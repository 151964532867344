import React from "react";

export const ChartWrapper = React.lazy(async () => {
  const module = await import("@syncfusion/ej2-react-charts");

  // Destructure the needed components from the imported module
  const {
    AnnotationDirective,
    AnnotationsDirective,
    Category,
    ChartAnnotation,
    ChartComponent,
    DataLabel,
    Inject,
    Legend,
    RangeColorSettingDirective,
    RangeColorSettingsDirective,
    ScatterSeries,
    SeriesCollectionDirective,
    SeriesDirective,
    StripLine,
    Tooltip
  } = module;

  // Return a default component that utilizes these imported components
  return {
    default: (props) => (
      <ChartComponent {...props.chartProps}>
        <Inject
          services={[
            ScatterSeries,
            Category,
            Legend,
            Tooltip,
            DataLabel,
            StripLine,
            ChartAnnotation
          ]}
        />
        <SeriesCollectionDirective>
          <SeriesDirective {...props.seriesProps} />
        </SeriesCollectionDirective>
        <AnnotationsDirective>
          <AnnotationDirective {...props.annotationProps1} />
          <AnnotationDirective {...props.annotationProps2} />
        </AnnotationsDirective>
        <RangeColorSettingsDirective>
          <RangeColorSettingDirective {...props.rangeColorSettingProps1} />
          <RangeColorSettingDirective {...props.rangeColorSettingProps2} />
          <RangeColorSettingDirective {...props.rangeColorSettingProps3} />
        </RangeColorSettingsDirective>
      </ChartComponent>
    )
  };
});

export const SimpleChartWrapper = React.lazy(async () => {
  const module = await import("@syncfusion/ej2-react-charts");

  // Destructure the needed components from the imported module
  const {
    Category,
    ChartComponent,
    DataLabel,
    Inject,
    Legend,
    ScatterSeries,
    SeriesCollectionDirective,
    SeriesDirective,
    Tooltip
  } = module;

  // Return a default component that utilizes these imported components
  return {
    default: (props) => {
      return (
        <ChartComponent {...props.chartProps}>
          <Inject
            services={[ScatterSeries, Category, Legend, Tooltip, DataLabel]}
          />
          <SeriesCollectionDirective>
            {props.seriesPropsMap.map((seriesProps, idx) => (
              <SeriesDirective {...seriesProps} />
            ))}
          </SeriesCollectionDirective>
        </ChartComponent>
      );
    }
  };
});
