import React, { useEffect, useState } from "react";
import { MySelect } from "../_components";
import { useTableStateContext } from "../context/stateContext";
import PropTypes from "prop-types";

const HeaderDropdown = ({
  headerTabs,
  setVisibleTable,
  handleTabChange,
  visibleTable,
  patientListId
}) => {
  const [selectItems, setSelectItems] = useState([]);
  const { updatePatientListTableState } = useTableStateContext();
  useEffect(() => {
    setSelectItems([]);
    setSelectItems((state) =>
      state.concat(
        headerTabs?.map((tab) => {
          return { label: tab.name, value: tab.id };
        })
      )
    );
  }, [headerTabs]);

  const goToTab = (id, index) => {
    // update the state of the patientListTableState object to include the new tab id as tab
    updatePatientListTableState({
      tab: id
    });

    setVisibleTable(1);
    handleTabChange(id, index);
  };

  const handleSelectChange = (name, val) => {
    let item = selectItems.find((itm) => itm.value === val.value);
    goToTab(val.value, selectItems.indexOf(item));
    // }
  };
  return (
    <div className="px-4 px-lg-6 col-lg-3 col-md-4 col-6">
      <MySelect
        options={selectItems}
        name="headertab-select"
        onChange={(name, val) => handleSelectChange(name, val)}
        placeholder="All Patients"
        value={
          visibleTable === 1 &&
          selectItems.find((item) => item.value === patientListId)
        }
      />
    </div>
  );
};

HeaderDropdown.propTypes = {
  headerTabs: PropTypes.array,
  setVisibleTable: PropTypes.func,
  handleTabChange: PropTypes.func,
  visibleTable: PropTypes.number,
  patientListId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export { HeaderDropdown };
