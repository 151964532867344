import React from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import PropTypes from "prop-types";

export const MyCircularProgressbar = ({ average, color }) => {
  return (
    <>
      <div
        className="tab-overview-circular-progressbar"
        aria-label={"circular progressbar " + average + "%"}
      >
        <CircularProgressbar
          value={average}
          circleRatio={0.75}
          text={`${average || 0}%`}
          top
          styles={buildStyles({
            pathColor: color,
            textColor: "#1f2f36",
            trailColor: "#edeeef",
            rotation: 1 / 2 + 1 / 8,
            textSize: "1.6rem"
          })}
        />
      </div>
      <p className="mb-0 small text-nowrap mt-neg-8px">Avg. Adherence</p>
    </>
  );
};
MyCircularProgressbar.propTypes = {
  average: PropTypes.number,
  color: PropTypes.string
};
