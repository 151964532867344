import { useAuth0 } from "@auth0/auth0-react";
import classnames from "classnames";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { NavItem } from "reactstrap";
import { Notifications, TemporaryDrawer } from "../_components";
import { allAccess, clearLocalStorage, permissionsEnum } from "../_helpers";
import {
  UseMobileOrSmallTabletMediaQuery,
  UseMobileOrTabletMediaQuery
} from "../_helpers/media-queries";
import {
  notificationService,
  patientInviteService,
  patientService
} from "../_services/index";
import { SmartContext, useSmartContext } from "../context/smartContext";
import { useUserContext } from "../context/userContext";
import fullColorLogo from "../images/ED-Hortizontal_2-color_Logo.png";
import fullLogo from "../images/EDHortizontal_White_Logo.png";
import logo from "../images/EDlogo.png";
import { ReactComponent as ChevronDown } from "../images/chevron_down_small.svg";
import { ReactComponent as Document } from "../images/description_24dp_1F2F36_FILL0_wght400_GRAD0_opsz24.svg";
import { ReactComponent as Support } from "../images/glyph-support.svg";
import { ReactComponent as Menu } from "../images/glyph_menu.svg";
import { ReactComponent as Logout } from "../images/glyph_logout.svg";
import { ReactComponent as Speech } from "../images/glyph_speech.svg";
import { ReactComponent as Graph } from "../images/monitoring_24dp_1F2F36_FILL0_wght400_GRAD0_opsz24.svg";
import { ReactComponent as Connection } from "../images/add_link_24dp_1F2F36_FILL0_wght400_GRAD0_opsz24.svg";
import { ReactComponent as List } from "../images/format_list_bulleted_24dp_1F2F36_FILL0_wght400_GRAD0_opsz24.svg";
import { ReactComponent as Mail } from "../images/mail_24dp_1F2F36_FILL0_wght400_GRAD0_opsz24.svg";
import { ReactComponent as Person } from "../images/digital_wellbeing_24dp_1F2F36_FILL0_wght400_GRAD0_opsz24.svg";
import { ReactComponent as Group } from "../images/groups_24dp_1F2F36_FILL0_wght400_GRAD0_opsz24.svg";
import { ReactComponent as User } from "../images/person_24dp_1F2F36_FILL0_wght400_GRAD0_opsz24.svg";
import { ReactComponent as UserSmart } from "../images/nav_user.svg";
import { ReactComponent as GraphSmart } from "../images/graph.svg";
import { ReactComponent as Info } from "../images/glyph_info.svg";
import { ReactComponent as RateReview } from "../images/rate-review.svg";
import { ReactComponent as QuickReference } from "../images/quick_reference_24dp_FILL0_wght400_GRAD0_opsz24.svg";
import { ReactComponent as IntegrationInstructions } from "../images/integration_instructions_24dp_FILL0_wght400_GRAD0_opsz24.svg";
import { ReactComponent as Settings } from "../images/settings_24dp_FILL0_wght400_GRAD0_opsz24.svg";
import { ReactComponent as AdvancedSettings } from "../images/settings_applications_24dp_FILL0_wght400_GRAD0_opsz24.svg";
import { ReactComponent as Summarize } from "../images/summarize_24dp_FILL0_wght400_GRAD0_opsz24.svg";
import { ReactComponent as AdminPanel } from "../images/manage_accounts_24dp_1F2F36_FILL0_wght400_GRAD0_opsz24.svg";
import { PrivateSupport } from "../support/PrivateSupport";
import { SuggestAFeature } from "../support/SuggestAFeature";
import { MyDropdown } from "./DropdownMenu";
import { useAlertContext } from "../context/alertContext";

const userSidebarDetails = {
  name: "Sidebar",
  padding: true,
  items: [
    {
      name: "Patient List",
      link: "/",
      glyph: <List className="mb-1 h-20px" aria-label="patient list" />
    },
    {
      name: "Invitations",
      glyph: <Mail className="mb-1 h-20px" aria-label="manage invitations" />,
      collapsible: true,
      items: [
        {
          name: "Invite a Patient",
          glyph: (
            <Person className="mb-1 h-20px" aria-label="invite a patient" />
          ),
          link: "/invite/patient/manual"
        },
        {
          name: "Manage Invitations",
          glyph: (
            <Mail className="mb-1 h-20px" aria-label="manage invitations" />
          ),
          link: "/invite/manage-invitations"
        },

        {
          name: "Connection Requests",
          glyph: (
            <Connection
              className="mb-1 h-20px"
              aria-label="connection requests"
            />
          ),
          link: "/invite/connections"
        }
      ]
    },
    {
      name: "Tasks",
      glyph: <Document className="mb-1 h-20px" aria-label="tasks" />,
      link: "/tasks"
    },
    {
      name: "Reports",
      glyph: <Summarize className="mb-1 h-20px" aria-label="reports" />,
      link: "/reports"
    },
    {
      name: "Resources",
      glyph: <QuickReference className="mb-1 h-20px" aria-label="resources" />,
      link: "/resources"
    },
    {
      name: "Admin",
      glyph: <AdminPanel className="mb-1 h-20px" aria-label="groups" />,
      collapsible: true,
      items: [
        {
          name: "Groups",
          glyph: <Group className="mb-1 h-20px" aria-label="groups" />,
          link: "/admin/groups"
        },
        {
          name: "Users",
          glyph: <User className="mb-1 h-20px" aria-label="users" />,
          link: "/admin/users"
        },
        {
          name: "Patients",
          glyph: <Person className="mb-1 h-20px" aria-label="paitents" />,
          link: "/admin/patients"
        },
        {
          name: "Patient Lists",
          glyph: <List className="mb-1 h-20px" aria-label="paitents" />,
          link: "/admin/patient-lists"
        },
        {
          name: "Risk Profiles",
          glyph: <Graph className="mb-1 h-20px" aria-label="risk profiles" />,
          link: "/admin/risk-profiles"
        },
        // {
        //   name: "Medication Plans",
        //   glyph: (
        //     <Document
        //       className="mb-1 h16-w16-style"
        //       aria-label="medication plans"
        //     />
        //   ),
        //   link: "/admin/plans"
        // },
        {
          name: "Integrations",
          glyph: (
            <IntegrationInstructions
              className="mb-1 h-20px"
              aria-label="integrations"
            />
          ),
          link: "/admin/integrations"
        },
        {
          name: "Settings",
          glyph: <Settings className="mb-1 h-20px" aria-label="branding" />,
          link: "/admin/branding"
        },
        {
          name: "Advanced",
          glyph: (
            <AdvancedSettings className="mb-1 h-20px" aria-label="advanced" />
          ),
          link: "/admin/advanced"
        }
      ]
    }
  ]
};

const NotificationDropdown = ({ smart, isMobileWidth }) => {
  // access patientId from smartContext if smartContext is true
  const { patientId } = useSmartContext();
  const { userState } = useUserContext();
  const { user } = userState;
  const [notifications, setNotifications] = useState([]);
  const [lastLoadedPage, setLastLoadedPage] = React.useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [newNotifications, setNewNotifications] = useState(0);
  const [loadingNotifications, setLoadingNotifications] = useState(false);

  const mounted = React.useRef(false);
  function sortByCreatedAt(a, b) {
    return new Date(b.sendAt) - new Date(a.sendAt);
  }

  const fetchNotifications = React.useCallback(
    ({ pageIndex, pageSize, isLoadMore, onPageLoad }, setLoading) => {
      if (user?.isSmartSession === false || patientId) {
        notificationService
          .getIsNew(patientId)
          .then((response) => {
            if (setLoading) setLoading(false);
            if (
              (response.new_unread_notifications || onPageLoad) &&
              mounted.current
            ) {
              setNewNotifications(response.new_unread_notifications_count);
              setLoadingNotifications(true);
              notificationService
                .getNotifications(patientId, pageSize, pageIndex)
                .then((response) => {
                  if (mounted.current) {
                    if (response.data.length > 0) {
                      if (isLoadMore) {
                        setNotifications((prev) =>
                          prev.concat(response.data).sort(sortByCreatedAt)
                        );
                      } else
                        setNotifications(response.data.sort(sortByCreatedAt));
                    }
                    setTotalPages(response.totalPages);
                    setLastLoadedPage(response.currentPage);
                    setLoadingNotifications(false);
                  }
                })
                .catch((e) => {
                  console.log(e);
                  setLoadingNotifications(false);
                });
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    [patientId, user?.isSmartSession]
  );

  const loadMore = React.useCallback(() => {
    if (lastLoadedPage < totalPages) {
      setLastLoadedPage(lastLoadedPage + 1);
      fetchNotifications({
        pageIndex: lastLoadedPage + 1,
        pageSize: 20,
        isLoadMore: true,
        onPageLoad: false
      });
    }
  }, [fetchNotifications, lastLoadedPage, totalPages]);

  React.useEffect(() => {
    const interval = 5 * 60 * 1000; // 300,000 milliseconds = 5 minutes
    const apiTimer = setInterval(
      () =>
        fetchNotifications({
          pageIndex: 1,
          pageSize: 20,
          isLoadMore: false,
          onPageLoad: false
        }),
      interval
    );
    mounted.current = true; // Will set it to true on mount ...
    return () => {
      clearInterval(apiTimer);
      mounted.current = false;
    }; // ... and to false on unmount
  }, [fetchNotifications]);

  useEffect(() => {
    fetchNotifications({
      pageIndex: 1,
      pageSize: 20,
      isLoadMore: false,
      onPageLoad: true
    });
  }, [fetchNotifications]);

  return (
    <Notifications
      setNotifications={setNotifications}
      notifications={notifications}
      newNotifications={newNotifications}
      setNewNotifications={setNewNotifications}
      loadMore={loadMore}
      loadingNotifications={loadingNotifications}
      smart={smart}
      isMobileWidth={isMobileWidth}
      fetchNotifications={fetchNotifications}
    />
  );
};

NotificationDropdown.propTypes = {
  smart: PropTypes.bool,
  isMobileWidth: PropTypes.bool
};
function MyNav({
  user,
  brandLogo,
  brandFavicon,
  userPermissions,
  tenantFeatures,
  profilePicture
}) {
  const { pathname } = useLocation();
  const is768Width = UseMobileOrTabletMediaQuery();
  const { logout } = useAuth0();
  const [defaultLogo, setDefaultLogo] = useState(fullColorLogo);
  const [favicon, setFavicon] = useState(logo);
  const [supportModal, setSupportModal] = useState(false);
  const [featureModal, setFeatureModal] = useState(false);
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  const [supportFeatureEnabled, setSupportFeatureEnabled] = useState(false);

  // This is for mobile drawer that opens to a different menu based on what part of the app you're in

  useEffect(() => {
    if (brandLogo) {
      setDefaultLogo(brandLogo);
    } else {
      setDefaultLogo(fullColorLogo);
    }
  }, [brandLogo]);

  useEffect(() => {
    if (brandFavicon) {
      setFavicon(brandFavicon);
    } else {
      setFavicon(logo);
    }
  }, [brandFavicon]);

  useEffect(() => {
    if (brandLogo !== fullColorLogo) {
      const bl = document.getElementById("brandLogo");
      bl?.addEventListener("error", function handleError() {
        setDefaultLogo(fullColorLogo);
      });
    }
  }, [brandLogo]);
  useEffect(() => {
    if (favicon !== logo) {
      const fav = document.getElementById("brandFavicon");
      fav?.addEventListener("error", function handleError() {
        setFavicon(logo);
      });
    }
  }, [favicon]);

  useEffect(() => {
    if (
      tenantFeatures?.["Notifications"] &&
      (allAccess(userPermissions) ||
        userPermissions?.indexOf(permissionsEnum.UserNotifications) > -1)
    ) {
      setNotificationsEnabled(true);
    }
    if (
      tenantFeatures?.["Support"] &&
      (allAccess(userPermissions) ||
        userPermissions?.indexOf(permissionsEnum.UserSupport) > -1)
    )
      setSupportFeatureEnabled(true);
  }, [tenantFeatures, userPermissions]);
  //Do not show the nav bar if the user is a smart user or not logged in
  if (!user) {
    return null;
  }

  const firstName = user?.firstName;
  const lastName = user?.lastName;

  const org = user?.organization;

  const toggleSupport = () => setSupportModal(!supportModal);
  const toggleFeature = () => setFeatureModal(!featureModal);

  const dropdownDetails = {
    name: "Support",
    glyph: (
      <Support
        className={classnames(
          { "mt-1 ml-2": is768Width },

          "support-glyph glyph-gray"
        )}
        aria-label="support"
      />
    ),

    header: <div className="">Help & Support</div>,
    items: [
      {
        name: "Help Center",
        link: "https://support.everydose.ai/provider/docs/faqs/",
        disabled: false,
        openTabType: "_blank"
      },

      {
        name: "Terms & Conditions",
        link: "https://www.everydose.ai/terms-provider/",
        disabled: false,
        openTabType: "_blank"
      },
      // { name: "Privacy Policy", link: "", disabled: true },
      {
        name: "Suggest a Feature",
        onClick: toggleFeature,
        disabled: false
      },
      {
        name: "Contact Support",
        onClick: toggleSupport,
        disabled: false
      }
    ],
    padding: true
  };

  const invitesDropdownDetails = {
    name: "Invitations",
    glyph: (
      <ChevronDown aria-label="dropdown" className="glyph-dark ml-3 h-8px" />
    ),
    title: "Invitations",
    items: [
      {
        name: "Invite a Patient",
        link: "/invite/patient/manual"
      },
      {
        name: "Manage Invitations",
        link: "/invite/manage-invitations"
      },
      {
        name: "Connection Requests",
        link: "/invite/connections"
      }
    ]
  };

  const accountDropdownDetails = {
    name: "Account",
    glyph: (
      <div
        className="e-avatar e-avatar-xsmall e-avatar-circle mr-lg-2 ml-lg-0 mx-2 my-lg-0 my-2"
        data-testid="profile-pic"
      >
        {profilePicture ? (
          <img
            className="image"
            aria-label="profile-picture"
            src={profilePicture}
            alt={user?.firstName[0] + user?.lastName[0]}
          />
        ) : (
          <>{user?.firstName[0] + user?.lastName[0]}</>
        )}
      </div>
    ),
    glyph2: (
      <ChevronDown aria-label="dropdown" className="glyph-dark ml-3 h-8px" />
    ),
    title: firstName + " " + lastName,
    header: (
      <div className="row ml-1">
        <div
          className="e-avatar e-avatar-small e-avatar-circle col-1 mr-2"
          data-testid="profile-pic"
        >
          {profilePicture ? (
            <img
              className="image"
              src={profilePicture}
              alt={user?.firstName[0] + user?.lastName[0]}
            />
          ) : (
            <>{user?.firstName[0] + user?.lastName[0]}</>
          )}
        </div>
        <div className="col-8 px-2 text-break white-space-prewrap">
          <div className="text-secondary  mb-0">
            {firstName + " " + lastName}
          </div>
          <div className="py-1 text-secondary font-size-normal">{org}</div>
        </div>
      </div>
    ),
    padding: true,
    items: [
      {
        name: "Profile & Settings",
        link: "/profile",
        disabled: false,
        glyph: <User aria-label="user profile" className="h20-w20-style" />
      },

      {
        name: "Sign Out",
        link: "",
        onClick: () => {
          clearLocalStorage();
          logout({ logoutParams: { returnTo: window.location.origin } });
        },
        glyph: <Logout aria-label="logout" className="h20-w20-style ml-2px" />
      }
    ]
  };
  return (
    <>
      {is768Width && (
        <nav className="navbar navbar-expand navbar-light bg-white d-flex justify-content-between align-items-center">
          <div className="navbar-nav align-items-center">
            <TemporaryDrawer
              logo={
                <img
                  id="brandLogo"
                  src={defaultLogo}
                  alt={user?.tenantsPreference?.appName || "EveryDose"}
                  className="logo brand-style"
                  data-testid="brand-logo"
                />
              }
              details={userSidebarDetails}
              position="Left"
              isMobileWidth={true}
            />
          </div>
          <div className="navbar-nav pr-0 align-items-center gap-6px">
            {notificationsEnabled ? (
              <NotificationDropdown isMobileWidth={true} />
            ) : (
              <></>
            )}
            <TemporaryDrawer
              details={
                supportFeatureEnabled && user?.userRole
                  ? dropdownDetails
                  : {
                      ...dropdownDetails,
                      items: dropdownDetails.items.slice(0, 3)
                    }
              }
              position="Right"
              isMobileWidth={true}
            />
            <TemporaryDrawer
              details={accountDropdownDetails}
              position="Right"
              isMobileWidth={true}
            />
          </div>
        </nav>
      )}
      {!is768Width && (
        <nav className="navbar navbar-expand navbar-light bg-white d-flex justify-content-between pr-0">
          <div className="navbar-nav d-flex align-items-center">
            <NavLink
              aria-label="home"
              end
              to="/"
              className="nav-item nav-link mr-auto pb-6  pr-7"
            >
              <img
                id="brandFavicon"
                src={favicon}
                alt={user?.tenantsPreference?.appName || "EveryDose"}
                className="position-absolute logo h-30px"
                data-testid="brand-favicon"
              />
            </NavLink>
            <div className="pt-1 d-flex">
              <div
                className={classnames({
                  "active-bottom-border":
                    pathname === "/" || pathname.startsWith("/patients")
                })}
              >
                <NavLink
                  end
                  to="/"
                  padding="true"
                  className="nav-item nav-link mx-5 mx-md-2"
                >
                  Patients
                </NavLink>
              </div>
              <div
                className={classnames({
                  "active-bottom-border": pathname.startsWith("/invite")
                })}
              >
                <MyDropdown
                  details={invitesDropdownDetails}
                  padding="true"
                  auto={true}
                />
              </div>
              <div
                className={classnames({
                  "active-bottom-border": pathname.startsWith("/tasks")
                })}
              >
                <NavLink
                  end
                  to="/tasks"
                  className="nav-item nav-link  mx-5 mx-md-2"
                >
                  Tasks
                </NavLink>
              </div>
              <div
                className={classnames({
                  "active-bottom-border": pathname.startsWith("/reports")
                })}
              >
                <NavLink
                  end
                  to="/reports"
                  className="nav-item nav-link mx-5 mx-md-2"
                >
                  Reports
                </NavLink>
              </div>
              <div
                className={classnames({
                  "active-bottom-border": pathname.startsWith("/resources")
                })}
              >
                <NavLink
                  end
                  to="/resources"
                  className="nav-item nav-link mx-5 mx-md-2"
                >
                  Resources
                </NavLink>
              </div>
              {user?.userRole > 0 && (
                <div
                  className={classnames({
                    "active-bottom-border": pathname.startsWith("/admin")
                  })}
                >
                  <NavLink
                    aria-label="admin"
                    to="/admin/groups"
                    className="nav-item nav-link mx-5 mx-md-2"
                  >
                    Admin Center
                  </NavLink>
                </div>
              )}
            </div>
          </div>

          <div className="navbar-nav">
            <div className="d-flex align-items-center">
              {notificationsEnabled ? <NotificationDropdown /> : <></>}
              <MyDropdown
                details={
                  supportFeatureEnabled && user?.userRole
                    ? dropdownDetails
                    : {
                        ...dropdownDetails,
                        items: dropdownDetails.items.slice(0, 3)
                      }
                }
                auto={true}
              />
            </div>
            <div className="mx-5 my-2 nav-line"></div>
            <div className="pt-1">
              <MyDropdown
                details={accountDropdownDetails}
                direction="right"
                auto={true}
              />
            </div>
          </div>
        </nav>
      )}
      <PrivateSupport isOpen={supportModal} toggle={toggleSupport} />
      <SuggestAFeature isOpen={featureModal} toggle={toggleFeature} />
    </>
  );
}
MyNav.propTypes = {
  user: PropTypes.object,
  brandLogo: PropTypes.string,
  brandFavicon: PropTypes.string,
  userPermissions: PropTypes.array,
  tenantFeatures: PropTypes.object,
  profilePicture: PropTypes.string
};

const SmartNav = (props) => {
  const { patientId, patientInviteStatus, isMPages } = useContext(SmartContext);
  const { userState } = useUserContext();
  const { pathname } = useLocation();
  const { alertMethods } = useAlertContext();
  const { user, tenantFeatures, userPermissions } = userState;
  const isSmallTabletWidth = UseMobileOrSmallTabletMediaQuery();

  const navigate = useNavigate();
  const [hideNav, setHideNav] = useState(false);
  const [name, setName] = useState("");
  const [memberSince, setMemberSince] = useState("");
  const [supportVisible, setSupportVisible] = useState(true);
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  const [link, setLink] = useState("");

  useEffect(() => {
    if (
      isMPages ||
      pathname.startsWith("/smart/Redirecting") ||
      pathname.startsWith("/smart/request-access") ||
      pathname.startsWith("/smart/go-live") ||
      pathname.startsWith("/smart/40") ||
      pathname === "/support" ||
      pathname === "/smart/mobile-link"
    )
      setHideNav(true);
    else setHideNav(false);
  }, [pathname, isMPages]);

  useEffect(() => {
    if (
      tenantFeatures?.["Notifications"] &&
      (userPermissions?.indexOf(permissionsEnum.SmartNotifications) > -1 ||
        allAccess(userPermissions))
    ) {
      setNotificationsEnabled(true);
    }
  }, [tenantFeatures, userPermissions]);

  useEffect(() => {
    if (patientId) {
      if (
        patientInviteStatus === 0 ||
        patientInviteStatus === 4 ||
        patientInviteStatus === 5
      ) {
        setLink(`/smart/invite/${patientId}`);
      } else if (patientInviteStatus === 1 || patientInviteStatus === 3) {
        setLink(`/smart/manage-invitations/manage/${patientId}`);
      } else if (patientInviteStatus >= 6) {
        setLink(`/smart/400`);
      } else {
        setLink(`/smart/patients/${patientId}`);
      }
    } else setLink("/smart/400");
  }, [patientId, patientInviteStatus]);

  const dropdownDetails = {
    name: "Settings",
    glyph: <Menu className="mr-2" aria-label="menu" />,
    items: [
      {
        name: "FAQs",
        glyph: (
          <Info className="glyph-dark mr-3 mb-1 w-16px" aria-label="faqs" />
        ),
        onClick: () => {
          try {
            window.open(
              "https://support.everydose.ai/provider/docs/faqs",
              "_blank"
              // "noopener"
            );
          } catch (e) {
            alertMethods.error(
              "Popup blocked by your browser. If the issue persists, please refresh the page and try again"
            );
          }
        }
      },
      {
        name: "Support",
        link: "/smart/support",
        glyph: (
          <Speech className="glyph-dark mr-3 w-16px" aria-label="support" />
        )
      },
      {
        name: "Settings",
        glyph: (
          <UserSmart
            aria-label="user profile"
            className="glyph-dark mr-3 w-16px h-18px"
          />
        ),
        onClick: () => {
          navigate("/smart/profile");
        },
        disabled: false
      },
      {
        name: "Reports",
        link: "/smart/reports",
        glyph: (
          <GraphSmart
            className="glyph-dark mr-3 w-16px mt-neg-10px"
            aria-label="reports"
          />
        )
      },
      {
        name: "Feedback",
        glyph: (
          <RateReview
            className="glyph-dark mr-3 w-16px"
            aria-label="feedback"
          />
        ),
        link: "/smart/feedback"
      }
    ]
  };

  useEffect(() => {
    let mounted = true;
    if (user?.patientId) {
      patientInviteService
        .getInvite(user?.patientId)
        .then((response) => {
          if (mounted) {
            setName(response.name);
          }
        })
        .catch((e) => {
          console.log(e);
        });
      patientService
        .getPatient(user?.patientId)
        .then((response) => {
          if (mounted) {
            let formattedDate = new Date(
              response.memberSince
            ).toLocaleDateString("en-US");
            if (formattedDate !== "12/31/1" && formattedDate !== "1/1/1")
              setMemberSince(formattedDate);
            else setMemberSince("");
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }

    return function cleanup() {
      mounted = false;
    };
  }, [user]);
  useEffect(() => {
    if (pathname === "/support" || isMPages) setSupportVisible(false);
    else setSupportVisible(true);
  }, [isMPages, pathname]);

  return (
    <nav className="navbar navbar-smart navbar-expand navbar-dark bg-dark d-flex justify-content-between pr-md-6 pr-4">
      <div className="mr-auto">
        <NavLink
          to={hideNav ? null : link}
          className="nav-item"
          data-testid="smart-logo"
        >
          <img src={fullLogo} alt="EveryDose" className="smart-logo" />
        </NavLink>

        {/* if location does not start with /smart/redirecting and /smart/request-access show this */}
        {!hideNav && patientId && (
          <NavLink
            className="btn btn-outline-light py-1 mx-4 back-to-patient-link nav-item"
            to={link}
            data-testid="smart-back"
          >
            Back to Patient
          </NavLink>
        )}
      </div>
      {/* if the user is unregistered do not show hamburger menu, resources and feedback  */}
      {!hideNav && (
        <div className="navbar-nav" data-testid="smart-navbar">
          <div className="d-flex align-items-center">
            <NavItem className="text-white small">{name}</NavItem>
            {name && (supportVisible || user?.patientInvitationStatus === 2) ? (
              <NavItem>
                <div className="bg-light align-self-center mx-5 small-border"></div>
              </NavItem>
            ) : (
              " "
            )}
            {user?.patientInvitationStatus === 2 && (
              <>
                <NavItem className="text-white small">
                  Member since {memberSince}
                </NavItem>
                {supportVisible && (
                  <NavItem>
                    <div className="bg-light align-self-center mx-5 small-border"></div>
                  </NavItem>
                )}
              </>
            )}
            {user?.userRole >= 1 && (
              <div
                className={classnames({
                  "active-bottom-border white":
                    pathname.startsWith("/smart/admin")
                })}
              >
                <NavLink
                  aria-label="admin"
                  to="/smart/admin/groups"
                  className="nav-item nav-link mx-md-4 mx-2"
                >
                  Admin
                </NavLink>
              </div>
            )}

            <div
              className={classnames({
                "active-bottom-border white":
                  pathname.startsWith("/smart/resources")
              })}
            >
              {!pathname.startsWith("/smart/redirecting") && (
                <NavLink
                  end
                  to="/smart/resources"
                  className="nav-item nav-link mx-md-4 mx-2"
                >
                  Resources
                </NavLink>
              )}
            </div>
            {!isSmallTabletWidth ? (
              <div
                className={classnames({
                  "active-bottom-border white":
                    pathname.startsWith("/smart/feedback")
                })}
              >
                {!pathname.startsWith("/smart/redirecting") && (
                  <NavLink
                    end
                    to="/smart/feedback"
                    className="nav-item nav-link mx-md-4 mx-2"
                  >
                    Feedback
                  </NavLink>
                )}
              </div>
            ) : (
              <></>
            )}
            {notificationsEnabled ? (
              <NotificationDropdown smart={true} />
            ) : (
              <></>
            )}
            {supportVisible ? (
              <MyDropdown
                details={
                  isSmallTabletWidth
                    ? dropdownDetails
                    : // remove Feedback item from dropdownDetails
                      {
                        ...dropdownDetails,
                        items: dropdownDetails.items.slice(0, 4)
                      }
                }
                direction="right"
                auto={true}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
    </nav>
  );
};

export { MyNav, SmartNav };
