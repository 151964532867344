import { ReactComponent as Allscripts } from "../images/allscripts-vector-logo.svg";
import { ReactComponent as Athenahealth } from "../images/Athenahealth-Logo.wine.svg";
import { ReactComponent as Cerner } from "../images/cerner-vector-logo.svg";
import { ReactComponent as Epic } from "../images/epic-systems-corporation-vector-logo.svg";
import { ReactComponent as Meditech } from "../images/Meditech-logo.svg";
import { ReactComponent as Nextgen } from "../images/nextgen-healthcare-vector-logo.svg";
import { ReactComponent as McKesson } from "../images/McKesson_logo.svg";
import * as Yup from "yup";

const unitOptions = [
  { label: "each", value: "each" },
  { label: "gram(s)", value: "gram(s)" },
  { label: "g/kg", value: "g/kg" },
  { label: "international unit(s)", value: "international unit(s)" },
  { label: "microgram(s)", value: "microgram(s)" },
  { label: "milliequivalent(s)", value: "milliequivalent(s)" },
  { label: "milligram(s)", value: "milligram(s)" },
  { label: "unit(s)", value: "unit(s)" }
];

const formOptions = [
  { label: "Capsule", value: "Capsule" },
  { label: "Capsule, extended release", value: "Capsule, extended release" },
  { label: "Tablet", value: "Tablet" },
  { label: "Tablet, chewable", value: "Tablet, chewable:" },
  { label: "Tablet, extended release", value: "Tablet, extended release" },
  { label: "Cream", value: "Cream" },
  { label: "Drops", value: "Drops" },
  { label: "Liquid", value: "Liquid" },
  { label: "Ointment", value: "Ointment" },
  { label: "Powder", value: "Powder" },
  { label: "Spray", value: "Spray" },
  { label: "Syrup", value: "Syrup" }
];
const everyXDayOptions = [
  { label: "2 Days", value: 2 },
  { label: "3 Days", value: 3 },
  { label: "4 Days", value: 4 },
  { label: "5 Days", value: 5 },
  { label: "6 Days", value: 6 },
  { label: "7 Days", value: 7 }
];
const everyXHourOptions = [
  { label: "1 Hours", value: 1 },
  { label: "2 Hours", value: 2 },
  { label: "3 Hours", value: 3 },
  { label: "4 Hours", value: 4 },
  { label: "5 Hours", value: 5 },
  { label: "6 Hours", value: 6 },
  { label: "7 Hours", value: 7 },
  { label: "8 Hours", value: 8 },
  { label: "9 Hours", value: 9 },
  { label: "10 Hours", value: 10 },
  { label: "11 Hours", value: 11 },
  { label: "12 Hours", value: 12 }
];
const instructionsOptions = ["With Food", "Before Food", "After Food"];
const doseOptions = [
  { label: ".5", value: 0.5 },
  { label: "1", value: 1 },
  { label: "1.5", value: 1.5 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 }
];
const scheduleTypes = [
  { value: 1, label: "PRN", description: "As Needed" },
  { value: 2, label: "Daily", description: "Every Day" },
  { value: 4, label: "Every X Days", description: "Every X Days" },
  { value: 5, label: "Specific Days", description: "Specific Days" }
  // { value: 3, label: "Every X Hours" },
  // { value: 6, label: "Cycle" }
  // { value: 7, label: "Medication Plan - New" },
  // { value: 8, label: "Medication Plan - Saved" }
];
const userRoleOptions = [
  { value: 0, label: "User" },
  { value: 1, label: "Global Admin" },
  { value: 2, label: "User Admin" },
  { value: 3, label: "Clinical Manager" }

  // { value: 2, label: "IT Admin" }
];
const userFunctionOptions = [
  { value: 1, label: "Administrative" },
  { value: 2, label: "Medication Assistant" },
  { value: 3, label: "Nurse" },
  { value: 4, label: "Nurse Practitioner" },
  { value: 5, label: "Nurse's Aide" },
  { value: 6, label: "Pharmacist" },
  { value: 7, label: "Pharmacy Technician" },
  { value: 8, label: "Physician" },
  { value: 9, label: "Physician Assistant" },
  { value: -1, label: "Other" }
];
const repeatCycleOptions = [
  { value: 0, label: "No" },
  {
    value: 1,
    label: "1 Time"
  },
  { value: 2, label: "2 Times" },
  { value: 3, label: "3 Times" },
  { value: 4, label: "4 Times" },
  { value: 5, label: "5 Times" },
  { value: 6, label: "6 Times" }
];

const accessOptions = [
  { value: 1, label: "Organization" },
  { value: 2, label: "Group" },
  { value: 3, label: "User" }
];

const profileSidebarDetails = {
  title: "Profile & Settings",
  target: "#profile-center",
  items: [
    {
      text: "Profile",
      link: "/profile",
      idx: 0
    },
    {
      text: "Account",
      link: "/profile/account",
      idx: 1
    },
    {
      text: "Health Trackers",
      link: "/profile/preferences",
      idx: 2
    },
    {
      text: "Notifications",
      link: "/profile/notifications",
      idx: 3
    },
    {
      text: "Accessibility",
      link: "/profile/accessibility",
      idx: 4
    }
  ]
};

const genderOptions = [
  { value: 0, label: "Unknown" },
  { value: 1, label: "M" },
  { value: 2, label: "F" },
  { value: 3, label: "NB" },
  { value: 4, label: "Other" }
];

const inviteGenderOptions = [
  { value: 0, label: "Prefer not to say" },
  { value: 1, label: "Male" },
  { value: 2, label: "Female" },
  { value: 3, label: "Non-binary" },
  { value: 4, label: "Other" }
];

const patientNavItems = [
  {
    name: "Overview",
    id: "overview",
    idx: 0
  },
  { name: "Medications", id: "meds", idx: 1 },
  { name: "Adherence", id: "adh", idx: 2 },
  { name: "Health", id: "health", idx: 3 },
  { name: "Outreach", id: "outreach", idx: 4 },
  { name: "Tasks", id: "tasks", idx: 5 },
  { name: "Reports", id: "reports", idx: 6 }
];

const inviteNavItems = [
  { name: "Invitation", id: "invite", idx: 0 },
  { name: "Tasks", id: "tasks", idx: 1 },
  { name: "Outreach", id: "outreach", idx: 2 }
];

const integrations = [
  {
    name: "AllscriptsSmartApp",
    id: 6,
    value: 6,
    label: "Allscripts Smart App",
    logo: <Allscripts aria-label="allscripts" />
  },
  {
    name: "AthenaSmartApp",
    id: 4,
    value: 4,
    label: "athenahealth Smart App",
    logo: <Athenahealth />
  },
  {
    name: "CernerSmartApp",
    id: 1,
    value: 1,
    label: "Cerner Smart App",
    logo: <Cerner aria-label="cerner" />
  },
  {
    name: "EpicSmartApp",
    id: 2,
    value: 2,
    label: "Epic Smart App",
    logo: <Epic aria-label="epic" />
  },
  {
    name: "MeditechSmartApp",
    id: 5,
    value: 5,
    label: "Meditech Smart App",
    logo: <Meditech aria-label="meditech" />
  },
  {
    name: "NextgenSmartApp",
    id: 3,
    value: 3,
    label: "nextgen Smart App",
    logo: <Nextgen />
  },
  {
    name: "MckessonSmartApp",
    id: 7,
    value: 7,
    label: "McKesson Smart App",
    logo: <McKesson aria-label="mckesson" />
  }
];

const compareOptions = [
  { value: 0, label: "=" },
  { value: 1, label: "≠" },
  { value: 2, label: ">" },
  { value: 3, label: "<" },
  { value: 6, label: "≥" },
  { value: 7, label: "≤" },
  { value: 8, label: "Between" }
];

const andOrOptions = [
  { value: 0, label: "AND" },
  { value: 1, label: "OR" }
];

const trueFalseOptions = [
  { value: true, label: "True" },
  { value: false, label: "False" }
];

const thenOptions = ["THEN"];

const riskOptions = [
  { value: 2, label: "High", color: "#d73838" },
  { value: 1, label: "Medium", color: "#fcb422" },
  { value: 0, label: "Low", color: "#65aa00" }
];

const riskProfileSummaryOptions = [
  { value: 1, label: "At Risk" },
  { value: 2, label: "Not At Risk" }
];

const criteriaOptions = [
  { value: -5, label: "Patient At Risk" },
  { value: -4, label: "Mobile App User" },
  { value: -3, label: "My Patient" },
  { value: -2, label: "Favorite Patient" },
  { value: -1, label: "Risk Profile" },
  { value: 0, label: "Medication Name" },
  { value: 1, label: "Active Ingredient" },
  { value: 2, label: "Therapeutic Class" },
  { value: 3, label: "Number of At Home Meds" },
  { value: 4, label: "Age" },
  { value: 5, label: "Number of Branded Drugs" },
  { value: 6, label: "Route" }
];

const timeOptions = [
  { value: "60", label: "1 hour after" },
  { value: "180", label: "3 hours after" },
  { value: "360", label: "6 hours after" },
  { value: "720", label: "12 hours after" },
  { value: "1440", label: "24 hours after" }
];

const specificTimeOptions = [
  { value: "00:00", label: "12:00 AM" },
  { value: "03:00", label: "3:00 AM" },
  { value: "06:00", label: "6:00 AM" },
  { value: "09:00", label: "9:00 AM" },
  { value: "12:00", label: "12:00 PM" },
  { value: "15:00", label: "3:00 PM" },
  { value: "18:00", label: "6:00 PM" },
  { value: "21:00", label: "9:00 PM" }
];
const taskTypeOptions = [
  { value: 0, label: "General" },
  { value: 1, label: "Call" },
  { value: 2, label: "Monitor" },
  { value: 3, label: "Patient Action" }
];

const outreachTypeOptions = [
  { value: 1, label: "Call" },
  { value: 2, label: "Email" },
  { value: 3, label: "Text" },
  { value: 4, label: "In-Person" }
];

const notificationOptions = [
  {
    type: "Medication",
    header: "Medication Missed/Taken",
    description:
      "Receive a notification when a patient misses or takes a certain scheduled or PRN medication",
    options: [
      { method: "ehr", name: "ehrMedicationNotifications" },
      { method: "email", name: "emailMedicationNotifications" },
      { method: "inApp", name: "inAppMedicationNotifications" }
    ]
  },
  {
    type: "TaskDue",
    header: "Task Due",
    description: "Receive a notification when a task is due",
    options: [
      null,
      { method: "email", name: "emailTaskDueNotifications" },
      { method: "inApp", name: "inAppTaskDueNotifications" }
    ]
  },
  {
    type: "Login",
    header: "Login Alerts",
    description:
      "Receive a notification when a new login occurs on your account",
    options: [
      null,
      { type: "Login", method: "email", name: "emailLoginNotifications" },
      { type: "Login", method: "inApp", name: "inAppLoginNotifications" }
    ]
  },
  {
    type: "Report",
    header: "Report Ready",
    description:
      "Receive a notification when your requested reports are ready for download",
    options: [
      null,

      { type: "Report", method: "email", name: "emailReportNotifications" },
      { type: "Report", method: "inApp", name: "inAppReportNotifications" }
    ]
  }
];

const suggestionSources = [
  { value: 0, label: "Physician" },
  { value: 1, label: "EHR" }
];

const inviteOptions = [
  { value: "1", label: "My Invitations" },
  { value: "2", label: "All Invitations" }
];

const connectionOptions = [
  { value: "1", label: "My Connections" },
  { value: "2", label: "All Connections" }
];

const timesOfDay = [
  { value: 0, label: "Morning" },
  { value: 1, label: "Afternoon" },
  { value: 2, label: "Evening" },
  { value: 3, label: "Night" },
  { value: 4, label: "Bedtime" }
];

const languageOptions = [
  { value: 0, label: "English" },
  { value: 1, label: "Spanish" }
];

const phoneRegExp = /^[+]?[(]?\d{3}[)]?[-\s.]?\d{3}[-\s.]?\d{5}$/im;
const emailRegExp = /^[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

const smartInviteValidationSchema = Yup.object().shape({
  emailCheckbox: Yup.boolean(),
  phoneCheckbox: Yup.boolean(),
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  dateOfBirth: Yup.string().optional("Date of birth is optional"),
  //logic to force selection of one checkbox
  neitherCheckbox: Yup.boolean().when(["emailCheckbox", "phoneCheckbox"], {
    is: false,
    then: (schema) => schema.oneOf([true], ""),
    otherwise: (schema) => schema
  }),
  //make sure email and phone are valid when email or phone box is checked
  email: Yup.string().when("emailCheckbox", {
    is: true,
    then: (schema) =>
      schema
        .matches(emailRegExp, { message: "Email is invalid" })
        .required("Email is required")
  }),
  phoneNumber: Yup.string().when("phoneCheckbox", {
    is: true,
    then: (schema) =>
      schema
        .matches(
          phoneRegExp,
          "Please enter a standard US phone number that contains only 10 digits."
        )
        .required("Phone number is required")
  }),
  // make sure mrn is required when name & dob details are not provided
  medicalRecordNumber: Yup.string().when(["firstName", "lastName", "dateOfBirth"], {
    is: (firstName, lastName, dateOfBirth) => !firstName && !lastName && !dateOfBirth,
    then: (schema) => schema.required("Medical Record Number is required"),
    otherwise: (schema) => schema.optional()
  })

});

export {
  criteriaOptions,
  riskOptions,
  thenOptions,
  trueFalseOptions,
  andOrOptions,
  compareOptions,
  integrations,
  patientNavItems,
  genderOptions,
  profileSidebarDetails,
  accessOptions,
  repeatCycleOptions,
  scheduleTypes,
  everyXDayOptions,
  everyXHourOptions,
  instructionsOptions,
  doseOptions,
  formOptions,
  unitOptions,
  userRoleOptions,
  timeOptions,
  specificTimeOptions,
  taskTypeOptions,
  userFunctionOptions,
  outreachTypeOptions,
  notificationOptions,
  suggestionSources,
  timesOfDay,
  languageOptions,
  inviteNavItems,
  inviteOptions,
  riskProfileSummaryOptions,
  inviteGenderOptions,
  smartInviteValidationSchema,
  connectionOptions
};
