import { fetchWrapper } from "../_helpers/index";

export const medicationsService = {
  searchMeds,
  activeIngredients,
  therapeuticCategories
};

function searchMeds(string) {
  let encodedString = encodeURIComponent(string);
  return fetchWrapper.get(
    `/api/medications/search?startsWith=${encodedString}`
  );
}

function activeIngredients(string) {
  return fetchWrapper.get("/api/medications/activeingredients");
}

function therapeuticCategories() {
  return fetchWrapper.get("/api/medications/therapeuticcategories");
}
