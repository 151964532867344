import classnames from "classnames";
import { Field } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Collapse } from "reactstrap";
import { ReactComponent as ChevronDown } from "../../images/chevron_down_small.svg";
import { ReactComponent as Chevron } from "../../images/chevron_right.svg";
import {
  CustomInstructionText,
  InstructionText,
  RouteText
} from "./InstructionText";
import { isPRN } from "../../_helpers";

export const Instructions = ({ values, errors, touched, findIsDisabled }) => {
  const [instructionsOpen, setInstructionsOpen] = useState(false);
  const toggleInstructions = () => setInstructionsOpen(!instructionsOpen);
  // Need ref for chip component in order to get currently selected chips

  useEffect(() => {
    if (document.querySelector(".add-meds-modal")) {
      // if collapse is not open, remove the scroll-modal class from the modal-body
      if (!instructionsOpen) {
        document
          .querySelector(".add-meds-modal")
          .classList.remove("scroll-modal");
      } else {
        document.querySelector(".add-meds-modal").classList.add("scroll-modal");
      }
    }
  }, [instructionsOpen]);

  return (
    <>
      <div
        type="button"
        id="instructions-label"
        className="mt-3"
        onClick={toggleInstructions}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            toggleInstructions();
          }
        }}
        tabIndex="0"
      >
        {!instructionsOpen ? (
          <Chevron className="mr-3 mb-1 glyph-gray w-8px" aria-label="up" />
        ) : (
          <ChevronDown className="mr-3 mb-1 glyph-gray" aria-label="down" />
        )}
        <span className="text-secondary font-weight-medium">Instructions</span>
      </div>

      <Collapse isOpen={instructionsOpen}>
        <div className="my-3">
          {values.dose || values.frequency?.value || isPRN(values) ? (
            <div className="d-flex">
              <InstructionText
                dose={values.dose}
                doseType={values.doseType}
                frequency={values.frequency}
                daysOn={values.daysOn}
                daysOff={values.daysOff}
                totalNumberOfCycles={values.totalNumberOfCycles}
                prn={isPRN(values)}
                prnReason={values.prnReason}
                exclamation={true}
                medScheduleTimes={values.medicationScheduleTimes}
              />
            </div>
          ) : (
            <></>
          )}
          {values.strengthAndForm?.routeId ? (
            <div className="d-flex">
              <RouteText
                route={values.strengthAndForm?.routeId}
                exclamation={true}
              />
            </div>
          ) : (
            <></>
          )}
          {values.patientInstructions ? (
            <CustomInstructionText
              instructionText={values.patientInstructions}
              exclamation={true}
            />
          ) : (
            <></>
          )}
          {/* <div className="d-flex flex-wrap pb-3" id="instructions-options">
            <ChipListComponent
              id="instructions"
              enabled={!findIsDisabled(values)}
              selection="Single"
              cssClass="big-chips"
              ref={instructionsRef}
              selectedChips={
                selectedMed && [
                  instructionsOptions.indexOf(values.instructions)
                ]
              }
              onClick={(e) =>
                onChangeInstructions(e, setFieldValue, setFieldTouched)
              }
            >
              <ChipsDirective>
                {instructionsOptions.map((instructions, idx) => (
                  <ChipDirective
                    key={instructions}
                    text={instructions}
                    data-testid={instructions}
                  ></ChipDirective>
                ))}
              </ChipsDirective>
            </ChipListComponent>
          </div> */}

          <Field
            as="textarea"
            placeholder="Type additional instructions here"
            name="customInstructions"
            disabled={findIsDisabled(values)}
            rows="3"
            type="text"
            className={classnames(
              { "is-invalid": errors.code && touched.code },
              "form-control"
            )}
          ></Field>
        </div>
      </Collapse>
    </>
  );
};

Instructions.propTypes = {
  findIsDisabled: PropTypes.func,
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object
};
