export const administrationRoutes = [
  {
    "value": 1,
    "label": "HEMO",
    "description": "hemodialysis",
    "instructionText": null
  },
  {
    "value": 2,
    "label": "IMP",
    "description": "implant",
    "instructionText": null
  },
  {
    "value": 3,
    "label": "INH",
    "description": "inhalation",
    "instructionText": "Take by inhaling"
  },
  {
    "value": 4,
    "label": "CPD",
    "description": "compounding",
    "instructionText": null
  },
  {
    "value": 5,
    "label": "INJECT",
    "description": "injectable",
    "instructionText": null
  },
  {
    "value": 6,
    "label": "INART",
    "description": "intra-articular",
    "instructionText": null
  },
  {
    "value": 7,
    "label": "IB",
    "description": "intrabiliary",
    "instructionText": null
  },
  {
    "value": 8,
    "label": "IND",
    "description": "intradermal",
    "instructionText": null
  },
  {
    "value": 9,
    "label": "IL",
    "description": "intralymphatic",
    "instructionText": null
  },
  {
    "value": 10,
    "label": "IM",
    "description": "intramuscular",
    "instructionText": "Given by injection into a muscle"
  },
  {
    "value": 11,
    "label": "INTO",
    "description": "intraocular",
    "instructionText": null
  },
  {
    "value": 12,
    "label": "INTP",
    "description": "intraperitoneal",
    "instructionText": null
  },
  {
    "value": 13,
    "label": "INTR",
    "description": "intrathecal",
    "instructionText": "Given by injection into the space around the spinal cord"
  },
  {
    "value": 14,
    "label": "INTT",
    "description": "intratracheal",
    "instructionText": null
  },
  {
    "value": 15,
    "label": "INTU",
    "description": "intrauterine",
    "instructionText": null
  },
  {
    "value": 16,
    "label": "IV",
    "description": "intravenous",
    "instructionText": "Given by injection into a vein"
  },
  {
    "value": 17,
    "label": "IR",
    "description": "irrigation",
    "instructionText": null
  },
  {
    "value": 18,
    "label": "MM",
    "description": "mucous membrane",
    "instructionText": null
  },
  {
    "value": 19,
    "label": "NAS",
    "description": "nasal",
    "instructionText": "Take via the nose"
  },
  {
    "value": 20,
    "label": "OPH",
    "description": "ophthalmic",
    "instructionText": "Take via the eye"
  },
  {
    "value": 21,
    "label": "PO",
    "description": "oral",
    "instructionText": "Take by mouth"
  },
  {
    "value": 22,
    "label": "POINJ",
    "description": "oral and injectable",
    "instructionText": null
  },
  {
    "value": 23,
    "label": "OM",
    "description": "oral transmucosal",
    "instructionText": null
  },
  {
    "value": 24,
    "label": "OTIC",
    "description": "otic",
    "instructionText": "Take via the ear"
  },
  {
    "value": 25,
    "label": "REC",
    "description": "rectal",
    "instructionText": "Take via the rectum"
  },
  {
    "value": 26,
    "label": "SUBCUT",
    "description": "subcutaneous",
    "instructionText": "Given by injection beneath the skin"
  },
  {
    "value": 27,
    "label": "SL",
    "description": "sublingual",
    "instructionText": "Placed under the tongue"
  },
  {
    "value": 28,
    "label": "TOP",
    "description": "topical",
    "instructionText": null
  },
  {
    "value": 29,
    "label": "TD",
    "description": "transdermal",
    "instructionText": null
  },
  {
    "value": 30,
    "label": "TRANU",
    "description": "transurethral",
    "instructionText": null
  },
  {
    "value": 31,
    "label": "VAG",
    "description": "vaginal",
    "instructionText": "Take via the vagina"
  },
  {
    "value": 32,
    "label": "EPD",
    "description": "epidural",
    "instructionText": null
  },
  {
    "value": 33,
    "label": "BC",
    "description": "buccal",
    "instructionText": "Placed between the gums and cheek"
  },
  {
    "value": 34,
    "label": "INAMN",
    "description": "intra-amniotic",
    "instructionText": null
  },
  {
    "value": 35,
    "label": "INVTR",
    "description": "intravitreal",
    "instructionText": null
  },
  {
    "value": 36,
    "label": "INPL",
    "description": "intrapleural",
    "instructionText": null
  },
  {
    "value": 37,
    "label": "INVS",
    "description": "intravesical",
    "instructionText": null
  },
  {
    "value": 38,
    "label": "POREC",
    "description": "oral and rectal",
    "instructionText": null
  },
  {
    "value": 39,
    "label": "POTOP",
    "description": "oral and topical",
    "instructionText": null
  },
  {
    "value": 40,
    "label": "EXAMN",
    "description": "extra-amniotic",
    "instructionText": null
  },
  {
    "value": 41,
    "label": "ENTER",
    "description": "enteral",
    "instructionText": null
  },
  {
    "value": 42,
    "label": "PERC",
    "description": "percutaneous",
    "instructionText": null
  },
  {
    "value": 43,
    "label": "spinal",
    "description": "spinal",
    "instructionText": null
  },
  {
    "value": 44,
    "label": "PAREN",
    "description": "parenteral",
    "instructionText": null
  },
  {
    "value": 45,
    "label": "subderm",
    "description": "subdermal",
    "instructionText": null
  },
  {
    "value": 46,
    "label": "urtl",
    "description": "ureteral",
    "instructionText": null
  }
]