import React, { createContext, useCallback, useMemo, useReducer } from "react";
import PropTypes from "prop-types";

const SmartContext = createContext();

const initialState = {
  patientId: null,
  patientInviteStatus: null,
  mPageType: -1,
  isMPages: false,
  patientName: ""
};

const smartReducer = (state, action) => {
  switch (action.type) {
    case "SET_PATIENT_ID":
      return { ...state, patientId: action.payload };
    case "SET_PATIENT_INVITE_STATUS":
      return { ...state, patientInviteStatus: action.payload };
    case "SET_M_PAGE_TYPE":
      return { ...state, mPageType: action.payload };
    case "SET_IS_MPAGES":
      return { ...state, isMPages: action.payload };
    case "SET_PATIENT_NAME":
      return { ...state, patientName: action.payload };
    default:
      return state;
  }
};

const SmartContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(smartReducer, initialState);

  const setPatientId = useCallback(
    (patientId) => {
      dispatch({ type: "SET_PATIENT_ID", payload: patientId });
    },
    [dispatch]
  );

  const setPatientInviteStatus = useCallback(
    (patientInviteStatus) => {
      dispatch({
        type: "SET_PATIENT_INVITE_STATUS",
        payload: patientInviteStatus
      });
    },
    [dispatch]
  );

  const setMPageType = useCallback(
    (mPageType) => {
      dispatch({ type: "SET_M_PAGE_TYPE", payload: mPageType });
    },
    [dispatch]
  );

  const setIsMPages = useCallback(
    (isMPages) => {
      dispatch({ type: "SET_IS_MPAGES", payload: isMPages });
    },
    [dispatch]
  );

  const setPatientName = useCallback(
    (name) => {
      dispatch({ type: "SET_PATIENT_NAME", payload: name });
    },
    [dispatch]
  );

  const value = useMemo(
    () => ({
      patientId: state.patientId,
      patientInviteStatus: state.patientInviteStatus,
      mPageType: state.mPageType,
      isMPages: state.isMPages,
      patientName: state.patientName,
      setPatientId,
      setPatientInviteStatus,
      setMPageType,
      setIsMPages,
      setPatientName
    }),
    [
      state.patientId,
      state.patientInviteStatus,
      state.mPageType,
      state.isMPages,
      state.patientName,
      setPatientId,
      setPatientInviteStatus,
      setMPageType,
      setIsMPages,
      setPatientName
    ]
  );

  return (
    <SmartContext.Provider value={value}>{children}</SmartContext.Provider>
  );
};

const useSmartContext = () => {
  const context = React.useContext(SmartContext);
  if (context === undefined) {
    return { initialState };
  }
  return context;
};

export { SmartContext, SmartContextProvider, useSmartContext };

SmartContextProvider.propTypes = {
  children: PropTypes.node
};
