import { fetchWrapper } from "../_helpers/index";

export const reportService = {
  getReports,
  getArchivedReports,
  downloadReport,
  downloadArchivedReport,
  createReport,
  archiveReport,
  getStream,
  getArchivedStream
};

const baseUrl = "/api/reports";

function getReports(filters, sortStr, pageSize = 50, page = 1) {
  if (!sortStr) sortStr = "createdDate.DESC";
  let url = baseUrl + `?pageSize=${pageSize}&page=${page}&SortBy=${sortStr}`;
  if (filters) {
    for (const key in filters) {
      let urlFilters = "";
      filters[key].forEach((item) => (urlFilters += `&${key}=${item}`));
      url = `${url}${urlFilters}`;
    }
  }
  return fetchWrapper.get(url);
}

function getArchivedReports(filters, sortStr, pageSize = 50, page = 1) {
  if (!sortStr) sortStr = "requestedDate.DESC";
  let url =
    baseUrl + `/archived?pageSize=${pageSize}&page=${page}&SortBy=${sortStr}`;
  if (filters) {
    for (const key in filters) {
      let urlFilters = "";
      filters[key].forEach((item) => (urlFilters += `&${key}=${item}`));
      url = `${url}${urlFilters}`;
    }
  }
  return fetchWrapper.get(url);
}

function downloadReport(id) {
  return fetchWrapper.get(`${baseUrl}/download?reportId=${id}`);
}

function downloadArchivedReport(id) {
  return fetchWrapper.get(`${baseUrl}/archived/download?reportId=${id}`);
}

function createReport(report) {
  return fetchWrapper.post(baseUrl, report);
}

function archiveReport(id) {
  return fetchWrapper.put(`${baseUrl}/archive/${id}`);
}

function getStream(id) {
  return fetchWrapper.get(`${baseUrl}/stream?reportId=${id}`);
}

function getArchivedStream(id) {
  return fetchWrapper.get(`${baseUrl}/archived/stream?reportId=${id}`);
}
