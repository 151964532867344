import dayjs from "dayjs";

// const eventTypeEdit = "EDIT_EVENT";
const eventTypeHistory = "HISTORY_EVENT";
const eventTypeUserCreated = "USER_CREATED_EVENT";

const createUserCreatedEvent = (medEditEvents, allEvents) => {
  for (const [date, eEvents] of Object.entries(medEditEvents)) {
    // if (eEvents.length === 1) {
    if (eEvents[0].eventType === 0) {
      allEvents.push({
        id: "user-created" + date,
        title: "User Created",
        date: date,
        allDay: true,
        classNames: ["fc-event-edit-meds"],
        extendedProps: {
          history: eEvents,
          type: eventTypeUserCreated
        }
      });
    }
    //   else
    //     allEvents.push({
    //       id: "e" + date,
    //       title: eEvents[0].title,
    //       date: date,
    //       allDay: true,
    //       classNames: ["fc-event-edit-meds"],
    //       extendedProps: { history: eEvents, type: eventTypeEdit }
    //     });
    // } else {
    //   let title;
    //   if (eEvents[0].eventType === 0) {
    //     title = "User Created";
    //   } else title = "Meds Modified";
    //   allEvents.push({
    //     id: "e" + date,
    //     title: title,
    //     date: date,
    //     allDay: true,
    //     classNames: ["fc-event-edit-meds"],
    //     extendedProps: { history: eEvents, type: eventTypeEdit }
    //   });
    // }
  }
};

const createMedHistoryEvents = (medHistoryEvents, allEvents) => {
  for (const [date, hEvents] of Object.entries(medHistoryEvents)) {
    const allMeds = hEvents.filter((event) => event.data.scheduledAt).length;
    let takenMeds = 0;
    hEvents.forEach((hEvent) => {
      if (hEvent.data.isTaken === 1 && hEvent.data.scheduledAt) {
        takenMeds++;
      }
    });
    if (takenMeds === 0) {
      allEvents.push({
        id: new Date(date).toLocaleString(),
        title: " No Meds Taken",
        date: date,
        allDay: true,
        classNames: ["fc-event-no-meds"],
        extendedProps: { history: hEvents, type: eventTypeHistory }
      });
    } else if (takenMeds === allMeds) {
      allEvents.push({
        id: new Date(date).toLocaleString(),
        title: " All Meds Taken",
        date: date,
        allDay: true,
        classNames: ["fc-event-all-meds"],
        extendedProps: { history: hEvents, type: eventTypeHistory }
      });
    } else {
      allEvents.push({
        id: new Date(date).toLocaleString(),
        title: ` ${takenMeds}/${allMeds} Meds Taken`,
        date: date,
        allDay: true,
        classNames: ["fc-event-partial-meds"],
        extendedProps: { history: hEvents, type: eventTypeHistory }
      });
    }
  }
};

const createMonthlyEvents = (params, filterMeds) => {
  const allEvents = [];
  const medHistoryEvents = [];
  const medEditEvents = [];
  let elements = [];
  //filter meds by what is selected before creating events
  if (filterMeds.length) {
    elements = params.filter(
      (val) => filterMeds.includes(val.medicationId) || val.eventType === 0
    );
  }
  elements.forEach((element) => {
    const date = dayjs(element.date).format("YYYY-MM-DD");
    if (element.eventTypeDescription === "MEDICATION_HISTORY") {
      if (!medHistoryEvents[date]) {
        medHistoryEvents[date] = [];
      }
      medHistoryEvents[date].push(element);
    } else {
      if (!medEditEvents[date]) {
        medEditEvents[date] = [];
      }
      medEditEvents[date].push(element);
    }
  });
  createMedHistoryEvents(medHistoryEvents, allEvents);
  createUserCreatedEvent(medEditEvents, allEvents);
  return { events: allEvents };
};
const setFirstTime = (times) => {
  times.sort(
    (a, b) => new Date(`2000-01-01T${a}`) - new Date(`2000-01-01T${b}`)
  );
  if (times[0]) return times[0];
};

const createWeeklyEvents = (params, filterMeds) => {
  const allEvents = [];
  let times = [];
  let i = 0;
  let elements = params.filter(
    (val) => filterMeds.includes(val.medicationId) || val.eventType === 0
  );
  const medEditEvents = [];

  elements.forEach((element) => {
    let medTime;
    let classname = "";

    if (element.data?.isTaken === 1) {
      classname = "taken";
      medTime = element.data.takenAt;
    } else if (element.data?.isTaken === 2) {
      medTime = element.data.scheduledAt;
      classname = "skipped";
    } else if (element.data?.isTaken === 0) {
      medTime = element.data.scheduledAt;
      classname = "missed";
    } else {
      // These are med edit events which do not have time slots in weekly view
      // Therefore set the time to 11:59 so it doesn't mess up the scroll to first dose functionality
      let idx = element.date.indexOf("T");
      medTime = element.date.slice(0, idx).concat("T11:59:59");
    }

    const date = dayjs(medTime).local().format("YYYY-MM-DD");
    let time = dayjs(medTime).local().format();

    let endTime = dayjs(medTime).local().add(20, "minutes").format();
    let hour = dayjs(medTime).local().subtract(1, "hour").format("HH:MM");

    if (times.indexOf(hour) < 0) times.push(hour);

    if (element.eventTypeDescription === "MEDICATION_HISTORY") {
      allEvents.push({
        id: "h" + i,
        title: element.medicationName,
        start: time,
        end: endTime,
        classNames: [classname],
        extendedProps: { history: [element], type: eventTypeHistory }
      });
    } else {
      if (!medEditEvents[date]) {
        medEditEvents[date] = [];
      }
      medEditEvents[date].push(element);
    }
  });
  createUserCreatedEvent(medEditEvents, allEvents);

  //Sorting and setting the first time for scrolling
  const firstTime = setFirstTime(times);
  return { events: allEvents, firstTime: firstTime };
};

export { createMonthlyEvents, createWeeklyEvents };
