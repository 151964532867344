import { ErrorMessage, Field } from "formik";
import PropTypes from "prop-types";
import React, { Suspense, useEffect, useState } from "react";
import { Collapse } from "reactstrap";
import { CustomCreatableDropdown, DatePickerField } from "../../_components";
import { deepCopy, findAndMoveToCommon } from "../../_helpers";
import { sigCodes } from "../../_helpers/Frequency";
import { doseOptions, timesOfDay } from "../../_helpers/constants";
import { formEnum } from "../../_helpers/formEnum";
import { prnReasons } from "../../_helpers/prnreasons";
import { quantityUnitsEnum } from "../../_helpers/quantityUnits";
import { ReactComponent as ChevronDown } from "../../images/chevron_down_small.svg";
import { ReactComponent as Chevron } from "../../images/chevron_right.svg";
import { Cycle, Dose } from "../formComponents";

export const ScheduleType = ({
  // setSelectedPlan,
  values,
  setFieldTouched,
  setFieldValue,
  errors,
  findIsDisabled,
  setInitialValues

  // selPlan,
  // setPlanAdded
}) => {
  const [prn, setPRN] = useState(false);
  const [everyXHours, setEveryXHours] = useState(false);
  const [editedSigCodes, setEditedSigCodes] = useState(sigCodes);
  const [editedPrnReasons, setEditedPrnReasons] = useState(prnReasons);
  const [editedQuantityUnits, setEditedQuantityUnits] = useState([
    {
      label: "Other",
      options: quantityUnitsEnum.map((unit) => {
        return {
          value: unit.value,
          label: unit.abbr,
          description: unit.label
        };
      })
    }
  ]);

  const [isOpen, setIsOpen] = useState(false);
  // const [medPlans, setMedPlans] = useState([]);
  // const [modal, setModal] = useState(false);
  // const [activeTab, setActiveTab] = useState("1");
  // const [editable, setEditable] = useState(true);
  // const [medList, setMedList] = useState([]);
  // const toggleModal = React.useCallback(() => {
  //   setModal(!modal);
  // }, [modal]);

  const toggle = () => setIsOpen(!isOpen);

  const mounted = React.useRef(false);

  React.useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => {
      mounted.current = false;
    }; // ... and to false on unmount
  }, []);

  useEffect(() => {
    if (document.querySelector(".add-meds-modal")) {
      // if collapse is not open, remove the scroll-modal class from the modal-body
      if (!isOpen) {
        document
          .querySelector(".add-meds-modal")
          .classList.remove("scroll-modal");
      } else {
        document.querySelector(".add-meds-modal").classList.add("scroll-modal");
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (values.strengthAndForm || values.strength) {
      if (values.strengthAndForm?.commonFrequencies) {
        let updatedEditedSigCodes = deepCopy(sigCodes);
        let commonGroup = {
          label: "Common",
          options: []
        };

        const cfSet = new Set(values.strengthAndForm.commonFrequencies);
        updatedEditedSigCodes = findAndMoveToCommon(
          cfSet,
          updatedEditedSigCodes,
          commonGroup
        );
        setEditedSigCodes((prev) => [commonGroup, ...updatedEditedSigCodes]);
      }
      let selectedForm = formEnum.find(
        (f) => f.value === values.strengthAndForm.formId || values.strength
      );

      if (
        values.strengthAndForm.preferredQuantityUnitId ||
        selectedForm?.preferredUnitId ||
        selectedForm?.commonUnitIds
      ) {
        let commonSet = new Set();
        if (values.strengthAndForm.preferredQuantityUnit) {
          commonSet.add(values.strengthAndForm.preferredQuantityUnit);
        }
        if (selectedForm?.preferredUnitId) {
          commonSet.add(selectedForm.preferredUnitId);
        }
        if (selectedForm?.commonUnitIds) {
          selectedForm.commonUnitIds.forEach((id) => commonSet.add(id));
        }
        const categorizedUnits = quantityUnitsEnum.reduce(
          (acc, item) => {
            if (commonSet.has(item.value)) {
              acc.common.push(item);
              commonSet.delete(item.value);
            } else {
              acc.other.push(item);
            }
            return acc;
          },
          { common: [], other: [] }
        );

        setEditedQuantityUnits([
          {
            label: "Common",
            options: categorizedUnits.common.map((unit) => {
              return {
                value: unit.value,
                label: unit.abbr,
                description: unit.label
              };
            })
          },
          {
            label: "Other",
            options: categorizedUnits.other.map((unit) => {
              return {
                value: unit.value,
                label: unit.abbr,
                description: unit.label
              };
            })
          }
        ]);
      } else {
        setEditedQuantityUnits([
          {
            label: "Other",
            options: quantityUnitsEnum.map((unit) => {
              return {
                value: unit.value,
                label: unit.abbr,
                description: unit.label
              };
            })
          }
        ]);
      }
    }
  }, [values.strength, values.strengthAndForm]);

  useEffect(() => {
    if (values.strengthAndForm) {
      if (values.strengthAndForm?.commonPrnReasons) {
        let updatedEditedPrnReasons = deepCopy(prnReasons);
        let commonGroup = {
          label: "Common",
          options: []
        };

        const cprSet = new Set(values.strengthAndForm.commonPrnReasons);
        updatedEditedPrnReasons = findAndMoveToCommon(
          cprSet,
          updatedEditedPrnReasons,
          commonGroup
        );
        setEditedPrnReasons((prev) => [
          commonGroup,
          ...updatedEditedPrnReasons
        ]);
      }
      // else {
      //   setEditedPrnReasons(prnReasons);
      // }
    }
  }, [values.strengthAndForm]);

  // Get plan options when opening the form to populate the dropdown
  // useEffect(() => {
  //   if (user && !user?.isSmartSession)
  //     medicationPlanService
  //       .getPlans()
  //       .then((res) => {
  //         // res.data.forEach((item) => medicationPlanService.deletePlan(item.id));
  //         if (mounted.current) {
  //           setMedPlans(
  //             res.data.map((item) => {
  //               return {
  //                 ...item,
  //                 value: item.id,
  //                 label: item.medicationPlanName
  //               };
  //             })
  //           );
  //         }
  //       })
  //       .catch((e) => console.log(e));
  // }, [user]);

  // The first doses have a default time, and we need to mark the field touched to mark the form as dirty

  function onChangeFrequency(name, val, setFieldValue, dose) {
    if (val?.numberOfDoses) {
      setEveryXHours(false);

      // create medicationScheduleTimes array with numberofDoses length
      const arr = [];
      for (let i = 0; i < val.numberOfDoses; i++) {
        arr.push({
          dose: dose,
          time: "",
          partOfDay: ""
          // time: values.medicationScheduleTimes[i]?.time || "",
          // partOfDay: values.medicationScheduleTimes[i]?.partOfDay,
        });
      }

      setFieldValue("medicationScheduleTimes", arr);
    } else if (val?.value === 1000 && !values.prnReason) {
      setEveryXHours(false);
      setPRN(true);
      setFieldValue("prnReason", { value: 0, label: "" });
    } else if (val?.value > 800 && val?.value < 1000) {
      setEveryXHours(true);
    }
    // if (val?.value < 8) {
    //   setFieldValue("savedPlan", "");
    // }
    // setSelectedPlan({});

    // Update field value if the frequency has SuggestedTimes
    if (val?.SuggestedTimes && val?.SuggestedTimes.length > 0) {
      const updatedTimes = val?.SuggestedTimes.map((timeValue, idx) => {
        const timeOfDay = timesOfDay.find((tod) => tod.value === timeValue);
        return {
          dose: dose,
          time: "",
          partOfDay: timeOfDay
        };
      });
      setFieldValue("medicationScheduleTimes", updatedTimes);
    }
    setFieldValue(name, val);
  }

  function onChangeDoseType(name, val, setFieldValue, medicationScheduleTimes) {
    setFieldValue(name, val);
    if (medicationScheduleTimes.length) {
      setFieldValue(
        "medicationScheduleTimes",
        medicationScheduleTimes.map((item) => {
          return {
            ...item,
            dose: val
          };
        })
      );
    }
  }

  // function onChangeSavedPlan(name, val, setFieldValue) {
  //   setFieldValue(name, val);
  //   setSelectedPlan(val);
  // }

  function onChangeStartTime(val, setFieldTouched, setFieldValue) {
    setFieldTouched("startDateTime", true);
    setFieldValue("startDateTime", val);
  }

  function onChangeEndTime(val, setFieldTouched, setFieldValue) {
    setFieldTouched("endDateTime", true);
    setFieldValue("endDateTime", val || "");
  }

  // const findPlan = () =>
  //   medPlans.find((item) => item.value === values.savedPlan.value)
  //     ? true
  //     : false;

  useEffect(() => {
    let freqVal;
    let medicationScheduleTimesVal = [];
    // if values.frequency is a string,
    // find the corresponding option in editedSigCodes
    // otherwise, return values.frequency
    if (
      values.frequency &&
      typeof values.frequency === "string" &&
      editedSigCodes.length
    ) {
      let foundSig = deepCopy(
        editedSigCodes
          .flatMap((item) => item.options) // Flatten the nested arrays
          .find((option) => option.label === values.frequency)
      );
      if (foundSig) {
        freqVal = foundSig;
        if (foundSig.numberOfDoses && foundSig.label !== "Cycle") {
          medicationScheduleTimesVal = [];
          for (let i = 0; i < foundSig.numberOfDoses; i++) {
            medicationScheduleTimesVal.push({
              dose: values.medicationScheduleTimes[i]?.dose || values.dose,
              time: values.medicationScheduleTimes[i]?.time || "",
              partOfDay: values.medicationScheduleTimes[i]?.partOfDay
            });
          }
        } else if (foundSig.label === "Cycle") {
          medicationScheduleTimesVal = values.medicationScheduleTimes;
        }
      } else {
        freqVal = {
          value: values.frequency,
          label: values.frequency
        };
      }
      setInitialValues((current) => ({
        ...current,
        frequency: freqVal,
        medicationScheduleTimes: medicationScheduleTimesVal.length
          ? medicationScheduleTimesVal
          : values.medicationScheduleTimes
      }));
    }
  }, [
    editedSigCodes,
    setInitialValues,
    values.dose,
    values.frequency,
    values.medicationScheduleTimes,
    values.medicationScheduleTimes.length
  ]);

  useEffect(() => {
    let prnVal;
    // if the field is cleared (and the value is not 0/true), set prn to false
    if (!values.prnReason && values.prnReason !== 0 && prn) setPRN(false);
    // if values.prnReason is a number,
    // find the corresponding option in editedPrnReasons
    // otherwise, return values.prnReason
    else if (typeof values.prnReason === "number" && editedPrnReasons.length) {
      setPRN(true);
      let foundPrn = deepCopy(
        editedPrnReasons
          .flatMap((item) => item.options) // Flatten the nested arrays
          .find((option) => option.value === values.prnReason)
      );
      if (foundPrn && !values.prnReasonOther) {
        prnVal = foundPrn;
      } else if (values.prnReasonOther) {
        prnVal = {
          value: values.prnReasonOther,
          label: values.prnReasonOther
        };
      } else {
        prnVal = {
          value: values.prnReason,
          label: ""
        };
      }
      setInitialValues((current) => ({
        ...current,
        prnReason: prnVal
      }));
    }
  }, [
    editedPrnReasons,
    prn,
    setInitialValues,
    values.prnReason,
    values.prnReasonOther
  ]);

  useEffect(() => {
    if (prn && values.frequency?.numberOfDoses) {
      // set medicationScheduleTimes to an array of length values.frequency.numberOfDoses with dose set to
      // values.doseType.value or 1 and time set to ""
      const arr = [];
      for (let i = 0; i < values.frequency.numberOfDoses; i++) {
        arr.push({
          dose: values.dose,
          time: "",
          partOfDay: ""
        });
      }

      setFieldValue("medicationScheduleTimes", arr);
    }
  }, [setFieldValue, values.dose, values.frequency?.numberOfDoses, prn]);

  return (
    <div>
      <div>
        <div className="form-row">
          <div className="form-group col-2 pr-0">
            <label htmlFor="dose" id="dose-label">
              <span className=" font-weight-medium">Dose</span>
            </label>
            <Field
              type="number"
              placeholder="Qty"
              name="dose"
              id="dose"
              min={0}
              step={"any"}
              disabled={findIsDisabled(values)}
              max={9999}
              className="form-control dose-field"
              onChange={(e) => {
                onChangeDoseType(
                  "dose",
                  e.target.value,
                  setFieldValue,
                  values.medicationScheduleTimes
                );
              }}
            ></Field>
            <ErrorMessage
              name={"dose"}
              component="div"
              className="invalid-feedback show"
            />
          </div>
          <div className="form-group col-2 mt-1">
            <label htmlFor="unit-filter"></label>
            <CustomCreatableDropdown
              name="doseType"
              id="unit-filter"
              value={values.doseType}
              onChange={(name, val) => {
                setFieldValue(name, val);
              }}
              placeholder={"Unit"}
              options={editedQuantityUnits}
              disabled={findIsDisabled(values)}
              createable={true}
            />
          </div>

          <div className="form-group col-4">
            <label htmlFor="frequency" id="frequency-label">
              <span className="font-weight-medium">Frequency</span>
            </label>
            <CustomCreatableDropdown
              name="frequency"
              id="frequency-filter"
              value={values.frequency}
              onChange={(name, val) =>
                onChangeFrequency(name, val, setFieldValue, values.dose)
              }
              placeholder="Select freq..."
              options={editedSigCodes}
              disabled={findIsDisabled(values)}
            />
          </div>
          <div className="form-group col-4">
            <label htmlFor="prnReason" id="dose-label">
              <span className="font-weight-medium">PRN</span>
            </label>
            {/* <MyGroupedSelectMulti */}
            <CustomCreatableDropdown
              id="prn-filter"
              name="prnReason"
              value={values.prnReason}
              onChange={(name, val) => {
                setFieldValue(name, val);
              }}
              placeholder={prn ? "Yes" : "Not PRN"}
              setFieldValue={setFieldValue}
              options={editedPrnReasons}
              disabled={findIsDisabled(values)}
              prn={prn}
              setPRN={setPRN}
              createable={true}
              frequencyPrn={values.frequency?.value === 1000}
            />
          </div>
        </div>
        {values.frequency?.label === "Cycle" && <Cycle />}
        <div
          type="button"
          id="instructions-label"
          className="mt-3 mb-3"
          onClick={toggle}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              toggle();
            }
          }}
          tabIndex="0"
        >
          {!isOpen ? (
            <Chevron className="mr-3 mb-1 glyph-gray w-8px" aria-label="up" />
          ) : (
            <ChevronDown className="mr-3 mb-1 glyph-gray" aria-label="down" />
          )}
          <span className="text-secondary font-weight-medium">
            Optional Schedule Details
          </span>
        </div>
        <Collapse isOpen={isOpen}>
          <>
            {/* {values.scheduleType.value === 6 && (
              <Cycle
                values={values}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
              />
            )} */}

            <div className="bg-gray border border-graydark1 rounded text-nowrap overflow-hidden">
              {values.frequency?.value && !prn && !everyXHours ? (
                <div className="p-3 border-bottom border-graydark1">
                  <div className="d-flex align-items-start">
                    <div>
                      {values.medicationScheduleTimes.map((dose, idx) => (
                        <Dose
                          key={dose.time + idx}
                          idx={idx}
                          setFieldTouched={setFieldTouched}
                          setFieldValue={setFieldValue}
                          label={true}
                          timeComponent={!prn && !everyXHours}
                          values={values}
                          doseOptions={doseOptions}
                          errors={errors}
                        />
                      ))}
                      {!values.medicationScheduleTimes?.length ? (
                        <div className="text-secondary">No Scheduled Times</div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="ml-auto">
                      {values.frequency?.label === "Cycle" && (
                        <button
                          className="btn btn-link py-0"
                          type="button"
                          onClick={() => {
                            // add a new dose to the medicationScheduleTimes array
                            setFieldValue("medicationScheduleTimes", [
                              ...values.medicationScheduleTimes,
                              {
                                dose: values.dose,
                                time: "",
                                partOfDay: ""
                              }
                            ]);
                          }}
                        >
                          Add a time
                        </button>
                      )}
                    </div>
                  </div>
                  {values.frequency?.label === "Cycle" &&
                    values.medicationScheduleTimes?.length > 1 && (
                      <div className="small text-secondary justify-content-end d-flex">
                        *Dose time is required for additional doses
                      </div>
                    )}
                </div>
              ) : (
                <></>
              )}

              <div className="form-group pt-3 px-3">
                <div className="d-flex align-items-center w-75 mb-2">
                  <label className="mb-0 text-nowrap" htmlFor="startDateTime">
                    Start Date:&nbsp;&nbsp;
                  </label>
                  <Suspense
                    fallback={<div className="date-time bg-gray"></div>}
                  >
                    <DatePickerField
                      classnames="date-time bg-gray"
                      selected={values.startDateTime}
                      placeholderText="Not Set"
                      onChange={(date) =>
                        onChangeStartTime(date, setFieldTouched, setFieldValue)
                      }
                      id="startDateTime"
                      name="startDateTime"
                      maxDate={values.endDateTime}
                      dateFormat="M/d/yyyy"
                    />
                  </Suspense>
                </div>
                {values.frequency?.label !== "Cycle" && (
                  <div className="d-flex align-items-center">
                    <label className="mb-0 text-nowrap" htmlFor="endDateTime">
                      End Date:&nbsp;&nbsp;
                    </label>
                    <Suspense
                      fallback={<div className="date-time bg-gray"></div>}
                    >
                      <DatePickerField
                        classnames="date-time bg-gray"
                        selected={values.endDateTime}
                        placeholderText="Not Set"
                        onChange={(date) =>
                          onChangeEndTime(date, setFieldTouched, setFieldValue)
                        }
                        id="endDateTime"
                        name="endDateTime"
                        minDate={values.startDateTime}
                        dateFormat="M/d/yyyy"
                      />
                    </Suspense>
                  </div>
                )}
              </div>
            </div>
          </>
        </Collapse>
        {/* {values.scheduleType?.value === 7 && (
          <button
            className="btn btn-link"
            onClick={() => {
              toggleModal();
              setEditable(true);
              setMedList([values]);
              if (selPlan.medicationPlanSteps) setActiveTab("3");
              else setActiveTab("1");
            }}
            disabled={!isValid}
            type="button"
          >
            <>
              {selPlan.medicationPlanSteps
                ? `${
                    selPlan.medicationPlanName ||
                    selPlan.medicationPlanSteps.length + " Step Plan"
                  } Added`
                : "Click to Create New Plan"}
              <Chevron className="ml-2 h-10px" />
            </>
          </button>
        )}
        {values.scheduleType?.value === 8 && (
          <div className="pt-4">
            <label htmlFor="savedPlan">
              Select a Plan <span className="text-danger">*</span>
            </label>
            <MySelect
              id="savedPlan"
              name="savedPlan"
              value={values.savedPlan}
              options={medPlans}
              onChange={(name, val) =>
                onChangeSavedPlan(name, val, setFieldValue)
              }
              onBlur={setFieldTouched}
              noOptionsMessage={() =>
                "No plans are available. Please create a new plan"
              }
            />
            {!findPlan() ? (
              <>
                <p className="text-secondary mt-4 mb-0 small">
                  This saved plan has been altered or deleted and can no longer
                  be edited.
                </p>
                <button
                  className="btn btn-link"
                  onClick={() => {
                    toggleModal();
                    setActiveTab("3");
                    setEditable(false);
                  }}
                  type="button"
                >{`View ${values.savedPlan.medicationPlanName}`}</button>
              </>
            ) : (
              <button
                className="btn btn-link"
                onClick={() => {
                  toggleModal();
                  setActiveTab("3");
                  setEditable(true);
                }}
                type="button"
              >{`View/Edit ${values.savedPlan.medicationPlanName}`}</button>
            )}
          </div>
        )}
        <CreateAPlan
          selectedPlan={selPlan}
          setSelectedPlan={setSelectedPlan}
          values={values}
          canAdd={isValid}
          setPlanAdded={setPlanAdded}
          type="add"
          editable={editable}
          setMedPlans={setMedPlans}
          toggleModal={toggleModal}
          modal={modal}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          setMedList={setMedList}
          medList={medList}
        /> */}
      </div>
    </div>
  );
};

ScheduleType.propTypes = {
  findIsDisabled: PropTypes.func,
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
  setInitialValues: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object,
  values: PropTypes.object
};
