import React from "react";
import { Col, Row } from "reactstrap";
import { CernerDateSelect } from "../_components";
import { ReactComponent as GlyphCheck } from "../images/glyph_check.svg";
import { ReactComponent as GlyphX } from "../images/glyph_x.svg";
import PropTypes from "prop-types";

const AdhSummary = ({
  adherence,
  handleAdherenceSummaryFilterChange,
  loadingAdhrence,
  patientId,
  version,
  range,
  activeOption,
  setActiveOption
}) => {
  return (
    <div>
      <div className="pb-6 pl-0 pt-0 w-100">
        <CernerDateSelect
          callback={handleAdherenceSummaryFilterChange}
          id={`${version}-tab-select-${patientId}`}
          range={range}
          activeOption={activeOption}
          setActiveOption={setActiveOption}
        />
      </div>
      {loadingAdhrence ? (
        <div className="ph-item border-0" data-testid="loading-adh-summary">
          {[...Array(3)].map((e, i) => {
            return (
              <div className="ph-col-9" key={i}>
                <div className="ph-row">
                  <div className="ph-col-6 medium"></div>
                  <div className="ph-col-half empty"></div>
                  <div className="ph-col-8"></div>
                  <div className="ph-col-half empty"></div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div>
          <Row className="mb-4">
            <Col sm="4" xs="4" className="pr-1">
              <div className="d-flex">
                <span className="pr-1 align-self-end">
                  <GlyphCheck aria-label="check" />
                </span>

                <span className="d-flex flex-column align-self-end">
                  <span>
                    <h2 className="meds-skipped mb-0">{adherence.taken}</h2>
                  </span>
                  <span className="mt-1">
                    <span className="font-weight-medium card-table-data">
                      Taken
                    </span>
                  </span>
                </span>
              </div>
            </Col>
            <Col sm="4" xs="4" className="pl-1 pr-1">
              <div className="d-flex">
                <span className="pr-1 align-self-end">
                  <GlyphX className="glyph-danger" aria-label="x" />
                </span>

                <span className="d-flex flex-column align-self-end">
                  <span>
                    <h2 className="meds-skipped mb-0">{adherence.skipped}</h2>
                  </span>
                  <span className="mt-1">
                    <span className="font-weight-medium card-table-data">
                      Skipped
                    </span>
                  </span>
                </span>
              </div>
            </Col>
            <Col sm="4" xs="4" className="pl-1 pr-1">
              <div className="d-flex">
                <span className="pr-1 align-self-end">
                  <GlyphX className="glyph-danger" aria-label="x" />
                </span>

                <span className="d-flex flex-column align-self-end">
                  <span>
                    <h2 className="meds-skipped mb-0">{adherence.missed}</h2>
                  </span>
                  <span className="mt-1">
                    <span className="font-weight-medium card-table-data">
                      Missed
                    </span>
                  </span>
                </span>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

AdhSummary.propTypes = {
  adherence: PropTypes.object,
  handleAdherenceSummaryFilterChange: PropTypes.func,
  loadingAdhrence: PropTypes.bool,
  patientId: PropTypes.string,
  version: PropTypes.string,
  range: PropTypes.object,
  activeOption: PropTypes.string,
  setActiveOption: PropTypes.func
};

export { AdhSummary };
