import React from "react";
import PropTypes from "prop-types";

const TableSkeletons = ({ isMobileWidth, emptyColSpan }) => {
  return (
    <tr className="hover-none">
      {isMobileWidth ? (
        <td colSpan="6" className="text-center">
          {[...Array(6)].map((e, i) => {
            if (i % 2 === 0) {
              return (
                <div className="ph-item" key={`ph-item-${i + 1}`}>
                  <div className="ph-col-12">
                    <div className="ph-row">
                      <div className="ph-col-real3 big"></div>
                      <div className="ph-col-2 empty"></div>
                      <div className="ph-col-6 big"></div>
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div className="ph-item" key={`ph-item-${i + 1}`}>
                  <div className="ph-col-12">
                    <div className="ph-row">
                      <div className="ph-col-4 big"></div>
                      <div className="ph-col-1 empty"></div>
                      <div className="ph-col-5 big"></div>
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </td>
      ) : (
        <>
          {emptyColSpan === 9 && (
            <td colSpan="9" className="text-center">
              {[...Array(6)].map((e, i) => {
                if (i % 2 === 0) {
                  return (
                    <div className="ph-item" key={`ph-item-${i + 1}`}>
                      <div className="ph-col-12">
                        <div className="ph-row">
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-half empty"></div>
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-1 big"></div>
                          <div className="ph-col-3 empty"></div>
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-half big"></div>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="ph-item" key={`ph-item-${i + 1}`}>
                      <div className="ph-col-12">
                        <div className="ph-row">
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-half empty"></div>
                          <div className="ph-col-1 big"></div>
                          <div className="ph-col-3 empty"></div>
                          <div className="ph-col-1 big"></div>
                          <div className="ph-col-3 empty"></div>
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-3 big"></div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </td>
          )}
          {emptyColSpan === 8 && (
            <td colSpan="8" className="text-center">
              {[...Array(6)].map((e, i) => {
                if (i % 2 === 0) {
                  return (
                    <div className="ph-item" key={`ph-item-${i + 1}`}>
                      <div className="ph-col-12">
                        <div className="ph-row">
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-half empty"></div>
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-1 big"></div>
                          <div className="ph-col-3 empty"></div>
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-half big"></div>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="ph-item" key={`ph-item-${i + 1}`}>
                      <div className="ph-col-12">
                        <div className="ph-row">
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-half empty"></div>
                          <div className="ph-col-1 big"></div>
                          <div className="ph-col-3 empty"></div>
                          <div className="ph-col-1 big"></div>
                          <div className="ph-col-3 empty"></div>
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-3 big"></div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </td>
          )}
          {emptyColSpan === 7 && (
            <td colSpan="7" className="text-center">
              {[...Array(6)].map((e, i) => {
                if (i % 2 === 0) {
                  return (
                    <div className="ph-item" key={`ph-item-${i + 1}`}>
                      <div className="ph-col-12">
                        <div className="ph-row">
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-half empty"></div>
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-1 big"></div>
                          <div className="ph-col-half empty"></div>
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-half empty"></div>
                          <div className="ph-col-1 big"></div>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="ph-item" key={`ph-item-${i + 1}`}>
                      <div className="ph-col-12">
                        <div className="ph-row">
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-half empty"></div>
                          <div className="ph-col-1 big"></div>
                          <div className="ph-col-3 empty"></div>
                          <div className="ph-col-1 big"></div>
                          <div className="ph-col-3 empty"></div>
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-1 big"></div>
                          <div className="ph-col-half empty"></div>
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-half empty"></div>
                          <div className="ph-col-3 big"></div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </td>
          )}
          {emptyColSpan === "7alt" && (
            <td colSpan="7" className="text-center">
              {[...Array(6)].map((e, i) => {
                if (i % 2 === 0) {
                  return (
                    <div className="ph-item" key={`ph-item-${i + 1}`}>
                      <div className="ph-col-12">
                        <div className="ph-row">
                          <div className="ph-col-half big"></div>
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-2 big"></div>
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-half big"></div>
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-half empty"></div>
                          <div className="ph-col-2 big"></div>
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-1 big"></div>
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-1 big"></div>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="ph-item" key={`ph-item-${i + 1}`}>
                      <div className="ph-col-12">
                        <div className="ph-row">
                          <div className="ph-col-half big"></div>
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-1 big"></div>
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-1 big"></div>
                          <div className="ph-col-3 empty"></div>
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-half empty"></div>
                          <div className="ph-col-1 big"></div>
                          <div className="ph-col-half empty"></div>
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-half empty"></div>
                          <div className="ph-col-3 big"></div>
                          <div className="ph-col-half empty"></div>
                          <div className="ph-col-half big"></div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </td>
          )}
          {(emptyColSpan === 5 || emptyColSpan === "5alt") && (
            <td colSpan="6" className="text-center">
              {[...Array(6)].map((e, i) => {
                if (i % 2 === 0) {
                  return (
                    <div className="ph-item" key={`ph-item-${i + 1}`}>
                      <div className="ph-col-12">
                        <div className="ph-row">
                          {emptyColSpan === 5 && (
                            <div className="ph-col-half empty"></div>
                          )}
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-1 empty"></div>
                          <div className="ph-col-2 big"></div>
                          <div className="ph-col-3 empty"></div>
                          <div className="ph-col-2 big"></div>
                          <div className="ph-col-half empty"></div>
                          <div className="ph-col-2 big"></div>
                          <div className="ph-col-3 empty"></div>
                          <div className="ph-col-3 big"></div>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="ph-item" key={`ph-item-${i + 1}`}>
                      <div className="ph-col-12">
                        <div className="ph-row">
                          {emptyColSpan === 5 && (
                            <div className="ph-col-half empty"></div>
                          )}
                          <div className="ph-col-2 big"></div>
                          <div className="ph-col-half empty"></div>
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-1 empty"></div>
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-2 big"></div>
                          <div className="ph-col-half empty"></div>
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-1 empty"></div>
                          <div className="ph-col-quarter empty"></div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </td>
          )}
          {emptyColSpan === 3 && (
            <td colSpan="3" className="text-center">
              {[...Array(9)].map((e, i) => {
                if (i % 2 === 0) {
                  return (
                    <div className="ph-item" key={`ph-item-${i + 1}`}>
                      <div className="ph-col-12">
                        <div className="ph-row">
                          <div className="ph-col-real3 big"></div>
                          <div className="ph-col-3 empty"></div>
                          <div className="ph-col-4 big"></div>
                          <div className="ph-col-real3 empty"></div>

                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-3 big"></div>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="ph-item" key={`ph-item-${i + 1}`}>
                      <div className="ph-col-12">
                        <div className="ph-row">
                          <div className="ph-col-2 big"></div>
                          <div className="ph-col-14 empty"></div>
                          <div className="ph-col-real3 big"></div>
                          <div className="ph-col-5 empty"></div>
                          <div className="ph-col-half big"></div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </td>
          )}
          {emptyColSpan === "6alt" && (
            <td colSpan="6" className="text-center">
              {[...Array(6)].map((e, i) => {
                if (i % 2 === 0) {
                  return (
                    <div className="ph-item" key={`ph-item-${i + 1}`}>
                      <div className="ph-col-12">
                        <div className="ph-row">
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-half empty"></div>
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-3 empty"></div>
                          <div className="ph-col-2 big"></div>
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-2 big"></div>
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-1 big"></div>
                          <div className="ph-col-1 empty"></div>
                          <div className="ph-col-1 big"></div>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="ph-item" key={`ph-item-${i + 1}`}>
                      <div className="ph-col-12">
                        <div className="ph-row">
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-half empty"></div>
                          <div className="ph-col-2 big"></div>
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-2 big"></div>
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-3 empty"></div>
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-half empty"></div>
                          <div className="ph-col-3 big"></div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </td>
          )}
          {emptyColSpan === 4 && (
            <td colSpan="4" className="text-center">
              {[...Array(6)].map((e, i) => {
                if (i % 2 === 0) {
                  return (
                    <div className="ph-item" key={`ph-item-${i + 1}`}>
                      <div className="ph-col-12">
                        <div className="ph-row">
                          <div className="ph-col-real3 big"></div>
                          <div className="ph-col-3 empty"></div>
                          <div className="ph-col-4 big"></div>
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-real3 big"></div>
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-3 big"></div>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="ph-item" key={`ph-item-${i + 1}`}>
                      <div className="ph-col-12">
                        <div className="ph-row">
                          <div className="ph-col-2 big"></div>
                          <div className="ph-col-14 empty"></div>
                          <div className="ph-col-real3 big"></div>
                          <div className="ph-col-1 empty"></div>
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-2 big"></div>
                          <div className="ph-col-1 empty"></div>
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-half big"></div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </td>
          )}
          {emptyColSpan === "4alt" && (
            <td colSpan="4" className="text-center">
              {[...Array(6)].map((e, i) => {
                if (i % 2 === 0) {
                  return (
                    <div className="ph-item" key={`ph-item-${i + 1}`}>
                      <div className="ph-col-12">
                        <div className="ph-row">
                          <div className="ph-col-2 big"></div>
                          <div className="ph-col-1 empty"></div>
                          <div className="ph-col-real3 big"></div>
                          <div className="ph-col-half empty"></div>
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-real3 big"></div>
                          <div className="ph-col-1 empty"></div>
                          <div className="ph-col-3 big"></div>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="ph-item" key={`ph-item-${i + 1}`}>
                      <div className="ph-col-12">
                        <div className="ph-row">
                          <div className="ph-col-2 big"></div>
                          <div className="ph-col-half big"></div>
                          <div className="ph-col-half empty"></div>
                          <div className="ph-col-2 big"></div>
                          <div className="ph-col-15 empty"></div>
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-2 big"></div>
                          <div className="ph-col-2 empty"></div>
                          <div className="ph-col-half big"></div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </td>
          )}
          {emptyColSpan === 6 && (
            <td colSpan="7" className="text-center">
              {[...Array(6)].map((e, i) => {
                if (i % 2 === 0) {
                  return (
                    <div className="ph-item" key={`ph-item-${i + 1}`}>
                      <div className="ph-col-12">
                        <div className="ph-row">
                          <div className="ph-col-half empty"></div>
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-half empty"></div>
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-half empty"></div>
                          <div className="ph-col-1 big"></div>
                          <div className="ph-col-3 empty"></div>
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-half empty"></div>
                          <div className="ph-col-1 big"></div>
                          <div className="ph-col-1 empty"></div>
                          <div className="ph-col-half big"></div>
                          <div className="ph-col-half empty"></div>
                          <div className="ph-col-half big"></div>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="ph-item" key={`ph-item-${i + 1}`}>
                      <div className="ph-col-12">
                        <div className="ph-row">
                          <div className="ph-col-half empty"></div>
                          <div className="ph-col-2 big"></div>
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-half empty"></div>
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-quarter empty"></div>
                          <div className="ph-col-15 big"></div>
                          <div className="ph-col-half empty"></div>
                          <div className="ph-col-1 big"></div>
                          <div className="ph-col-half empty"></div>
                          <div className="ph-col-quarter big"></div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </td>
          )}
        </>
      )}
    </tr>
  );
};

TableSkeletons.propTypes = {
  isMobileWidth: PropTypes.bool,
  emptyColSpan: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export { TableSkeletons };
