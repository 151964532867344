import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { patientInviteService } from "../_services";

const MPages = () => {
  const [patientName, setPatientName] = useState(null);
  const { patientId, patientInviteStatus } = useParams();

  useEffect(() => {
    let mounted = true;
    window.CernerSmartEmbeddableLib.calcFrameHeight = function () {
      return 300;
    };
    patientInviteService
      .getInvite(patientId)
      .then((response) => {
        if (mounted) {
          setPatientName(response.firstName);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return function cleanup() {
      mounted = false;
    };
  }, [patientId]);

  return (
    <div className="d-flex justify-content-center">
      <Card className="bg-white mb-0 w-30rem">
        <CardBody>
          <div className="">
            {patientInviteStatus === "0" || Number(patientInviteStatus) >= 4 ? (
              <>
                <h4 className="text-center">Invite Patient to EveryDose</h4>
                <p className="text-center">
                  Visit the main EveryDose app in the Table of Contents menu to
                  invite this patient to the EveryDose mobile app.
                </p>
              </>
            ) : patientInviteStatus === "null" ? (
              <>
                <h4 className="text-center">
                  You don't have permission to access this feature
                </h4>
                <p className="text-center">
                  Visit the main EveryDose app in the Table of Contents menu to
                  use the Admin Center
                </p>
              </>
            ) : (
              <>
                <h4 className="text-center">
                  Great! {patientName} has been invited to <br />
                  sign up for EveryDose
                </h4>
                <p className="text-center">
                  We’ll let you know when {patientName} accepts the invitation.
                  Visit the main EveryDose app in the Table of Contents menu to
                  view and edit the invitation
                </p>
              </>
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export { MPages };
