import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { CloseButton, FormInTable } from "../../../_components";
import PropTypes from "prop-types";

export const AlertModal = ({
  alertModal,
  toggleAlertModal,
  selectedRow,
  bulkUpdate,
  fetchMedications,
  setBulkUpdate,
  selectedRows,
  bulkAlertType,
  setMultiSelect
}) => {
  const [text, setText] = useState("missed");
  useEffect(() => {
    if (
      bulkAlertType === "taken" ||
      selectedRow?.original?.medicationSchedule?.scheduleType === 1
    ) {
      setText("taken");
    } else setText("missed");
  }, [bulkAlertType, selectedRow]);
  return (
    <Modal
      isOpen={alertModal}
      toggle={toggleAlertModal}
      className="center-modal"
    >
      <ModalHeader
        toggle={toggleAlertModal}
        close={<CloseButton toggle={toggleAlertModal} />}
      >
        Medication Alert
      </ModalHeader>
      <ModalBody>
        <p>Set an alert to notify you when a patient has {text} a medication</p>
        {selectedRow.original && (
          <h6>
            {selectedRow.original?.medicationName},{" "}
            {selectedRow.original?.medicationStrength.strength}{" "}
            {selectedRow.original?.medicationStrength.form}
          </h6>
        )}
        {bulkUpdate ? (
          <FormInTable
            fetchMedications={fetchMedications}
            //Need these null props to make the reusable component easier to reuse
            row={{
              original: { alertInfo: null, scheduleType: null }
            }}
            setBulkUpdate={setBulkUpdate}
            selectedRows={selectedRows}
            toggleAlertModal={toggleAlertModal}
            bulkAlertType={bulkAlertType}
            setMultiSelect={setMultiSelect}
          />
        ) : (
          <FormInTable
            row={
              selectedRow?.original
                ? selectedRow
                : {
                    original: { alertInfo: null, scheduleType: null }
                  }
            }
            fetchMedications={fetchMedications}
            toggleAlertModal={toggleAlertModal}
          />
        )}
      </ModalBody>
    </Modal>
  );
};
AlertModal.propTypes = {
  alertModal: PropTypes.bool,
  toggleAlertModal: PropTypes.func,
  selectedRow: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
    PropTypes.object
  ]),
  bulkUpdate: PropTypes.bool,
  fetchMedications: PropTypes.func,
  setBulkUpdate: PropTypes.func,
  selectedRows: PropTypes.array,
  bulkAlertType: PropTypes.string,
  setMultiSelect: PropTypes.func
};
