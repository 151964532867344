import classnames from "classnames";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import PropTypes from "prop-types";
import React, { lazy, Suspense, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Badge,
  CustomInput,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "reactstrap";
import { findType } from "../_helpers";
import { notificationService } from "../_services";
import { useAlertContext } from "../context/alertContext";
import { useSmartContext } from "../context/smartContext";
import { useUserContext } from "../context/userContext";
import { ReactComponent as Bell } from "../images/bell_alert-off.svg";
import { ReactComponent as ChevronDown } from "../images/chevron_down_small.svg";
import { ReactComponent as Smile } from "../images/circle-face.svg";
import { ReactComponent as GlyphX } from "../images/glyph_x.svg";
import { MyBasicTooltip } from "./MyTooltip";

import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(utc);
dayjs.extend(relativeTime);
const Waypoint = lazy(() =>
  import("react-waypoint").then((module) => ({
    default: module.Waypoint
  }))
);

const SidebarComponent = lazy(() =>
  import("@syncfusion/ej2-react-navigations").then((module) => ({
    default: module.SidebarComponent
  }))
);
const NotificationList = ({
  notifications,
  clearAll,
  setNotifications,
  isSubmitting,
  markAllAsRead,
  markAllAsReadIsSubmitting,
  markAllAsUnRead,
  markAllAsUnreadIsSubmitting,
  setNewNotifications,
  toggle,
  loadMore,
  open,
  loadingNotifications,
  sidebar,
  patientId,
  fetchNotifications,
  mounted
}) => {
  const [actionsDropdown, setActionsDropdown] = useState(false);
  const [showUnreadOnly, setShowUnreadOnly] = useState(true);
  const [filterNotifications, setFilterNotifications] = useState([]); //[...notifications
  const [loading, setLoading] = useState(false);
  const toggleActionsDropdown = () => setActionsDropdown(!actionsDropdown);

  const toggleShowUnreadOnly = () => setShowUnreadOnly(!showUnreadOnly);

  const filterNotificationsByReadStatus = React.useCallback(
    (isRead) => {
      return notifications.filter((x) => x.isRead === isRead);
    },
    [notifications]
  );

  useEffect(() => {
    if (localStorage.getItem("showUnreadOnly") === "false") {
      setShowUnreadOnly(false);
    } else {
      setShowUnreadOnly(true);
    }
  }, []);

  useEffect(() => {
    if (showUnreadOnly) {
      localStorage.setItem("showUnreadOnly", true);
      setFilterNotifications(filterNotificationsByReadStatus(false));
    } else {
      localStorage.setItem("showUnreadOnly", false);
      setFilterNotifications(notifications);
    }
  }, [showUnreadOnly, notifications, filterNotificationsByReadStatus]);

  function onLeave() {
    // this is intentional
  }

  return (
    <>
      <div
        className={classnames(
          "pl-6 pr-4 primary-underline w-100 pb-md-2 pb-5 pt-lg-3 pt-0 text-nowrap"
        )}
      >
        <h4 className="font-weight-medium mb-0">Notification Center</h4>

        {notifications.length ? (
          <div className="d-flex justify-content-between align-items-center">
            <Dropdown isOpen={actionsDropdown} toggle={toggleActionsDropdown}>
              <DropdownToggle className="btn-link btn" id="actions-toggle">
                Actions
                <ChevronDown className="ml-3" aria-label="chevron down" />
              </DropdownToggle>
              <DropdownMenu className="">
                <DropdownItem
                  className={classnames({
                    disabled:
                      filterNotificationsByReadStatus(false).length === 0
                  })}
                  onClick={() => {
                    markAllAsRead();
                  }}
                >
                  {markAllAsReadIsSubmitting && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  Mark All as Read
                </DropdownItem>
                <DropdownItem
                  className={classnames({
                    disabled: filterNotificationsByReadStatus(true).length === 0
                  })}
                  onClick={() => {
                    markAllAsUnRead();
                  }}
                >
                  {markAllAsUnreadIsSubmitting && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  Mark All as Unread
                </DropdownItem>

                <DropdownItem
                  className={classnames({
                    disabled: !notifications.length
                  })}
                  onClick={() => {
                    clearAll();
                  }}
                >
                  {isSubmitting && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  Clear All
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <div className="d-flex ">
              <label
                htmlFor="read-only-toggle"
                className="small text-secondary mr-2"
              >
                Only show unread
              </label>
              <CustomInput
                checked={showUnreadOnly}
                type="switch"
                data-testid="read-only-toggle"
                name="read-only-toggle"
                id="read-only-toggle"
                onChange={toggleShowUnreadOnly}
                className="with-x with-check"
                aria-checked={showUnreadOnly}
                aria-label="toggle show unread only"
              />
            </div>
          </div>
        ) : (
          <div className="small text-secondary mt-0 font-weight-medium mb-1">
            No notifications
          </div>
        )}
      </div>
      <div id="notification-dropdown" data-testid="notifications-list">
        {filterNotifications.length ? (
          <div className="">
            {filterNotifications.map((item, index) => {
              return (
                <div key={item.id}>
                  <Notification
                    setNotifications={setNotifications}
                    index={index}
                    item={item}
                    length={filterNotifications.length}
                    setNewNotifications={setNewNotifications}
                    toggle={toggle}
                    patientId={patientId}
                    mounted={mounted}
                  />

                  {index + 1 === filterNotifications.length &&
                    (open || sidebar) ? (
                    <>
                      <Suspense fallback={<></>}>
                        <Waypoint
                          onEnter={() => {
                            loadMore();
                          }}
                          onLeave={
                            onLeave
                            //this is intentional
                          }
                          scrollableAncestor={
                            open
                              ? document.getElementById("notification-dropdown")
                              : document.getElementById("notifications")
                          }
                        // debug={true}
                        >
                          <div className="text-white">Load More</div>
                        </Waypoint>
                      </Suspense>
                      {loadingNotifications ? (
                        <div className="w-100 border-top">
                          <div className="ph-item border-0 mb-1 pt-4 mx-6">
                            <div className="ph-col-12 pl-0">
                              <div className="ph-row">
                                <div className="ph-col-8 big mb-1"></div>
                              </div>
                              <div className=" ph-row">
                                <div className="ph-col-6 mb-3"></div>
                              </div>
                              <div className=" ph-row">
                                <div className="ph-col-12 mb-0"></div>
                              </div>
                              <div className=" ph-row">
                                <div className="ph-col-6 mb-2"></div>
                              </div>
                              <div className=" ph-row">
                                <div className="ph-col-8 empty"></div>
                                <div className="ph-col-4 mb-0 big"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </>
                  ) : null}
                </div>
              );
            })}
          </div>
        ) : (
          <div className="px-6 py-5 text-center overflow-hidden">
            <Smile aria-label="smile" />
            <h4 className="text-secondary mt-4 mb-0 text-nowrap">
              No {showUnreadOnly && notifications.length ? "Unread " : ""}{" "}
              Notifications Found
            </h4>
            <div className="d-flex align-items-center justify-content-center">
              <span>
                {loading ? (
                  <span className="spinner-border spinner-border-sm text-primary"></span>
                ) : null}
                <button
                  className="text-primary btn-link btn px-1 text-nowrap"
                  tabIndex={0}
                  onClick={(e) => {
                    setLoading(true);
                    e.preventDefault();
                    fetchNotifications(
                      {
                        pageIndex: 1,
                        pageSize: 20,
                        isLoadMore: false
                      },
                      setLoading
                    );
                  }}
                  type="button"
                >
                  Refresh
                </button>{" "}
              </span>
              <span>or check back later for updates</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

NotificationList.propTypes = {
  notifications: PropTypes.array,
  clearAll: PropTypes.func,
  setNotifications: PropTypes.func,
  isSubmitting: PropTypes.bool,
  markAllAsRead: PropTypes.func,
  markAllAsReadIsSubmitting: PropTypes.bool,
  markAllAsUnRead: PropTypes.func,
  markAllAsUnreadIsSubmitting: PropTypes.bool,
  setNewNotifications: PropTypes.func,
  toggle: PropTypes.func,
  loadMore: PropTypes.func,
  open: PropTypes.bool,
  loadingNotifications: PropTypes.bool,
  sidebar: PropTypes.bool,
  patientId: PropTypes.string,
  fetchNotifications: PropTypes.func,
  mounted: PropTypes.shape({ current: PropTypes.bool })
};

const Notifications = ({
  setNotifications,
  isMobileWidth,
  newNotifications,
  notifications,
  setNewNotifications,
  loadMore,
  smart,
  loadingNotifications,
  fetchNotifications
}) => {
  const { patientId } = useSmartContext();
  const { alertMethods } = useAlertContext();
  const [open, setOpen] = useState(false);

  const [sidebar, setSidebar] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [markAllAsReadIsSubmitting, setMarkAllAsReadIsSubmitting] =
    useState(false);
  const [markAllAsUnreadIsSubmitting, setMarkAllAsUnreadIsSubmitting] =
    useState(false);

  const sidebarRef = useRef(null);

  const mounted = React.useRef(false);
  const toggle = () => {
    setOpen((prevState) => !prevState);
  };
  const toggleSidebar = () => setSidebar(!sidebar);
  const onCreate = () => {
    // added if condition to prevent error when sidebarRef.current is null
    if (sidebarRef.current && sidebarRef.current !== null)
      sidebarRef.current.element.classList.remove("hidden"); // Remove an old class
  };
  React.useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => {
      mounted.current = false;
    }; // ... and to false on unmount
  }, []);

  function clearAll() {
    setIsSubmitting(true);
    notificationService
      .deleteNotifications(patientId)
      .then(() => {
        setIsSubmitting(false);
        setNotifications([]);
        setNewNotifications(0);
      })
      .catch(() => {
        setIsSubmitting(false);

        alertMethods.error(
          "<div class='font-weight-bold'>" +
          "An Error Occurred" +
          "</div>" +
          "Something went wrong. Please try again."
        );
      });
  }

  function markAllAsRead() {
    setMarkAllAsReadIsSubmitting(true);
    notificationService
      .markAllAsRead(patientId)
      .then(() => {
        setMarkAllAsReadIsSubmitting(false);
        setNewNotifications(0);
        setNotifications((notifications) =>
          notifications.map((x) => {
            x.isRead = true;

            return x;
          })
        );
      })
      .catch(() => {
        setMarkAllAsReadIsSubmitting(false);
        alertMethods.error(
          "<div class='font-weight-bold'>" +
          "An Error Occurred" +
          "</div>" +
          "Something went wrong. Please try again."
        );
      });
  }

  function markAllAsUnRead() {
    const notificationsMapCallback = (x) => ({ ...x, isRead: false });

    setMarkAllAsUnreadIsSubmitting(true);
    notificationService
      .markAllAsUnread(patientId)
      .then(() => {
        setMarkAllAsUnreadIsSubmitting(false);
        setNewNotifications(notifications.length);
        setNotifications((notifications) =>
          notifications.map(notificationsMapCallback)
        );
      })
      .catch(() => {
        setMarkAllAsUnreadIsSubmitting(false);
        alertMethods.error(
          "<div class='font-weight-bold'>" +
          "An Error Occurred" +
          "</div>" +
          "Something went wrong. Please try again."
        );
      });
  }

  useEffect(() => {
    if (open) {
      const firstItem = document.getElementById("actions-toggle");
      firstItem?.focus();
    }
  }, [open]);
  return (
    <>
      {isMobileWidth && (
        <div className="control-section">
          <Suspense
            fallback={
              <Bell
                aria-label="bell"
                className={classnames("mobile-glyph glyph-gray mx-lg-0 mx-2")}
              />
            }
          >
            <React.Fragment>
              <button
                type="button"
                onClick={toggleSidebar}
                aria-label="notifications"
                tabIndex="0"
                // on key down open if key is enter or shift
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    toggleSidebar();
                  }
                }}
                className=" btn-link btn text-secondary"
              >
                <Bell
                  aria-label={newNotifications ? "bell-alert" : "bell-no-alert"}
                  className={classnames("mobile-glyph glyph-gray mx-lg-0 mx-2")}
                />
                {newNotifications ? (
                  <Badge
                    color="primary mt-neg-8px ml-neg-22px"
                    aria-label={`${newNotifications} unread notifications`}
                  >
                    {newNotifications < 99 ? newNotifications : "99+"}
                  </Badge>
                ) : (
                  <></>
                )}
              </button>

              <SidebarComponent
                id="notifications"
                ref={sidebarRef}
                closeOnDocumentClick={false}
                type="Over"
                isOpen={sidebar}
                position="Right"
                created={onCreate}
                className="scrollable hidden"
              >
                <div role="presentation">
                  <div className="Notification">
                    <span>
                      <button
                        className="close px-4 py-2"
                        onClick={toggleSidebar}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            toggleSidebar();
                          }
                        }}
                      >
                        <GlyphX className="mb-2" aria-label="x" />
                      </button>
                    </span>

                    <NotificationList
                      notifications={notifications}
                      clearAll={clearAll}
                      setNotifications={setNotifications}
                      isSubmitting={isSubmitting}
                      markAllAsRead={markAllAsRead}
                      markAllAsReadIsSubmitting={markAllAsReadIsSubmitting}
                      markAllAsUnRead={markAllAsUnRead}
                      markAllAsUnreadIsSubmitting={markAllAsUnreadIsSubmitting}
                      setNewNotifications={setNewNotifications}
                      toggle={setSidebar}
                      loadMore={loadMore}
                      sidebar={sidebar}
                      loadingNotifications={loadingNotifications}
                      patientId={patientId}
                      fetchNotifications={fetchNotifications}
                      mounted={mounted}
                    />
                  </div>
                </div>
              </SidebarComponent>
            </React.Fragment>
          </Suspense>
        </div>
      )}
      {!isMobileWidth && (
        <Dropdown isOpen={open} toggle={toggle}>
          <DropdownToggle
            tag="a"
            aria-label="notifications"
            className="nav-link"
            tabIndex="0"
            // onKeyDown toggle if it's the enter key - this works by default when the menu is closed but not when it is open
            onKeyDown={(e) => {
              if ((e.key === "Enter" || e.key === "Shift") && open) {
                toggle();
              }
            }}
          >
            <Bell
              aria-label={newNotifications ? "bell-alert" : "bell-no-alert"}
              className={classnames(
                "mobile-glyph mx-0",
                { "glyph-gray": !smart },
                { "glyph-white": smart }
              )}
            />
            {newNotifications ? (
              <Badge
                color="primary"
                className="mt-neg-8px ml-neg-14px position-relative"
                aria-label={`${newNotifications} unread notifications`}
              >
                {newNotifications < 99 ? newNotifications : "99+"}
              </Badge>
            ) : (
              <></>
            )}
          </DropdownToggle>

          <DropdownMenu
            className={classnames("notification mt-4", {
              "smart-notifications": smart
            })}
          >
            <NotificationList
              notifications={notifications}
              clearAll={clearAll}
              setNotifications={setNotifications}
              isSubmitting={isSubmitting}
              markAllAsRead={markAllAsRead}
              markAllAsReadIsSubmitting={markAllAsReadIsSubmitting}
              markAllAsUnRead={markAllAsUnRead}
              markAllAsUnreadIsSubmitting={markAllAsUnreadIsSubmitting}
              setNewNotifications={setNewNotifications}
              toggle={toggle}
              loadMore={loadMore}
              open={open}
              loadingNotifications={loadingNotifications}
              patientId={patientId}
              fetchNotifications={fetchNotifications}
              mounted={mounted}
            />
          </DropdownMenu>
        </Dropdown>
      )}
    </>
  );
};

Notifications.propTypes = {
  setNotifications: PropTypes.func,
  isMobileWidth: PropTypes.bool,
  newNotifications: PropTypes.number,
  notifications: PropTypes.array,
  setNewNotifications: PropTypes.func,
  loadMore: PropTypes.func,
  smart: PropTypes.bool,
  loadingNotifications: PropTypes.bool,
  fetchNotifications: PropTypes.func
};

const NotificationBody = ({
  title,
  convertCreatedAt,
  sendAt,
  taskType,
  associatedPatient,
  patientName,
  medication,
  scheduledTime,
  takenTime,
  body
}) => {
  return (
    <div>
      <div className="small font-weight-bold">{title}</div>

      <div className="small text-secondary font-weight-bold mb-3">
        {convertCreatedAt(sendAt)}
      </div>
      <div className="small text-secondary mb-1 notification-body">
        <div>{taskType}</div>
        <div>{associatedPatient}</div>
        {patientName && <div className="mb-2">{patientName}</div>}
        <div>{medication}</div>
        <div>{scheduledTime}</div>
        {takenTime && <div>{takenTime}</div>}
        <div>{body}</div>
      </div>
    </div>
  );
};

NotificationBody.propTypes = {
  title: PropTypes.string,
  convertCreatedAt: PropTypes.func,
  sendAt: PropTypes.string,
  taskType: PropTypes.string,
  associatedPatient: PropTypes.string,
  patientName: PropTypes.string,
  medication: PropTypes.string,
  scheduledTime: PropTypes.string,
  takenTime: PropTypes.string,
  body: PropTypes.string
};

const Notification = (props) => {
  const {
    item,
    setNotifications,
    index,
    length,
    setNewNotifications,
    toggle,
    patientId,
    mounted
  } = props;
  const { id, isRead, sendAt } = item;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [associatedPatient, setAssociatedPatient] = useState("");
  const [taskType, setTaskType] = useState("");
  const [medication, setMedication] = useState("");
  const [scheduledTime, setScheduledTime] = useState("");
  const [takenTime, setTakenTime] = useState("");
  const [patientName, setPatientName] = useState("");
  const [markingAsReadUnread, setMarkingAsReadUnread] = useState(false);
  const [link, setLink] = useState({ text: "", url: "" });
  const { alertMethods } = useAlertContext();
  const { userState } = useUserContext();

  const { user } = userState;
  const navigate = useNavigate();

  useEffect(() => {
    const {
      data,
      patientFullName,
      notificationType,
      patientId,
      isMobileAppConnected
    } = item;
    switch (notificationType) {
      case 1:
        setTitle(`Your task ${data?.taskName} is due today`);
        setAssociatedPatient(
          `Associated Patient: ${patientFullName.trim() !== "" ? patientFullName : "N/A"
          }`
        );
        setTaskType(`Task Type: ${findType(data?.type)}`);
        setLink({ text: "Go to Tasks", url: "/tasks" });
        break;
      case 2:
      case 3:
        if (!data?.takenDateTime) {
          setTitle("Missed medication alert");
        } else setTitle("Taken medication alert");
        setPatientName(`Patient Name: ${patientFullName}`);
        setMedication(`Medication: ${data?.name} ${data?.strength}`);
        if (data?.scheduledDateTime) {
          setScheduledTime(
            `Scheduled Time: ${dayjs(data?.scheduledDateTime).format(
              "h:mm A"
            )} ${convertCreatedAt(data?.scheduledDateTime, true)}`
          );
        } else setScheduledTime("Scheduled Time: N/A");
        if (data?.takenDateTime)
          setTakenTime(
            `Taken Time: ${dayjs(data?.takenDateTime).format(
              "h:mm A"
            )} ${convertCreatedAt(data?.takenDateTime, true)}`
          );
        isMobileAppConnected
          ? setLink({ text: "Go to Patient", url: `/patients/${patientId}` })
          : setLink({
            text: "Go to Invitation",
            url: `/invite/manage-invitations/manage/${patientId}`
          });
        break;

      case 4:
        setTitle(`Scheduled Maintenance: ${data?.title}`);
        setBody(data?.message);
        break;
      case 5:
        setTitle(data?.title);
        setBody(data?.message);
        break;
      case 6:
        setTitle("Your report is ready to be viewed");
        setBody(
          "We've finished processing your report. You can access the report in the Reports tab"
        );

        setLink({ text: "Go to Reports", url: "/reports" });
        break;
      case 7:
        setTitle(`You have ${data?.count} tasks due`);
        setBody(
          `Your ${dayjs(data?.dueAt).format(
            "MMM D, h:mm A"
          )} tasks are due. Go to the tasks page to take action.`
        );
        setLink({ text: "Go to Tasks", url: "/tasks" });
        break;
      case 8:
        setTitle("New Login Alert");
        setBody(
          `A new login has been detected for your account in ${data?.location} using ${data?.browser}. If this was you, you can ignore this message. If this was not you, please contact your administrator immediately.`
        );
        break;
      default:
        setTitle("Notification");
    }
  }, [sendAt, item]);

  function convertCreatedAt(timestampStr, type) {
    let result;
    const today = dayjs(Date.now()).format("YYYY-MM-DD");
    const yesterday = dayjs(Date.now()).subtract(1, "day").format("YYYY-MM-DD");

    const timestamp = dayjs.utc(timestampStr);
    const timeDifferenceHours = dayjs(Date.now()).diff(timestamp, "hours");

    if (type && timestamp.format("YYYY-MM-DD") === today) {
      result = "Today";
    } else if (type && timestamp.format("YYYY-MM-DD") === yesterday) {
      result = "Yesterday";
    } else if (timeDifferenceHours < 24) {
      if (timeDifferenceHours < 1) {
        result = "Less than an hour ago";
      } else if (timeDifferenceHours < 2) {
        result = "1 hour ago";
      } else {
        result = `${timeDifferenceHours} hours ago`;
      }
    } else if (timeDifferenceHours < 48) {
      result = "Yesterday";
    } else {
      result = timestamp.format("MMMM D");
    }

    return result;
  }
  function removeNotification(id, isRead) {
    if (!isSubmitting) {
      setIsSubmitting(true);
      notificationService
        .deleteNotification(id)
        .then(() => {
          if (mounted.current) {
            if (!isRead)
              setNewNotifications((newNotifications) => newNotifications - 1);
            setNotifications((notifications) =>
              notifications.filter((x) => x.id !== id)
            );
            setIsSubmitting(false);
          }
        })
        .catch((error) => {
          if (mounted.current) {
            setIsSubmitting(false);

            alertMethods.error(
              "<div class='font-weight-bold'>" +
              "An Error Occurred" +
              "</div>" +
              "Something went wrong. Please try again."
            );
          }
        });
    }
  }
  function markAsRead(id) {
    const notificationsMapCallback = (x) => ({
      ...x,
      isRead: x.id === id ? true : x.isRead
    });
    if (!markingAsReadUnread) {
      setMarkingAsReadUnread(true);
      notificationService
        .markAsRead(id, patientId)
        .then((res) => {
          if (mounted.current) {
            setNewNotifications((newNotifications) => newNotifications - 1);
            setNotifications((notifications) =>
              notifications.map(notificationsMapCallback)
            );
            setTimeout(() => {
              setMarkingAsReadUnread(false);
            }, 300);
          }
        })
        .catch((error) => {
          if (mounted?.current) {
            setIsSubmitting(false);
            setMarkingAsReadUnread(false);

            alertMethods.error(
              "<div class='font-weight-bold'>" +
              "An Error Occurred" +
              "</div>" +
              "Something went wrong. Please try again."
            );
          }
        });
    }
  }

  function markAsUnread(id) {
    const notificationsMapCallback = (x) => ({
      ...x,
      isRead: x.id === id ? false : x.isRead
    });
    if (!markingAsReadUnread) {
      setMarkingAsReadUnread(true);
      notificationService
        .markAsUnread(id, patientId)
        .then((res) => {
          if (mounted.current) {
            setNewNotifications((newNotifications) => newNotifications + 1);
            setNotifications((notifications) =>
              notifications.map(notificationsMapCallback)
            );
            setTimeout(() => {
              setMarkingAsReadUnread(false);
            }, 300);
          }
        })
        .catch((error) => {
          if (mounted.current) {
            setIsSubmitting(false);
            setMarkingAsReadUnread(false);

            alertMethods.error(
              "<div class='font-weight-bold'>" +
              "An Error Occurred" +
              "</div>" +
              "Something went wrong. Please try again."
            );
          }
        });
    }
  }

  const handleClick = () => {
    if (!isRead) markAsRead(id);
    toggle();
    navigate(link.url, { state: { backText: "to Previous Page" } });
  };

  return (
    <div
      className={classnames(
        {
          "notification-hover-section": !user?.isSmartSession && link.url
        },
        {
          "notification-hover-section-smart": user?.isSmartSession || !link.url
        }
      )}
      role="button"
      aria-disabled={user?.isSmartSession || !link.url}
      onClick={(user?.isSmartSession || !link.url) ? undefined: handleClick}
      onKeyDown={(e) => {
        if (e.key === "Enter" && (!user?.isSmartSession && link.url)) {
          handleClick();
        }
      }}
    >
      <div
        className={classnames(
          { "primary-underline": index < length - 1 },
          "px-4 pt-3 pb-2 w-100"
        )}
      >
        <div className="d-flex">
          {!isRead ? (
            <>
              <button
                data-testid="mark-as-read"
                className="btn btn-link align-self-start pt-0 mr-2 image-only"
                onClick={(e) => {
                  e.stopPropagation();
                  markAsRead(id);
                }}
                onKeyDown={(e) => {
                  e.stopPropagation();
                  if (e.key === "Enter") {
                    markAsRead(id);
                  }
                }}
                id={`mark-as-read-${id}`}
                aria-label="mark as read"
                type="button"
              >
                <div className="d-inline-block mx-2 mark-as-read"></div>
              </button>{" "}
              <MyBasicTooltip
                placement="bottom-left"
                target={`mark-as-read-${id}`}
                text="Mark as read"
              />
            </>
          ) : (
            <>
              <button
                data-testid="mark-as-unread"
                className="btn btn-link align-self-start pt-0 mr-2 image-only"
                onClick={(e) => {
                  e.stopPropagation();
                  markAsUnread(id);
                }}
                onKeyDown={(e) => {
                  e.stopPropagation();
                  if (e.key === "Enter") {
                    markAsUnread(id);
                  }
                }}
                id={`mark-as-unread-${id}`}
                aria-label="mark as unread"
                type="button"
              >
                <div className="d-inline-block mx-2 mark-as-unread"></div>
              </button>
              <MyBasicTooltip
                placement="bottom-left"
                target={`mark-as-unread-${id}`}
                text="Mark as unread"
              />
            </>
          )}

          <NotificationBody
            title={title}
            convertCreatedAt={convertCreatedAt}
            sendAt={sendAt}
            taskType={taskType}
            associatedPatient={associatedPatient}
            patientName={patientName}
            medication={medication}
            scheduledTime={scheduledTime}
            takenTime={takenTime}
            body={body}
          />

          <button
            type="button"
            className="close ml-auto align-self-start"
            onClick={(e) => {
              e.stopPropagation();
              removeNotification(id, isRead);
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
              if (e.key === "Enter") {
                removeNotification(id, isRead);
              }
            }}
          >
            {isSubmitting ? (
              <span className="mb-2 mx-2 spinner-border spinner-border-sm text-secondary"></span>
            ) : (
              <GlyphX className="mb-2 mx-2 glyph-gray" aria-label="close" />
            )}
          </button>
        </div>
        {user && !user.isSmartSession ? (
          <div className="d-flex justify-content-end">
            <Link
              to={link.url}
              className="btn btn-link"
              state={{ backText: "to Previous Page" }}
              onClick={(e) => {
                e.stopPropagation();
                if (!isRead) markAsRead(id);
                toggle();
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
                if (e.key === "Enter") {
                  handleClick();
                }
              }}
            >
              {link.text}
            </Link>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

Notification.propTypes = {
  item: PropTypes.object,
  setNotifications: PropTypes.func,
  index: PropTypes.number,
  length: PropTypes.number,
  setNewNotifications: PropTypes.func,
  toggle: PropTypes.func,
  patientId: PropTypes.string,
  mounted: PropTypes.shape({ current: PropTypes.bool })
};

export { Notifications };
