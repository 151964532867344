import React, { useState } from "react";
import {
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Button
} from "reactstrap";
import { CloseButton } from "./CloseButton";
import { Support } from "../support/Support";
import { ReactComponent as Lock } from "../images/lock-in-a-circle.svg";
import PropTypes from "prop-types";

const LockScreen = ({ feature, type }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const title = "Contact our Sales Team";
  const text = `Please fill out the form below with the nature of your request, and one of our representatives will be in touch shortly. Do not include Protected Health Information ("PHI") or sensitive patient data in your request.`;
  const tag = "permissions";
  return (
    <div className="d-flex justify-content-center">
      <Card className="bg-white mt-7 w-400px">
        <CardBody className="text-center">
          <Lock className="mb-4 glyph-primary-fill" aria-label="lock" />
          <h3 className="">
            {type === "user-permissions"
              ? "You don't have permission to access this feature"
              : `Your organization doesn't have access to the ${feature} feature`}
          </h3>
          <p className="">
            {type === "user-permissions"
              ? "Please contact your administrator to change the permissions on your account"
              : "Please contact EveryDose Sales if you wish to add this feature to your organization's package."}
          </p>
          {type !== "user-permissions" && (
            <Button
              className="btn btn-primary"
              id="contact-sales"
              onClick={() => {
                toggle();
                // const btn = document.getElementById("contact-sales");
                // btn.blur();
              }}
            >
              Contact Sales
            </Button>
          )}
        </CardBody>
      </Card>
      <Modal isOpen={isOpen} toggle={toggle} className="center-modal">
        <ModalHeader toggle={toggle} close={<CloseButton toggle={toggle} />}>
          Contact Sales
        </ModalHeader>
        <ModalBody>
          <div className="align-self-center pt-3">
            <div className="">
              <Support
                title={title}
                text={text}
                tag={tag}
                toggle={toggle}
                feature={feature}
              />
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

LockScreen.propTypes = {
  feature: PropTypes.string,
  type: PropTypes.string
};

export { LockScreen };
