import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Support } from "./Support";
import { CloseButton } from "../_components";
import PropTypes from "prop-types";

const PrivateSupport = (props) => {
  const { isOpen, toggle } = props;
  const title = "Make a new Support Request";
  const tag = "contact_support";
  const text =
    "Please fill out the form below with the nature of your request, and one of our representatives will be in touch shortly. Do not include Protected Health Information ('PHI') or sensitive patient data in your request.";

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} className="center-modal">
        <ModalHeader toggle={toggle} close={<CloseButton toggle={toggle} />}>
          Support Request
        </ModalHeader>
        <ModalBody>
          <div className=" align-self-center pt-3">
            <div className="">
              <Support title={title} text={text} tag={tag} toggle={toggle} />
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

PrivateSupport.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

export { PrivateSupport };
