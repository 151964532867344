import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import classnames from "classnames";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const MyDropdown = (props) => {
  const { title, header, items, glyph, name, glyph2 } = props.details;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { padding, container, direction, auto } = props;
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Dropdown
      className="d-inline-block"
      isOpen={dropdownOpen}
      toggle={toggle}
      direction={auto ? "down" : "left"}
    >
      <DropdownToggle
        tag="a"
        aria-label={name}
        tabIndex="0"
        className={classnames(
          { "mx-3": padding },
          { "px-1": !padding },
          { "mr-6": name === "Account" },
          "nav-link d-flex align-items-center nav-item"
        )}
      >
        {name === "Account" ? (
          <div className="d-flex align-items-center">
            {glyph}
            {title}
            {glyph2}
          </div>
        ) : (
          <div>
            {header && glyph} {title}
          </div>
        )}
        {/* The left side dropdowns in the nav bar need a glyph after and have no header */}
        {!header && glyph}
      </DropdownToggle>
      <DropdownMenu
        className={name}
        container={container}
        right={direction === "right"}
      >
        {header && (
          <>
            <DropdownItem header>{header}</DropdownItem>
            <DropdownItem divider />
          </>
        )}
        {items.map((item) => {
          if (!item.mobileOnly) {
            if (item.details) {
              return (
                <DropdownItem
                  key={item.id || item.name}
                  disabled={item.disabled}
                >
                  <div className="d-flex">
                    <span>{item.glyph}</span>
                    <div>
                      <span className="text-dark">{item.name}</span>
                      <div className="small text-dark">{item.details}</div>
                    </div>
                  </div>
                </DropdownItem>
              );
            } else if (item.onClick) {
              return (
                <div key={item.name}>
                  <DropdownItem onClick={item.onClick} className="d-flex">
                    <span className={classnames({ "col-1": item.glyph })}>
                      {item.glyph}
                    </span>
                    <span
                      className={classnames(
                        { "col-11": item.glyph },
                        item.class
                      )}
                    >
                      {item.name}
                    </span>
                  </DropdownItem>
                </div>
              );
            } else if (item.disabled) {
              return (
                <DropdownItem key={item.name} disabled={item.disabled}>
                  {item.name}
                </DropdownItem>
              );
            } else {
              return (
                <Link
                  className=""
                  to={item.link}
                  target={item.openTabType || ""}
                  key={item.name}
                >
                  <DropdownItem disabled={item.disabled} className="d-flex">
                    <span className={classnames({ "col-1": item.glyph })}>
                      {item.glyph}
                    </span>

                    <span className={classnames({ "col-11": item.glyph })}>
                      {item.name}
                    </span>
                  </DropdownItem>
                </Link>
              );
            }
          } else return <div key={item.name}></div>;
        })}
      </DropdownMenu>
    </Dropdown>
  );
};

MyDropdown.propTypes = {
  details: PropTypes.shape({
    title: PropTypes.string,
    header: PropTypes.object,
    items: PropTypes.array,
    glyph: PropTypes.object,
    name: PropTypes.string,
    glyph2: PropTypes.object
  }),
  padding: PropTypes.string,
  container: PropTypes.string,
  direction: PropTypes.string,
  auto: PropTypes.bool
};

export { MyDropdown };
