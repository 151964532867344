import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useUserContext } from "../context/userContext";
import { CloseButton } from "./CloseButton";

const PauseModal = ({
  pauseModal,
  togglePause,
  name,
  toggleAdd,
  setMeds,
  rowId,
  selectedMed,
  submitAddMed,
  isSideBarRoute
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { userState } = useUserContext();
  const { user } = userState;

  const onPause = () => {
    setIsSubmitting(true);
    // Need to implement pause api call here when that api exists
    let status;
    if (!selectedMed.status) {
      status = 2;
    } else {
      status = 0;
    }

    if (setMeds)
      setMeds((state) =>
        state
          .filter((item, idx) => idx !== rowId)
          .concat({ ...selectedMed, status: status })
      );
    if (submitAddMed) {
      const { medicationSchedule, medicationStatusString, ...newObj } =
        selectedMed;

      if (status === 0) {
        submitAddMed(
          {
            ...selectedMed,
            status: status,
            medicationApprovalStatus: 1,
            medicationId: selectedMed.id,
            medicationSchedule: {
              ...selectedMed.medicationSchedule,
              totalNumberOfCycles:
                selectedMed.medicationSchedule.totalNumberOfCycles || -1,
              daysOn: selectedMed.medicationSchedule.daysOn || 0,
              daysOff: selectedMed.medicationSchedule.daysOff || 0
            }
          },
          setIsSubmitting,
          togglePause
        );
      } else {
        submitAddMed(
          {
            ...newObj,
            status: status,
            medicationApprovalStatus: 1,
            medicationId: selectedMed.id
          },
          setIsSubmitting,
          togglePause
        );
      }
    } else {
      setIsSubmitting(false);
      togglePause();
      toggleAdd();
    }
  };

  return (
    <Modal
      isOpen={pauseModal}
      toggle={togglePause}
      className={classNames(
        { "sidebar-modal": isSideBarRoute && user && !user.isSmartSession },
        "center-modal"
      )}
      autoFocus={false}
    >
      <ModalHeader
        toggle={togglePause}
        close={<CloseButton toggle={togglePause} />}
      >
        Are you sure you want to{" "}
        {selectedMed?.status === 2 ? "resume" : "pause"} {name}?
      </ModalHeader>
      <ModalBody>
        <div className="form-group d-flex flex-md-row flex-column align-items-center">
          <button
            type="button"
            className="btn btn-primary w-25 mr-md-4 mb-md-0 mb-4"
            onClick={onPause}
            autoFocus
            disabled={isSubmitting}
          >
            {isSubmitting && (
              <span className="spinner-border spinner-border-sm mr-1"></span>
            )}
            Yes
          </button>
          <button
            type="button"
            className="btn btn-outline-primary w-25"
            onClick={togglePause}
          >
            No
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

PauseModal.propTypes = {
  pauseModal: PropTypes.bool,
  togglePause: PropTypes.func,
  name: PropTypes.string,
  toggleAdd: PropTypes.func,
  setMeds: PropTypes.func,
  rowId: PropTypes.number,
  selectedMed: PropTypes.object,
  submitAddMed: PropTypes.func,
  isSideBarRoute: PropTypes.bool
};

export { PauseModal };
