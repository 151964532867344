import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { CloseButton } from "../_components";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { ReactComponent as PatientIcon } from "../images/nav-person.svg";
import classnames from "classnames";
import { useSmartContext } from "../context/smartContext";
import PropTypes from "prop-types";

const RemovePatientModal = ({
  onRemovePatient,
  removePatientModal,
  toggleRemovePatient,
  selectedPatient,
  deleteVersion
}) => {
  const { patientId } = useSmartContext();
  const removePatientValidationSchema = Yup.object().shape({
    name: Yup.string()
      .required()
      .test("match", "Patient name must match", function (val) {
        return selectedPatient.name?.toLowerCase() === val.toLowerCase();
      })
  });
  return (
    <Modal
      isOpen={removePatientModal}
      toggle={toggleRemovePatient}
      className="center-modal"
    >
      <ModalHeader
        toggle={toggleRemovePatient}
        close={<CloseButton toggle={toggleRemovePatient} />}
      >
        {deleteVersion ? "Delete Patient" : "Disconnect Patient"}
      </ModalHeader>
      <ModalBody>
        <div>
          <h4>
            <PatientIcon aria-label="patient icon" /> &nbsp;&nbsp;
            {deleteVersion
              ? "Delete Patient from Portal?"
              : "Disconnect from Mobile App?"}
          </h4>
          <p>
            {deleteVersion
              ? "Deleting patient will remove them from your organization. This action cannot be undone. Please retype the patient name below"
              : "Disconnecting patient will disconnect their account from your organization. Please retype the patient name below"}
          </p>
          <p>{selectedPatient.name}</p>
          <Formik
            initialValues={{ name: "" }}
            validationSchema={removePatientValidationSchema}
            onSubmit={onRemovePatient}
          >
            {({ errors, touched, isSubmitting, isValid, dirty }) => (
              <Form data-testid="remove-patient-form">
                <div>
                  <div className="form-group">
                    <label htmlFor="name">Retype Patient Name</label>
                    <Field
                      id="name"
                      name="name"
                      placeholder="Name of patient"
                      type="text"
                      className={classnames(
                        { "is-invalid": errors.name && touched.name },
                        "form-control"
                      )}
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  {selectedPatient?.patientId === patientId &&
                    deleteVersion && (
                      <p className="text-secondary small">
                        *This is the active patient. If you remove this patient,
                        you will need to relaunch EveryDose to continue using
                        the app.
                      </p>
                    )}
                  <div className="form-group d-flex flex-lg-row flex-column align-items-center">
                    <button
                      type="submit"
                      disabled={isSubmitting || !isValid || !dirty}
                      className="btn btn-danger w-25 mr-lg-5 mb-lg-0 mb-4 min-width-9rem"
                    >
                      {isSubmitting && (
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                      )}
                      {deleteVersion ? "Remove" : "Disconnect"}
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-primary w-25"
                      onClick={toggleRemovePatient}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </ModalBody>
    </Modal>
  );
};

RemovePatientModal.propTypes = {
  onRemovePatient: PropTypes.func,
  removePatientModal: PropTypes.bool,
  toggleRemovePatient: PropTypes.func,
  selectedPatient: PropTypes.object,
  deleteVersion: PropTypes.bool
};

export { RemovePatientModal };
