import React, {
  createContext,
  useReducer,
  useMemo,
  useCallback,
  useContext
} from "react";
import PropTypes from "prop-types";

const TableStateContext = createContext();

const useTableStateContext = () => useContext(TableStateContext);

// Define the initial state for each table
const initialState = {
  patientListTableState: {},
  tasksTableState: {},
  reportsTableState: {},
  patientListManagementTableState: {},
  groupPatientsTableState: {},
  groupUsersTableState: {},
  patientsTableState: {},
  usersTableState: {},
  userInvitationsTableState: {},
  outreachTableState: {},
  riskProfileTableState: {},
  connectionRequestsTableState: {},
  archivedConnectionsTableState: {},
  manageInvitationsTableState: {},
  patientTaskTableState: {},
  adminPatientListManagementTableState: {}

  // ... add more table states as needed
};

// Define the reducer function to handle state updates
const tableStateReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_PATIENT_LIST_TABLE_STATE":
      return {
        ...state,
        patientListTableState: {
          ...state.patientListTableState,
          ...action.payload
        }
      };
    case "UPDATE_TASKS_TABLE_STATE":
      return {
        ...state,
        tasksTableState: { ...state.tasksTableState, ...action.payload }
      };
    case "UPDATE_REPORTS_TABLE_STATE":
      return {
        ...state,
        reportsTableState: { ...state.reportsTableState, ...action.payload }
      };
    case "UPDATE_PATIENT_LIST_MANAGEMENT_TABLE_STATE":
      return {
        ...state,
        patientListManagementTableState: {
          ...state.patientListManagementTableState,
          ...action.payload
        }
      };
    case "UPDATE_GROUP_PATIENTS_TABLE_STATE":
      return {
        ...state,
        groupPatientsTableState: {
          ...state.groupPatientsTableState,
          ...action.payload
        }
      };
    // case clear group patients table state
    case "CLEAR_GROUP_PATIENTS_TABLE_STATE":
      return {
        ...state,
        groupPatientsTableState: {}
      };

    case "UPDATE_GROUP_USERS_TABLE_STATE":
      return {
        ...state,
        groupUsersTableState: {
          ...state.groupUsersTableState,
          ...action.payload
        }
      };
    // case clear group users table state
    case "CLEAR_GROUP_USERS_TABLE_STATE":
      return {
        ...state,
        groupUsersTableState: {}
      };

    case "UPDATE_PATIENTS_TABLE_STATE":
      return {
        ...state,

        patientsTableState: {
          ...state.patientsTableState,
          ...action.payload
        }
      };

    case "UPDATE_USERS_TABLE_STATE":
      return {
        ...state,
        usersTableState: {
          ...state.usersTableState,
          ...action.payload
        }
      };

    case "UPDATE_USER_INVITATIONS_TABLE_STATE":
      return {
        ...state,
        userInvitationsTableState: {
          ...state.userInvitationsTableState,
          ...action.payload
        }
      };

    case "UPDATE_OUTREACH_TABLE_STATE":
      return {
        ...state,

        outreachTableState: {
          ...state.outreachTableState,
          ...action.payload
        }
      };
    // clear outreach table state
    case "CLEAR_OUTREACH_TABLE_STATE":
      return {
        ...state,
        outreachTableState: {}
      };

    case "UPDATE_RISK_PROFILE_TABLE_STATE":
      return {
        ...state,
        riskProfileTableState: {
          ...state.riskProfileTableState,
          ...action.payload
        }
      };

    case "UPDATE_CONNECTION_REQUESTS_TABLE_STATE":
      return {
        ...state,
        connectionRequestsTableState: {
          ...state.connectionRequestsTableState,
          ...action.payload
        }
      };

    case "UPDATE_ARCHIVED_CONNECTIONS_TABLE_STATE":
      return {
        ...state,
        archivedConnectionsTableState: {
          ...state.archivedConnectionsTableState,
          ...action.payload
        }
      };

    case "UPDATE_MANAGE_INVITATIONS_TABLE_STATE":
      return {
        ...state,
        manageInvitationsTableState: {
          ...state.manageInvitationsTableState,
          ...action.payload
        }
      };

    case "UPDATE_PATIENT_TASK_TABLE_STATE":
      return {
        ...state,
        patientTaskTableState: {
          ...state.patientTaskTableState,
          ...action.payload
        }
      };
    // clear patient task table state
    case "CLEAR_PATIENT_TASK_TABLE_STATE":
      return {
        ...state,
        patientTaskTableState: {}
      };

    case "UPDATE_ADMIN_PATIENT_LIST_MANAGEMENT_TABLE_STATE":
      return {
        ...state,
        adminPatientListManagementTableState: {
          ...state.adminPatientListManagementTableState,
          ...action.payload
        }
      };

    // ... add more cases for each table state
    default:
      return state;
  }
};

const TableStateProvider = ({ children }) => {
  const [tableState, dispatch] = useReducer(tableStateReducer, initialState);

  const updatePatientListTableState = useCallback((state) => {
    dispatch({ type: "UPDATE_PATIENT_LIST_TABLE_STATE", payload: state });
  }, []);

  const updateTasksTableState = useCallback((state) => {
    dispatch({ type: "UPDATE_TASKS_TABLE_STATE", payload: state });
  }, []);

  const updateReportsTableState = useCallback((state) => {
    dispatch({ type: "UPDATE_REPORTS_TABLE_STATE", payload: state });
  }, []);

  const updatePatientListManagementTableState = useCallback((state) => {
    dispatch({
      type: "UPDATE_PATIENT_LIST_MANAGEMENT_TABLE_STATE",
      payload: state
    });
  }, []);

  const updateGroupPatientsTableState = useCallback((state) => {
    dispatch({
      type: "UPDATE_GROUP_PATIENTS_TABLE_STATE",
      payload: state
    });
  }, []);

  const updateGroupUsersTableState = useCallback((state) => {
    dispatch({
      type: "UPDATE_GROUP_USERS_TABLE_STATE",
      payload: state
    });
  }, []);

  const updatePatientsTableState = useCallback((state) => {
    dispatch({
      type: "UPDATE_PATIENTS_TABLE_STATE",
      payload: state
    });
  }, []);

  const updateUsersTableState = useCallback((state) => {
    dispatch({
      type: "UPDATE_USERS_TABLE_STATE",
      payload: state
    });
  }, []);

  const updateUserInvitationsTableState = useCallback((state) => {
    dispatch({
      type: "UPDATE_USER_INVITATIONS_TABLE_STATE",
      payload: state
    });
  }, []);

  const updateOutreachTableState = useCallback((state) => {
    dispatch({
      type: "UPDATE_OUTREACH_TABLE_STATE",
      payload: state
    });
  }, []);

  const updateRiskProfileTableState = useCallback((state) => {
    dispatch({
      type: "UPDATE_RISK_PROFILE_TABLE_STATE",
      payload: state
    });
  }, []);

  const updateConnectionRequestsTableState = useCallback((state) => {
    dispatch({
      type: "UPDATE_CONNECTION_REQUESTS_TABLE_STATE",
      payload: state
    });
  }, []);

  const updateArchivedConnectionsTableState = useCallback((state) => {
    dispatch({
      type: "UPDATE_ARCHIVED_CONNECTIONS_TABLE_STATE",
      payload: state
    });
  }, []);

  const updateManageInvitationsTableState = useCallback((state) => {
    dispatch({
      type: "UPDATE_MANAGE_INVITATIONS_TABLE_STATE",
      payload: state
    });
  }, []);

  const clearGroupPatientsTableState = useCallback(() => {
    dispatch({
      type: "CLEAR_GROUP_PATIENTS_TABLE_STATE"
    });
  }, []);

  const clearGroupUsersTableState = useCallback(() => {
    dispatch({
      type: "CLEAR_GROUP_USERS_TABLE_STATE"
    });
  }, []);

  const clearOutreachTableState = useCallback(() => {
    dispatch({
      type: "CLEAR_OUTREACH_TABLE_STATE"
    });
  }, []);

  const updatePatientTaskTableState = useCallback((state) => {
    dispatch({
      type: "UPDATE_PATIENT_TASK_TABLE_STATE",
      payload: state
    });
  }, []);

  const clearPatientTaskTableState = useCallback(() => {
    dispatch({
      type: "CLEAR_PATIENT_TASK_TABLE_STATE"
    });
  }, []);

  const updateAdminPatientListManagementTableState = useCallback((state) => {
    dispatch({
      type: "UPDATE_ADMIN_PATIENT_LIST_MANAGEMENT_TABLE_STATE",
      payload: state
    });
  }, []);

  // ... add more update functions for each table state

  // ... define more update functions for each table state

  const contextValue = useMemo(
    () => ({
      tableState,
      updatePatientListTableState,
      updateTasksTableState,
      updateReportsTableState,
      updatePatientListManagementTableState,
      updateGroupPatientsTableState,
      updateGroupUsersTableState,
      updatePatientsTableState,
      updateUsersTableState,
      updateUserInvitationsTableState,
      updateOutreachTableState,
      updateRiskProfileTableState,
      updateConnectionRequestsTableState,
      updateArchivedConnectionsTableState,
      updateManageInvitationsTableState,
      clearGroupPatientsTableState,
      clearGroupUsersTableState,
      clearOutreachTableState,
      updatePatientTaskTableState,
      clearPatientTaskTableState,
      updateAdminPatientListManagementTableState

      // ... add more update functions for each table state
    }),
    [
      tableState,
      updatePatientListTableState,
      updateTasksTableState,
      updateReportsTableState,
      updatePatientListManagementTableState,
      updateGroupPatientsTableState,
      updateGroupUsersTableState,
      updatePatientsTableState,
      updateUsersTableState,
      updateUserInvitationsTableState,
      updateOutreachTableState,
      updateRiskProfileTableState,
      updateConnectionRequestsTableState,
      updateArchivedConnectionsTableState,
      updateManageInvitationsTableState,
      clearGroupPatientsTableState,
      clearGroupUsersTableState,
      clearOutreachTableState,
      updatePatientTaskTableState,
      clearPatientTaskTableState,
      updateAdminPatientListManagementTableState

      // ... add more update functions for each table state
    ]
  );

  return (
    <TableStateContext.Provider value={contextValue}>
      {children}
    </TableStateContext.Provider>
  );
};

export { useTableStateContext, TableStateProvider, TableStateContext };

TableStateProvider.propTypes = {
  children: PropTypes.node
};
