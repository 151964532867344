import React from "react";

export const FullCalendarWrapper = React.lazy(async () => {
  const [FullCalendar, dayGridPlugin, interactionPlugin, timeGridPlugin] =
    await Promise.all([
      import("@fullcalendar/react").then((module) => module.default),
      import("@fullcalendar/daygrid").then((module) => module.default),
      import("@fullcalendar/interaction").then((module) => module.default),
      import("@fullcalendar/timegrid").then((module) => module.default)
    ]);

  return {
    default: (props) => (
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
        initialView="dayGridMonth"
        ref={props.calendarRef}
        {...props.calendarProps}
      />
    )
  };
});
