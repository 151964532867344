import React, { useEffect, useRef } from "react";
import { ReactComponent as GlyphX } from "../images/glyph_x.svg";
import PropTypes from "prop-types";

const CloseButton = ({ toggle, color, popoverVersion, isSidebarOpen }) => {
  const closeBtnRef = useRef(null);
  useEffect(() => {
    if (closeBtnRef.current && (popoverVersion || isSidebarOpen)) {
      closeBtnRef.current.focus();
    }
  }, [isSidebarOpen, popoverVersion]);

  return (
    <button ref={closeBtnRef} className="close ml-auto" onClick={toggle}>
      <GlyphX className={`mb-1 ${color || ""}`} aria-label="close" />
    </button>
  );
};

CloseButton.propTypes = {
  toggle: PropTypes.func.isRequired,
  color: PropTypes.string
};

export { CloseButton };
