import classnames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import { MyTooltip } from "./MyTooltip";
import { ReactComponent as Alert } from "../images/glyph-exclamation.svg";

const HeaderActions = ({
  headerActions,
  allRowsSelected,
  handleToggleAllRows,
  data,
  selectedFlatRows,
  selectedRows,
  selectConditionColumn,
}) => {
  const [selectableRows, setSelectableRows] = React.useState([]);
  React.useEffect(() => {
    if (selectConditionColumn) {
      let selectableRows = data.filter(
        (item) =>
          item.status === 1 ||
          item.status === 3 ||
          item.invitationStatus === 1 ||
          item.invitationStatus === 3 ||
          item.invitationStatus === 4
      );
      setSelectableRows(selectableRows);
    } else setSelectableRows(data);
  }, [data, selectConditionColumn]);

  return (
    <>
      {headerActions && (
        <div>
          <label
            className={classnames(
              {
                "text-graydark1": !selectableRows.length,
              },
              "g-table-checkbox mx-lg-6 mx-4"
            )}
          >
            Select All
            <input
              type="checkbox"
              checked={allRowsSelected}
              onChange={handleToggleAllRows}
              disabled={!selectableRows.length}
            />
            <span className="checkmark"></span>
          </label>
          {headerActions.map((headerAction, i) => (
            <span key={headerAction?.text}>
              <button
                key={headerAction?.text}
                disabled={
                  selectedFlatRows.length === 0 ||
                  //selectedRows.length === 0 ||
                  (selectedRows.filter((item) => item.status !== 4).length ===
                    0 &&
                    headerAction?.text === "") ||
                  (selectedRows.filter((item) => item.invitationStatus === 4)
                    .length &&
                    headerAction?.text === "Withdraw")
                }
                className={classnames(
                  {
                    "text-danger":
                      headerAction.text === "Withdraw" ||
                      headerAction.text === "Reject",
                  },
                  "btn btn-link mx-md-4 mx-3"
                )}
                onClick={() => {
                  headerAction.action(selectedRows);
                }}
              >
                {headerAction.text}
              </button>
              {selectedRows.filter((item) => item.invitationStatus === 4)
                .length && headerAction?.text === "Withdraw" ? (
                <>
                  <button
                    className="btn btn-link p-0 ml-neg-0-5rem"
                    id={headerAction?.text}
                  >
                    <Alert
                      aria-label="alert"
                      className="glyph-danger h16-w16-style"
                    />
                  </button>
                  <MyTooltip
                    target={headerAction?.text}
                    placement="bottom"
                    text="You have selected a withdrawn invitation. Please deselect it to withdraw."
                  />
                </>
              ) : (
                ""
              )}
            </span>
          ))}
        </div>
      )}
    </>
  );
};

HeaderActions.propTypes = {
  headerActions: PropTypes.array,
  allRowsSelected: PropTypes.bool,
  handleToggleAllRows: PropTypes.func,
  data: PropTypes.array,
  selectedFlatRows: PropTypes.array,
  selectedRows: PropTypes.array,
  selectConditionColumn: PropTypes.string,
};

export { HeaderActions };
