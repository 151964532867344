import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import classnames from "classnames";
import PropTypes from "prop-types";

export const DailyPillBox = ({ loadingMedications, medications }) => {
  const [morningMeds, setMorningMeds] = useState(0);
  const [noonMeds, setNoonMeds] = React.useState(0);
  const [eveningMeds, setEveningMeds] = React.useState(0);
  const [nightMeds, setNightMeds] = React.useState(0);

  useEffect(() => {
    let mMeds = 0;
    let aMeds = 0;
    let eMeds = 0;
    let nMeds = 0;

    // Create an object to store unique times for each medication
    const uniqueTimesByMedication = {};

    medications
      ?.filter(
        (med) =>
          med.medicationSchedule.scheduleType === 2 &&
          new Date(med.medicationSchedule.startDate).setHours(0, 0, 0, 0) <=
            new Date().setHours(0, 0, 0, 0) &&
          (!med.medicationSchedule.endDate ||
            new Date(med.medicationSchedule.endDate).setHours(0, 0, 0, 0) >=
              new Date().setHours(0, 0, 0, 0))
      )
      .forEach((element) => {
        if (
          // element.medicationSchedule?.sigCode !== "PRN" &&
          element.status === 0
        ) {
          // Initialize the unique times set for this medication
          uniqueTimesByMedication[element.medicationId] = new Set();

          element.medicationSchedule?.medicationScheduleTimes.forEach(
            (item) => {
              const hour = item.time?.slice(0, 2);

              if (!uniqueTimesByMedication[element.medicationId].has(hour)) {
                // Add the hour to the unique times set for this medication
                uniqueTimesByMedication[element.medicationId].add(hour);

                // Increment the corresponding medication count based on the hour range
                if (hour === "24" || (hour >= "00" && hour < "12")) {
                  mMeds++;
                } else if (hour >= "12" && hour < "17") {
                  aMeds++;
                } else if (hour >= "17" && hour < "21") {
                  eMeds++;
                } else if (hour >= "21" && hour < "24") {
                  nMeds++;
                }
              }
            }
          );
        }
      });

    setMorningMeds(mMeds);
    setNoonMeds(aMeds);
    setEveningMeds(eMeds);
    setNightMeds(nMeds);
  }, [medications]);

  return (
    <div className="card-body">
      <div className="card-title">Daily Pill Box</div>
      {loadingMedications ? (
        <div className="ph-item border-0 mt-5 ml-5">
          <div className="ph-col-12">
            <div className="ph-row">
              <div className="ph-col-real3 big"></div>
              <div className="ph-col-real3 empty"></div>
              <div className="ph-col-real3 big"></div>
            </div>
            <div className="ph-row">
              <div className="ph-col-5 big"></div>
              <div className="ph-col-15 empty"></div>
              <div className="ph-col-5 big"></div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <Row className={classnames("d-flex justify-content-center")}>
            <Col xs="6" className={classnames(" text-center")}>
              <div className="card-number">{morningMeds}</div>

              <p className={classnames(" card-info")}>Morning Meds</p>
            </Col>
            <Col xs="6" className={classnames(" text-center")}>
              <div className="card-number">{noonMeds}</div>
              <p className={classnames(" card-info")}>Afternoon Meds</p>
            </Col>
          </Row>
          <Row className={classnames("d-flex justify-content-center mb-6")}>
            <Col xs="6" className={classnames("text-center")}>
              <div className="card-number ">{eveningMeds}</div>
              <p className={classnames(" card-info")}>Evening Meds</p>
            </Col>
            <Col xs="6" className={classnames(" text-center")}>
              <div className="card-number">{nightMeds}</div>

              <p className={classnames(" card-info")}>Night Meds</p>
            </Col>
          </Row>
        </>
      )}
      <p className={classnames(" text-secondary mb-0 mt-6")}>
        Note: This card does not show PRN, weekly, monthly, specific days, or
        cycle meds
      </p>
    </div>
  );
};
DailyPillBox.propTypes = {
  loadingMedications: PropTypes.bool,
  medications: PropTypes.array
};
