import classNames from "classnames";
import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Popover,
  PopoverBody
} from "reactstrap";
import { CloseButton, PdfViewer } from "../_components";
import { UseMobileOrTabletMediaQuery } from "../_helpers";
import { patientInviteService } from "../_services";
import { useAlertContext } from "../context/alertContext";
import PropTypes from "prop-types";

export const PrintSignUpDoc = ({ text, id, language, version }) => {
  const [documentModal, setDocumentModal] = useState(false);
  const [signUpDocument, setSignUpDocument] = useState(null);
  const [prevLanguage, setPrevLanguage] = useState(null);
  const [docBlob, setDocBlob] = useState(null);
  const [popover, setPopover] = useState(false);
  const [loadingDoc, setLoadingDoc] = useState(false);
  const { alertMethods } = useAlertContext();

  const togglePopover = () => {
    setPopover(!popover);
  };

  const isMobileOrTablet = UseMobileOrTabletMediaQuery();
  const toggleDocumentModal = () => {
    setDocumentModal(!documentModal);
  };

  const openPdf = (doc) => {
    try {
      window.open(doc, "_blank");
    } catch (e) {
      alertMethods.error(
        "Popup blocked by your browser. If the issue persists, please refresh the page and try again"
      );
    }
  };

  function openDocument(doc, document) {
    if (window?.navigator?.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(document);
    } else if (isMobileOrTablet) {
      openPdf(doc);
    } else {
      toggleDocumentModal();
    }
  }

  function handleSuccess(document) {
    if (document) {
      const newBlob = new Blob([document], { type: "application/pdf" });
      setDocBlob(newBlob);

      let doc = URL.createObjectURL(newBlob);
      setSignUpDocument(doc);
      openDocument(doc, newBlob);
    }
  }

  function fetchDocument(lang) {
    if (signUpDocument && prevLanguage === lang) {
      openDocument(signUpDocument, docBlob);
    } else {
      setLoadingDoc(true);
      if (id) {
        patientInviteService
          .getPatientSignUpDocument(id, lang)
          .then((document) => {
            handleSuccess(document);
            setPrevLanguage(lang);
            setLoadingDoc(false);
          })
          .catch((e) => {
            setSignUpDocument(null);
            setDocBlob(null);
            setLoadingDoc(false);
            alertMethods.error("Error fetching document. Please try again.");
          });
      } else {
        patientInviteService
          .getSignUpDocument(lang)
          .then((document) => {
            handleSuccess(document);
            setLoadingDoc(false);
            setPrevLanguage(lang);
          })
          .catch((e) => {
            setSignUpDocument(null);
            setDocBlob(null);
            setLoadingDoc(false);
            alertMethods.error("Error fetching document. Please try again.");
          });
      }
    }
  }
  return (
    <>
      <button
        className="btn btn-link"
        type="button"
        onClick={() => {
          if (language) fetchDocument(language.value);
          else togglePopover();
        }}
        id={"print-doc"}
      >
        {text}
        {loadingDoc && (
          <span className="spinner-border spinner-border-sm ml-1"></span>
        )}
      </button>
      {!language && (
        <Popover
          target={"print-doc"}
          isOpen={popover}
          toggle={togglePopover}
          trigger="legacy"
          placement="bottom"
          className="language-popover"
        >
          <PopoverBody>
            <div>
              <button
                onClick={() => {
                  fetchDocument(0);
                  togglePopover();
                }}
                onKeyDown={(e) => {
                  // focus back on the sign up button
                  if (e.key === "Enter") {
                    fetchDocument(0);
                    togglePopover();
                    e.preventDefault();
                    document.getElementById("print-doc").focus();
                  }
                }}
                className="btn btn-link text-secondary"
                type="button"
              >
                English
              </button>
            </div>
            <div>
              <button
                onClick={() => {
                  fetchDocument(1);
                  togglePopover();
                }}
                onKeyDown={(e) => {
                  // focus back on the sign up button
                  if (e.key === "Enter") {
                    fetchDocument(0);
                    togglePopover();
                    e.preventDefault();
                    document.getElementById("print-doc").focus();
                  }
                }}
                className="btn btn-link text-secondary"
                type="button"
              >
                Spanish
              </button>
            </div>
          </PopoverBody>
        </Popover>
      )}
      <Modal
        isOpen={documentModal}
        toggle={toggleDocumentModal}
        className={classNames(
          { "sidebar-modal": version === "sidebar" },
          "scroll-modal modal-md"
        )}
      >
        <ModalHeader
          toggle={toggleDocumentModal}
          close={<CloseButton toggle={toggleDocumentModal} />}
        >
          Document Viewer
        </ModalHeader>
        <ModalBody>
          <PdfViewer internalFile={signUpDocument} id="sign-up-pdf" />
        </ModalBody>
      </Modal>
    </>
  );
};
PrintSignUpDoc.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  id: PropTypes.string,
  language: PropTypes.object,
  version: PropTypes.string
};
