import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { Nav, NavLink } from "reactstrap";
import { Carousel } from "../_components";
import PropTypes from "prop-types";

const HeaderTabs = ({
  activeTab,
  headerTabs,
  handleTabChange,
  is768Width,
  mobileCarousel,
  isMobileWidth,
  updateTableState,
  headerTabsDisabled
}) => {
  const [carouselNumber, setCarouselNumber] = useState(isMobileWidth ? 3 : 5);

  // Use Effect functions
  useEffect(() => {
    if (is768Width && !isMobileWidth) {
      setCarouselNumber(5);
    } else if (isMobileWidth) setCarouselNumber(3);
  }, [is768Width, isMobileWidth]);
  return (
    <div>
      {headerTabs && (
        <Nav
          className={classnames("mx-lg-3 mx-0", {
            "w-100": isMobileWidth && headerTabs.length > 3
          })}
        >
          {isMobileWidth && mobileCarousel ? (
            <Carousel
              numberToDisplay={carouselNumber}
              items={headerTabs}
              handleTabChange={handleTabChange}
              activeTab={activeTab}
            />
          ) : (
            <>
              {headerTabs.map((headerTab, i) => (
                <div key={headerTab.id}>
                  <NavLink
                    tabIndex="0"
                    className={classnames(
                      { active: activeTab === i },
                      { "ml-4": i === 0 && is768Width },
                      { disabled: headerTabsDisabled },
                      "table-header"
                    )}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        if (updateTableState) {
                          updateTableState({ tab: headerTab.id });
                          updateTableState({ activeTab: i });
                        }
                        handleTabChange(headerTab.id, i);
                      }
                    }}
                    onClick={() => {
                      if (updateTableState) {
                        updateTableState({ tab: headerTab.id });
                        updateTableState({ activeTab: i });
                      }
                      handleTabChange(headerTab.id, i);
                    }}
                  >
                    {headerTab.name}
                  </NavLink>
                </div>
              ))}
            </>
          )}
        </Nav>
      )}
    </div>
  );
};

HeaderTabs.propTypes = {
  activeTab: PropTypes.number,
  headerTabs: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  handleTabChange: PropTypes.func,
  is768Width: PropTypes.bool,
  mobileCarousel: PropTypes.bool,
  isMobileWidth: PropTypes.bool,
  updateTableState: PropTypes.func,
  headerTabsDisabled: PropTypes.bool
};

export { HeaderTabs };
