import React, { useEffect } from "react";
import { useExpanded, useFilters, useRowSelect, useTable } from "react-table";
import { Table } from "reactstrap";
import classnames from "classnames";
import PropTypes from "prop-types";

const CernerTable = ({
  columns,
  data,
  fetchData,
  loadingMedications,
  start,
  end,
  headerActions,
  setSelectedRows,
  multiSelect,
  bulkUpdate,
  medlistLength
}) => {
  const tableInstance = useTable(
    {
      columns,
      data,
      manualFilters: true,
      autoResetFilters: false
    },
    useFilters,
    useExpanded,
    useRowSelect
  );
  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    selectedFlatRows,
    toggleAllRowsSelected,
    toggleAllRowsExpanded
  } = tableInstance;

  useEffect(() => {
    if (!multiSelect && !bulkUpdate && selectedFlatRows.length) {
      toggleAllRowsSelected(false);
      setSelectedRows([]);
    } else setSelectedRows(selectedFlatRows);
  }, [
    selectedFlatRows,
    setSelectedRows,
    multiSelect,
    toggleAllRowsSelected,
    bulkUpdate
  ]);

  //Close rows if bulk is opened
  useEffect(() => {
    if (multiSelect) {
      toggleAllRowsExpanded(false);
    }
  }, [multiSelect, toggleAllRowsExpanded]);

  useEffect(() => {
    fetchData({ start, end });
  }, [start, end, fetchData]);

  // Render the UI for your table
  return (
    <div>
      <div className="d-flex justify-content-end">
        <div className="d-flex align-items-center py-3">
          {headerActions.map((action) => action)}
        </div>
      </div>
      <Table className={classnames("cerner")} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <React.Fragment key={row.getRowProps().key}>
                <tr
                  className={classnames(
                    { expanded: row.isExpanded },
                    { "bg-light": row.index % 2 !== 0 },
                    { "bg-white": row.index % 2 === 0 }
                  )}
                >
                  {row.cells.map((cell, idx) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>

                {/* /*
                    If the row is in an expanded state, render a row with a
                    column that fills the entire length of the table.
                   */}
              </React.Fragment>
            );
          })}

          {rows.length === 0 && !loadingMedications ? (
            <tr>
              <td colSpan="8" className="pt-5 text-center">
                <h4>Nothing to see here!</h4>
                <p>This patient has no active medications</p>
              </td>
            </tr>
          ) : (
            <tr></tr>
          )}
          {loadingMedications ? (
            <tr className="hover-none">
              <td colSpan="8" className="pt-3 text-center">
                {[...Array(medlistLength || 6)].map((e, i) => {
                  if (i % 2 === 0) {
                    return (
                      <div className="ph-item mb-3" key={i}>
                        <div className="ph-col-12">
                          <div className="ph-row mb-0">
                            <div className="ph-col-15 fifteenpx"></div>
                            <div className="ph-col-quarter empty"></div>
                            <div className="ph-col-3 fifteenpx"></div>
                            <div className="ph-col-half empty"></div>
                            <div className="ph-col-15 fifteenpx"></div>
                            <div className="ph-col-quarter empty"></div>
                            <div className="ph-col-1 fifteenpx"></div>
                            <div className="ph-col-half empty"></div>
                            <div className="ph-col-quarter empty"></div>
                            <div className="ph-col-1 fifteenpx"></div>
                            <div className="ph-col-half empty"></div>
                            <div className="ph-col-15 fifteenpx"></div>
                            <div className="ph-col-half empty"></div>
                            <div className="ph-col-1 fifteenpx"></div>
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div className="ph-item mb-3" key={i}>
                        <div className="ph-col-12">
                          <div className="ph-row mb-0">
                            <div className="ph-col-3 fifteenpx"></div>
                            <div className="ph-col-1 empty"></div>
                            <div className="ph-col-1 fifteenpx"></div>
                            <div className="ph-col-quarter empty"></div>
                            <div className="ph-col-1 fifteenpx"></div>
                            <div className="ph-col-3 empty"></div>
                            <div className="ph-col-15 fifteenpx"></div>
                            <div className="ph-col-quarter empty"></div>
                            <div className="ph-col-1 fifteenpx"></div>
                            <div className="ph-col-half empty"></div>
                            <div className="ph-col-1 fifteenpx"></div>
                            <div className="ph-col-1 empty"></div>
                            <div className="ph-col-3 fifteenpx"></div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </td>
            </tr>
          ) : (
            <tr></tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

CernerTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  fetchData: PropTypes.func,
  loadingMedications: PropTypes.bool,
  start: PropTypes.string,
  end: PropTypes.string,
  headerActions: PropTypes.array,
  setSelectedRows: PropTypes.func,
  multiSelect: PropTypes.bool,
  bulkUpdate: PropTypes.bool,
  medlistLength: PropTypes.number
};

export { CernerTable };
