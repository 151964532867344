import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { SuggestedMeds } from "../components/patient/medListComponents";
import { PatientContext } from "../context/patientContext";
import { useUserContext } from "../context/userContext";
import { ReactComponent as PillBottle } from "../images/pill_bottle_x.svg";
import { CloseButton } from "./CloseButton";

const NoMedsFound = ({
  toggle,
  dataChange,
  setDataChange,
  fetchSuggestedChanges,
  suggestedMedFilterApplied
}) => {
  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);
  const { userState } = useUserContext();
  const { state } = useContext(PatientContext);
  const { suggestedMedCount } = state;

  const { user } = userState;
  return (
    <div className="dashed-border w-100 d-flex flex-column justify-content-center align-items-center mt-6 mt-xl-0 py-6 mb-4 h50-vh">
      <PillBottle className="w-3rem h-3rem" aria-label="pill bottle" />
      <div className="text-secondary text-center ">
        {suggestedMedCount ? (
          <>
            <h4 className="mb-4">
              This patient has pending medication suggestions <br></br> that
              they haven't yet added to their app
            </h4>
            <button
              className="btn btn-outline-primary mb-3"
              onClick={toggleModal}
            >
              View Pending Meds
            </button>
          </>
        ) : (
          <h4 className="mb-4">This patient has no medications</h4>
        )}
      </div>
      {!user?.isSmartSession && (
        <button className="btn btn-primary" onClick={toggle}>
          + Add Medication
        </button>
      )}
      <Modal
        isOpen={modal}
        toggle={toggleModal}
        className={"center-modal modal-md"}
      >
        <ModalHeader
          toggle={toggleModal}
          close={<CloseButton toggle={toggleModal} />}
        >
          Suggested Meds
        </ModalHeader>
        <div>
          <SuggestedMeds
            version="modal"
            dataChange={dataChange}
            setDataChange={setDataChange}
            fetchSuggestedChanges={fetchSuggestedChanges}
            suggestedMedFilterApplied={suggestedMedFilterApplied}
          />
        </div>
      </Modal>
    </div>
  );
};

NoMedsFound.propTypes = {
  toggle: PropTypes.func,
  dataChange: PropTypes.bool,
  setDataChange: PropTypes.func,
  fetchSuggestedChanges: PropTypes.func,
  suggestedMedFilterApplied: PropTypes.bool
};

export { NoMedsFound };
