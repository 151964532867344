import React from "react";
import { MyTooltip } from "../_components";
import PropTypes from "prop-types";

const EmailTooltip = ({ email, maxLength, id, placement }) => {
  return (
    <>
      {email?.length > maxLength ? (
        <div>
          <button
            className="btn focusable-text text-left p-0"
            aria-describedby="email"
            id={`email-${id}`}
          >
            {email.slice(0, maxLength)}...
          </button>
          <MyTooltip
            target={`email-${id}`}
            text={email}
            placement={placement}
          ></MyTooltip>
        </div>
      ) : (
        <div>{email}</div>
      )}
    </>
  );
};

EmailTooltip.propTypes = {
  maxLength: PropTypes.number,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  email: PropTypes.string,
  placement: PropTypes.string,
};

export { EmailTooltip };
