import React, { Suspense, useState } from "react";
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import { unitsEnum } from "../../../_helpers";
import { UnitsDropdown } from "./UnitsDropdown";
import { HealthTrackerTable } from "./HealthTrackerTable";
import { LastReading } from "./LastReading";
import PropTypes from "prop-types";
import { SimpleChartWrapper } from "../../../_components";

export const ChartCard = ({
  trackerInfo,
  sortedValues,
  item,
  userPreference,
  activeTab,
  chartId,
  latestReading
}) => {
  const defaultUnitValue = trackerInfo.units[0].label;
  // Check that the unit returned from the api is a valid unit before selecting it
  const isValidUnit = () => {
    if (userPreference) {
      return trackerInfo.units.some(
        (obj) => obj.value === unitsEnum[userPreference]
      );
    }
  };
  // Set selected unit to the user preference or to the default unit
  const [selectedUnit, setSelectedUnit] = useState(() => {
    if (isValidUnit()) return unitsEnum[userPreference];
    else return defaultUnitValue;
  });

  // If the user has a preference that is not the default unit set alt unit selected to trigger conversion
  const [alternateUnitSelected, setAlternateUnitSelected] = useState(
    unitsEnum[userPreference] &&
      unitsEnum[userPreference] !== defaultUnitValue &&
      isValidUnit()
  );

  const marker = {
    width: 10,
    height: 10,
    visible: true
  };
  const tooltip = { enable: true };
  const primaryxAxis = {
    // Needs to be a category axis to show all data points, even if days are missing
    valueType: "Category",
    labelPlacement: "OnTicks",
    // So we can read all the axis labels and the data points are not on the edge of the chart
    plotOffset: 16
  };

  // Colors for the different data points
  const fills = ["#0078c8", "#747474", "#65aa00", "#d73838"];
  function compareLogDate(a, b) {
    const dateA = a.date.getTime();
    const dateB = b.date.getTime();

    return dateA - dateB;
  }

  const chartProps = {
    width: "100%",
    height: "300px",
    id: chartId,
    primaryXAxis: {
      ...primaryxAxis
    },
    tooltip: tooltip,
    primaryYAxis: {
      valueType: trackerInfo.dataType,
      labelFormat: trackerInfo.labelType,
      interval:
        alternateUnitSelected && trackerInfo.altInterval
          ? trackerInfo.altInterval
          : trackerInfo.interval
    }
  };

  const seriesPropsMap =
    sortedValues &&
    Object.keys(sortedValues).map((type, idx) => {
      let data = sortedValues[type] || [];

      let editedData;
      if (alternateUnitSelected) {
        editedData = data.map((item) => {
          return {
            ...item,
            value: Number(trackerInfo.conversion(item.value))
          };
        });
      } else if (type === "Duration" || type === "Sleep Duration") {
        editedData = data.map((item) => {
          return {
            ...item,
            value: Number(item.value / 60)
          };
        });
        type = "Hours";
      } else
        editedData = data.map((item) => {
          return { ...item, value: Number(item.value) };
        });
      return {
        key: type.replace(/\s/g, ""),
        dataSource: editedData.sort(compareLogDate),
        xName: "logDate",
        type: "Scatter",
        yName: "value",
        name: type,
        marker: marker,
        fill: fills[idx]
      };
    });

  return (
    <>
      <Col xl="6" lg="12">
        <Card className="bg-white">
          <CardHeader className="bg-white pt-4">
            <div className="d-flex justify-content-between">
              <b> {trackerInfo.name}</b>
              {/* Only show the dropdown if more than one unit is available */}
              {trackerInfo.units.length > 1 ? (
                <UnitsDropdown
                  trackerInfo={trackerInfo}
                  setSelectedUnit={setSelectedUnit}
                  selectedUnit={selectedUnit}
                  setAlternateUnitSelected={setAlternateUnitSelected}
                />
              ) : (
                <h6 className="font-weight-normal pt-2">
                  Units: {selectedUnit}
                </h6>
              )}
            </div>
          </CardHeader>
          <CardBody className="pt-3">
            {latestReading ? (
              <>
                <LastReading
                  sortedValues={latestReading}
                  selectedUnit={selectedUnit}
                  item={item}
                  alternateUnitSelected={alternateUnitSelected}
                  trackerInfo={trackerInfo}
                />
                <Suspense fallback={<div>Loading Chart...</div>}>
                  <SimpleChartWrapper
                    chartProps={chartProps}
                    seriesPropsMap={seriesPropsMap}
                  />
                </Suspense>
              </>
            ) : (
              <>No data has been logged</>
            )}
          </CardBody>
        </Card>
      </Col>
      {activeTab > 0 ? (
        <HealthTrackerTable
          tracker={item}
          trackerInfo={trackerInfo}
          selectedUnit={selectedUnit}
          alternateUnitSelected={alternateUnitSelected}
        />
      ) : (
        <></>
      )}
    </>
  );
};

ChartCard.propTypes = {
  trackerInfo: PropTypes.object,
  sortedValues: PropTypes.object,
  item: PropTypes.object,
  userPreference: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  activeTab: PropTypes.number,
  chartId: PropTypes.string,
  latestReading: PropTypes.object
};
