import React, { useEffect, useRef, useState } from "react";
import { therapeuticCategories } from "../_helpers/therapeuticCategories";
import {
  Badge,
  Collapse,
  CustomInput,
  Dropdown,
  DropdownMenu,
  DropdownToggle
} from "reactstrap";
import classNames from "classnames";
import PropTypes from "prop-types";
import { ReactComponent as ChevronDown } from "../images/chevron_down_small.svg";
import { ReactComponent as CloseIcon } from "../images/glyph_x.svg";
import { isIE } from "../_helpers";

const extractOptions = (data) => {
  return Object.entries(data).reduce((acc, [key, value]) => {
    if (Array.isArray(value)) {
      return [...acc, key, ...value];
    }
    return [...acc, ...extractOptions(value)];
  }, []);
};
const filterData = (data, searchTerm, setOpenCollapses) => {
  const lowercasedSearchTerm = searchTerm.toLowerCase();
  // filter also needs to open the parent if the child is found

  return Object.entries(data).reduce((acc, [key, value]) => {
    if (key.toLowerCase().includes(lowercasedSearchTerm)) {
      acc[key] = value;
    } else if (Array.isArray(value)) {
      const filteredArray = value.filter((item) =>
        item.toLowerCase().includes(lowercasedSearchTerm)
      );
      if (filteredArray.length > 0) {
        acc[key] = filteredArray;
        setOpenCollapses((prevState) => [...prevState, key]);
      }
    } else {
      const nestedResult = filterData(value, searchTerm, setOpenCollapses);
      if (Object.keys(nestedResult).length > 0) {
        acc[key] = nestedResult;
        setOpenCollapses((prevState) => [...prevState, key]);
      }
    }
    return acc;
  }, {});
};

function TherapeuticCategories({
  options,
  setCheckedData,
  checkedData,
  name,
  onBlur,
  openCollapses,
  setOpenCollapses
}) {
  const handleCheckChange = (event, item, nestedData = null) => {
    const isChecked = event.target.checked;
    let allOptions = [];
    if (nestedData) {
      if (Array.isArray(nestedData)) {
        allOptions = nestedData;
      } else {
        allOptions = extractOptions(nestedData);
      }
    }
    setCheckedData((prevState) =>
      isChecked
        ? [...prevState, item, ...allOptions]
        : prevState.filter((rItem) => ![item, ...allOptions].includes(rItem))
    );
    if (document.getElementById(`${name}-input`))
      document.getElementById(`${name}-input`).focus();
    onBlur(name, true);
  };
  //   when checkedData changes, call the onChange function

  return (
    <ParentCheck
      data={options}
      onCheck={handleCheckChange}
      checkedData={checkedData}
      setOpenCollapses={setOpenCollapses}
      openCollapses={openCollapses}
    />
  );
}

TherapeuticCategories.propTypes = {
  options: PropTypes.object,
  setCheckedData: PropTypes.func,
  checkedData: PropTypes.array,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  openCollapses: PropTypes.array,
  setOpenCollapses: PropTypes.func
};

const DropdownCheckbox = ({ item, value, checkedData, onCheck }) => {
  return (
    <CustomInput
      type="checkbox"
      className="dark-checkmark"
      checked={checkedData.includes(item)}
      onChange={(event) => onCheck(event, item, value)}
      label={item}
      id={item}
      tabIndex={0}
      role="menu-item"
    />
  );
};

DropdownCheckbox.propTypes = {
  item: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  checkedData: PropTypes.array,
  onCheck: PropTypes.func
};

const CollapseItem = ({
  item,
  value,
  onCheck,
  checkedData,
  setOpenCollapses,
  openCollapses
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleCollapse = (item) => {
    if (isOpen) {
      setOpenCollapses((prevState) =>
        prevState.filter((collapseItem) => collapseItem !== item)
      );
    } else {
      setOpenCollapses((prevState) => [...prevState, item]);
    }
  };

  // if the item is in the openCollapses array, set isOpen to true
  useEffect(() => {
    if (openCollapses?.includes(item)) {
      setIsOpen(true);
    } else if (!openCollapses?.includes(item)) {
      setIsOpen(false);
    }
  }, [item, openCollapses]);

  return (
    <React.Fragment key={item}>
      <div className="px-3 py-1 text-wrap d-flex">
        <DropdownCheckbox
          item={item}
          value={value}
          checkedData={checkedData}
          onCheck={onCheck}
        />
        <button
          className="btn btn-link ml-auto p-0"
          type="button"
          // role="menu-item"
          onClick={() => toggleCollapse(item)}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              toggleCollapse(item);
            }
          }}
        >
          <ChevronDown className="glyph-dark" aria-label="chevron down" />
        </button>
      </div>

      <Collapse className="ml-4" isOpen={isOpen}>
        <ChildCheck
          data={value}
          onCheck={onCheck}
          checkedData={checkedData}
          openCollapses={openCollapses}
          setOpenCollapses={setOpenCollapses}
        />
      </Collapse>
    </React.Fragment>
  );
};

CollapseItem.propTypes = {
  item: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onCheck: PropTypes.func,
  checkedData: PropTypes.array,
  setOpenCollapses: PropTypes.func,
  openCollapses: PropTypes.array
};

const ParentCheck = ({
  data,
  onCheck,
  checkedData,
  setOpenCollapses,
  openCollapses
}) => {
  return (
    <>
      {Object.entries(data).map(([key, value]) => {
        return (
          <React.Fragment key={key}>
            {value.length > 0 ||
            // value has keys and values
            (typeof value === "object" && Object.keys(value).length > 0) ? (
              <CollapseItem
                item={key}
                value={value}
                onCheck={onCheck}
                checkedData={checkedData}
                setOpenCollapses={setOpenCollapses}
                openCollapses={openCollapses}
              />
            ) : (
              <div className="px-3 py-1 text-wrap d-flex">
                <DropdownCheckbox
                  item={key}
                  value={value}
                  checkedData={checkedData}
                  onCheck={onCheck}
                />
              </div>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

ParentCheck.propTypes = {
  data: PropTypes.object,
  onCheck: PropTypes.func,
  checkedData: PropTypes.array,
  setOpenCollapses: PropTypes.func,
  openCollapses: PropTypes.array
};

const ChildCheck = ({
  data,
  onCheck,
  checkedData,
  openCollapses,
  setOpenCollapses
}) => {
  if (!Array.isArray(data)) {
    return (
      <ParentCheck
        data={data}
        onCheck={onCheck}
        checkedData={checkedData}
        setOpenCollapses={setOpenCollapses}
        openCollapses={openCollapses}
      />
    );
  }

  return (
    <>
      {data.map((item) => (
        <div className="px-3 py-1 text-wrap d-flex" key={item}>
          <DropdownCheckbox
            item={item}
            checkedData={checkedData}
            onCheck={onCheck}
          />
        </div>
      ))}
    </>
  );
};

ChildCheck.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onCheck: PropTypes.func,
  checkedData: PropTypes.array,
  openCollapses: PropTypes.array,
  setOpenCollapses: PropTypes.func
};

const TherapeuticCategoriesDropdown = ({
  placeholder,
  value,
  onChange,
  name,
  onBlur,
  options
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputVal, setInputVal] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [checkedData, setCheckedData] = React.useState(value);
  const [openCollapses, setOpenCollapses] = useState([]);
  let wordLength = 20;

  const toggleDropdown = () => setIsOpen(!isOpen);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        document.getElementById("tcDropdown") &&
        !document.getElementById("tcDropdown").contains(event.target) &&
        document.getElementById("tcToggle") &&
        !document.getElementById("tcToggle").contains(event.target)
      ) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleInputClick = (e) => {
    e.stopPropagation();
    if (!isOpen) {
      setIsOpen(true);
    }
  };

  const handleInputChange = (e) => {
    // if the first character is a space, ignore it
    if (e.target.value[0] === " ") {
      return;
    }
    if (!isOpen) {
      setIsOpen(true);
    }
    setInputVal(e.target.value);
    setOpenCollapses([]);
    // filter the options based on the input value
    setFilteredOptions(
      filterData(therapeuticCategories, e.target.value, setOpenCollapses)
    );
  };

  useEffect(() => {
    console.log(filteredOptions);
  }, [filteredOptions]);

  useEffect(() => {
    onChange(name, checkedData);
  }, [checkedData, name, onChange]);

  return (
    <div className={classNames("position-relative")}>
      <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
        <DropdownToggle
          tag="span"
          data-toggle="dropdown"
          aria-expanded={isOpen}
          className={classNames(
            "d-flex align-items-center expandable-dropdown custom-dropdown form-control"
          )}
          id="tcToggle"
        >
          <div className="d-flex flex-wrap overflow-hidden">
            {value?.length > 0 &&
              Array.isArray(value) &&
              value.map((item, idx) => (
                <Badge
                  color="badge-gray"
                  className="mr-1 font-weight-normal p-2 font-size-85 mb-1"
                  key={`${item}-badge-${idx}`}
                >
                  {item.length > wordLength
                    ? item.slice(0, wordLength) + "..."
                    : item}
                  {/* add x button that unchecks item on click */}
                  <CloseIcon
                    className="glyph-dark h8-w8-style ml-2 close-icon"
                    onClick={(e) => {
                      e.stopPropagation();

                      setCheckedData((prevState) =>
                        prevState.filter((rItem) => rItem !== item)
                      );
                    }}
                    tabIndex={0}
                    aria-label={`Remove ${item}`}
                    role="button"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        setCheckedData((prevState) =>
                          prevState.filter((rItem) => rItem !== item)
                        );
                      }
                    }}
                  />
                </Badge>
              ))}{" "}
            <input
              type="text"
              placeholder={value?.length ? "" : placeholder}
              onChange={handleInputChange}
              value={inputVal}
              className={classNames("overflow-hidden p-0")}
              id={`${name}-input`}
              data-testid={`${name}-input`}
              onClick={handleInputClick}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === "ArrowDown") {
                  e.preventDefault();
                  toggleDropdown();
                }
              }}
              // disabled={disabled}
            />
          </div>
          <div
            className={classNames(
              { "d-flex align-items-center": !isIE },
              "ml-auto"
            )}
          >
            {value?.length > 0 && (
              <button
                className="btn btn-link p-0 close-icon"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpen(false);
                  setCheckedData([]);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Backspace" || e.key === "Enter") {
                    e.preventDefault();
                    setIsOpen(false);
                    setCheckedData([]);
                  }
                }}
                type="button"
                tabIndex={0}
              >
                <CloseIcon
                  className="glyph-gray mr-1 h12-w12-style close-icon"
                  aria-label="Clear all"
                />
              </button>
            )}

            <ChevronDown
              className="glyph-dark h12-w12-style"
              aria-label="chevron down"
            />
          </div>
        </DropdownToggle>
        <DropdownMenu
          className="therapeuticClass scrollable-dropdown-menu pb-3"
          id="tcDropdown"
        >
          {/* if filteredOptions is an empty object, return "No Options Found" */}
          {Object.keys(filteredOptions).length === 0 ? (
            <div className="px-4 pt-3 pb-2 text-secondary">
              No Options Available
            </div>
          ) : (
            <TherapeuticCategories
              onChange={onChange}
              onBlur={onBlur}
              name={name}
              options={filteredOptions}
              setCheckedData={setCheckedData}
              checkedData={checkedData}
              setInputVal={setInputVal}
              setFilteredOptions={setFilteredOptions}
              openCollapses={openCollapses}
              setOpenCollapses={setOpenCollapses}
            />
          )}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

TherapeuticCategoriesDropdown.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.array,
  onChange: PropTypes.func,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  options: PropTypes.object
};

export default TherapeuticCategoriesDropdown;
