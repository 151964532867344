import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { Card, CardBody, CardHeader } from "reactstrap";
import { convertUTCTimeToLocalTime } from "../../../_helpers";
dayjs.extend(utc);
export const TaskCard = ({ tasks }) => {
  const [overdueTasks, setOverdueTasks] = useState([]);
  const [dueTodayTasks, setDueTodayTasks] = useState([]);
  useEffect(() => {
    setOverdueTasks(
      // filter tasks by dueDate is less than today
      tasks.filter((task) => {
        // if dueDate is less than today return true
        // if dueDate is today, check if due time is less than current time return true
        return !!(
          dayjs(task.dueDate).utc().isBefore(dayjs(), "day") ||
          (dayjs(task.dueDate).utc().isSame(dayjs(), "day") &&
            convertUTCTimeToLocalTime(task.dueTime, true) <
              dayjs().format("HH:mm"))
        );
      })
    );
    setDueTodayTasks(
      //   filter tasks by due date is today
      tasks.filter((task) => dayjs(task.dueDate).utc().isSame(dayjs(), "day"))
    );
  }, [tasks]);

  return (
    <Card className="bg-white">
      <CardHeader>
        <h6>Task Summary</h6>
      </CardHeader>
      <CardBody className="py-4 px-4 px-lg-5">
        <div className="d-flex align-items-center justify-content-around">
          <div className="d-flex flex-column align-items-center">
            <h2>{tasks.length}</h2> Pending
          </div>
          <div className="d-flex flex-column align-items-center">
            <h2 className=""> {overdueTasks.length}</h2> Overdue
          </div>

          <div className="d-flex flex-column align-items-center">
            <h2>{dueTodayTasks.length}</h2> Due Today
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

TaskCard.propTypes = {
  tasks: PropTypes.array
};
