import React, { lazy } from "react";
// lazy import Input and isValidPhoneNumber from react-phone-number-input
const Input = lazy(() => import("react-phone-number-input/input"));
// const isValidPhoneNumber = lazy(() =>
//   import("react-phone-number-input").then((module) => ({
//     default: module.isValidPhoneNumber
//   }))
// );

export const PhoneNumberInput = ({
  onChange,
  onBlur,
  value,
  classnames,
  id,
  name,
  placeholder
}) => {
  return (
    <Input
      id={id}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      defaultCountry={"US"}
      name={name}
      placeholder={placeholder}
      className={classnames}
    />
  );
};
