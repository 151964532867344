import classnames from "classnames";
import { ErrorMessage, Form, Formik } from "formik";
import debounce from "lodash.debounce";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { FormikValidate, Loader } from "../_components";
import {
  UseMobileMediaQuery,
  filterMeds,
  isValidDate,
  timesOfDay
} from "../_helpers";
import { formEnum } from "../_helpers/formEnum";
import { medicationsService } from "../_services";
import { useAlertContext } from "../context/alertContext";
import { AsyncMedDropdown, Instructions, ScheduleType } from "./formComponents";
import { StrengthAndForm } from "./formComponents/StrengthAndForm";
import { quantityUnitsEnum } from "../_helpers/quantityUnits";

const AddMedications = ({
  setModalNumber,
  setMedName,
  submitAddMed,
  setMeds,
  meds,
  selectedMed,
  rowId,
  setFormEdited,
  toggleDelete,
  togglePause,
  disableFields
}) => {
  const [customMed, setCustomMed] = useState(false);
  const [strengthOptions, setStrengthOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [numberOfChars, setNumberOfChars] = useState(0);
  const [medFieldDisabled, setMedFieldDisabled] = useState(false);

  const isMobileWidth = UseMobileMediaQuery();
  const { alertMethods } = useAlertContext();

  // Disable certain fields when there is no med name and strength selected
  const findIsDisabled = (values) =>
    !values.medName ||
    (!values.strengthAndForm &&
      (!values.strength || !values.form || !values.unit));

  const [initialValues, setInitialValues] = useState({
    medName: "",
    strengthAndForm: "",
    customInstructions: "",
    strength: "",
    unit: "",
    form: "",
    dose: "",
    doseType: "",
    startDateTime: "",
    endDateTime: "",
    frequency: "",
    prnReason: "",
    prnReasonOther: "",
    medicationScheduleTimes: [],
    patientInstructions: "",
    daysOn: undefined,
    daysOff: undefined,
    totalNumberOfCycles: undefined
    // savedPlan: ""
  });

  const mounted = React.useRef(false);

  React.useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => {
      mounted.current = false;
    }; // ... and to false on unmount
  }, []);

  let validationSchema = Yup.object().shape({
    medName: Yup.object().shape({
      label: Yup.string().required("Please select a medication"),
      value: Yup.string().required("Please select a medication"),
      strengths: Yup.array()
    }),
    // Conditional strength fields required based on custom med or not
    strengthAndForm: Yup.lazy((values) => {
      return !customMed
        ? Yup.object()
            .nullable()
            .shape({
              value: Yup.string().required("Please select a strength and form"),
              label: Yup.string().required("Please select a strength and form")
            })
        : Yup.mixed().nullable().notRequired();
    }),
    strength: Yup.lazy((values) => {
      return customMed
        ? Yup.number().required("Please enter a strength")
        : Yup.mixed().nullable().notRequired();
    }),
    unit: Yup.lazy((values) => {
      return customMed
        ? Yup.object()
            .nullable()
            .shape({
              value: Yup.string().required("Please select a unit"),
              label: Yup.string().required("Please select a unit")
            })
        : Yup.mixed().nullable().notRequired();
    }),
    form: Yup.lazy((values) => {
      return customMed
        ? Yup.object()
            .nullable()
            .shape({
              value: Yup.string().required("Please select a form"),
              label: Yup.string().required("Please select a form")
            })
        : Yup.mixed().nullable().notRequired();
    }),
    // savedPlan: Yup.string().when("scheduleType", {
    //   is: (value) => {
    //     return value?.value === 8;
    //   },
    //   then: schema => schema.required("Please choose a plan"),
    //   otherwise: schema => schema
    // }),
    medicationScheduleTimes: Yup.lazy((values, otherValues) => {
      return Yup.array(
        Yup.object().shape({
          dose: Yup.number().nullable(),
          // Dose is not required
          time: Yup.date().when(["partOfDay", "dose"], {
            // When dose is different from the dose set in the parent field and there is no partOfDay, time is required
            is: (value, value2) => {
              return (
                (value2 &&
                  Number(value2) !== Number(otherValues.parent.dose) &&
                  !value?.label) ||
                (otherValues.parent.frequency?.label === "Cycle" &&
                  !value?.label &&
                  // when there is more than one item in the array, time is required
                  values.length > 1)
              );
            },
            then: (schema) =>
              schema.required(
                otherValues.parent.frequency?.label === "Cycle"
                  ? ""
                  : "Time is required when a custom dose is set"
              ),
            otherwise: (schema) => schema.nullable().notRequired()
          })
        })
      );
    }),
    // when frequency.label is Cycle, daysOn and daysOff are required
    daysOn: Yup.number().when("frequency", {
      is: (value) => value?.label === "Cycle",
      then: (schema) => schema.required("Days On is required"),
      otherwise: (schema) => schema.nullable().notRequired()
    }),
    daysOff: Yup.number().when("frequency", {
      is: (value) => value?.label === "Cycle",
      then: (schema) => schema.required("Days On is required"),
      otherwise: (schema) => schema.nullable().notRequired()
    }),
    // dose is not required but must be more than 0
    dose: Yup.number().nullable().min(0.1, "Dose must be more than 0")
  });

  const presetCustomMed = React.useCallback(
    (strength, medicationName, form, formId) => {
      let strengthIdx = strength?.indexOf(" ") || 0;
      if (!formId && form) {
        formId = formEnum.find((f) => f.label === form.toLowerCase())?.value;
      }
      setInitialValues((current) => ({
        ...current,
        medName: { label: medicationName, value: medicationName },
        strength: strength ? strength.slice(0, strengthIdx) : "",
        form: form ? { value: formId || form, label: form } : "",
        unit: strength
          ? {
              value: quantityUnitsEnum.find(
                (unit) =>
                  strength.includes(unit.label) || strength.includes(unit.abbr)
              )?.value,
              label: quantityUnitsEnum.find(
                (unit) =>
                  strength.includes(unit.label) || strength.includes(unit.abbr)
              )?.abbr
            }
          : ""
      }));
      setLoading(false);
    },
    []
  );

  const setDoseTime = (item) => {
    // the doseTime variable is initialized with null to ensure a consistent return type, according to sonarcloud bug
    let doseTime = null;
    let formattedDate = "";
    if (item.time) {
      const date = new Date("Dec 2, 2022");
      const timeParts = item.time
        .split(":")
        .map((part) => part.replace(/\D/g, ""));

      const hours = parseInt(timeParts[0], 10);

      const minutes = parseInt(timeParts[1], 10);

      const seconds = 0;
      const milliseconds = 0;
      date.setHours(hours);
      date.setMinutes(minutes);
      date.setSeconds(seconds);
      date.setMilliseconds(milliseconds);
      doseTime = date;
      formattedDate = new Date(doseTime);
    }
    return formattedDate;
  };

  const debouncedSearchMedicationCall = React.useMemo(
    () =>
      debounce(async (inputValue, callback) => {
        if (inputValue.length < 3) {
          setOptions([]); // storing our option in FE state
          callback([]); //store options in react-select
        } else {
          setNumberOfChars(inputValue.length);
          // Your existing logic for API call
          try {
            const response = await medicationsService.searchMeds(inputValue);
            const medOptions = response.medications.map((med) => ({
              value: med.drugId,
              label: med.name,
              strengths: med.strengths.filter(
                (strength) => strength.strength !== null
              )
            }));

            setOptions(medOptions);
            if (callback) callback(medOptions);
          } catch (e) {
            alertMethods.error(
              "Something went wrong. Refresh the page and try again."
            );
          }
        }
      }, 500),
    [alertMethods]
  );

  const loadMedicationOptions = (inputValue, callback) => {
    // Clear options if the input value is less than 3
    if (!options.length || inputValue.length < numberOfChars) {
      debouncedSearchMedicationCall(inputValue, callback);
    } else if (inputValue?.length >= 3 && options?.length > 0) {
      // If there are options available and the user types more than 3 characters, filter the options
      if (mounted.current) {
        callback(filterMeds(inputValue, options));
      }
    }
  };

  const presetDatabaseMed = React.useCallback(
    (medicationName, drugDetailId, medicationStrength) => {
      medicationsService
        .searchMeds(medicationName)
        .then((res) => {
          // If it's not a custom med, we have to search for the med in the api in order to get the strength options
          if (mounted.current) {
            const medName =
              res.medications.find((item) => item.name === medicationName) ||
              res[0];
            setMedFieldDisabled(true);

            const { strength, form } = medicationStrength;
            setInitialValues((current) => ({
              ...current,
              medName: {
                label: medName.name,
                value: medName.drugId,
                strengths: medName.strengths.filter(
                  (strength) =>
                    strength.strength !== null &&
                    (!strength.isDeleted || strength.id === drugDetailId)
                )
              },
              strengthAndForm: strength
                ? {
                    value: drugDetailId,
                    label: strength + " " + form,
                    ...medName.strengths.find((s) => s.id === drugDetailId)
                  }
                : ""
            }));
            setLoading(false);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    []
  );

  const presetMedInfo = React.useCallback(
    (selectedMed) => {
      setLoading(true);
      const {
        drugDetailId,
        medicationName,
        medicationSchedule,
        medicationStrength,
        // selectedPlan,
        status
      } = selectedMed;
      const {
        startDate,
        endDate,
        medicationScheduleTimes,
        instructionText,
        doseQuantity,
        quantityUnit,
        quantityUnitId,
        sigCode,
        prnReason,
        prnReasonOther,
        patientInstructions,
        daysOn,
        daysOff,
        totalNumberOfCycles
      } = medicationSchedule;
      const { strength, form, formId } = medicationStrength;
      let qu = quantityUnitsEnum.find((unit) => unit.value === quantityUnitId);

      // Create a Set to store unique times
      let uniqueTimesSet = new Set();

      // Filter the medicationScheduleTimes array to include only unique times
      let uniqueTimesArray = medicationScheduleTimes?.filter((item) => {
        if ((item?.time && !uniqueTimesSet.has(item.time)) || !item.time) {
          // Add the unique time to the Set
          uniqueTimesSet.add(item.time);
          return true; // Include this item in the filtered array
        }
        return false; // Exclude this item from the filtered array
      });
      // Initial values that are the same for custom and non-custom meds
      setInitialValues((current) => {
        let doseType = "";
        if (quantityUnitId) {
          doseType = {
            value: quantityUnitId,
            label: quantityUnit || qu?.abbr,
            description: qu?.label
          };
        } else if (quantityUnit) {
          doseType = { value: quantityUnit, label: quantityUnit };
        }

        return {
          ...current,
          customInstructions: instructionText || "",
          patientInstructions: patientInstructions || "",
          startDateTime:
            startDate && isValidDate(startDate) ? new Date(startDate) : "",
          endDateTime: endDate && isValidDate(endDate) ? new Date(endDate) : "",
          frequency: sigCode || "",
          daysOn: daysOn > 0 ? daysOn : undefined,
          daysOff: daysOff > 0 ? daysOff : undefined,
          totalNumberOfCycles:
            totalNumberOfCycles > 0 ? totalNumberOfCycles : undefined,
          status: status,
          doseType,
          dose: String(doseQuantity || ""),
          prnReason: prnReason,
          prnReasonOther: prnReasonOther,
          medicationScheduleTimes: medicationScheduleTimes?.length
            ? uniqueTimesArray.map((item) => {
                return {
                  frequencyQualifier: item.frequencyQualifier,
                  dose: String(item.doseQuantity || ""),
                  time: item.time ? setDoseTime(item) : "",
                  partOfDay:
                    item.partOfDay >= 0
                      ? timesOfDay.find((tod) => tod?.value === item.partOfDay)
                      : ""
                };
              })
            : []
          // savedPlan:
          //   scheduleType || realScheduleType?.value === 8 ? selectedPlan : ""
        };
      });
      if (drugDetailId) {
        // Set values that are specific to meds in the database
        presetDatabaseMed(medicationName, drugDetailId, medicationStrength);
      } else {
        // for custom meds set custom med specific fields
        presetCustomMed(strength, medicationName, form, formId);
      }
    },
    [presetCustomMed, presetDatabaseMed]
  );

  // const presetPlanInviteFlow = React.useCallback(
  //   (selectedMed) => {
  //     const {
  //       drugDetailId,
  //       medicationName,
  //       medicationStrength,
  //       medicationPlanName,
  //       id
  //     } = selectedMed;
  //     const { strength, form, mmdc } = medicationStrength;
  //     // setSelectedPlan(selectedMed);
  //     setInitialValues((current) => ({
  //       ...current,
  //       instructions: "",
  //       customInstructions: "",
  //       endDateTime: "",
  //       // if the plan has a name, it is a saved plan so select plan - saved; otherwise select plan -new
  //       scheduleType: medicationPlanName ? scheduleTypes[7] : scheduleTypes[6],
  //       status: 0,
  //       savedPlan: {
  //         ...selectedMed,
  //         value: id,
  //         label: medicationPlanName
  //       }
  //     }));
  //     if (drugDetailId) {
  //       presetDatabaseMed(medicationName, drugDetailId, strength, form, mmdc);
  //     } else {
  //       presetCustomMed(strength, medicationName, form);
  //     }
  //   },
  //   [presetCustomMed, presetDatabaseMed]
  // );

  useEffect(() => {
    //preset med info in form if editing a med from list
    if (selectedMed?.medicationName && selectedMed?.medicationSchedule) {
      presetMedInfo(selectedMed);
    }
    // This is for medication plans in the edit invite flow
    // if (selectedMed?.medicationName && selectedMed.medicationPlanSteps) {
    //   presetPlanInviteFlow(selectedMed);
    // }
  }, [
    presetCustomMed,
    presetDatabaseMed,
    presetMedInfo,
    // presetPlanInviteFlow,
    selectedMed
  ]);

  function formatMedForSubmit(fields) {
    const {
      customInstructions,
      medName,
      strengthAndForm,
      startDateTime,
      endDateTime,
      frequency,
      strength,
      unit,
      form,
      medicationScheduleTimes,
      prnReason,
      prnReasonOther,
      daysOn,
      daysOff,
      totalNumberOfCycles
      // times

      // savedPlan
    } = fields;

    let mednamevalue;
    if (typeof medName.value === "string") {
      mednamevalue = medName.value
        // replace all spaces with a hyphen
        .replace(/\s/g, "-");
    } else {
      mednamevalue = medName.value;
    }

    let instructionText = customInstructions;
    let formId = strengthAndForm?.formId || form?.value;
    let prnReasonValue;
    if (prnReason && typeof prnReason.value === "number") {
      prnReasonValue = prnReason.value;
    } else if (prnReason && typeof prnReason.value === "string") {
      prnReasonValue = -1;
    } else {
      prnReasonValue = null;
    }
    if (typeof formId !== "number") formId = null;
    return {
      id: selectedMed?.id || undefined,
      tempId: selectedMed?.id || mednamevalue,
      medicationName: medName.label,
      medicationId: selectedMed?.id || null,
      drugDetailId: !strengthAndForm ? null : strengthAndForm.value,
      isSmartAppMedication: selectedMed.isSmartAppMedication,
      medicationApprovalStatus: 1,
      status: 0,
      source: 0,

      medicationSchedule: {
        id: undefined,
        startDate: startDateTime
          ? dayjs(startDateTime).format("YYYY-MM-DD")
          : null,
        endDate: endDateTime ? dayjs(endDateTime).format("YYYY-MM-DD") : null,
        sigCode: frequency?.label,
        frequencyId:
          frequency?.value && typeof frequency?.value === "number"
            ? frequency?.value
            : null,
        instructionText: instructionText,
        // prnReason: if there is a prnReason and the type of prnReason.value is a number, then set prnReason.value to the number, otherwise set it to null
        prnReason: prnReasonValue,
        prnReasonOther: prnReasonOther,
        doseQuantity: fields.dose,

        quantityUnit: fields.doseType?.label,
        quantityUnitId:
          typeof fields.doseType?.value === "number"
            ? fields.doseType?.value
            : null,
        daysOn: daysOn || 0,
        daysOff: daysOff || 0,
        totalNumberOfCycles: totalNumberOfCycles || -1,

        medicationScheduleTimes: medicationScheduleTimes
          .filter((schedule) => schedule.time || schedule.partOfDay)
          .map((schedule, idx) => {
            let time = new Date(schedule.time).toTimeString().slice(0, 5);
            return {
              id: undefined,
              time: schedule.time ? time : "",
              doseQuantity: schedule.dose,
              partOfDay:
                schedule.partOfDay?.value >= 0 ? schedule.partOfDay.value : null
            };
          })
      },
      medicationStrength: {
        id: undefined,
        mmdc: strengthAndForm?.mmdc,
        strength: strength
          ? strength + " " + unit.label
          : strengthAndForm.strength,
        formId: formId,
        form: form ? form.label : strengthAndForm.form,
        isSelected: true,
        routeId: strengthAndForm?.routeId,
        unit: unit.value,
        rxcui: strengthAndForm?.rxcui
      }
      // selectedPlan: selPlan.medicationPlanSteps ? selPlan : savedPlan,
      // medicationStepsId: undefined
    };
  }

  function onSubmit(fields, { setSubmitting }) {
    const submitMed = formatMedForSubmit(fields);
    if (setMedName) setMedName(fields.medName.label);
    //Sometimes you need to show the med name in the success modal
    if (setFormEdited) setFormEdited(true);
    if (submitAddMed) {
      submitAddMed({ ...submitMed }, setSubmitting);
    }

    //In some cases this is the function that actually formats and adds the med through the API. In invite flow, this is not the case

    //This is for when you edit a med on edit invite flow
    //Remove the old med from the list and then add the updated med to the end
    else {
      // Otherwise we are in invite flow and are either editing or adding to the the meds array;
      if (rowId || rowId === 0) {
        setMeds((state) =>
          state.filter((item, idx) => idx !== rowId).concat(submitMed)
        );
      } else if (setMeds) {
        setMeds((state) =>
          state.concat({ ...submitMed, isSmartAppMedication: false })
        );
      }
      //Artificial timeout before showing success modal
      if (setModalNumber && mounted.current) setModalNumber(2);
    }
  }

  //   function formIsDisabled(isSubmitting, isValid, dirty, values) {
  //     return (
  //       isSubmitting ||
  //       !isValid ||
  //       !dirty ||
  //       // && !planAdded
  //       values.scheduleType?.value === 7
  //       //  && !planAdded
  //     );
  // }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        values,
        isValid,
        dirty,
        resetForm
      }) => {
        return (
          <>
            {loading ? (
              <Loader />
            ) : (
              <FormikValidate>
                <Form
                  id="add-meds-form"
                  data-testid="add-meds-form"
                  className="form"
                >
                  <div className="form-group">
                    <AsyncMedDropdown
                      meds={meds}
                      setStrengthOptions={setStrengthOptions}
                      values={values}
                      setFieldValue={setFieldValue}
                      setCustomMed={setCustomMed}
                      customMed={customMed}
                      loadOptions={loadMedicationOptions}
                      options={options}
                      clearValue={true}
                      setOptions={setOptions}
                      setNumberOfChars={setNumberOfChars}
                      disableFields={
                        !!(disableFields && selectedMed.isSmartAppMedication) ||
                        medFieldDisabled
                      }
                    />
                    <ErrorMessage
                      name="medName"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <StrengthAndForm
                    setCustomMed={setCustomMed}
                    strengthOptions={strengthOptions}
                    customMed={customMed}
                    values={values}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    disableFields={
                      !!(disableFields && selectedMed.isSmartAppMedication)
                    }
                  />
                  <ScheduleType
                    // setSelectedPlan={setSelectedPlan}
                    values={values}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    findIsDisabled={findIsDisabled}
                    // selPlan={selPlan}
                    setInitialValues={setInitialValues}

                    // setPlanAdded={setPlanAdded}
                  />

                  <Instructions
                    findIsDisabled={findIsDisabled}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    values={values}
                    selectedMed={selectedMed}
                    errors={errors}
                    touched={touched}
                  />

                  <div
                    className={classnames(
                      "form-group d-flex mt-6 flex flex-wrap",
                      { "justify-content-md-start": !selectedMed },
                      {
                        "justify-content-md-between":
                          selectedMed?.medicationName
                      }
                    )}
                  >
                    {selectedMed?.medicationName ? (
                      <div className={classnames({ "w-100": isMobileWidth })}>
                        <button
                          className="btn btn-outline-danger mr-md-3 mr-0 mb-md-0 mb-3"
                          type="button"
                          onClick={() => {
                            toggleDelete(values);
                          }}
                        >
                          Delete Med
                        </button>
                        {togglePause ? (
                          <>
                            {selectedMed.status !== 2 ? (
                              <button
                                onClick={() => {
                                  togglePause(values);
                                  // const btn =
                                  //   document.getElementById("pause-med-btn");
                                  // btn.blur();
                                }}
                                className="btn btn-outline-secondary mb-md-0 mb-3"
                                type="button"
                                id="pause-med-btn"
                              >
                                Pause Med
                              </button>
                            ) : (
                              <button
                                onClick={() => {
                                  togglePause(values);
                                  // const btn =
                                  //   document.getElementById("resume-med-btn");
                                  // btn.blur();
                                }}
                                className="btn btn-outline-secondary mb-md-0 mb-3"
                                type="button"
                                id="resume-med-btn"
                              >
                                Resume Med
                              </button>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                    <button
                      data-testid="save-med-btn"
                      type="submit"
                      disabled={isSubmitting || !dirty || !isValid}
                      className="btn btn-primary mb-4 mb-md-0"
                    >
                      {isSubmitting && (
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                      )}
                      Save
                    </button>

                    {!selectedMed?.status && !selectedMed?.medicationName && (
                      <button
                        data-testid="clear-med-btn"
                        type="button"
                        disabled={!dirty || isSubmitting}
                        className="btn btn-outline-primary ml-0 ml-md-4"
                        onClick={() => {
                          setCustomMed(false);
                          resetForm();
                        }}
                      >
                        Clear
                      </button>
                    )}
                  </div>
                  {/* if submit add med function exists, these are all just suggestions sent to the patient */}
                  {submitAddMed ? (
                    <div className="text-secondary small mb-3">
                      On submit, med change will be sent to the patient to
                      confirm
                    </div>
                  ) : (
                    <></>
                  )}
                </Form>
              </FormikValidate>
            )}
          </>
        );
      }}
    </Formik>
  );
};

AddMedications.propTypes = {
  setModalNumber: PropTypes.func,
  setMedName: PropTypes.func,
  submitAddMed: PropTypes.func,
  setMeds: PropTypes.func,
  meds: PropTypes.array,
  selectedMed: PropTypes.object,
  rowId: PropTypes.number,
  setFormEdited: PropTypes.func,
  toggleDelete: PropTypes.func,
  togglePause: PropTypes.func,
  disableFields: PropTypes.bool
};

export { AddMedications };
