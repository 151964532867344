import React, { useContext, useEffect, useRef, useState } from "react";
import { MyPopover, RiskProfileCircle } from "../../_components";
import { ReactComponent as NewLink } from "../../images/glyph-new-link.svg";
import { PatientContext } from "../../context/patientContext";

const PatientRiskProfileModal = () => {
  const { state } = useContext(PatientContext);
  const { patient } = state;
  const [riskModal, setRiskModal] = useState(false);
  const [atRiskProfiles, setAtRiskProfiles] = useState([]); // patient?.riskProfiles?.filter((item) => item.atRisk) || [

  useEffect(() => {
    if (patient?.riskProfiles) {
      setAtRiskProfiles(patient.riskProfiles.filter((item) => item.atRisk));
    }
  }, [patient]);

  const toggleRiskModal = () => {
    setRiskModal(!riskModal);
  };
  const mounted = useRef(false);

  React.useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => {
      mounted.current = false;
    }; // ... and to false on unmount
  }, []);

  return (
      <div className="info-val mb-1 ml-4 d-flex align-items-end">
        {atRiskProfiles?.length ? (
          <RiskProfileCircle
            level={Math.max(...atRiskProfiles.map((item) => item.severity))}
            textOnly={true}
          />
        ) : (
          <></>
        )}
        {atRiskProfiles?.length > 0 ? (
          <>
            <button
              className="btn btn-link py-0 pl-2 ml-neg-0-1rem"
              id="patient-riskprofiles"
              onClick={toggleRiskModal}
            >
              <NewLink
                className="glyph-grooveblue ml-1"
                aria-label="risk-profiles"
              />
            </button>
            <MyPopover
              id="risk-popover"
              target="patient-riskprofiles"
              title="Risk Profile(s)"
              placement="right-start"
              text={
                <div>
                  {atRiskProfiles.map((profile) => (
                    <div key={profile.id} className="d-flex align-items-center">
                      <RiskProfileCircle
                        name={profile.name}
                        level={profile.severity}
                      />
                    </div>
                  ))}
                </div>
              }
            />
          </>
        ) : (
          <></>
        )}
      </div>
  );
};

export { PatientRiskProfileModal };
