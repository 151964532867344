import classnames from "classnames";
import { ErrorMessage, Field, Form, Formik } from "formik";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import React, { Suspense, useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import {
  DatePickerField,
  FormikValidate,
  MySelect,
  TimePickerField
} from "../_components";
import {
  Use1440MediaQuery,
  convertLocalTimeToUTCTime,
  convertUTCDateTimeToLocalDateTime,
  convertUTCTimeToLocalTime
} from "../_helpers";
import { patientService } from "../_services";
import { useAlertContext } from "../context/alertContext";
import { useTableStateContext } from "../context/stateContext";
import { useUserContext } from "../context/userContext";
import { ReactComponent as Lock } from "../images/glyph_lock.svg";

const typeOptions = [
  { value: 1, label: "Call" },
  { value: 2, label: "Email" },
  { value: 3, label: "Text" },
  { value: 4, label: "In-Person" }
];

const resultOptions = [
  { value: 1, label: "Contacted" },
  { value: 2, label: "Left Voicemail" },
  { value: 3, label: "Unable to Reach" },
  { value: 4, label: "Reached, but refused conversation" }
];

const OutreachForm = ({
  patient,
  patientId,
  groups,
  fetchData,
  users,
  toggleAdd,
  selectedItem,
  setSelectedItem
}) => {
  const { userState } = useUserContext();
  const is1400Width = Use1440MediaQuery();
  const { user } = userState;
  const { alertMethods } = useAlertContext();
  const { tableState } = useTableStateContext();
  const [initialValues, setInitialValues] = useState({
    title: "",
    description: "",
    result: "",
    outreachType: "",
    contactedBy: { value: user?.userId, label: user?.name },
    associatedPatient: patient.name || "",
    associatedGroup: "",
    dateContacted: "",
    timeContacted: ""
  });

  const dateContacted = useMemo(() => {
    let utcDataTime = ""
    if (selectedItem?.dueDate && selectedItem?.dueTime) {
      utcDataTime = convertUTCDateTimeToLocalDateTime(
        selectedItem.dueDate,
        selectedItem.dueTime
      );
    } else if (selectedItem?.dueDate) {
      utcDataTime = new Date(selectedItem.dueDate.slice(0, -1));
    }
    return utcDataTime;
  }, [selectedItem]);

  // useEffect to set initial values of outreach form when editing
  useEffect(() => {
    if (selectedItem?.title) {
      setInitialValues({
        title: selectedItem.title,
        description: selectedItem.description || "",
        result: resultOptions.find(
          (item) => item.value === selectedItem.result
        ),
        outreachType: typeOptions.find(
          (item) => item.value === selectedItem.type
        ),
        contactedBy: {
          value: selectedItem.contactedByUserId,
          label: selectedItem.contactedByUser
        },
        associatedPatient: patient.name || "",
        associatedGroup: selectedItem.group || "",
        dateContacted: dateContacted,
        timeContacted: selectedItem.dueTime
          ? new Date(
              `1/1/2020 ${convertUTCTimeToLocalTime(selectedItem.dueTime)}`
            )
          : ""
      });
    } else if (patient.name) {
      setInitialValues((prev) => ({
        ...prev,
        associatedPatient: patient.name
      }));
    }
  }, [patient.name, selectedItem, dateContacted]);

  const newOutreachValidationSchema = Yup.object().shape({
    title: Yup.string()
      .required("Name is required")
      .max(100, "Title must be 100 or fewer characters"),
    outreachType: Yup.object()
      .nullable()
      .shape({
        value: Yup.string().required("Type is required"),
        label: Yup.string().required("Type is required")
      }),
    contactedBy: Yup.object()
      .nullable()
      .shape({
        value: Yup.string().required("Contacted by is required"),
        label: Yup.string().required("Contacted by is required")
      }),
    result: Yup.object()
      .nullable()
      .shape({
        value: Yup.string().required("Result is required"),
        label: Yup.string().required("Result is required")
      })
  });

  function onAdd(fields, { setSubmitting, resetForm }) {
    const getContactedDate = () => {
      if (fields.timeContacted) {
        return utcDate;
      } else if (fields.dateContacted) {
        return dayjs(fields.dateContacted).format("YYYY-MM-DD");
      }
      return "";
    };

    setSubmitting(true);
    let time, dateString, dateObj, utcDate, timeObj;
    const formattedDate = dayjs(fields.dateContacted).format("MM/DD/YYYY");

    if (fields.timeContacted) {
      // If editing, time is in this format "10:00 AM" and needs to be converted to an object
      if (typeof fields.timeContacted !== "object") {
        timeObj = new Date("2000-01-01 " + fields.timeContacted);
      } else {
        timeObj = fields.timeContacted;
      }

      // Extract hours and minutes from the time object
      const hours = timeObj.getHours();
      const minutes = timeObj.getMinutes();

      // Convert the local time to UTC time in "HH:mm" format using the custom function
      time = convertLocalTimeToUTCTime(timeObj.toTimeString().slice(0, 5));

      // Create a new date object with the combined date and time strings
      dateString = `${formattedDate} ${hours}:${minutes}`;
      dateObj = new Date(dateString);

      // Convert the date object to its UTC representation as a string
      utcDate = dateObj.toUTCString();
    }
    let id = patientId;
    let body = {
      contactedByUserId: fields.contactedBy?.value,
      title: fields.title,
      description: fields.description,
      result: fields.result.value,
      type: fields.outreachType.value,
      patientId: patientId,
      groupId: fields.associatedGroup ? fields.associatedGroup.value : "",
      contactedDate: getContactedDate(),
      contactedTime: time
    };

    if (selectedItem?.title && id) {
      patientService
        .editOutreach(id, selectedItem.id, body)
        .then(() => {
          setSubmitting(false);
          toggleAdd();
          setSelectedItem({});
          fetchData(tableState.outreachTableState);
          alertMethods.success("Outreach has been edited");
        })
        .catch(() => {
          setSubmitting(false);
          alertMethods.error("Outreach could not be edited");
        });
    } else if (id) {
      patientService
        .createOutreach(id, body)
        .then(() => {
          alertMethods.success("Outreach has been created");
          setSubmitting(false);
          if (toggleAdd) toggleAdd();
          resetForm();
          fetchData(tableState.outreachTableState);
        })
        .catch((e) => {
          alertMethods.error("Outreach could not be created");
          setSubmitting(false);
        });
    }
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={newOutreachValidationSchema}
      onSubmit={onAdd}
      enableReinitialize
      validateOnChange={true}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        isValid,
        dirty,
        setFieldValue,
        setFieldTouched,
        resetForm
      }) => {
        return (
          <FormikValidate>
            <Form data-testid="create-new-outreach-form">
              <div>
                <div className="form-group">
                  <label htmlFor="title">Title *</label>
                  <Field
                    id="title"
                    name="title"
                    type="text"
                    placeholder="Create an outreach title"
                    className={classnames(
                      { "is-invalid": errors.title && touched.title },
                      "form-control"
                    )}
                  />
                  <ErrorMessage
                    name="title"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="description"> Notes</label>
                  <Field
                    id="description"
                    name="description"
                    data-testid="notes-field"
                    placeholder="​Description of the outreach"
                    component="textarea"
                    rows="4"
                    className={classnames(
                      {
                        "is-invalid": errors.description && touched.description
                      },
                      "form-control"
                    )}
                  />
                </div>
                <div className="form-group">
                  <MySelect
                    name="outreachType"
                    id="outreachType"
                    label="Type *"
                    placeholder="Select Type"
                    options={typeOptions}
                    value={values.outreachType}
                    onChange={(name, val) => {
                      setFieldValue(name, val);
                      setFieldValue("result", { value: "", label: "" });
                    }}
                    onBlur={setFieldTouched}
                    error={errors.outreachType}
                    touched={touched.outreachType}
                    fontSize="1rem"
                  />
                  <ErrorMessage
                    name="outreachType"
                    component="div"
                    className="invalid-feedback"
                  >
                    {(message) => <div className="error">{message.label}</div>}
                  </ErrorMessage>
                </div>
                <div className="form-group">
                  <MySelect
                    id="result"
                    name="result"
                    label="Result *"
                    placeholder=""
                    options={
                      values.outreachType?.value !== 1
                        ? resultOptions.filter((item) => item.value === 1)
                        : resultOptions
                    }
                    value={values.result}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    error={errors.result}
                    touched={touched.result}
                    fontSize="1rem"
                  />

                  <ErrorMessage
                    name="result"
                    component="div"
                    className="invalid-feedback"
                  >
                    {(message) => <div className="error">{message.label}</div>}
                  </ErrorMessage>
                </div>
                <div className="form-row">
                  <div className="form-group col-12">
                    {user?.userRole === 0 ? (
                      <>
                        <label htmlFor="contactedBy">Contacted By *</label>
                        <Field
                          id="contactedBy"
                          name="contactedBy"
                          value={values.contactedBy.label}
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          error={errors.contactedBy}
                          touched={touched.contactedBy}
                          readOnly={true}
                          className={classnames(
                            {
                              "is-invalid":
                                errors.contactedBy && touched.contactedBy
                            },
                            "form-control bg-gray"
                          )}
                        ></Field>
                        <Lock className="form_glyph" aria-label="lock" />
                      </>
                    ) : (
                      <MySelect
                        name="contactedBy"
                        id="contactedBy"
                        label="Contacted By *"
                        options={users}
                        value={values.contactedBy}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        error={errors.contactedBy}
                        touched={touched.contactedBy}
                        fontSize="1rem"
                      />
                    )}
                    <ErrorMessage
                      name="contactedBy"
                      component="div"
                      className="invalid-feedback"
                    >
                      {(message) => (
                        <div className="error">{message.label}</div>
                      )}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-12">
                    <label htmlFor="associatedPatient">
                      Associated Patient *
                    </label>
                    <Field
                      id="associatedPatient"
                      name="associatedPatient"
                      placeholder=""
                      type="text"
                      tabIndex="-1"
                      readOnly={true}  
                      className={classnames(
                        {
                          "is-invalid":
                            errors.associatedPatient &&
                            touched.associatedPatient
                        },
                        "form-control bg-gray"
                      )}
                    />
                    <Lock className="form_glyph" aria-label="lock" />
                    <ErrorMessage
                      name="associatedPatient"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
                {!user?.isSmartSession && groups.length ? (
                  <div className="form-group">
                    <MySelect
                      name="associatedGroup"
                      label="Associated Group"
                      options={groups}
                      placeholder=""
                      value={values.associatedGroup}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      error={errors.associatedGroup}
                      touched={touched.associatedGroup}
                      fontSize="1rem"
                    />
                    <ErrorMessage
                      name="associatedGroup"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                ) : (
                  <></>
                )}
                <div className="d-flex justify-content-center pt-md-3">
                  <div className={classnames("form-row w-100")}>
                    <div
                      className={classnames(
                        { "col-12 pl-0 pr-0": is1400Width },
                        { "col-6 pr-4 pl-0": !is1400Width },
                        "form-group"
                      )}
                    >
                      <div>
                        <label htmlFor="dateContacted">Date Contacted</label>
                      </div>
                      <div className="primary-underline w-100">
                        <Suspense
                          fallback={
                            <div className="form-control date-time"></div>
                          }
                        >
                          <DatePickerField
                            dateFormat="M/d/yyyy"
                            classnames="form-control date-time"
                            selected={values.dateContacted}
                            placeholderText="M/D/YYYY"
                            onChange={(date) => {
                              setFieldValue("dateContacted", date);
                              !date && setFieldValue("timeContacted", "");
                            }}
                            id="dateContacted"
                          />
                        </Suspense>
                      </div>
                    </div>
                    <div
                      className={classnames(
                        { "col-12 pl-0 pr-0": is1400Width },
                        { "col-6 pl-4 pr-0": !is1400Width },
                        "form-group"
                      )}
                    >
                      <div>
                        <label htmlFor="timeContacted">Time Contacted</label>
                      </div>
                      <div className="primary-underline w-100">
                        <Suspense
                          fallback={
                            <div className="form-control date-time"></div>
                          }
                        >
                          <TimePickerField
                            selected={
                              values.timeContacted ? values.timeContacted : ""
                            }
                            onChange={(date) => {
                              setFieldValue("timeContacted", date);
                            }}
                            timeIntervals={30}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            classnames="form-control date-time"
                            placeholderText="HH:MM AM/PM"
                            id="timeContacted"
                            minTime={
                              // 6:00 AM
                              new Date("1/1/2020 06:00")
                            }
                            maxTime={
                              // 7:00 PM
                              new Date("1/1/2020 19:00")
                            }
                            disabled={!values.dateContacted}
                          />
                        </Suspense>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group pt-6 d-flex flex-column justify-content-center">
                  <button
                    type="submit"
                    data-testid="submit-outreach-button"
                    disabled={isSubmitting || !isValid || !dirty}
                    className="btn btn-primary w-100"
                  >
                    {isSubmitting && (
                      <span className="spinner-border spinner-border-sm mr-1"></span>
                    )}
                    Save Changes
                  </button>
                </div>

                <div className="form-group">
                  {selectedItem ? (
                    <button
                      type="button"
                      data-testid="clear-outreach-button"
                      className={classnames(
                        "btn-link text-secondary btn  w-100"
                      )}
                      onClick={toggleAdd}
                    >
                      Cancel
                    </button>
                  ) : (
                    <button
                      type="button"
                      data-testid="clear-outreach-button"
                      className={classnames(
                        "btn-link text-secondary btn  w-100"
                      )}
                      onClick={resetForm}
                    >
                      Clear Changes
                    </button>
                  )}
                </div>
              </div>
            </Form>
          </FormikValidate>
        );
      }}
    </Formik>
  );
};

OutreachForm.propTypes = {
  patient: PropTypes.object,
  patientId: PropTypes.string,
  groups: PropTypes.array,
  fetchData: PropTypes.func,
  users: PropTypes.array,
  toggleAdd: PropTypes.func,
  selectedItem: PropTypes.object,
  setSelectedItem: PropTypes.func
};

export { OutreachForm };
