import { fetchWrapper } from "../_helpers/index";
export const connectionsService = {
  getRequests,
  getArchivedRequests,
  acceptRequest,
  rejectRequest,
  createConnectionRequestReport
};

const baseUrl = "/api/patients/connection_requests";

function acceptRequest(fields) {
  return fetchWrapper.post(`${baseUrl}/accept`, fields);
}

function rejectRequest(fields) {
  return fetchWrapper.post(`${baseUrl}/reject`, fields);
}

function getRequests(
  searchText,
  pageSize,
  page,
  sortStr,
  showAllConnectionRequests = false
) {
  const url = `${baseUrl}?searchText=${searchText}&pageSize=${pageSize}&page=${page}&SortBy=${sortStr}&showAllConnectionRequests=${showAllConnectionRequests}`;
  return fetchWrapper.get(url, {
    searchText,
    pageSize,
    page
  });
}

function getArchivedRequests(searchText, pageSize, page, sortStr, filterStr) {
  let url = `${baseUrl}/archieved?searchText=${searchText}&pageSize=${pageSize}&page=${page}&SortBy=${sortStr}`;
  if (filterStr) {
    url = `${url}&${filterStr}`;
  }
  return fetchWrapper.get(url, {
    searchText,
    pageSize,
    page
  });
}

function createConnectionRequestReport(
  searchText,
  sortStr,
  showAllConnectionRequests = false
) {
  if (!searchText) searchText = "";

  let url = `${baseUrl}/report?searchText=${searchText}&sortBy=${sortStr}&reportDocumentType=2&showAllConnectionRequests=${showAllConnectionRequests}`;
  return fetchWrapper.post(url);
}
