import { fetchWrapper } from "../_helpers/index";
export const userPatientlistService = {
  getUserPatientlist,
  rearrange,
  subscribe,
  unsubscribe,
  getPatients,
  exportPatientList,
  getListDetails,
  createPatientReport
};

const baseUrl = "/api/userpatientlist";

function getUserPatientlist(subscribed) {
  return fetchWrapper.get(`${baseUrl}?isSubscribed=${subscribed}`);
}

function getListDetails(id) {
  return fetchWrapper.get(`${baseUrl}/${id}`);
}

function rearrange(models) {
  let url = `${baseUrl}/reorder`;
  return fetchWrapper.put(url, models);
}

function subscribe(id) {
  return fetchWrapper.put(`${baseUrl}/${id}/subscribe`);
}

function unsubscribe(id) {
  return fetchWrapper.put(`${baseUrl}/${id}/unsubscribe`);
}

function getPatients(id, searchText, pageSize, pageIndex, sortStr, filter) {
  if (!searchText) searchText = "";
  if (!pageSize) pageSize = 50;
  if (!pageIndex) pageIndex = 1;
  if (!sortStr) sortStr = "overallAdherence.DESC";

  let url = `${baseUrl}/${id}/patients?searchText=${searchText}&pageSize=${pageSize}&page=${pageIndex}&sortBy=${sortStr}`;
  if (filter) {
    url += `&AtRisk=${filter}`;
  }
  return fetchWrapper.get(url);
}

function createPatientReport(id, searchText, sortStr, filter) {
  if (!searchText) searchText = "";

  if (!sortStr) sortStr = "overallAdherence.DESC";

  let url = `${baseUrl}/${id}/patients/report?searchText=${searchText}&sortBy=${sortStr}&reportDocumentType=2`;
  if (filter) {
    url += `&atRisk=${filter}`;
  }
  return fetchWrapper.post(url);
}

function exportPatientList(id, fields) {
  return fetchWrapper.get(`${baseUrl}/${id}/export`, fields);
}
