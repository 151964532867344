import classnames from "classnames";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import * as Yup from "yup";
import { CloseButton, Criteria, MySelect } from "../_components";
import {
  UseMobileMediaQuery,
  UseTabletMediaQuery
} from "../_helpers/media-queries";
import { riskprofileService } from "../_services";

import PropTypes from "prop-types";
import {
  colorfulColorStyles,
  createCriteriaValidationMessage,
  createCriteriaValidatonTest,
  formatFilterValue
} from "../_helpers";
import { criteriaOptions, riskOptions } from "../_helpers/constants";
import { useAlertContext } from "../context/alertContext";

const RiskProfileModal = ({
  fetchData,
  addModal,
  toggleAdd,
  selectedItem,
  setSelectedItem
}) => {
  const { alertMethods } = useAlertContext();
  const [isDeleting, setIsDeleting] = useState(false);
  const mounted = React.useRef(false);
  const is768Width = UseTabletMediaQuery();
  const isMobileWidth = UseMobileMediaQuery();
  const [filteredCriteriaOptions, setFilteredCriteriaOptions] = useState([]);

  useEffect(() => {
    setFilteredCriteriaOptions(
      criteriaOptions.filter((option) => option.value >= 0)
    );
  }, []);

  const [initialValues, setInitialValues] = useState({
    listName: "",
    description: "",
    listCriteria: [
      {
        id: 0,
        andor: { value: 0, label: "AND" },
        criteria: "",
        compare: "",
        choice: ""
      }
    ],
    groups: [],
    severity: ""
  });

  useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => {
      mounted.current = false;
    }; // ... and to false on unmount
  }, []);

  const newListValidationSchema = Yup.object().shape({
    listName: Yup.string().required("List Name is required"),
    description: Yup.string().required("Description is required"),
    severity: Yup.object().shape({
      value: Yup.number().required("Severity is required"),
      label: Yup.string().required("Severity is required")
    }),

    listCriteria: Yup.array()
      .of(
        Yup.object().shape({
          andor: Yup.object().shape({
            value: Yup.number().required("AND/OR is required"),
            label: Yup.string().required("AND/OR is required")
          }),
          criteria: Yup.object().shape({
            value: Yup.number().required("Criteria is required"),
            label: Yup.string().required("Criteria is required")
          }),
          compare: Yup.object().shape({
            value: Yup.number().required("Comparison is required"),
            label: Yup.string().required("Comparison is required")
          }),
          choice: Yup.mixed().test({
            name: "choice-validation",
            message: ({ path, value }) =>
              createCriteriaValidationMessage(value),
            test: (value) => createCriteriaValidatonTest(value)
          })
        })
      )
      .min(1, "At least one criteria is required")
      .required()
  });

  function onDelete() {
    setIsDeleting(true);
    riskprofileService
      .deleteRiskProfile(selectedItem.id)
      .then((res) => {
        alertMethods.success("Risk profile has been deleted");
        setIsDeleting(false);
        toggleAdd();
        fetchData();
      })
      .catch((e) => alertMethods.error(e.message));
  }

  function onAdd(fields, { setSubmitting, resetForm }) {
    setSubmitting(true);
    let formComplete = true;
    let filters = [];
    fields.listCriteria.forEach((item, idx) => {
      filters.push({
        filterOperator: item.andor.value,
        filterType: item.criteria.value,
        operationType: item.compare.value,
        filterValue: formatFilterValue(item.choice),
        seqNumber: item.id + 1
      });
    });

    const body = {
      id: selectedItem?.id || undefined,
      // If we're editing the risk profile, it already has an id
      name: fields.listName,
      description: fields.description,
      filters: filters,
      severity: fields.severity.value
    };
    if (formComplete) {
      if (selectedItem?.id) {
        riskprofileService
          .editRiskProfile(selectedItem.id, body)
          .then((res) => {
            alertMethods.success("Risk profile has been edited");
            setSubmitting(false);
            resetForm();
            toggleAdd();
            fetchData();
            setSelectedItem(undefined);
          })
          .catch((error) => {
            alertMethods.error("Risk profile could not be edited");
            setSubmitting(false);
          });
      } else {
        riskprofileService
          .createRiskProfile(body)
          .then((res) => {
            alertMethods.success("Risk profile has been created");
            setSubmitting(false);
            resetForm();
            toggleAdd();
            fetchData();
            setSelectedItem(undefined);
          })
          .catch((error) => {
            alertMethods.error("Risk profile could not be created");
            setSubmitting(false);
          });
      }
    }
  }

  return (
    <Modal
      isOpen={addModal}
      toggle={toggleAdd}
      className="center-modal scroll-modal riskProfileCriteria"
    >
      <ModalHeader
        toggle={toggleAdd}
        close={<CloseButton toggle={toggleAdd} />}
      >
        {selectedItem?.id ? "Edit Risk Profile" : "New Risk Profile"}
      </ModalHeader>
      <ModalBody>
        <Formik
          initialValues={initialValues}
          validationSchema={newListValidationSchema}
          onSubmit={onAdd}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            isValid,
            dirty,
            setFieldValue,
            setFieldTouched,
            resetForm
          }) => {
            return (
              <Form data-testid="create-new-risk-profile-form" className="form">
                <div>
                  <div className="form-group col-lg-11 col-12 px-0">
                    <label htmlFor="listName">List Name *</label>
                    <Field
                      id="listName"
                      name="listName"
                      type="text"
                      className={classnames(
                        { "is-invalid": errors.listName && touched.listName },
                        "form-control"
                      )}
                    />
                    <ErrorMessage
                      name="listName"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group col-lg-11 col-12 px-0">
                    <label htmlFor="severity">Severity *</label>
                    <MySelect
                      placeholder={"Select Risk Level"}
                      name="severity"
                      options={riskOptions}
                      value={values.severity}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      error={errors.severity}
                      touched={touched.severity}
                      styles={colorfulColorStyles}
                    />
                  </div>
                  <div className="form-group col-lg-11 col-12 px-0">
                    <label htmlFor="description">Description *</label>
                    <Field
                      id="description"
                      name="description"
                      placeholder="​Description of Risk Profile"
                      component="textarea"
                      rows="4"
                      className={classnames(
                        {
                          "is-invalid":
                            errors.description && touched.description
                        },
                        "form-control"
                      )}
                    />
                  </div>

                  <Criteria
                    values={values}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    criteriaOptions={filteredCriteriaOptions}
                    setInitialValues={setInitialValues}
                    selectedItem={selectedItem}
                    errors={errors}
                    touched={touched}
                  />
                  <div className="form-group d-flex flex-lg-row flex-column align-items-center pt-6">
                    <button
                      type="submit"
                      disabled={isSubmitting || !isValid || !dirty}
                      className="btn btn-primary mr-lg-5 mr-0 mb-lg-0 mb-4"
                    >
                      {isSubmitting && (
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                      )}
                      Save Risk Profile
                    </button>
                    {selectedItem?.id ? (
                      <button
                        className="btn btn-danger "
                        type="button"
                        onClick={onDelete}
                      >
                        {isDeleting && (
                          <span className="spinner-border spinner-border-sm mr-1"></span>
                        )}
                        Delete Risk Profile
                      </button>
                    ) : (
                      <button
                        type="button"
                        className={classnames(
                          {
                            "btn-outline-primary": !is768Width && !isMobileWidth
                          },
                          {
                            "btn-link text-secondary":
                              is768Width || isMobileWidth
                          },
                          "btn mr-lg-5 mr-0 mb-lg-0 mb-4"
                        )}
                        onClick={resetForm}
                      >
                        Clear Changes
                      </button>
                    )}
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

RiskProfileModal.propTypes = {
  fetchData: PropTypes.func,
  addModal: PropTypes.bool,
  toggleAdd: PropTypes.func,
  selectedItem: PropTypes.object,
  setSelectedItem: PropTypes.func
};

export { RiskProfileModal };
