import React from "react";
import { EmailTooltip } from "../../../_components";
import PropTypes from "prop-types";

export const TooltipCell = ({ value, row }) => {
  return value?.length > 30 ? (
    <EmailTooltip email={value} id={row.id} maxLength={30} />
  ) : (
    <>{value}</> 
  );
};

TooltipCell.propTypes = {
  value: PropTypes.string,
  row: PropTypes.object,
};
