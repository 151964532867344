import classnames from "classnames";
import React from "react";
import { CustomInput } from "reactstrap";
import { MySelectFilter } from ".";
import { UseMobileMediaQuery } from "../_helpers/media-queries";
import {
  clearableFilterColourStyles,
  mobileMedFilterColourStyles
} from "../_helpers/select-styles";
import PropTypes from "prop-types";

const MedicationSelector = ({
  medSelectFilterOptions,
  handleMedSelectFilterChange,
  isAllMedications,
  handleSelectAllRadioChange,
  availableMedications,
  loadingAdhrence,
  loadingMedications,
  handleMedClick,
  selectedMedicationIds
}) => {
  return (
    <div className="card bg-white">
      <div className="card-header">Medication Selector</div>
      <div className="card-body ">
        {loadingAdhrence && loadingMedications ? (
          <div className="ph-item border-0" data-testid="loading-adh">
            <div className="ph-col-12">
              <div className="ph-row">
                <div className="ph-col-5 fifteenpx"></div>
                <div className="ph-col-real3 empty"></div>
                <div className="ph-col-4 fifteenpx"></div>
              </div>
              <div className="ph-row">
                <div className="ph-col-real3 fifteenpx"></div>
                <div className="ph-col-real3 empty"></div>
                <div className="ph-col-6 fifteenpx"></div>
              </div>
              <div className="ph-row">
                <div className="ph-col-5 fifteenpx"></div>
                <div className="ph-col-real3 empty"></div>
                <div className="ph-col-4 fifteenpx"></div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="d-flex">
              <div className="form-group col-6 mb-4 pl-0 pt-0">
                <MySelectFilter
                  name="medSelectFilter"
                  options={medSelectFilterOptions}
                  defaultValue={medSelectFilterOptions[0]}
                  onChange={handleMedSelectFilterChange}
                ></MySelectFilter>
              </div>
              <div className="form-group mt-1 mb-0 col-4 pr-0 pt-0 ml-auto">
                <CustomInput
                  id="medSelectAll"
                  type="radio"
                  label="Select All"
                  inline={true}
                  checked={isAllMedications}
                  onChange={handleSelectAllRadioChange}
                />
              </div>
            </div>
            {!availableMedications?.length ? (
              <p>No medications available</p>
            ) : (
              availableMedications.map((medication) => {
                return (
                  <button
                    key={medication.id}
                    id={medication.id}
                    onClick={() => {
                      const btn = document.getElementById(medication.id);
                      btn.blur();
                      handleMedClick(medication.id);
                    }}
                    className={classnames(
                      {
                        "btn-primary":
                          selectedMedicationIds.includes(medication.id) &&
                          !isAllMedications
                      },
                      {
                        "btn-outline-secondary":
                          !selectedMedicationIds.includes(medication.id) ||
                          isAllMedications
                      },
                      "adh-btn btn m-1 btn-small break-word"
                    )}
                  >
                    {medication.medicationName}
                    {medication.medicationStrength?.strength ||
                    medication.medicationStrength?.form
                      ? ", "
                      : ""}
                    {medication.medicationStrength?.strength}{" "}
                    {medication.medicationStrength?.form}
                  </button>
                );
              })
            )}
          </div>
        )}
      </div>
    </div>
  );
};

MedicationSelector.propTypes = {
  medSelectFilterOptions: PropTypes.array,
  handleMedSelectFilterChange: PropTypes.func,
  isAllMedications: PropTypes.bool,
  handleSelectAllRadioChange: PropTypes.func,
  availableMedications: PropTypes.array,
  loadingAdhrence: PropTypes.bool,
  loadingMedications: PropTypes.bool,
  handleMedClick: PropTypes.func,
  selectedMedicationIds: PropTypes.array
};

const MedDropdown = ({
  isAllMedications,
  handleSelectAllRadioChange,
  availableMedications,
  placeholder,
  handleFilterChange,
  selected,
  isMulti,
  isClearable
}) => {
  const isMobileWidth = UseMobileMediaQuery();

  return (
      <MySelectFilter
        styles={
          isMobileWidth
            ? mobileMedFilterColourStyles
            : clearableFilterColourStyles
        }
        name="medSelectFilter"
        options={availableMedications.map((med) => ({
          value: med.id,
          label: med.medicationName
        }))}
        defaultValue={selected?.length ? selected : isAllMedications}
        onChange={(val, item, current) => {
          if (!item) {
            handleSelectAllRadioChange();
          } else {
            handleFilterChange(item);
          }
        }}
        placeholder={placeholder || "All Meds"}
        multi={isMulti !== false}
        isClearable={isClearable}
      ></MySelectFilter>
  );
};

MedDropdown.propTypes = {
  isAllMedications: PropTypes.bool,
  handleSelectAllRadioChange: PropTypes.func,
  availableMedications: PropTypes.array,
  placeholder: PropTypes.string,
  handleFilterChange: PropTypes.func,
  selected: PropTypes.array,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool
};

export { MedicationSelector, MedDropdown };
