import { fetchWrapper } from "../_helpers/index";
export const patientlistService = {
  getPatientlist,
  addPatientlist,
  editPatientlist,
  deletePatientlist
};

const baseUrl = "/api/patientlist";

function getPatientlist() {
  return fetchWrapper.get(baseUrl);
}

function addPatientlist(fields) {
  return fetchWrapper.post(`${baseUrl}/create`, fields);
}

function editPatientlist(id, fields) {
  return fetchWrapper.put(`${baseUrl}/${id}`, fields);
}

function deletePatientlist(id) {
  return fetchWrapper.delete(`${baseUrl}/${id}`);
}
