import React from "react";
import { year } from "../_helpers";
import { ReactComponent as Exclamation } from "../images/circle-exclaimation.svg";
import { Link } from "react-router-dom";
const PatientNotFound = (props) => {
  return (
    <div
      id="access-denied"
      className="col-6 offset-3 col-lg-4 offset-lg-4 d-flex flex-column justify-content-between"
    >
      <div className="d-flex">
        <Exclamation
          className="mr-2 glyph-gray h-2rem w-4-5rem"
          aria-label="exclamation"
        />
        <div>
          <h4>Patient Not Found</h4>
          <h6 className="mb-4">Error 400: Not Found</h6>
          <p>
            This patient's account has been deleted. For more details contact
            your system administrator. If you want to invite this patient to
            EveryDose, relaunch the EveryDose app from your EHR.
          </p>
        </div>
      </div>

      <div className="text-secondary text-center">
        <Link to="/support" className="text-primary">
          Contact Support
        </Link>
        <div className="pt-4">© {year} EveryDose. All rights reserved. </div>
      </div>
    </div>
  );
};

export { PatientNotFound };
