import { Auth0Provider } from "@auth0/auth0-react";
import classnames from "classnames";
import React, {
  lazy,
  Suspense,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate
} from "react-router-dom";
import {
  AccessDenied,
  Auth0Authentication,
  Banner,
  BlockedUser,
  IdleLogout,
  Loader,
  MyNav,
  RequireAuth,
  SmartNav
} from "./_components";
import { adjust, clearSmartTokens, getRGB, permissionsEnum } from "./_helpers";
import { accountService, adminService, smartService } from "./_services";
import { useAlertContext } from "./context/alertContext";
import { useAuthContext } from "./context/auth0Context";
import { SmartContextProvider } from "./context/smartContext";
import { ThemeContext } from "./context/themeContext";
import { useUserContext } from "./context/userContext";
import "./sass/custom.scss";
import { AcceptTerms, SmartSupport, Workflow } from "./smart";
import { GoLive } from "./smart/GoLive";

const Admin = lazy(() => import("./admin/Admin"));
const Account = lazy(() => import("./account/Account"));
const Profile = lazy(() => import("./profile/Profile"));
const Home = lazy(() => import("./home/Home"));
const Invitations = lazy(() => import("./invitations/Invitations"));
const SmartRoutes = lazy(() => import("./smart/SmartRoutes"));
const Resources = lazy(() => import("./resources/Resources"));

function App() {
  const { alertMethods } = useAlertContext();
  window.addEventListener("storage", (event) => {
    const credentials =
      localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));
    if (event.key === "REQUESTING_SHARED_CREDENTIALS" && credentials) {
      localStorage.setItem(
        "CREDENTIALS_SHARING",
        JSON.stringify({ token: credentials.name })
      );
      localStorage.removeItem("CREDENTIALS_SHARING");
    }
    if (event.key === "CREDENTIALS_SHARING" && !credentials && event.newValue) {
      sessionStorage.setItem("CREDENTIALS_TOKEN", event.newValue);
    }
    if (event.key === "CREDENTIALS_FLUSH" && credentials) {
      window.sessionStorage.removeItem("user");
      sessionStorage.removeItem("CREDENTIALS_TOKEN");
      window.location.reload();
    }
    if (event.key === "LOGIN" && !credentials) {
      window.location.reload();
    }
  });

  const favicon = document.getElementById("favicon");
  const { pathname } = useLocation();
  const [banner, setBanner] = useState(false);

  const navigate = useNavigate();

  const navigateRef = useRef();

  useEffect(() => {
    const loadDatadog = async () => {
      const { initializeDatadogRum } = await import("./datadog");
      initializeDatadogRum();
    };

    loadDatadog();
  }, []);

  useEffect(() => {
    navigateRef.current = navigate;
  }, [navigate]);
  const [checkedValue, setCheckedValue] = useState(1);
  const [refreshTokenTrigger, setRefreshTokenTrigger] = useState(false);
  const [showTermsPage, setShowTermsPage] = useState(false);

  const { theme, toggleTheme } = useContext(ThemeContext);
  const { shouldUseAuth0, updateShouldUseAuth0 } = useAuthContext();

  const {
    userState,
    updateUser,
    updatePermissions,
    updateTenantFeatures,
    setBrandLogo,
    setBrandFavicon,
    setProfilePicture
  } = useUserContext();
  const {
    user,
    userPermissions,
    tenantFeatures,
    brandLogo,
    brandFavicon,
    profilePicture
  } = userState;

  useEffect(() => {
    window.localStorage.setItem(
      "REQUESTING_SHARED_CREDENTIALS",
      Date.now().toString()
    );
    window.localStorage.removeItem("REQUESTING_SHARED_CREDENTIALS");
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("failedLogin")) {
      alertMethods.error("User failed to authenticate. Please try again.");
      sessionStorage.removeItem("failedLogin");
    }
  }, [alertMethods]);

  useEffect(() => {
    if (!user && localStorage.getItem("smartToken")) {
      updateShouldUseAuth0(false);
      accountService
        .getProfile()
        .then((user) => {
          const sessionUser = { ...user, isSmartSession: true };
          updateUser(sessionUser);
        })
        .catch((e) => {
          console.log(e);
        });
      accountService
        .getPermissions()
        .then((permissions) => {
          updatePermissions(permissions);
        })
        .catch((e) => {
          console.log(e);
        });
      accountService
        .getTenantFeatures()
        .then((tenantFeatures) => {
          updateTenantFeatures(tenantFeatures);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [
    updateUser,
    updatePermissions,
    updateTenantFeatures,
    user,
    updateShouldUseAuth0
  ]);

  useEffect(() => {
    let mounted = true;

    if (
      user?.tenantsPreference &&
      mounted &&
      user?.tenantsPreference.brandColor?.indexOf("#") > -1 &&
      user?.tenantsPreference.brandColor !== "#0078c8"
    ) {
      const root = document.documentElement;
      root.style.setProperty("--primary", user?.tenantsPreference.brandColor);
      root.style.setProperty(
        "--dark-primary",
        adjust(user?.tenantsPreference.brandColor, -40)
      );
      root.style.setProperty(
        "--light-primary",
        adjust(user?.tenantsPreference.brandColor, 50)
      );
      root.style.setProperty(
        "--extra-light-primary",
        adjust(user?.tenantsPreference.brandColor, 90)
      );
      root.style.setProperty(
        "--rgb-primary",
        getRGB(user?.tenantsPreference.brandColor)
      );
    } else {
      const root = document.documentElement;
      root.style.setProperty("--primary", "#0078c8");
      root.style.setProperty("--dark-primary", adjust("#0078c8", -40));
      root.style.setProperty("--rgb-primary", getRGB("#0078c8"));
      root.style.setProperty("--light-primary", "#1da1f2");
      root.style.setProperty("--extra-light-primary", "#4fc0e7");
    }
    if (user?.tenantsPreference.appPrimaryColor) {
      const root = document.documentElement;
      root.style.setProperty(
        "--app-primary",
        user?.tenantsPreference.appPrimaryColor
      );
    } else {
      const root = document.documentElement;
      root.style.setProperty("--app-primary", "#0078c8");
    }
    return function cleanup() {
      mounted = false;
    };
  }, [user]);

  useEffect(() => {
    if (user?.isSmartSession === false) {
      adminService
        .getTenantImage(0)
        .then((res) => {
          if (res) {
            const url = window.URL.createObjectURL(res);
            favicon.href = url;
            setBrandFavicon(url);
          }
        })
        .catch((e) => {
          setBrandFavicon(null);
        });
    }
  }, [favicon, setBrandFavicon, user?.isSmartSession]);

  useEffect(() => {
    if (user?.isSmartSession === false) {
      adminService
        .getTenantImage(1)
        .then((res) => {
          if (res) {
            const url = window.URL.createObjectURL(res);
            setBrandLogo(url);
          }
        })
        .catch((e) => {
          setBrandLogo(null);
        });
    }
  }, [setBrandLogo, user?.isSmartSession]);

  useEffect(() => {
    if (user?.isSmartSession === false) {
      accountService
        .getProfilePicture()
        .then((res) => {
          if (res) {
            const url = window.URL.createObjectURL(res);
            setProfilePicture(url);
          }
        })
        .catch((e) => {
          setProfilePicture(null);
        });
    }
  }, [setProfilePicture, user?.isSmartSession]);

  document.body.addEventListener(
    "wheel",
    (e) => {
      if (e.ctrlKey && user?.isSmartSession) e.preventDefault(); //prevent zoom
    },
    { passive: false }
  );

  const getSmartRefreshToken = React.useCallback(
    (refreshToken) => {
      smartService
        .getSmartToken(refreshToken)
        .then((response) => {
          localStorage.setItem("smartToken", response.accessToken);
          localStorage.setItem("refreshToken", response.smartRefreshToken);
          localStorage.setItem(
            "refreshTokenExpiresAt",
            response.smartTokenExpiresAt
          );
          //  allow time for local storage to update before triggering another refresh

          setTimeout(() => {
            setRefreshTokenTrigger((prev) => !prev);
          }, 5000);
        })
        .catch((e) => {
          clearSmartTokens();
          navigate("/smart/404");
        });
    },
    [navigate]
  );
  // get smartTokenExpiresAt from localStorage, calculate the difference between now and smartTokenExpiresAt, if the difference is less than 2 minutes, refresh the token
  useEffect(() => {
    let timeoutRef;

    const refreshTokenIfNeeded = () => {
      if (localStorage.getItem("smartToken")) {
        const smartTokenExpiresAt = localStorage.getItem(
          "refreshTokenExpiresAt"
        );
        const refreshToken = localStorage.getItem("refreshToken");
        const smartToken = localStorage.getItem("smartToken");
        const smartTokenExpiresAtDate = new Date(smartTokenExpiresAt);

        if (smartToken === "undefined") {
          clearSmartTokens();
          navigateRef.current("/smart/404");
        } else {
          const now = new Date();
          let diff = smartTokenExpiresAtDate - now;
          // setTimeout to refresh the token 2 minutes before it expires
          if (diff > 120000 && refreshToken && smartToken) {
            timeoutRef = setTimeout(() => {
              getSmartRefreshToken(refreshToken);
            }, diff - 120000);
          } else if (diff < 120000 && refreshToken && smartToken) {
            getSmartRefreshToken(refreshToken);
          }
        }
      }
    };

    refreshTokenIfNeeded();

    // Clear timeout on unmount or when token is refreshed
    return () => {
      clearTimeout(timeoutRef);
    };
  }, [getSmartRefreshToken, refreshTokenTrigger]);

  useEffect(() => {
    const body = document.getElementsByTagName("body")[0];
    if (user) {
      const { fontSize, isColorContrastEnabled } = user;
      setCheckedValue(fontSize > 3 ? 2 : 1);

      if (isColorContrastEnabled) {
        toggleTheme("high-contrast");
      }
      if (user?.isSmartSession) {
        body.classList.add("smart-theme");
      } else {
        body.classList.remove("smart-theme");
      }
    }
  }, [user, toggleTheme]);

  useEffect(() => {
    const body = document.getElementsByTagName("body")[0];
    if (checkedValue === 2) {
      body.classList.add("large-font-theme");
    } else {
      body.classList.remove("large-font-theme");
    }
    if (theme === "high-contrast") {
      body.classList.add("accessible-theme");
    } else {
      body.classList.remove("accessible-theme");
    }
  }, [checkedValue, theme, user]);

  return (
    <>
      {shouldUseAuth0 ? (
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
          useRefreshTokens={true}
          cacheLocation="localstorage"
          authorizationParams={{
            redirect_uri: window.location.origin,
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: "openid profile email offline_access"
          }}
        >
          <div className={classnames("app-container", {})}>
            <Auth0Authentication setShowTermsPage={setShowTermsPage} />
            <IdleLogout />
            {showTermsPage ? (
              <AcceptTerms setShowTermsPage={setShowTermsPage} />
            ) : (
              <>
                <Banner banner={banner} showBanner={setBanner} />
                {user?.name && !user.isSmartSession && (
                  <MyNav
                    user={user}
                    userPermissions={userPermissions}
                    tenantFeatures={tenantFeatures}
                    brandLogo={brandLogo}
                    brandFavicon={brandFavicon}
                    profilePicture={profilePicture}
                  />
                )}
                <Suspense fallback={<Loader />}>
                  <Routes>
                    <Route
                      path="/account/*"
                      element={<Account />}
                      name="Account"
                    />
                    <Route
                      path="/admin/*"
                      element={
                        <RequireAuth>
                          <Admin />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/invite/*"
                      element={
                        <RequireAuth>
                          <Invitations />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/*"
                      element={
                        <RequireAuth>
                          <Home />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/resources/*"
                      element={
                        <RequireAuth
                          featureNo={permissionsEnum.UserResource}
                          name="Resources"
                        >
                          <Resources />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/profile/*"
                      element={
                        <RequireAuth>
                          <Profile
                            checkedValue={checkedValue}
                            setIsChecked={setCheckedValue}
                          />
                        </RequireAuth>
                      }
                    />

                    <Route
                      path="/support"
                      element={<SmartSupport publicVersion={true} />}
                    />

                    <Route
                      path="/:url*(/+)"
                      element={<Navigate to={pathname.slice(0, -1)} />}
                    />
                    {/* <Route path="/workflow/*" element={<Workflow />} /> */}
                    <Route path="/403" element={<AccessDenied />} />
                    <Route path="/401" element={<BlockedUser />} />
                    <Route path="go-live" element={<GoLive />} />

                    <Route path="*" element={<Navigate to="/" />} />
                    <Route path="/accept-terms" element={<AcceptTerms />} />
                  </Routes>
                </Suspense>
              </>
            )}
          </div>
        </Auth0Provider>
      ) : (
        <div className="app-container">
          <SmartContextProvider>
            <Banner banner={banner} showBanner={setBanner} />
            <SmartNav />

            <Suspense fallback={<Loader />}>
              <Routes>
                <Route path="/smart/*" element={<SmartRoutes />} />
                <Route
                  path="/support"
                  element={<SmartSupport publicVersion={true} />}
                />
                {/* <Route path="/403" element={<AccessDenied />} /> */}
                {/* <Route path="/400" element={<PatientNotFound />} /> */}
                <Route path="*" element={<Navigate to="/smart/403" />} />
              </Routes>
            </Suspense>
          </SmartContextProvider>
        </div>
      )}
    </>
  );
}

export default App;
