import "cerner-smart-embeddable-lib";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import React, { useCallback, useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Progress
} from "reactstrap";
import {
  AlertTypeModal,
  CernerDateSelect,
  CustomMedPopover,
  DDITableExpandable,
  MedDropdown,
  MyPopover,
  ReviewSidebar
} from "../_components";
import {
  convertUTCTimeToLocalTime,
  isIE,
  isPRN,
  isValidDate,
  specificTimeOptions,
  timeOptions
} from "../_helpers";
import { patientService } from "../_services";
import {
  AlertModal,
  BulkActions
} from "../components/patient/medListComponents";
import { useSmartContext } from "../context/smartContext";
import { useUserContext } from "../context/userContext";
import { ReactComponent as Caution } from "../images/caution.svg";
import { ReactComponent as ChevronRight } from "../images/chevron_right.svg";
import { ReactComponent as Calendar } from "../images/glyph-calendar.svg";
import { ReactComponent as GlyphSkip } from "../images/glyph-skip.svg";
import { ReactComponent as GlyphCheck } from "../images/glyph_check.svg";
import { ReactComponent as Menu } from "../images/glyph_menu.svg";
import { ReactComponent as GlyphX } from "../images/glyph_x.svg";
import { CernerTable } from "./CernerTable";
import PropTypes from "prop-types";

dayjs.extend(utc);

const IndeterminateCheckbox = React.forwardRef(
  ({ paddingBottom, indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <div>
        <label className={`g-table-checkbox ${paddingBottom}`}>
          <input
            type="checkbox"
            ref={resolvedRef}
            {...rest}
            data-testid="checkbox"
          />
          <span className="checkmark"></span>
        </label>
      </div>
    );
  }
);

//Bulk action dropdown which shows and hides the multi select column
const BulkAction = ({
  setMultiSelect,
  setDeleteBulkAlerts,
  setBulkAlertType,
  setSelectedRows,
  toggleModal
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen((prevState) => !prevState);

  return (
    <div className="ml-auto">
      <Dropdown isOpen={isOpen} toggle={toggle} className="export">
        <DropdownToggle tag="a" className="nav-link p-2" tabIndex="0">
          <Menu aria-label="menu" className="glyph-dark w-3rem" />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem
            onClick={() => {
              setSelectedRows([]);
              setMultiSelect(false);
              setBulkAlertType(null);
              toggleModal();
            }}
          >
            Set Bulk Alerts
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              setMultiSelect(true);
              setBulkAlertType(null);
              setDeleteBulkAlerts(true);
            }}
          >
            Delete Bulk Alerts
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};
BulkAction.propTypes = {
  setMultiSelect: PropTypes.func,
  setDeleteBulkAlerts: PropTypes.func,
  setBulkAlertType: PropTypes.func,
  setSelectedRows: PropTypes.func,
  toggleModal: PropTypes.func
};

const MedHistory = ({
  events,
  selectedMedicationIds,
  setSelectedEvent,
  setSidebarType
}) => {
  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setSidebarType("event-history");
  };

  return (
    <div className="pr-5 min-height-70vh">
      {/* Month list view for all meds */}
      {!selectedMedicationIds.length && events.length ? (
        <>
          {events.map((event) => (
            <div
              key={event.id}
              className="d-flex align-items-center custom-event"
            >
              <b className="w-3-5rem">{dayjs(event.date).format("MMM D")}</b>
              <div className="mx-3 my-3 bg-bg workflow-style"></div>
              <b className="white-space-prewrap">{event.title}</b>
              <button
                onClick={() => handleEventClick(event)}
                className="btn btn-link ml-auto"
              >
                <ChevronRight aria-label="chevron" className="glyph-dark" />
              </button>
            </div>
          ))}
        </>
      ) : (
        <div>No results match the filters</div>
      )}
      {/* // List view for single med */}
      {selectedMedicationIds.length && events.length ? (
        <>
          {events.map((event) => (
            <div
              key={event.id}
              className="d-flex align-items-center custom-event"
            >
              <b className="w-3-5rem white-space-nowrap">
                {dayjs(event.date).format("MMM D")}
              </b>
              <div className="mx-3 my-3 bg-bg workflow-style"></div>
              <b className="white-space-prewrap">{event.title}</b>
              <div className="d-flex align-items-center flex-wrap justify-content-end ml-auto">
                {event.extendedProps.times?.map((time, idx) => (
                  <div key={idx}>
                    {time.isTaken === 1 && (
                      <div className="white-space-nowrap">
                        <GlyphCheck
                          className="glyph-success m-2"
                          aria-label="check"
                        />
                        <span>{time.time}</span>
                      </div>
                    )}{" "}
                    {time.isTaken === 0 && (
                      <div className="white-space-nowrap">
                        <GlyphX className="glyph-danger m-2" aria-label="x" />
                        <span>{time.time}</span>
                      </div>
                    )}{" "}
                    {time.isTaken === 2 && (
                      <div className="white-space-nowrap">
                        <GlyphSkip
                          className="glyph-warning-stroke m-2"
                          aria-label="skip"
                        />
                        <span>{time.time}</span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </>
      ) : (
        <></>
      )}
      {!selectedMedicationIds.length && !events.length ? (
        <div className="px-5 d-flex flex-column justify-content-center align-items-center mt-6 mt-xl-0  py-6">
          <h4 className="text-secondary text-center">Nothing to see here!</h4>
          <h6 className="text-secondary text-center">
            No medication history is available for this month
          </h6>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
MedHistory.propTypes = {
  events: PropTypes.array,
  selectedMedicationIds: PropTypes.array,
  setSelectedEvent: PropTypes.func,
  setSidebarType: PropTypes.func
};

const MedNameCell = ({ value }) => {
  return <div className="">{value}</div>;
};
MedNameCell.propTypes = {
  value: PropTypes.string
};

const GlyphCell = ({
  row,
  multiSelect,
  setSidebarType,
  toggleSidebar,
  DDIs,
  bulkAlertType,
  deleteBulkAlerts
}) => {
  const [selectable, setSelectable] = useState(false);
  useEffect(() => {
    if (
      (isPRN(row.original.medicationSchedule) && bulkAlertType === "taken") ||
      (!isPRN(row.original.medicationSchedule) && bulkAlertType === "missed") ||
      (deleteBulkAlerts && row.original.alert?.alertScheduleType > -1)
    ) {
      setSelectable(true);
    } else setSelectable(false);
  }, [bulkAlertType, deleteBulkAlerts, row.original]);
  return (
    <div>
      {multiSelect && selectable ? (
        <div className="text-center">
          <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
        </div>
      ) : (
        <div className="text-center">
          {row.original.isCustomMedication && (
            <div>
              <div>
                <CustomMedPopover id={`workflow-${row.id}`} />
              </div>
            </div>
          )}
          {row.original.isInteractionPresent &&
            !row.original.isCustomMedication && (
              <div>
                <div>
                  <Caution
                    aria-label="caution"
                    className="glyph-danger w-18px"
                    href="#"
                    id={`workflow-${row.id}`}
                  />
                  <MyPopover
                    accept={
                      <button
                        className="btn btn-link pl-0"
                        onClick={() => {
                          setSidebarType("ddi");
                          toggleSidebar(true);
                        }}
                      >
                        View Details
                      </button>
                    }
                    target={`workflow-${row.id}`}
                    title="Major Interaction"
                    text={`${DDIs} major interaction(s) total`}
                  />
                </div>
              </div>
            )}
        </div>
      )}
    </div>
  );
};
GlyphCell.propTypes = {
  row: PropTypes.object,
  multiSelect: PropTypes.bool,
  setSidebarType: PropTypes.func,
  toggleSidebar: PropTypes.func,
  DDIs: PropTypes.number,
  bulkAlertType: PropTypes.string,
  deleteBulkAlerts: PropTypes.bool
};

const TimeCell = ({ value, isPRN }) => {
  if (isPRN) return <div>PRN</div>;

  if (value?.length) {
    // Create a Set to store unique time values
    let uniqueTimesSet = new Set();

    // Filter the value array to include only unique time values
    const uniqueTimesArray = value.filter((time) => {
      if (time && time.time && !uniqueTimesSet.has(time.time)) {
        uniqueTimesSet.add(time.time);
        return true; // Include this time in the filtered array
      }
      return false; // Exclude this time from the filtered array
    });

    return uniqueTimesArray.map((time, i) => {
      const formattedTime =
        // remove leading 0 from time.timeString
        time.timeString.replace(/^0+/, "");

      return (
        <span key={i}>
          Take{time.quantityPerDose} at <span>{formattedTime}</span>
          {i < uniqueTimesArray.length - 1 && ", "}
        </span>
      );
    });
  } else return <div>---</div>;
};
TimeCell.propTypes = {
  value: PropTypes.array,
  isPRN: PropTypes.bool
};

const AdherenceCell = ({ value, findColor }) => {
  return (
    <>
      {value || value === 0 ? (
        <>
          <label className="text-left" id="progressbar1">
            {Math.round(value)}%{" "}
          </label>
          <Progress
            aria-label="progressbar"
            barAriaValueText={"progressbar " + value + "%"}
            barAriaLabelledBy={"progressbar1"}
            value={value}
            color={findColor(value)}
          />
        </>
      ) : (
        <>
          <div>N/A</div>
        </>
      )}
    </>
  );
};
AdherenceCell.propTypes = {
  value: PropTypes.number,
  findColor: PropTypes.func
};

const MedicationCell = ({ multiSelect, getToggleAllRowsSelectedProps }) => {
  if (!multiSelect) {
    return null;
  }

  return (
    <span>
      <IndeterminateCheckbox
        paddingBottom="mb-3"
        id="select-all"
        {...getToggleAllRowsSelectedProps()}
      />
    </span>
  );
};
MedicationCell.propTypes = {
  multiSelect: PropTypes.bool,
  getToggleAllRowsSelectedProps: PropTypes.func
};

const Workflow = (props) => {
  const { patientId } = useSmartContext();
  // const patientId = 419;
  // Loading med table state
  const [meds, setMedications] = useState([]);
  const [loadingMedications, setLoadingMedications] = useState(false);
  const [medlistLength, setMedlistLength] = useState(0);
  const [DDIs, setDDIs] = useState(0);
  const [alertModal, setAlertModal] = useState(false);

  // Sidebar State
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
  const [scrollPosition, setScrollPosition] = useState(document.body.scrollTop);
  const [clinicalInteractions, setClinicalInteractions] = React.useState([]);
  const [sidebarType, setSidebarType] = useState("");

  //For the calendar view
  const [totalMeds, setTotalMeds] = useState(0);
  const [taken, setTaken] = useState(0);
  const [tempDate, setTempDate] = useState(false);
  const [loadingCalendar, setLoadingCalendar] = useState(false);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [startDay, setStartDay] = useState(
    dayjs().startOf("month").format("YYYY-MM-DD")
  );
  const [endDay, setEndDay] = useState(dayjs().format("YYYY-MM-DD"));
  const [selectedMedicationIds, setSelectedMedicationIds] = useState([]);
  const [selectedMed, setSelectedMed] = useState([]);
  const [activeOption, setActiveOption] = useState("Last 7 Days");
  // Alert related state
  const [selectedRow, setSelectedRow] = useState({});
  const [multiSelect, setMultiSelect] = useState(false);
  const [alertsLoading, setAlertsLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [bulkUpdate, setBulkUpdate] = useState(false);
  const [deleteBulkAlerts, setDeleteBulkAlerts] = useState(false);
  const [bulkAlertType, setBulkAlertType] = useState(null);
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };
  //These are for the date filter
  const [range, setRange] = useState({
    start: dayjs().utc().subtract(6, "days").startOf("day").format(),
    end: dayjs().utc().format()
  });

  const { userState } = useUserContext();
  const { user } = userState;
  const mounted = React.useRef(false);

  const toggleAlertModal = React.useCallback(() => {
    setAlertModal(!alertModal);
  }, [alertModal]);

  useEffect(() => {
    if (isSidebarOpen) {
      document.body.style.height = "100%";
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "";
      document.body.style.height = "";
    }
  }, [isSidebarOpen]);

  useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => {
      mounted.current = false;
    }; // ... and to false on unmount
  }, []);
  useEffect(() => {
    if (!multiSelect || deleteBulkAlerts) {
      setBulkAlertType(null);
    }
  }, [multiSelect, deleteBulkAlerts]);
  const toggleSidebar = React.useCallback((arg) => {
    setScrollPosition(document.body.scrollTop);
    return setIsSidebarOpen(arg);
  }, []);

  const fetchMedications = React.useCallback(() => {
    setLoadingMedications(true);
    setMedications([]);
    if (patientId)
      patientService
        .getMedications(patientId)
        .then((res) => {
          if (mounted.current) {
            window.CernerSmartEmbeddableLib.calcFrameHeight = function () {
              if (res.length * 75 > 350) return res.length * 75;
              else return 350;
            };
            // Get adherence data for the table, add that info to the medication data
            patientService
              .getAdhrence(patientId, range.start, range.end)
              .then((res2) => {
                res.forEach((med) => {
                  let match = res2.find((item) => item.id === med.id);

                  med.overallAdherencePercentage =
                    match?.overallAdherencePercentage;
                });
              })
              .catch((e) => {
                console.log(e);
              });
            patientService
              .getClinicalInteractions(patientId)
              .then((res3) => {
                if (mounted) {
                  res.forEach((med) => {
                    let match = res3.find(
                      (item) => item.drugName.includes(med.medicationName)
                      // item.drugIdentifier1 === med.drugIdentifier ||
                      // item.drugIdentifier2 === med.drugIdentifier
                    );
                    if (match) {
                      med.isInteractionPresent = true;
                    } else med.isInteractionPresent = false;
                  });
                  setDDIs(res3.length);
                  setClinicalInteractions(res3);
                  setAlertsLoading(false);
                  setLoadingMedications(false);
                  setMedlistLength(res.length);

                  setMedications(res.filter((item) => item.status === 0));
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        })
        .catch((e) => {
          console.log(e);
        });
  }, [patientId, range]);

  const createTitle = (takenMeds, allMeds, filterMeds, hEvents) => {
    if (!filterMeds.length) {
      if (takenMeds === 0) {
        return "No Meds Taken";
      } else if (takenMeds === allMeds) {
        return "All Meds Taken";
      } else {
        return `${takenMeds}/${allMeds} Meds Taken`;
      }
    } else {
      return hEvents[0].medicationName + ", " + hEvents[0].data.strength;
    }
  };
  const StartEndCell = ({ row }) => {
    return (
      <div>
        {isValidDate(row.medicationSchedule.startDate) ? (
          <span>
            {dayjs(row.medicationSchedule.startDate).format("M/D/YY")}
          </span>
        ) : (
          <>N/A</>
        )}
        -{" "}
        {row.medicationSchedule.endDate &&
          isValidDate(row.medicationSchedule.endDate) && (
            <span>
              {dayjs(row.medicationSchedule.endDate).format("M/D/YY")}
            </span>
          )}
      </div>
    );
  };
  const createMedHistoryEvents = React.useCallback(
    (medHistoryEvents, allEvents, filterMeds) => {
      let count = 0;
      for (const [date, hEvents] of Object.entries(medHistoryEvents)) {
        const allMeds = hEvents.filter(
          (event) => event.data.scheduledAt
        ).length;
        let takenMeds = 0;
        // eslint-disable-next-line no-loop-func
        hEvents.forEach((hEvent) => {
          if (hEvent.data.isTaken === 1 && hEvent.data.scheduledAt) {
            count++;
            takenMeds++;
          }
        });

        if (takenMeds === 0) {
          let title = createTitle(takenMeds, allMeds, filterMeds, hEvents);

          allEvents.push({
            id: new Date(date).toDateString(),
            title: title,
            date: date,
            allDay: true,
            extendedProps: {
              history: hEvents,
              type: "HISTORY_EVENT",
              times: hEvents.map((event) => {
                return {
                  isTaken: event.data.isTaken,
                  time: event.data.scheduledAtString
                };
              })
            }
          });
        } else if (takenMeds === allMeds) {
          let title = createTitle(takenMeds, allMeds, filterMeds, hEvents);
          allEvents.push({
            id: new Date(date).toDateString(),
            title: title,
            date: date,
            allDay: true,
            extendedProps: {
              history: hEvents,
              type: "HISTORY_EVENT",
              times: hEvents.map((event) => {
                return {
                  isTaken: event.data.isTaken,
                  time: event.data.takenAtString
                };
              })
            }
          });
        } else {
          let title = createTitle(takenMeds, allMeds, filterMeds, hEvents);
          allEvents.push({
            id: new Date(date).toDateString(),
            title: title,
            date: date,
            allDay: true,
            extendedProps: {
              history: hEvents,
              type: "HISTORY_EVENT",
              times: hEvents.map((event) => {
                return {
                  isTaken: event.data.isTaken,
                  time: event.data.takenAtString
                    ? event.data.takenAtString
                    : event.data.scheduledAtString
                };
              })
            }
          });
        }
      }
      setTaken(count);
    },
    []
  );

  const createEvents = React.useCallback(
    (params, filterMeds) => {
      const allEvents = [];
      if (params) {
        const medHistoryEvents = [];
        let elements;
        if (!filterMeds.length) elements = params;
        else {
          elements = params.filter((val) =>
            filterMeds.includes(val.medicationId)
          );
        }
        setTotalMeds(
          elements.filter(
            (el) => el.eventTypeDescription === "MEDICATION_HISTORY"
          ).length
        );

        // Sort them in reverse order
        elements.sort(function (a, b) {
          return new Date(b.date) - new Date(a.date);
        });
        elements.forEach((element) => {
          const date = dayjs(element.date).format("YYYY-MM-DD");
          if (element.eventTypeDescription === "MEDICATION_HISTORY") {
            if (!medHistoryEvents[date]) {
              medHistoryEvents[date] = [];
            }
            medHistoryEvents[date].push(element);
          }
        });
        //Counting every time we hit that a med is taken in the loop for the sidebar header
        createMedHistoryEvents(medHistoryEvents, allEvents, filterMeds);

        setEvents(allEvents);
      }
    },
    [createMedHistoryEvents]
  );

  const fetchCalendar = React.useCallback(
    (sDay, eDay) => {
      setLoadingCalendar(true);

      // Set the loading state
      patientService
        .getCalendar(patientId, sDay, eDay)
        .then((res) => {
          if (mounted.current) {
            setLoadingCalendar(false);
            createEvents(res, selectedMedicationIds);
          }
        })
        .catch((e) => {
          if (mounted.current) {
            setLoadingCalendar(false);
            console.log(e);
          }
        });
    },
    [createEvents, patientId, selectedMedicationIds]
  );

  useEffect(() => {
    if (patientId)
      //fetch the calendar whenever the start/end day or filter is changed
      fetchCalendar(startDay, endDay, selectedMedicationIds);
    // Clear existing data
  }, [endDay, fetchCalendar, startDay, selectedMedicationIds, patientId]);

  //This is to check if there is an event when navigating to a new month and if not, to change to month calendar page saying nothing to see here
  useEffect(() => {
    let newEvent;
    if (tempDate) {
      newEvent = events.find((event) => event.id === tempDate.toDateString());
      if (newEvent) setSelectedEvent(newEvent);
      else if (!events.length) setSidebarType("calendar");
    }
  }, [events, tempDate]);
  function findColor(num, type) {
    if (num >= 80) {
      return "success";
    } else if (num < 80 && num >= 50) {
      return "warning";
    } else return "danger";
  }
  const formatDose = (props) => {
    const value = props.value;
    if (value) {
      const { strength, form } = value;
      return (
        <>
          {strength} {form}
        </>
      );
    } else return <>--</>;
  };

  const formatAlertInfo = useCallback(
    (item) => {
      const { alert, medicationSchedule } = item.row.original;
      const scheduleType = medicationSchedule?.scheduleType;
      let formattedTime;
      let formattedType;
      if (alert.alertScheduleType === 1) {
        let option = timeOptions.find(
          (val) => val.value === alert.alertScheduleTime
        );
        formattedTime = option.label;
      } else if (alert.alertScheduleType === 2) {
        let option = specificTimeOptions.find(
          (val) =>
            val.value ===
            convertUTCTimeToLocalTime(alert.alertScheduleTime, true)
        );
        formattedTime = option?.label;
      }

      if (scheduleType === 1) formattedType = "med taken";
      else formattedType = "med missed";

      return (
        <>
          {/* {item.value ? (
            <div>In EHR</div>
          ) : (
            <div>
              <div>Not in EHR</div>
  
            </div>
          )} */}

          {alert?.alertScheduleType && (
            <div>
              <button
                className="btn btn-link p-0 text-left"
                onClick={() => {
                  setSelectedRow(item.row);
                  toggleAlertModal();
                }}
              >
                {alert.alertScheduleType > -1 ? (
                  <>
                    {formattedTime}{" "}
                    {alert.alertScheduleType === 1 && formattedType}
                  </>
                ) : (
                  <>Set Alert</>
                )}
              </button>
            </div>
          )}
        </>
      );
    },
    [toggleAlertModal]
  );

  const handleFilterChange = (medlist) => {
    setEvents([]);
    setSelectedMed([medlist]);
    setSelectedMedicationIds([medlist.value]);
  };

  const handleDateFilterChange = (start, end) => {
    setRange({
      start: dayjs(start).utc().format(),
      end: dayjs(end).utc().format()
    });
  };

  const handleSelectAllRadioChange = (val) => {
    setSelectedMed([]);
    setSelectedMedicationIds([]);
  };

  const handlePreviousClick = (event) => {
    let date = new Date(selectedEvent.id);
    date.setDate(date.getDate() - 1);
    let newEvent = events.find((item) => item.id === date.toDateString());
    if (newEvent) setSelectedEvent(newEvent);
    else {
      setSelectedEvent(false);
      setStartDay(
        dayjs(startDay)
          .subtract(1, "month")
          .startOf("month")
          .utc()
          .format("YYYY-MM-DD")
      );
      // if the end day is greater than the current date, set it to the current date
      if (dayjs(startDay).subtract(1, "month").endOf("month") > dayjs())
        setEndDay(dayjs().format("YYYY-MM-DD"));
      else
        setEndDay(
          dayjs(startDay)
            .subtract(1, "month")
            .endOf("month")
            .utc()
            .format("YYYY-MM-DD")
        );

      setTempDate(date);
    }
  };

  const handleNextClick = (info) => {
    let date = new Date(selectedEvent.id);
    let today = new Date();
    today.setDate(today.getDate() - 2);
    date.setDate(date.getDate() + 1);
    let newEvent = events.find((event) => event.id === date.toDateString());
    if (newEvent) setSelectedEvent(newEvent);
    else {
      setSelectedEvent(false);
      setStartDay(
        dayjs(startDay)
          .add(1, "month")
          .startOf("month")
          .utc()
          .format("YYYY-MM-DD")
      );
      if (dayjs(startDay).add(1, "month").endOf("month") > dayjs())
        setEndDay(dayjs().format("YYYY-MM-DD"));
      else
        setEndDay(
          dayjs(startDay)
            .add(1, "month")
            .endOf("month")
            .utc()
            .format("YYYY-MM-DD")
        );
      setTempDate(date);
    }
  };

  const goBackOneMonth = () => {
    setStartDay(
      dayjs(startDay)
        .subtract(1, "month")
        .startOf("month")
        .utc()
        .format("YYYY-MM-DD")
    );
    if (dayjs(startDay).subtract(1, "month").endOf("month") > dayjs())
      setEndDay(dayjs().format("YYYY-MM-DD"));
    else
      setEndDay(
        dayjs(startDay)
          .subtract(1, "month")
          .endOf("month")
          .utc()
          .format("YYYY-MM-DD")
      );
  };

  const goForwardOneMonth = () => {
    setStartDay(
      dayjs(startDay)
        .add(1, "month")
        .startOf("month")
        .utc()
        .format("YYYY-MM-DD")
    );
    if (dayjs(startDay).add(1, "month").endOf("month") > dayjs())
      setEndDay(dayjs().format("YYYY-MM-DD"));
    else
      setEndDay(
        dayjs(startDay)
          .add(1, "month")
          .endOf("month")
          .utc()
          .format("YYYY-MM-DD")
      );
  };

  const headerActions = [
    <div className="cerner-date" key="cerner-date">
      <CernerDateSelect
        key="select"
        callback={handleDateFilterChange}
        range={range}
        setActiveOption={setActiveOption}
        activeOption={activeOption}
      />
    </div>,
    <button
      key="cal"
      className="btn btn-small btn-xs"
      onClick={() => {
        setSidebarType("calendar");
        toggleSidebar(true);
      }}
    >
      &nbsp;&nbsp;&nbsp;
      <Calendar aria-label="calendar" data-testid="calendar" />
    </button>,
    <BulkAction
      setMultiSelect={setMultiSelect}
      setDeleteBulkAlerts={setDeleteBulkAlerts}
      key="bulk"
      setBulkAlertType={setBulkAlertType}
      setSelectedRows={setSelectedRows}
      toggleModal={toggleModal}
    />
  ];
  const medColumns = React.useMemo(
    () => [
      {
        Header: (props) => {
          return (
            <div className="text-center">
              <MedicationCell multiSelect={multiSelect} {...props} />
            </div>
          );
        },
        accessor: "ehrIntegrations",
        Cell: (action) => {
          return (
            <GlyphCell
              row={action.row}
              multiSelect={multiSelect}
              setSidebarType={setSidebarType}
              toggleSidebar={toggleSidebar}
              DDIs={DDIs}
              bulkAlertType={bulkAlertType}
              deleteBulkAlerts={deleteBulkAlerts}
            />
          );
        }
      },
      {
        Header: "Medication",
        accessor: "medicationName",
        Cell: (action) => {
          return (
            <MedNameCell
              value={action.value}
              row={action.row}
              multiSelect={multiSelect}
              setSidebarType={setSidebarType}
              toggleSidebar={toggleSidebar}
              DDIs={DDIs}
            />
          );
        }
      },
      {
        Header: "Dose",
        accessor: "medicationStrength",
        Cell: (props) => {
          return formatDose(props);
        }
      },
      {
        Header: "Start/End",
        accessor: (row) => {
          return <StartEndCell row={row} />;
        }
      },
      {
        Header: "Time(s)",
        accessor: "medicationSchedule",
        Cell: (action) => {
          return (
            <TimeCell
              value={action.value.medicationScheduleTimes}
              isPRN={isPRN(action.value)}
            />
          );
        }
      },
      {
        Header: "Supply Remaining",
        Cell: (props) => {
          return (
            <div>
              {props.row.original.medicationStrength.supplyRemaining || "--"}
            </div>
          );
        },
        minWidth: 200
      },
      {
        Header: "Adherence",
        accessor: "overallAdherencePercentage",
        Cell: (action) => {
          return <AdherenceCell value={action.value} findColor={findColor} />;
        }
      },
      {
        Header: "",
        accessor: "isInEhr",
        Cell: (action) => {
          return formatAlertInfo(action);
        }
      }
    ],
    [
      multiSelect,
      toggleSidebar,
      DDIs,
      bulkAlertType,
      deleteBulkAlerts,
      formatAlertInfo
    ]
  );

  return (
    <div>
      <CernerTable
        columns={medColumns}
        data={meds}
        loadingMedications={loadingMedications}
        headerActions={headerActions}
        multiSelect={multiSelect}
        fetchData={fetchMedications}
        start={range.start}
        bulkUpdate={bulkUpdate}
        setBulkUpdate={setBulkUpdate}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        end={range.end}
        medlistLength={medlistLength}
      />
      <ReviewSidebar
        user={user}
        isSidebarOpen={isSidebarOpen}
        scrollPosition={scrollPosition}
        isIE={isIE}
        toggleSidebar={toggleSidebar}
        selectedEvent={selectedEvent}
        selectedMed={selectedMed}
        selectedMedicationIds={selectedMedicationIds}
        takenMeds={taken}
        totalMeds={totalMeds}
        table={
          <DDITableExpandable
            isIE={isIE}
            loading={alertsLoading}
            clinicalInteractions={clinicalInteractions}
          />
        }
        startDay={startDay}
        endDay={endDay}
        setEndDay={setEndDay}
        setSidebarType={setSidebarType}
        sidebarType={sidebarType}
        clinicalInteractions={clinicalInteractions}
        handlePreviousClick={handlePreviousClick}
        handleNextClick={handleNextClick}
        goForwardOneMonth={goForwardOneMonth}
        goBackOneMonth={goBackOneMonth}
        MedDropdown={
          <MedDropdown
            availableMedications={meds}
            handleFilterChange={handleFilterChange}
            handleSelectAllRadioChange={handleSelectAllRadioChange}
            placeholder="Filter by Specific Med"
            selected={selectedMed}
            isMulti={false}
            isClearable={true}
          />
        }
        calendar={
          <MedHistory
            events={events}
            selectedMedicationIds={selectedMedicationIds}
            setSelectedEvent={setSelectedEvent}
            setSidebarType={setSidebarType}
          />
        }
        // calendarRef={calendarRef}
        loadingCalendar={loadingCalendar}
      />
      <AlertModal
        alertModal={alertModal}
        toggleAlertModal={toggleAlertModal}
        selectedRow={selectedRow}
        bulkUpdate={bulkUpdate}
        fetchMedications={fetchMedications}
        setBulkUpdate={setBulkUpdate}
        selectedRows={selectedRows.map((row) => row.original)}
        bulkAlertType={bulkAlertType}
        setMultiSelect={setMultiSelect}
      />
      <p className="mx-4 px-lg-0">
        The details show here are populated using the data from the patient's
        EveryDose Mobile App
      </p>
      <BulkActions
        multiSelect={multiSelect}
        deleteBulkAlerts={deleteBulkAlerts}
        selectedRows={selectedRows.map((row) => row.original)}
        setMultiSelect={setMultiSelect}
        setBulkUpdate={setBulkUpdate}
        fetchMedications={fetchMedications}
        setDeleteBulkAlerts={setDeleteBulkAlerts}
        toggleAlertModal={toggleAlertModal}
        setSelectedRow={setSelectedRow}
        bulkAlertType={bulkAlertType}
      />
      <AlertTypeModal
        modal={modal}
        toggleModal={toggleModal}
        setBulkAlertType={setBulkAlertType}
        setMultiSelect={setMultiSelect}
        bulkAlertType={bulkAlertType}
        prnMeds={meds.filter(
          (med) =>
            med.medicationSchedule.prnReason ||
            med.medicationSchedule.prnReasonOther ||
            med.medicationSchedule.prnReason === 0
        )}
        scheduledMeds={meds.filter(
          (med) =>
            !med.medicationSchedule.prnReason &&
            !med.medicationSchedule.prnReasonOther &&
            med.medicationSchedule.prnReason !== 0
        )}
      />
    </div>
  );
};
export { Workflow };
