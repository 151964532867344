import React, { lazy, Suspense, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { CloseButton } from "../_components";
import {
  accessOptions,
  userFunctionOptions,
  userRoleOptions
} from "../_helpers";
import { adminService, patientService } from "../_services";
import { useAlertContext } from "../context/alertContext";
import patientXlsxFile from "../documents/PatientBulkInviteTemplate.xlsx";
import xlsxFile from "../documents/UserBulkInviteTemplate.xlsx";

import PropTypes from "prop-types";
import { useUserContext } from "../context/userContext";

// lazy load react-papaparse

const genderOptions = [
  { value: 0, label: "Unknown" },
  { value: 1, label: "Male" },
  { value: 2, label: "Female" },
  { value: 3, label: "Non-binary" },
  { value: 4, label: "Other" }
];

function findGender(gndr) {
  let gender = genderOptions.find((elem) => elem.label.trim() === gndr.trim());
  return gender?.value || 0;
}

function findUserRole(role) {
  let userRole = userRoleOptions.find(
    (elem) => elem.label.trim() === role.trim()
  );
  return userRole?.value || 0;
}

function findAccessLevel(access) {
  let accessLevel = accessOptions.find(
    (elem) => elem.label.trim() === access.trim()
  );
  return accessLevel?.value || -1;
}

function findUserFunction(f) {
  let userFunction = userFunctionOptions.find(
    (elem) => elem.label.trim() === f.trim()
  );

  return userFunction?.value || -1;
}

function displayFailureData(failureData) {
  return failureData.map((item, idx) => {
    if (idx === 0)
      return `<br>Row ${idx + 1}: ${item.errorMessage.slice(0, -1)}`;
    else return ` Row ${idx + 1}: ${item.errorMessage.slice(0, -1)}`;
  });
}

const LazyBulkInviteWrapper = lazy(async () => {
  const { useCSVReader } = await import("react-papaparse");

  const BulkInviteForm = ({ name, toggleModal }) => {
    const { CSVReader } = useCSVReader();
    const { userState } = useUserContext();
    const { user } = userState;
    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState(false);
    const [results, setResults] = useState([]);
    const { alertMethods } = useAlertContext();
    const mounted = React.useRef(false);
    React.useEffect(() => {
      mounted.current = true; // Will set it to true on mount ...
      return () => {
        mounted.current = false;
      }; // ... and to false on unmount
    }, []);

    const onUpload = (inputCsv) => {
      setSubmitting(true);
      bulkAdd(results);
    };
    function addUsers(json) {
      adminService
        .bulkInvite(json)
        .then((res) => {
          if (mounted.current) {
            const failureData = res.filter((item) => !item.isSuccess);
            if (failureData.length) {
              let errorMessage = `<b>${
                res.length - failureData.length
              } user(s) invited. ${
                failureData.length
              } error(s) found in the list:</b>`;
              alertMethods.error(
                errorMessage + `${displayFailureData(failureData)}`,
                { keepAfterRouteChange: true }
              );
            } else {
              alertMethods.success("Users have been invited", {
                keepAfterRouteChange: true
              });
              if (user?.isSmartSession) {
                navigate("/smart/admin/users/invitations");
              } else {
                navigate("/admin/users/invitations");
              }
            }
            setSubmitting(false);
            toggleModal(false);
          }
        })
        .catch((e) => {
          if (mounted.current) {
            setSubmitting(false);
            alertMethods.error(
              "Something went wrong submitting your file. Please try again."
            );
          }
        });
    }
    function addPatients(json) {
      patientService
        .bulkInvite(json)
        .then((res) => {
          if (mounted.current) {
            const failureData = res.filter((item) => !item.isSuccess);
            if (failureData.length) {
              let errorMessage = `<b>${
                res.length - failureData.length
              } patient(s) invited.
              ${failureData.length} error(s) found in the list:</b>`;
              alertMethods.error(
                errorMessage + `${displayFailureData(failureData)}`,
                { keepAfterRouteChange: true }
              );
            } else {
              alertMethods.success("Patients have been invited", {
                keepAfterRouteChange: true
              });
              navigate("/invite/manage-invitations");
            }
            setSubmitting(false);
          }
        })
        .catch((e) => {
          if (mounted.current) {
            setSubmitting(false);

            alertMethods.error(
              "Something went wrong submitting your file. Please try again."
            );
          }
        });
    }
    function bulkAdd(json) {
      setResults([]);
      if (name === "users") {
        let body =
          // remove first 3 lines from json
          json.slice(6).map((item) => {
            return {
              email: item[0],
              integrationSourceIds: item[1] ? { CernerSmartApp: item[1] } : {},
              firstName: item[2],
              lastName: item[3],
              userRole: findUserRole(item[6]),
              function: findUserFunction(item[4]),
              otherFunction: item[5],
              accessLevel: findAccessLevel(item[7]),
              groups: []
            };
          });

        addUsers(body);
      } else {
        let body = json.slice(2).map((item) => {
          return {
            firstName: item[0],
            lastName: item[1],
            dateOfBirth: item[2],
            gender: findGender(item[3]),
            phoneNumber: item[4],
            phoneInvitationEnabled: item[5] === "TRUE",
            email: item[6],
            emailInvitationEnabled: item[7] === "TRUE"
          };
        });

        addPatients(body);
      }
    }
    return (
      <div>
        <h4 className="mb-5">
          To add multiple {name}, download our Excel template and then upload
          the updated template below.
        </h4>

        <CSVReader
          config={{
            skipEmptyLines: "greedy"
          }}
          onUploadAccepted={(results) => {
            if (results.errors.length) {
              alertMethods.error(
                "We found something wrong in your file. Please check the file and try again."
              );
            } else if (!results.data.length) {
              alertMethods.error(
                "No data found in the file. Please check the file and try again."
              );
            } else {
              setResults(results.data);
            }
          }}
        >
          {({
            getRootProps,
            acceptedFile,
            ProgressBar,
            getRemoveFileProps
          }) => (
            <>
              <div className="d-flex">
                <div
                  className="form-control col-md-8 col-7 bulk-invite-ellipsis"
                  data-testid="readonly-input"
                >
                  {acceptedFile?.name}
                </div>
                <div>
                  <button
                    type="button"
                    className="btn btn-primary col-md-3 col-5 mb-0"
                    id="attach-file"
                    {...getRootProps()}
                  >
                    Attach File
                  </button>
                </div>
              </div>{" "}
              <div className="">
                (Files in text/csv, .csv, and application/vnd.ms-excel format
                are accepted)
              </div>
              <div className="csv-progress-bar">
                <ProgressBar />{" "}
              </div>
            </>
          )}
        </CSVReader>

        <div className="d-flex flex-lg-row flex-column mt-6 mb-5 align-items-center">
          <a
            className="px-0 mr-lg-5 text-nowrap"
            href={name === "users" ? xlsxFile : patientXlsxFile}
          >
            Excel Template
          </a>

          <button
            type="button"
            className="btn btn-primary mr-lg-5 mb-lg-0 mb-3 w-25"
            disabled={!results.length || submitting}
            onClick={onUpload}
          >
            {submitting && (
              <span className="spinner-border spinner-border-sm mr-1"></span>
            )}
            Upload
          </button>
          {toggleModal && (
            <button
              className="btn btn-outline-primary w-25"
              onClick={toggleModal}
            >
              Cancel
            </button>
          )}
        </div>
      </div>
    );
  };
  return { default: BulkInviteForm };
});

const BulkInviteModal = ({ modal, setModal, toggleModal, name }) => {
  return (
    <Modal
      isOpen={modal}
      toggle={toggleModal}
      className="center-modal scroll-modal"
    >
      <ModalHeader close={<CloseButton toggle={toggleModal} />}>
        Add Multiple {name.charAt(0).toUpperCase() + name.slice(1)}
      </ModalHeader>
      <ModalBody>
        <BulkInviteWrapper name={name} toggleModal={toggleModal} />
      </ModalBody>
    </Modal>
  );
};

const BulkInviteWrapper = (props) => {
  return (
    <Suspense
      fallback={
        <div>
          <h4 className="mb-5">
            To add multiple {props.name}, download our Excel template and then
            upload the updated template below.
          </h4>
          <div className="ph-item border-0 mt-5">
            <div className="ph-col-9">
              <div className="ph-row">
                <div className="ph-col-6 big"></div>
                <div className="ph-col-half empty"></div>
                <div className="ph-col-10 big mb-6"></div>
                <div className="ph-col-half empty"></div>
                <div className="ph-col-2 big"></div>
                <div className="ph-col-half empty"></div>
                <div className="ph-col-2 big"></div>
              </div>
            </div>
          </div>
        </div>
      }
    >
      <LazyBulkInviteWrapper {...props} />
    </Suspense>
  );
};

BulkInviteWrapper.propTypes = {
  name: PropTypes.string,
  toggleModal: PropTypes.func
};
BulkInviteModal.propTypes = {
  name: PropTypes.string,
  modal: PropTypes.bool,
  setModal: PropTypes.func,
  toggleModal: PropTypes.func
};

export { BulkInviteWrapper as BulkInviteForm, BulkInviteModal };
