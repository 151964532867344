import React, { Suspense } from "react";
import { TabContent, TabPane } from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import classNames from "classnames";
import * as Yup from "yup";
import { ReactComponent as Checkmark } from "../images/glyph_check.svg";
import { ReactComponent as Xmark } from "../images/glyph_x.svg";
import PropsTypes from "prop-types";
import { PhoneNumberInput } from ".";

export const PhoneForm = ({
  onSubmitPhone,
  phoneFailure,
  phoneSuccess,
  text
}) => {
  const phoneRegExp = /^[+]?[(]?\d{3}[)]?[-\s.]?\d{3}[-\s.]?\d{5}$/im;
  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .optional()
      .matches(phoneRegExp, {
        message:
          "Please enter a standard US phone number that contains only 10 digits."
      })
      .required("Required")
  });
  return (
    <>
      <p>Enter a patient's phone number below to {text}</p>
      <Formik
        validationSchema={validationSchema}
        initialValues={{ phoneNumber: "" }}
        onSubmit={onSubmitPhone}
        enableReinitialize
      >
        {({
          errors,
          touched,
          isSubmitting,
          isValid,
          dirty,
          setFieldValue,
          setFieldTouched,
          values
        }) => (
          <Form>
            <div className="d-flex">
              <Suspense
                fallback={<div className="form-control br-5-5px"></div>}
              >
                <PhoneNumberInput
                  id="phoneNumber"
                  onChange={(val) => {
                    setFieldValue("phoneNumber", val);
                  }}
                  onBlur={(val) => {
                    setFieldTouched("phoneNumber", true);
                  }}
                  value={values.phoneNumber}
                  name="phoneNumber"
                  placeholder="Patient's Mobile"
                  classnames={classNames(
                    {
                      "is-invalid": errors.phoneNumber && touched.phoneNumber
                    },
                    "form-control br-5-5px"
                  )}
                />
              </Suspense>
              <ErrorMessage
                name="phoneNumber"
                component="div"
                className="invalid-feedback"
              />

              <button
                className="btn btn-primary br-0-0px"
                type="submit"
                //   type="submit"
                disabled={isSubmitting || !isValid || !dirty}
              >
                {isSubmitting && (
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                )}
                Send
              </button>
            </div>
            {phoneSuccess && (
              <div className="text-primary mt-1">
                <Checkmark
                  className="glyph-grooveblue mr-2 mb-1"
                  aria-label="checkmark"
                />
                Your submission was succesful.
              </div>
            )}
            {phoneFailure && (
              <div className="text-danger mt-1">
                <Xmark className="glyph-danger mr-2 mb-1" aria-label="x-mark" />
                Something went wrong with your submission. Please try again.
              </div>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};
PhoneForm.propTypes = {
  onSubmitPhone: PropsTypes.func,
  phoneFailure: PropsTypes.bool,
  phoneSuccess: PropsTypes.bool,
  text: PropsTypes.string
};

export const EmailForm = ({
  onSubmitEmail,
  emailFailure,
  emailSuccess,
  text
}) => {
  const emailRegExp = /^[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

  const emailValidationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(emailRegExp, { message: "Invalid Email" })
      .required("Required")
  });
  return (
    <>
      <p>Enter a patient's email address below to {text}</p>
      <Formik
        validationSchema={emailValidationSchema}
        initialValues={{ email: "" }}
        onSubmit={onSubmitEmail}
        enableReinitialize
      >
        {({ errors, touched, isSubmitting, isValid, values, dirty }) => (
          <Form>
            <div className="d-flex">
              <Field
                id="email"
                name="email"
                placeholder="Patient's Email"
                className={classNames(
                  {
                    "is-invalid": errors.email && touched.email
                  },
                  "form-control br-5-5px"
                )}
              />
              <button
                className="btn btn-primary br-0-0px"
                type="submit"
                disabled={isSubmitting || !isValid || !dirty}
              >
                {isSubmitting && (
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                )}
                Send
              </button>
            </div>
            {emailSuccess && (
              <div className="text-primary mt-1">
                <Checkmark
                  className="glyph-grooveblue mr-2 mb-1"
                  aria-label="checkmark"
                />
                Your submission was succesful.
              </div>
            )}
            {emailFailure && (
              <div className="text-danger mt-1">
                <Xmark className="glyph-danger mr-2 mb-1" aria-label="x-mark" />
                Something went wrong with your submission. Please try again.
              </div>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};
EmailForm.propTypes = {
  onSubmitEmail: PropsTypes.func,
  emailFailure: PropsTypes.bool,
  emailSuccess: PropsTypes.bool,
  text: PropsTypes.string
};

export const EmailTextTabs = ({
  activeTab,
  onSubmitEmail,
  onSubmitPhone,
  phoneFailure,
  phoneSuccess,
  emailFailure,
  emailSuccess,
  text
}) => {
  return (
    <TabContent activeTab={activeTab} className="">
      <TabPane tabId="1">
        <PhoneForm
          onSubmitPhone={onSubmitPhone}
          phoneFailure={phoneFailure}
          phoneSuccess={phoneSuccess}
          text={text}
        />
      </TabPane>
      <TabPane tabId="2">
        <EmailForm
          onSubmitEmail={onSubmitEmail}
          emailFailure={emailFailure}
          emailSuccess={emailSuccess}
          text={text}
        />
      </TabPane>
    </TabContent>
  );
};
EmailTextTabs.propTypes = {
  activeTab: PropsTypes.string,
  onSubmitEmail: PropsTypes.func,
  onSubmitPhone: PropsTypes.func,
  phoneFailure: PropsTypes.bool,
  phoneSuccess: PropsTypes.bool,
  emailFailure: PropsTypes.bool,
  emailSuccess: PropsTypes.bool,
  text: PropsTypes.string
};
