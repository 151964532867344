import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from "react";
import PropTypes from "prop-types";

// Create the AlertContext
export const AlertContext = createContext();

// Create a custom hook to access the AlertContext
export function useAlertContext() {
  return useContext(AlertContext);
}

let currentId = 1;

// Define the alert reducer
function alertReducer(state, action) {
  switch (action.type) {
    case "ALERT_SUCCESS":
    case "ALERT_ERROR":
    case "ALERT_INFO":
    case "ALERT_WARNING":
      return [...state, { ...action.payload }];
    case "ALERT_CLEAR":
      return state.filter((alert) => alert.id !== action.payload.id);
    case "ALERT_CLEAR_ALL":
      return [];

    default:
      return state;
  }
}
export const AlertType = {
  Success: "Success",
  Error: "Error",
  Info: "Info",
  Warning: "Warning",
};

// Create the AlertProvider component
export function AlertProvider({ children }) {
  const [alerts, dispatch] = useReducer(alertReducer, []);

  const alert = useCallback((alert) => {
    const newAlert = { ...alert, id: currentId++, autoClose: true };
    // Implement your alert logic here
    dispatch({ type: `ALERT_${alert.type.toUpperCase()}`, payload: newAlert });
  }, []);

  const clear = useCallback((id = "default-alert") => {
    // Implement your clear logic here
    dispatch({ type: "ALERT_CLEAR", payload: { id } });
  }, []);

  const clearAll = useCallback(() => {
    // Implement your clear logic here
    dispatch({ type: "ALERT_CLEAR_ALL" });
  }, []);

  const success = useCallback(
    (message, options) => alert({ ...options, type: "Success", message }),
    [alert]
  );
  const error = useCallback(
    (message, options) => alert({ ...options, type: "Error", message }),
    [alert]
  );

  const info = useCallback(
    (message, options) => alert({ ...options, type: "Info", message }),
    [alert]
  );
  const warn = useCallback(
    (message, options) => alert({ ...options, type: "Warning", message }),
    [alert]
  );

  const alertMethods = useMemo(
    () => ({
      success,
      error,
      info,
      warn,
      clear,
      clearAll,
    }),
    [success, error, info, warn, clear, clearAll]
  );

  // This will be a separate object with the alerts array
  const alertService = useMemo(
    () => ({
      alertMethods: alertMethods,
      alerts,
    }),
    [alertMethods, alerts]
  );

  return (
    <AlertContext.Provider value={alertService}>
      {children}
    </AlertContext.Provider>
  );
}

AlertProvider.propTypes = {
  children: PropTypes.node,
};
