import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Navigate } from "react-router-dom";
import { allAccess } from "../_helpers";
import { useUserContext } from "../context/userContext";
import { Loader } from "./Loader";
import { LockScreen } from "./LockScreen";
import PropTypes from "prop-types";

function RequireAuth({ children, name, featureNo }) {
  const { userState } = useUserContext();
  const { user, userPermissions, tenantFeatures } = userState;
  const { isAuthenticated, isLoading } = useAuth0();
  if (!user && (isAuthenticated || isLoading)) {
    return <Loader />;
  }

  if (!isAuthenticated && !isLoading) {
    return (
      <Navigate
        data-testid="navigate"
        to={user?.isSmartSession ? "/smart/403" : "/account/login"}
      />
    );
  }

  if (tenantFeatures && name && tenantFeatures[name] === false) {
    return (
      <LockScreen
        feature={
          name === "EHR" ? "EHR" : name.replace(/([A-Z])/g, " $1").trim()
        }
      />
    );
  }

  if (
    userPermissions &&
    featureNo &&
    userPermissions?.indexOf(featureNo) < 0 &&
    !allAccess(userPermissions)
  ) {
    return <LockScreen feature={name} type="user-permissions" />;
  }
  return children;
}

RequireAuth.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  featureNo: PropTypes.number
};

export { RequireAuth };
