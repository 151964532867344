import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as GlyphSmile } from "../../../images/smile.svg";

export const NoUpdatesCard = ({
  hideHealthTrackers,
  hideOutreach,
  hideTasks,
}) => {
  return (
    <div className="dashed-border d-flex flex-column justify-content-center align-items-center my-6 mt-xl-0 py-3">
      <GlyphSmile aria-label="smile" />
      <h4 className="text-secondary text-center">No Updates Found</h4>
      <div className="text-secondary mt-2">
        This patient has no updates related to:
        <ul>
          {!hideHealthTrackers && <li>Health Trackers</li>}
          {!hideOutreach && <li>Outreach</li>}
          {!hideTasks && <li>Tasks</li>}
        </ul>
      </div>
    </div>
  );
};

NoUpdatesCard.propTypes = {
  hideHealthTrackers: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  hideOutreach: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  hideTasks: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};
