import React, { Suspense, lazy } from "react";
import { Loader } from "../../../_components";

const GanttComponent = lazy(() =>
  import("@syncfusion/ej2-react-gantt").then((module) => ({
    default: module.GanttComponent
  }))
);

const GanttData = [
  {
    TaskID: 1,
    TaskName: "Glipizide",
    StartDate: new Date("01/01/2023"),
    EndDate: new Date("06/30/2023"),
    subtasks: [
      {
        TaskID: 2,
        TaskName: "5 mg Tablet",
        StartDate: new Date("02/04/2023"),
        Duration: 120,
        Progress: 100,
        Segments: [
          { StartDate: new Date("02/04/2023"), Duration: 30 },
          { StartDate: new Date("03/12/2023"), Duration: 30 },
          { StartDate: new Date("04/12/2023"), Duration: 30 },
          { StartDate: new Date("05/27/2023"), Duration: 30 }
        ]
      },
      {
        TaskID: 3,
        TaskName: "10 mg Tablet",
        StartDate: new Date("01/01/2023"),
        Duration: 30,
        Progress: 100
      }
    ]
  },
  {
    TaskID: 4,
    TaskName: "Metformin",
    StartDate: new Date("01/07/2023"),
    // EndDate: new Date("06/30/2023"),
    subtasks: [
      {
        TaskID: 5,
        TaskName: "500 mg Tablet",
        StartDate: new Date("01/07/2023"),
        Duration: 150,
        Progress: 100,
        Segments: [
          { StartDate: new Date("01/07/2023"), Duration: 30 },
          { StartDate: new Date("02/11/2023"), Duration: 30 },
          { StartDate: new Date("03/27/2023"), Duration: 90 }
        ]
      }
    ]
  },
  {
    TaskID: 6,
    TaskName: "Warfarin",
    StartDate: new Date("01/13/2023"),
    // EndDate: new Date("06/30/2023"),
    subtasks: [
      {
        TaskID: 7,
        TaskName: "2 mg Tablet",
        StartDate: new Date("01/13/2023"),
        Duration: 159,
        Progress: 100,
        Segments: [
          { StartDate: new Date("01/13/2023"), Duration: 30 },
          { StartDate: new Date("02/15/2023"), Duration: 30 },
          { StartDate: new Date("03/19/2023"), Duration: 30 },
          { StartDate: new Date("04/23/2023"), Duration: 30 },
          { StartDate: new Date("05/23/2023"), Duration: 30 },
          { StartDate: new Date("06/22/2023"), Duration: 9 }
        ]
      }
    ]
  }
];

export const GanttChart = (props) => {
  const timelineSettings = {
    timelineViewMode: "Month",
    // timelineUnitSize: 40,
    topTier: {
      unit: "Month",
      format: "MMM yyyy"
    },
    bottomTier: {
      unit: "Week",
      format: "M/d"
    }
  };
  const taskFields = {
    id: "TaskID",
    name: "TaskName",
    startDate: "StartDate",
    // endDate: "EndDate",
    duration: "Duration",
    progress: "Progress",
    child: "subtasks",
    segments: "Segments"
  };
  return (
    <Suspense fallback={<Loader />}>
      <GanttComponent
        dataSource={GanttData}
        height="auto"
        taskFields={taskFields}
        timelineSettings={timelineSettings}
        workWeek={[
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday"
        ]}
      />
    </Suspense>
  );
};
