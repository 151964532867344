import React from "react";
import logo from "../images/ED-Hortizontal_2-color_Logo.png";

const GoLive = () => {
  return (
    <div className="d-flex flex-column align-items-center smart-container pt-7">
      <div className="card bg-white align-self-center invite">
        <div className="card-body">
          <div className="d-flex justify-content-center pb-4">
            <img src={logo} alt="EveryDose" className="logo go-live-logo" />
          </div>
          <h3 className=" text-center">Almost ready for lift off!</h3>
          <div className="py-5 mx-6">
            EveryDose makes it easy for patients and clinicians to track <br />
            medication adherence. We’re in the implementation process <br />
            with your organization, but we haven’t launched quite yet. <br />
            Contact your administrator with any questions.
          </div>
        </div>
      </div>
    </div>
  );
};

export { GoLive };
