import classnames from "classnames";
import { ErrorMessage, Field, Form, Formik } from "formik";
import debounce from "lodash.debounce";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import React, { Suspense, useEffect, useState } from "react";
import * as Yup from "yup";
import {
  DatePickerField,
  MyAsyncSelect,
  MySelect,
  TimePickerField,
} from "../_components";
import {
  convertLocalTimeToUTCTime,
  convertUTCDateTimeToLocalDateTime,
  convertUTCTimeToLocalTime,
  permissionsEnum,
} from "../_helpers";
import {
  Use1440MediaQuery,
  UseMobileOrTabletMediaQuery,
} from "../_helpers/media-queries";
import {
  patientService,
  taskService,
  userPatientlistService,
} from "../_services";
import { useAlertContext } from "../context/alertContext";
import { useUserContext } from "../context/userContext";
import { ReactComponent as Lock } from "../images/glyph_lock.svg";

const typeOptions = [
  { value: 0, label: "General" },
  { value: 1, label: "Call" },
  { value: 2, label: "Monitor" },
  { value: 3, label: "Patient Action" },
];

const taskFormPropTypes = {
  patient: PropTypes.object,
  patientId: PropTypes.string,
  groups: PropTypes.array.isRequired,
  toggleAdd: PropTypes.func,
  fetchData: PropTypes.func.isRequired,
  users: PropTypes.array,
  version: PropTypes.string,
  selectedItem: PropTypes.object,
  toggleEdit: PropTypes.func,
  tableState: PropTypes.object,
};

const TaskForm = ({
  patient,
  patientId,
  groups,
  toggleAdd,
  fetchData,
  users,
  version,
  selectedItem,
  toggleEdit,
  tableState,
}) => {
  const { userState } = useUserContext();
  const [patientListId, setPatientListId] = useState(undefined);
  const [noOpsMessage, setNoOpsMessage] = useState(
    "Type to search for patients."
  );

  const { user, userPermissions } = userState;
  const { alertMethods } = useAlertContext();
  const isMobileOrTabletWidth = UseMobileOrTabletMediaQuery();
  const is1400Width = Use1440MediaQuery();
  const mounted = React.useRef(false);

  React.useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => {
      mounted.current = false;
    }; // ... and to false on unmount
  }, []);
  useEffect(() => {
    if (!user?.isSmartSession) {
      let allpatients = user?.userPatientLists.find(
        (list) => list.name === "All Patients"
      ).id;
      setPatientListId(allpatients);
    }
  }, [user]);

  const onSetDueDate = () => {
    if(selectedItem?.dueTime) {
      return convertUTCDateTimeToLocalDateTime(
        selectedItem.dueDate,
        selectedItem.dueTime
      );
    }
    return selectedItem?.dueDate
      ? new Date(selectedItem?.dueDate.slice(0, -1))
      : "";
  };

  const initialValues = {
    taskTitle: selectedItem?.title || "",
    description: selectedItem?.description || "",
    taskType: typeOptions.find((op) => op.value === selectedItem?.type) || "",
    associatedUser: selectedItem
      ? { value: selectedItem.userId, label: selectedItem.userName }
      : { value: user?.userId, label: user?.name },
    associatedPatient:
      patient || selectedItem?.patientId
        ? {
            value: patient?.patientId || selectedItem?.patientId,
            label: patient?.name || selectedItem?.patientName,
          }
        : "",
    associatedGroup:
      selectedItem?.group && groups
        ? groups.find((grp) => grp.label === selectedItem.group)
        : {},
    dueDate: onSetDueDate(),
    time: selectedItem?.dueTime
      ? new Date(`1/1/2020 ${convertUTCTimeToLocalTime(selectedItem.dueTime)}`)
      : "",
  };

  const newTaskValidationSchema = Yup.object().shape({
    taskTitle: Yup.string()
      .required("Name is required")
      .max(100, "Name must be 100 or fewer characters"),
    taskType: Yup.object()
      .nullable()
      .shape({
        value: Yup.string().required("Type is required"),
        label: Yup.string().required(),
      }),
    dueDate: Yup.string().required("Due date is required"),
    associatedUser: Yup.object()
      .nullable()
      .shape({
        value: Yup.string().required("Task must be assigned to a user"),
        label: Yup.string().required(),
      }),
  });

  function onAdd(fields, { setSubmitting, resetForm }) {
    setSubmitting(true);
    let time, dateString, dateObj, utcDate, timeObj;
    const formattedDate = dayjs(fields.dueDate).format("MM/DD/YYYY");
    if (fields.time) {
      // If editing, time is in this format "10:00 AM" and needs to be converted to an object
      if (typeof fields.time !== "object") {
        timeObj = new Date("2000-01-01 " + fields.time);
      } else {
        timeObj = fields.time;
      }

      // Extract hours and minutes from the time object
      const hours = timeObj.getHours();
      const minutes = timeObj.getMinutes();

      // Convert the local time to UTC time in "HH:mm" format using the custom function
      time = convertLocalTimeToUTCTime(timeObj.toTimeString().slice(0, 5));

      // Create a new date object with the combined date and time strings
      dateString = `${formattedDate} ${hours}:${minutes}`;
      dateObj = new Date(dateString);

      // Convert the date object to its UTC representation as a string
      utcDate = dateObj.toUTCString();
    }

    // Prepare the body object with the necessary data for further processing
    let body = {
      userId: fields.associatedUser.value,
      title: fields.taskTitle,
      description: fields.description,
      type: fields.taskType.value,
      patientId: fields.associatedPatient ? fields.associatedPatient.value : "",
      groupId: fields.associatedGroup ? fields.associatedGroup.value : "",
      dueDate: fields.time
        ? utcDate
        : dayjs(fields.dueDate).format("YYYY-MM-DD"),
      dueTime: time,
    };

    if (version === "edit") {
      taskService
        .editTask(selectedItem.id, body)
        .then(() => {
          alertMethods.success("Task has been edited");
          toggleEdit();
          setSubmitting(false);
          fetchData(tableState);
        })
        .catch((e) => {
          alertMethods.error("Task could not be edited");
          setSubmitting(false);
        });
    } else if (patientId) {
      patientService
        .createTask(patientId, body)
        .then(() => {
          alertMethods.success("Task has been created");
          setSubmitting(false);
          resetForm();
          if (toggleAdd) toggleAdd();
          fetchData(tableState);
        })
        .catch((e) => {
          alertMethods.error("Task could not be created");
          setSubmitting(false);
        });
    } else {
      taskService
        .createTask(body)
        .then(() => {
          alertMethods.success("Task has been created");
          resetForm();
          toggleAdd();
          setSubmitting(false);
          fetchData(tableState);
        })
        .catch((e) => {
          alertMethods.error("Task could not be created");
          setSubmitting(false);
        });
    }
  }

  const debouncedSearchPatientCall = React.useMemo(
    () =>
      debounce(async (inputValue, callback) => {
        try {
          const response = await userPatientlistService.getPatients(
            patientListId,
            inputValue
          );
          if (mounted.current) {
            const patientOptions = response.data.map((pt) => ({
              value: pt.patientId,
              label: pt.name,
            }));
            callback(patientOptions);
          }
        } catch (e) {
          alertMethods.error(
            "Something went wrong getting your patients. Refresh the page and try again"
          );
        }
      }, 500),
    [alertMethods, patientListId]
  );

  const loadOptions = (inputValue, callback) => {
    debouncedSearchPatientCall(inputValue, callback);
  };

  const patientListPermissions =
    userPermissions?.indexOf(permissionsEnum.UserPatientList) > -1;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={newTaskValidationSchema}
      onSubmit={onAdd}
      enableReinitialize
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        isValid,
        dirty,
        setFieldValue,
        setFieldTouched,
        resetForm,
      }) => {
        return (
          <Form data-testid="create-new-task-form">
            <div>
              <div className="form-group">
                <label htmlFor="taskTitle">Title *</label>
                <Field
                  placeholder="Create a task title"
                  id="taskTitle"
                  name="taskTitle"
                  data-testid={`${version}task-title`}
                  type="text"
                  className={classnames(
                    { "is-invalid": errors.taskTitle && touched.taskTitle },
                    "form-control"
                  )}
                />
                <ErrorMessage
                  name="taskTitle"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group">
                <label htmlFor="description"> Description</label>
                <Field
                  id="description"
                  name="description"
                  placeholder="Description of the task"
                  data-testid={`${version}task-description`}
                  component="textarea"
                  rows="4"
                  className={classnames(
                    {
                      "is-invalid": errors.description && touched.description,
                    },
                    "form-control"
                  )}
                />
              </div>
              <div className="form-group">
                <MySelect
                  name="taskType"
                  label="Type *"
                  placeholder="What type of task is this?"
                  options={typeOptions}
                  value={values.taskType}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  error={errors.taskType}
                  touched={touched.taskType}
                  fontSize="1rem"
                />
                <ErrorMessage
                  name="taskType"
                  component="div"
                  className="invalid-feedback"
                >
                  {(message) => <div className="error">{message.value}</div>}
                </ErrorMessage>
              </div>
              <div className="form-row">
                <div className="form-group col-12">
                  {user?.userRole === 0 ? (
                    <>
                      <label htmlFor="associatedUser">Assigned To *</label>
                      <Field
                        id="associatedUser"
                        name="associatedUser"
                        placeholder="Who is this for?"
                        label="Assigned To"
                        value={values.associatedUser.label}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        error={errors.associatedUser}
                        touched={touched.associatedUser}
                        readOnly={true}
                        className={classnames(
                          {
                            "is-invalid":
                              errors.associatedUser && touched.associatedUser,
                          },
                          "form-control bg-white"
                        )}
                      ></Field>
                      <Lock className="form_glyph" aria-label="lock" />
                    </>
                  ) : (
                    <MySelect
                      name="associatedUser"
                      label="Assigned To *"
                      options={users || []}
                      value={values.associatedUser}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      error={errors.associatedUser}
                      touched={touched.associatedUser}
                      fontSize="1rem"
                    />
                  )}
                  <ErrorMessage
                    name="associatedUser"
                    component="div"
                    className="invalid-feedback"
                  >
                    {" "}
                    {(message) => <div className="error">{message.label}</div>}
                  </ErrorMessage>
                </div>
              </div>
              <div
                className={classnames({
                  "form-row": patientListPermissions || patient,
                })}
              >
                <div
                  className={classnames({
                    "form-group col-12": patientListPermissions || patient,
                  })}
                >
                  {patient && (
                    <>
                      <label htmlFor="associatedPatient">
                        Associated Patient
                      </label>
                      <MySelect
                        id="associatedPatient"
                        name="associatedPatient"
                        value={values.associatedPatient}
                        options={[values.associatedPatient]}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        readOnly={true}
                      ></MySelect>
                      <Lock className="form_glyph" aria-label="lock" />
                    </>
                  )}
                  {!patient && patientListPermissions && (
                    <MyAsyncSelect
                      name="associatedPatient"
                      blurInputOnSelect={true} // actually allows for ^^ to work
                      label="Associated Patient"
                      onInputChange={(val) => {
                        if (val) {
                          setNoOpsMessage("No patients match your search");
                        } else {
                          setNoOpsMessage("Type to search for patients");
                        }
                      }}
                      isClearable={true}
                      value={values.associatedPatient}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      error={errors.associatedPatient}
                      touched={touched.associatedPatient}
                      fontSize="1rem"
                      loadOptions={loadOptions}
                      placeholder="Type to search"
                      noOptionsMessage={<>{noOpsMessage}</>}
                    />
                  )}
                  <ErrorMessage
                    name="associatedPatient"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              {!user?.isSmartSession && groups.length ? (
                <div className="form-group">
                  <MySelect
                    name="associatedGroup"
                    label="Associated Group"
                    placeholder=""
                    options={groups}
                    value={values.associatedGroup}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    error={errors.associatedGroup}
                    touched={touched.associatedGroup}
                    fontSize="1rem"
                    // position={document.body}
                  />
                  <ErrorMessage
                    name="associatedGroup"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              ) : (
                <></>
              )}
              <div className="d-flex justify-content-center pt-md-3">
                <div className={classnames("form-row w-100")}>
                  <div
                    className={classnames(
                      { "col-12 pl-0 pr-0": is1400Width },
                      { "col-6 pr-4 pl-0": !is1400Width },
                      "form-group"
                    )}
                  >
                    <div>
                      <label htmlFor="dueDate">Due Date *</label>
                    </div>
                    <div className="primary-underline w-100">
                      <Suspense
                        fallback={
                          <div className="form-control date-time"></div>
                        }
                      >
                        <DatePickerField
                          dateFormat="M/d/yyyy"
                          classnames="form-control date-time"
                          selected={values.dueDate}
                          placeholderText="M/D/YYYY"
                          onChange={(date) => {
                            setFieldValue("dueDate", date);
                            !date && setFieldValue("time", "");
                          }}
                          id="dueDate"
                        />
                      </Suspense>
                    </div>
                  </div>
                  <div
                    className={classnames(
                      { "col-12 pl-0 pr-0": is1400Width },
                      { "col-6 pl-4 pr-0": !is1400Width },
                      "form-group"
                    )}
                  >
                    <div>
                      <label htmlFor="time">Time</label>
                    </div>
                    <div className="primary-underline w-100">
                      <Suspense
                        fallback={
                          <div className="form-control date-time"></div>
                        }
                      >
                        <TimePickerField
                          selected={values.time}
                          onChange={(date) => {
                            setFieldValue("time", date);
                          }}
                          id="time"
                          showTimeSelect
                          // make clearable
                          //isClearable={true}
                          showTimeSelectOnly
                          timeIntervals={30}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          classnames="form-control date-time"
                          placeholderText="HH:MM AM/PM"
                          minTime={
                            // 6:00 AM
                            new Date("1/1/2020 06:00")
                          }
                          maxTime={
                            // 7:00 PM
                            new Date("1/1/2020 19:00")
                          }
                          disabled={!values.dueDate}
                        />
                      </Suspense>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={classnames(
                  "pt-6 form-group d-flex flex-column",
                  { "justify-content-center ": patient },
                  {
                    "flex-md-row align-items-center": !patient,
                  }
                )}
              >
                <button
                  data-testid={
                    version !== "patient"
                      ? `${version}task-submit-button`
                      : "submit-task-button"
                  }
                  type="submit"
                  disabled={isSubmitting || !isValid || !dirty}
                  className={classnames("btn btn-primary", {
                    "mr-md-3 mr-0 mb-md-0 mb-3": !patient,
                  })}
                >
                  {isSubmitting && (
                    <span
                      data-testid="submitting-circle"
                      className="spinner-border spinner-border-sm mr-1"
                    ></span>
                  )}
                  Save Changes
                </button>
                <button
                  type="button"
                  className={classnames("btn-link text-secondary btn", {
                    "w-100 mt-4": patient,
                  })}
                  onClick={
                    !isMobileOrTabletWidth && patient && version !== "edit"
                      ? resetForm
                      : toggleAdd
                  }
                  data-testid="clear-task-button"
                >
                  {!isMobileOrTabletWidth && patient && version !== "edit"
                    ? "Clear changes"
                    : "Cancel"}
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

TaskForm.propTypes = taskFormPropTypes;

export { TaskForm };
