import React, { Suspense } from "react";
import { FormGroup, Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import { CloseButton } from "./CloseButton";
import Dropzone from "react-dropzone";
import { ReactComponent as Docu } from "../images/document.svg";
import { ReactComponent as Image } from "../images/glyph_image.svg";
import { ReactComponent as TrashCan } from "../images/glyph_trashcan.svg";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useAlertContext } from "../context/alertContext";
const AvatarEditor = React.lazy(() => import("react-avatar-editor"));

export const PicModal = ({
  modal,
  toggle,
  setPic,
  handleDrop,
  handleUpload,
  pic,
  picRef,
  scale,
  setDisabled,
  setScale,
  disabled,
  loading,
  type,
  width,
  borderRadius,
  height
}) => {
  const { alertMethods } = useAlertContext();
  const [uploading, setUploading] = React.useState(false);
  const [tempInfo, setTempInfo] = React.useState(null);

  const allowedFileTypes = ["image/jpeg", "image/png"]; // allowed file types
  const maxFileSize = 2097152; // maximum file size in bytes (2 MB)

  const handleFileInputChange = (file) => {
    if (file && file.size > maxFileSize) {
      alertMethods.error("File size exceeds the maximum limit of 2MB.");
    } else if (!allowedFileTypes.includes(file?.type)) {
      alertMethods.error("Invalid file type. Please try again.");
    } else {
      setUploading(true);

      setTempInfo(file);

      setPic(URL.createObjectURL(file));
    }
  };

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} close={<CloseButton toggle={toggle} />}>
        {type === "profile" ? "Profile Picture" : type}
      </ModalHeader>
      <ModalBody>
        <h4>Upload a New {type === "profile" ? "Profile Picture" : type}</h4>
        <p>
          You can follow the link below in order to upload a{" "}
          {type === "profile" ? "picture" : "file"} from your device.
          Alternatively, you can drag a file into the space below.{" "}
        </p>
        <p>Please keep all files under 2 mb and in JPG and PNG format only.</p>
        {type !== "profile" && (
          <p>
            For best results, resize your logo to fill the dark box and align
            with the {type === "Logo" ? "top left corner." : "top center"}
          </p>
        )}
        <div className="">
          <label
            className="btn btn-link pl-0 text-primary"
            type="button"
            onBeforeInput={(e) => {
              e.preventDefault();
              document.getElementById("file-upload").click();
            }}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                document.getElementById("file-upload").click();
              }
            }}
          >
            Upload {type === "profile" ? "Picture" : "File"} from Device
            <input
              className="d-none"
              type="file"
              accept="image/jpeg, image/png"
              data-testid="file-upload"
              id="file-upload"
              onChange={(e) => {
                const file = e.target.files[0];
                handleFileInputChange(file);
              }}
            />
          </label>
          <Dropzone
            onDrop={(e) => {
              const file = e[0];
              handleFileInputChange(file);
            }}
            noClick
            noKeyboard
            className="h-250px w-250px"
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                {(!pic || uploading) && (
                  <div className="d-flex align-items-center justify-content-center mb-7 mt-5 pic-modal-dropzone">
                    <div className="text-primary">
                      <Docu
                        className="mr-3 glyph-link-blue"
                        aria-label="document"
                      />
                      Drag your file here
                    </div>
                  </div>
                )}
                <div className={classnames("d-flex justify-content-center")}>
                  <Suspense fallback={<div className=""></div>}>
                    <AvatarEditor
                      ref={picRef}
                      image={pic}
                      className={classnames(
                        { "d-none": !pic || uploading },
                        { "bg-dark-dark": type !== "profile" }
                      )}
                      width={width}
                      borderRadius={borderRadius}
                      height={height || 250}
                      border={50}
                      color={[255, 255, 255, 0.6]} // RGBA
                      scale={scale}
                      rotate={0}
                      onLoadSuccess={() => {
                        setDisabled(false);
                        setUploading(false);
                      }}
                      onLoadFailure={() => {
                        setDisabled(true);
                        setPic("");
                      }}
                    />
                  </Suspense>
                  <input {...getInputProps()} />
                </div>
              </div>
            )}
          </Dropzone>
          {pic && !uploading && (
            <div>
              <FormGroup className="mx-6 d-flex align-items-center mt-2 justify-content-center">
                <Image className="glyph-dark w-12px" aria-label="small image" />
                <Input
                  className="mx-3"
                  type="range"
                  name="range"
                  id="exampleRange"
                  min={0.1}
                  max={3}
                  step={0.05}
                  value={scale}
                  onChange={(e) => {
                    setScale(Number(e.target.value));
                  }}
                />
                <Image className="glyph-dark w-18px" aria-label="large image" />
                <button
                  className="btn btn-link ml-5"
                  onClick={() => {
                    setPic("");
                    setDisabled(true);
                    document.getElementById("file-upload").value = "";
                  }}
                >
                  <TrashCan
                    className="glyph-dark h-15px"
                    aria-label="trashcan"
                  />
                </button>
              </FormGroup>
            </div>
          )}
        </div>
        <div className="mt-4 mb-1 d-flex flex-column align-items-center">
          <button
            disabled={disabled}
            className="btn btn-primary mb-4 w-100"
            onClick={() => {
              // sending fileName to handleUpload so we can update it when handle upload has completed
              handleUpload(tempInfo);
            }}
          >
            {loading && (
              <span className="spinner-border spinner-border-sm mr-1"></span>
            )}
            Save
          </button>
          <button
            className="btn btn-link text-secondary w-100"
            onClick={toggle}
          >
            Cancel
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

PicModal.propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  setPic: PropTypes.func,
  handleDrop: PropTypes.func,
  handleUpload: PropTypes.func,
  pic: PropTypes.string,
  picRef: PropTypes.object,
  scale: PropTypes.number,
  setDisabled: PropTypes.func,
  setScale: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  type: PropTypes.string,
  setInfo: PropTypes.func,
  width: PropTypes.number,
  borderRadius: PropTypes.number,
  height: PropTypes.number
};
