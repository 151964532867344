import classNames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import { CloseButton } from "../../_components";
import { resourceService } from "../../_services";
import { PatientContext } from "../../context/patientContext";
import { ReactComponent as Download } from "../../images/download.svg";
import { InteractionsReport } from "../../resources/InteractionsReport";
import { LeafletReport } from "../../resources/LeafletReport";

const PatientPacket = (props) => {
  const [modal, setModal] = useState(false);
  const { state, setSpanishLeaflets } = useContext(PatientContext);

  const {
    medications,
    spanishLeaflets,
    englishLeaflets,
    allInteractions,
    activeMedNameList
  } = state;
  const [visibleLeaflets, setVisibleLeaflets] = useState([]);
  const [activeTab, setActiveTab] = useState("1");

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    if (englishLeaflets?.length) setVisibleLeaflets(englishLeaflets);
  }, [englishLeaflets]);

  const fetchLeaflets = React.useCallback(
    (meds, tab, setSubmitting) => {
      if (tab === 1) {
        setVisibleLeaflets(spanishLeaflets);
        if (!spanishLeaflets.length) {
          if (medications.length)
            resourceService
              .getLeaflets(activeMedNameList, 1)
              .then((res) => {
                setSubmitting(false);
                let medsWithLeaflets = res.filter((med) => med.leaflet);
                setSpanishLeaflets(medsWithLeaflets);
                setVisibleLeaflets(medsWithLeaflets);
              })
              .catch((err) => {
                setSubmitting(false);
                console.log(err);
              });
        } else setSubmitting(false);
      } else {
        setVisibleLeaflets(englishLeaflets);
        setSubmitting(false);
      }
    },
    [
      activeMedNameList,
      englishLeaflets,
      medications.length,
      setSpanishLeaflets,
      spanishLeaflets
    ]
  );
  const toggle = () => {
    setModal(!modal);

    setVisibleLeaflets(englishLeaflets);
  };

  return (
    <>
      <button
        className="btn btn-small bg-white ml-3 btn-outline-graydark1 text-dark d-flex align-items-center"
        onClick={() => {
          toggle();
          // remove focus on button after click
        }}
        id="create-patient-packet"
      >
        <Download
          className="h24-w24-style mr-1"
          aria-label="create-patient-packet"
        />{" "}
        Med Guide
      </button>

      <Modal
        isOpen={modal}
        toggle={toggle}
        className="patient-modal modal-large"
      >
        <ModalHeader toggle={toggle} close={<CloseButton toggle={toggle} />}>
          Patient Education Packet
        </ModalHeader>
        <ModalBody className="p-0">
          <Nav tabs className="py-1">
            <NavItem>
              <NavLink
                className={classNames({ active: activeTab === "1" })}
                onClick={() => {
                  toggleTab("1");
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") toggleTab("1");
                }}
                tabIndex={0}
              >
                Leaflets
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames({ active: activeTab === "2" })}
                onClick={() => {
                  toggleTab("2");
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") toggleTab("2");
                }}
                tabIndex={0}
              >
                Drug Interactions
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab} className="">
            <TabPane tabId="1">
              <LeafletReport
                leaflets={visibleLeaflets}
                version="patient"
                getResources={fetchLeaflets}
              />
            </TabPane>
            <TabPane tabId="2">
              <InteractionsReport
                version="patient"
                interactions={allInteractions}
              />
            </TabPane>
            <TabPane tabId="3"></TabPane>
          </TabContent>
        </ModalBody>
      </Modal>
    </>
  );
};

export { PatientPacket };
