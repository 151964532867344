import React, {
  createContext,
  useCallback,
  useMemo,
  useReducer,
  useContext
} from "react";
import PropTypes from "prop-types";

const UserContext = createContext(false);

const initialState = {
  user: undefined,
  userPermissions: undefined,
  tenantFeatures: undefined,
  brandLogo: undefined,
  brandFavicon: undefined,
  profilePicture: undefined
};

const userReducer = (state, action) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: action.payload
      };
    case "SET_USER_PERMISSIONS":
      return {
        ...state,
        userPermissions: action.payload
      };
    case "SET_TENANT_FEATURES":
      return {
        ...state,
        tenantFeatures: action.payload
      };
    case "SET_BRAND_LOGO":
      return {
        ...state,
        brandLogo: action.payload
      };
    case "SET_BRAND_FAVICON":
      return {
        ...state,
        brandFavicon: action.payload
      };
    case "SET_PROFILE_PICTURE":
      return {
        ...state,
        profilePicture: action.payload
      };
    default:
      return state;
  }
};

const UserProvider = ({ children }) => {
  const [userState, dispatch] = useReducer(userReducer, initialState);

  const updateUser = useCallback((user) => {
    dispatch({ type: "SET_USER", payload: user });
  }, []);

  const updatePermissions = useCallback((permissions) => {
    dispatch({ type: "SET_USER_PERMISSIONS", payload: permissions });
  }, []);

  const updateTenantFeatures = useCallback((tenantFeatures) => {
    dispatch({ type: "SET_TENANT_FEATURES", payload: tenantFeatures });
  }, []);

  const setBrandLogo = useCallback((brandLogo) => {
    dispatch({ type: "SET_BRAND_LOGO", payload: brandLogo });
  }, []);

  const setBrandFavicon = useCallback((brandFavicon) => {
    dispatch({ type: "SET_BRAND_FAVICON", payload: brandFavicon });
  }, []);

  const setProfilePicture = useCallback((profilePicture) => {
    dispatch({ type: "SET_PROFILE_PICTURE", payload: profilePicture });
  }, []);

  const contextValue = useMemo(
    () => ({
      userState,
      updateUser,
      updatePermissions,
      updateTenantFeatures,
      setBrandLogo,
      setBrandFavicon,
      setProfilePicture
    }),
    [
      userState,
      updateUser,
      updatePermissions,
      updateTenantFeatures,
      setBrandLogo,
      setBrandFavicon,
      setProfilePicture
    ]
  );

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};
const useUserContext = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    return { initialState };
  }
  return context;
};

export { useUserContext, UserProvider, UserContext };

UserProvider.propTypes = {
  children: PropTypes.node
};
