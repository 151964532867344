import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";

export const FormikValidate = ({ children }) => {
  const { values, validateForm } = useFormikContext();
  useEffect(() => {
    validateForm();
  }, [values, validateForm]);
  return <>{children}</>;
};
FormikValidate.propTypes = {
  children: PropTypes.node
};
