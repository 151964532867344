import React from "react";

import { Card, CardBody, CardHeader } from "reactstrap";
import { dummyQualityMeasures, claimsAlertData } from "../DummyClaimsData";

export const QualityCard = (props) => {
  claimsAlertData.filter((item) => item.severity !== "low");

  return (
    <Card className="bg-white">
      <CardHeader>
        <h6>Quality Summary</h6>
      </CardHeader>
      <CardBody className="p-4">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex flex-column align-items-center">
            <h2>
              {
                dummyQualityMeasures.filter((item) => item.status === "Active")
                  .length
              }
            </h2>
            <div className="text-center">Active Measures</div>
          </div>
          <div className="d-flex flex-column align-items-center mx-1">
            <h2 className="">
              {claimsAlertData.filter((item) => item.severity !== "low").length}
            </h2>
            <div className="text-center"> Action Items</div>
          </div>

          <div className="d-flex flex-column align-items-center">
            <h2>
              {
                dummyQualityMeasures.filter((item) => item.status !== "Active")
                  .length
              }
            </h2>
            <div className="text-center">Closed Measures</div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
