import React from "react";
import { ReactComponent as Check } from "../images/circle-check-alt.svg";
import PropTypes from "prop-types";

const SuccessModal = ({
  toggle,
  medName,
  goBack,
  editVersion,
  setSelectedMed,
  setRowId
}) => {
  return (
    <div className="col-md-10 offset-md-1">
      <div className="d-flex mb-4 mt-3">
        <Check className="mr-3" aria-label="checkmark" />
        <h3>Success!</h3>
      </div>
      <p>
        You've {editVersion ? "edited" : "added"} {medName}. Would you like to
        add another medication for this patient?
      </p>
      <div className="form-group pt-2 d-flex flex-lg-row flex-column align-items-center">
        <button
          type="button"
          className="btn btn-primary  mr-lg-4 mr-0 mb-lg-0 mb-4"
          onClick={() => {
            setRowId(null);
            setSelectedMed({});
            goBack();
          }}
        >
          Add Another Med
        </button>
        <button
          type="button"
          className="btn btn-outline-primary "
          onClick={() => {
            setRowId(null);
            setSelectedMed({});
            toggle();
          }}
        >
          Back to Invitation
        </button>
      </div>
    </div>
  );
};
SuccessModal.propTypes={
  toggle: PropTypes.func,
  medName: PropTypes.string,
  goBack: PropTypes.func,
  editVersion: PropTypes.bool,
  setSelectedMed: PropTypes.func,
  setRowId: PropTypes.func

}
export { SuccessModal };
