import classnames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Popover, PopoverBody, PopoverHeader } from "reactstrap";
import {
  deepCopy,
  findAndMoveToCommon,
  formatTime,
  isPRN,
  timesOfDay
} from "../_helpers";
import { sigCodes } from "../_helpers/Frequency";
import { formEnum } from "../_helpers/formEnum";
import { quantityUnitsEnum } from "../_helpers/quantityUnits";
import { medicationsService } from "../_services";
import { ReactComponent as Exclamation } from "../images/glyph-exclamation.svg";
import { CloseButton } from "./CloseButton";
import { CustomCreatableDropdown } from "./CustomCreatableDropdown";
import { MyTooltip } from "./MyTooltip";
import { NoEditModal } from "./NoEditModal";

const MissingInfoBox = ({ text, id, onClick }) => {
  return (
    <button
      className="btn btn-outline-danger bg-gray border-radius-normal text-dark px-2 btn-small word-break-normal d-flex flex-wrap align-items-center justify-content-center"
      id={id}
      type="button"
      onClick={() => {
        if (onClick) onClick();
      }}
    >
      <Exclamation className="mr-1 mb-1" aria-label="exlamation" />
      <div>{text}</div>
    </button>
  );
};
MissingInfoBox.propTypes = {
  text: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func
};

function replaceDailyWithString(str) {
  return str.replace(/DAILY/g, "Daily");
}
const FrequencyCell = ({
  value,
  row,
  setMeds,
  editDisabled,
  setFormEdited
}) => {
  const { id, tempId, medicationSchedule } = row;
  const { scheduleType, daysOn, daysOff, sigCode } = medicationSchedule;
  const [newSig, setNewSig] = useState({});
  const [editedSigCodes, setEditedSigCodes] = useState(sigCodes);
  const [popover, setPopover] = useState(false);
  const [modal, setModal] = useState(false);
  let medId = id || tempId;

  const toggle = () => {
    if (editDisabled) {
      setModal(!modal);
    } else setPopover(!popover);
  };

  let text = "Add SIG code";

  let cycleStr = "";

  if (scheduleType === 6 || sigCode === "Cycle") {
    cycleStr = daysOn + " + " + daysOff;
  }

  const handleSelect = (val) => {
    if (val?.value) {
      setNewSig(val);
    } else setNewSig(undefined);
  };

  const handleSave = () => {
    const timesOfDayFindCallBack = (val) => (item) => item.value === val;
    if (setMeds && newSig?.value) {
      setMeds((prev) => {
        const newMeds = [...prev];
        const med = newMeds.find((m) => {
          if (tempId) return m.tempId === medId;
          else return m.id === medId;
        });
        med.medicationSchedule.frequencyId = newSig.value;
        med.medicationSchedule.sigCode = newSig.label;
        let updatedTimes = [];
        if (newSig.SuggestedTimes && newSig.SuggestedTimes.length > 0) {
          updatedTimes = newSig.SuggestedTimes.map((timeValue, idx) => {
            const timeOfDay = timesOfDay.find(
              timesOfDayFindCallBack(timeValue)
            );
            return {
              doseQuantity: newSig?.numberOfDoses,
              time: "",
              partOfDay: timeOfDay?.value
            };
          });
        }
        med.medicationSchedule.medicationScheduleTimes = updatedTimes;
        return newMeds;
      });
    }
    if (setFormEdited) setFormEdited(true);
  };

  useEffect(() => {
    popover && setNewSig(undefined);
    if (popover && row.drugDetailId) {
      medicationsService.searchMeds(row?.medicationName).then((res) => {
        let med = res.medications[0];
        if (med) {
          let selectedDrug = med.strengths.find(
            (m) => m.id === row.drugDetailId
          );
          if (selectedDrug?.commonFrequencies) {
            let updatedEditedSigCodes = deepCopy(sigCodes);
            let commonGroup = {
              label: "Common",
              options: []
            };

            const cfSet = new Set(selectedDrug.commonFrequencies);
            updatedEditedSigCodes = findAndMoveToCommon(
              cfSet,
              updatedEditedSigCodes,
              commonGroup
            );
            setEditedSigCodes((prev) => [
              commonGroup,
              ...updatedEditedSigCodes
            ]);
          } else setEditedSigCodes(sigCodes);
        }
      });
    }
  }, [popover, row.drugDetailId, row?.medicationName]);

  return (
    <>
      {value || scheduleType === 6 ? (
        <>
          {replaceDailyWithString(value)}
          {(scheduleType === 6 || sigCode === "Cycle") && <> ({cycleStr})</>}
          {isPRN(medicationSchedule) && value !== "PRN" ? ", PRN" : ""}{" "}
        </>
      ) : (
        <>
          <MissingInfoBox
            text={text}
            id={`sigpopover-${medId}`}
            onClick={toggle}
          />
          {!editDisabled ? (
            <Popover
              placement="bottom"
              isOpen={popover}
              target={`sigpopover-${medId}`}
              toggle={toggle}
              trigger="legacy"
              className="dose-popover"
            >
              <PopoverHeader>
                Select Frequency{" "}
                <CloseButton
                  toggle={toggle}
                  color="glyph-white"
                  popoverVersion={true}
                />
              </PopoverHeader>
              <PopoverBody className="px-4">
                <label htmlFor={`frequency-${medId}`}>
                  <span className=" font-weight-medium">Frequency</span>
                </label>

                <CustomCreatableDropdown
                  name={`frequency-${medId}`}
                  value={newSig}
                  classname="frequency"
                  id={`frequency-filter-${medId}`}
                  onSelect={handleSelect}
                  placeholder="Select freq..."
                  options={editedSigCodes}
                  noSaveButton={true}
                />

                <div className="d-flex justify-content-end mt-4">
                  <button
                    className="btn btn-primary"
                    disabled={!newSig?.value}
                    onClick={handleSave}
                    data-testid="save-dose"
                  >
                    Save
                  </button>
                </div>
              </PopoverBody>
            </Popover>
          ) : (
            <NoEditModal isOpen={modal} toggle={() => setModal(!modal)} />
          )}
        </>
      )}
    </>
  );
};
FrequencyCell.propTypes = {
  value: PropTypes.string,
  row: PropTypes.object,
  setMeds: PropTypes.func,
  editDisabled: PropTypes.bool,
  setFormEdited: PropTypes.func
};

const DosesCell = ({ medicationSchedule }) => {
  const {
    medicationScheduleTimes,
    doseQuantity,
    quantityUnit,
    quantityUnitId
  } = medicationSchedule;

  let qu = quantityUnit;
  if (!quantityUnit && quantityUnitId) {
    qu = quantityUnitsEnum.find((unit) => unit.value === quantityUnitId)?.abbr;
  }

  // Filter out strings made up of 0s and spaces
  let contentSet = new Set();
  const sortedData = medicationScheduleTimes.sort((a, b) => {
    const timeA = a.time.split(":").map(Number);
    const timeB = b.time.split(":").map(Number);

    if (timeA[0] !== timeB[0]) {
      return timeA[0] - timeB[0]; // Sort by hours
    } else {
      return timeA[1] - timeB[1]; // If hours are the same, sort by minutes
    }
  });
  if (sortedData?.length) {
    sortedData
      .sort((a, b) => Number(a.time) - Number(b.time))
      .forEach((time) => {
        let str = formatTime(time.time);
        let quantityStr = time.doseQuantity ? `${time.doseQuantity} ` : "";
        if (str?.startsWith("0")) {
          str = str.replace(/^0+/, "12");
        }
        if (str && !isPRN(medicationSchedule)) {
          contentSet.add(
            `Take ${quantityStr}${qu ? qu.toLowerCase() + " " : ""}at ${str}`
          );
        } else {
          contentSet.add(
            `Take ${quantityStr}${qu ? qu.toLowerCase() + " " : ""}As Needed`
          );
        }
      });
  }

  // Convert the set back to an array
  let content;
  if (contentSet.size)
    content = Array.from(contentSet).map((str, i) => (
      <li key={`${i}-${str}`}>{str}</li>
    ));
  else if (doseQuantity) {
    content = <li>Take {doseQuantity}</li>;
  }

  return (
    <>
      {content ? (
        <ul className="pl-5">{content}</ul>
      ) : (
        <MissingInfoBox text="Add Dose" />
      )}
    </>
  );
};
DosesCell.propTypes = {
  medicationSchedule: PropTypes.object
};

const SimpleMedicationCell = ({ row, value }) => {
  const { original } = row;
  const { medicationStrength, drugDetailId } = original;

  return (
    <div>
      <div className="font-weight-bold">
        {value}
        {!drugDetailId && "*"}
      </div>
      <div>
        {medicationStrength.strength}
        {drugDetailId || medicationStrength?.form
          ? " " + medicationStrength?.form
          : ""}
      </div>
    </div>
  );
};

SimpleMedicationCell.propTypes = {
  row: PropTypes.object,
  value: PropTypes.string
};

const MedicationCell = ({ row, value, version }) => {
  const { original } = row;
  const { status, medicationStrength, isDuplicate, id } = original;

  return (
    <div
      className={classnames({
        "text-graydark1": status && version !== "med-list"
      })}
    >
      <div className="font-weight-bold">
        {isDuplicate && (
          <>
            <button
              aria-describedby="email"
              href="#"
              type="button"
              id={`duplicate-${id}`}
              className="sentvia-popover btn focusable-text ml-neg-2rem"
            >
              <Exclamation
                className="mb-1 glyph-danger mr-1"
                aria-label="exlamation"
              />
            </button>
            <MyTooltip
              target={`duplicate-${id}`}
              text="Remove/unselect duplicate medications before proceeding"
            />
          </>
        )}
        {value}
      </div>
      <div>
        {medicationStrength.strength}
        {medicationStrength?.form ? " " + medicationStrength?.form : ""}
      </div>
    </div>
  );
};
MedicationCell.propTypes = {
  row: PropTypes.object,
  value: PropTypes.string,
  version: PropTypes.string
};

const DoseCell = ({
  medicationSchedule,
  row,
  setMeds,
  editDisabled,
  setFormEdited
}) => {
  const [popover, setPopover] = useState(false);
  const [editedDoseOptions, setEditedDoseOptions] = useState([
    {
      label: "Other",
      options: quantityUnitsEnum.map((unit) => ({
        value: unit.value,
        label: unit.abbr,
        description: unit.label
      }))
    }
  ]);

  const [modal, setModal] = useState(false);

  const toggle = () => {
    if (editDisabled) {
      setModal(!modal);
    } else setPopover(!popover);
  };

  const { id, medicationStrength, tempId } = row;
  let medId = id || tempId;
  const { doseQuantity, quantityUnit, quantityUnitId } = medicationSchedule;
  let qu =
    quantityUnit ||
    quantityUnitsEnum.find((unit) => unit.value === quantityUnitId)?.abbr;

  const [newDose, setNewDose] = useState({
    value: quantityUnitId,
    label: qu
  });
  const [newDoseQuantity, setNewDoseQuantity] = useState(doseQuantity);
  useEffect(() => {
    setNewDoseQuantity(popover ? doseQuantity : null);
    setNewDose(
      popover
        ? {
            value: quantityUnitId,
            label: qu
          }
        : null
    );
  }, [doseQuantity, popover, qu, quantityUnitId]);

  let dq = doseQuantity;

  useEffect(() => {
    if (popover && medicationStrength?.formId) {
      let selectedForm = formEnum.find(
        (f) => f.value === medicationStrength.formId
      );
      if (
        medicationStrength?.preferredQuantityUnitId ||
        selectedForm?.preferredUnitId ||
        selectedForm?.commonUnitIds
      ) {
        let commonSet = new Set();
        if (medicationStrength?.preferredQuantityUnit) {
          commonSet.add(medicationStrength.preferredQuantityUnit);
        }
        if (selectedForm?.preferredUnitId) {
          commonSet.add(selectedForm.preferredUnitId);
        }
        if (selectedForm?.commonUnitIds) {
          selectedForm.commonUnitIds.forEach((id) => commonSet.add(id));
        }
        const categorizedUnits = quantityUnitsEnum.reduce(
          (acc, item) => {
            if (commonSet.has(item.value)) {
              acc.common.push(item);
              commonSet.delete(item.value);
            } else {
              acc.other.push(item);
            }
            return acc;
          },
          { common: [], other: [] }
        );

        setEditedDoseOptions([
          {
            label: "Common",
            options: categorizedUnits.common.map((unit) => ({
              value: unit.value,
              label: unit.abbr,
              description: unit.label
            }))
          },
          {
            label: "Other",
            options: categorizedUnits.other.map((unit) => ({
              value: unit.value,
              label: unit.abbr,
              description: unit.label
            }))
          }
        ]);
      } else
        setEditedDoseOptions([
          {
            label: "Other",
            options: quantityUnitsEnum.map((unit) => ({
              value: unit.value,
              label: unit.abbr,
              description: unit.label
            }))
          }
        ]);
    }
  }, [
    medicationStrength.formId,
    medicationStrength.preferredQuantityUnit,
    medicationStrength?.preferredQuantityUnitId,
    popover
  ]);

  const handleSelect = (val) => {
    if (val?.value) {
      setNewDose(val);
    } else setNewDose(undefined);
  };

  const handleSave = () => {
    setMeds((prev) => {
      const newMeds = [...prev];
      const med = newMeds.find((m) => {
        if (tempId) return m.tempId === medId;
        else return m.id === medId;
      });
      med.medicationSchedule.quantityUnitId = newDose.value;
      med.medicationSchedule.quantityUnit = newDose.label;
      med.medicationSchedule.doseQuantity = newDoseQuantity;
      return newMeds;
    });
    if (setFormEdited) setFormEdited(true);
  };

  return (
    <>
      {dq ? (
        <>
          {dq} <span className="white-space-nowrap">{qu}</span>
        </>
      ) : (
        <>
          <MissingInfoBox
            text={"Add Dose"}
            id={`dosepopover-${medId}`}
            onClick={toggle}
          />
          {!editDisabled ? (
            <Popover
              placement="bottom"
              isOpen={popover}
              target={`dosepopover-${medId}`}
              toggle={toggle}
              trigger="legacy"
              className="dose-popover"
            >
              <PopoverHeader>
                Select Dose{" "}
                <CloseButton
                  toggle={toggle}
                  color="glyph-white"
                  popoverVersion={true}
                />
              </PopoverHeader>
              <PopoverBody className="px-4">
                <div className="form-row">
                  <div className="form-group col-4 pr-0">
                    <label htmlFor="dose" id="dose-label">
                      <span className=" font-weight-medium">Dose</span>
                    </label>
                    <input
                      type="number"
                      placeholder="Qty"
                      name="dose"
                      id="dose"
                      min={0}
                      step={"any"}
                      max={9999}
                      className="form-control dose-field bg-gray"
                      onChange={(e) => {
                        if (e.target.value) {
                          setNewDoseQuantity(e.target.value);
                        }
                      }}
                    />
                  </div>
                  <div className="form-group col-8 mt-1">
                    <label htmlFor={`doseType-${medId}`}></label>

                    <CustomCreatableDropdown
                      value={newDose}
                      name={`doseType-${medId}`}
                      classname="doseType"
                      id={`unit-filter-${medId}`}
                      onSelect={handleSelect}
                      placeholder="Unit"
                      noSaveButton={true}
                      options={editedDoseOptions}
                      createable={true}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <button
                    className="btn btn-primary"
                    disabled={
                      !newDoseQuantity ||
                      newDoseQuantity <= 0 ||
                      !newDose?.value
                    }
                    onClick={handleSave}
                    data-testid="save-dose"
                  >
                    Save
                  </button>
                </div>
              </PopoverBody>
            </Popover>
          ) : (
            <NoEditModal isOpen={modal} toggle={() => setModal(!modal)} />
          )}
        </>
      )}
    </>
  );
};
DoseCell.propTypes = {
  medicationSchedule: PropTypes.object,
  row: PropTypes.object,
  setMeds: PropTypes.func,
  editDisabled: PropTypes.bool,
  setFormEdited: PropTypes.func
};

export {
  DoseCell,
  DosesCell,
  FrequencyCell,
  MedicationCell,
  SimpleMedicationCell
};
