import React, { useEffect, useContext, useState, useRef } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { accountService, patientInviteService } from "../_services";
import { useUserContext } from "../context/userContext";
import { SmartContext } from "../context/smartContext";
import { AcceptTerms } from "./AcceptTerms";
import { smartService } from "../_services/smart.service";

const Smart = () => {
  const {
    patientId,
    patientInviteStatus,
    mPageType,
    setPatientId,
    setPatientInviteStatus,
    setMPageType,
    setIsMPages,
    isMPages,
    setPatientName
  } = useContext(SmartContext);
  const { updateUser, updatePermissions, updateTenantFeatures } =
    useUserContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [showTermsPage, setShowTermsPage] = useState(false);
  const [success, setSuccess] = useState(false);
  const [noPatientId, setNoPatientId] = useState(false);
  const [noInviteStatus, setNoInviteStatus] = useState(false);

  function isMPage() {
    return window.self != window.top && window.CernerSmartEmbeddableLib;
  }

  const navigateRef = useRef();

  useEffect(() => {
    navigateRef.current = navigate;
  }, [navigate]);

  useEffect(() => {
    // remove hidden attribute from html element

    setSuccess(false);
    setShowTermsPage(false);
    let mounted = true;
    localStorage.removeItem("isMPages");
    sessionStorage.removeItem("patientId");
    sessionStorage.removeItem("invitationStatus");
    smartService
      .smartAuthorize(location.search, isMPage())
      .then((response) => {
        if (mounted) {
          const {
            integrationType: it,
            mPageType: mpt,
            showTermsPage,
            patientInvitationStatus,
            patientId,
            patientName
          } = response;

          // Ensure the 'hidden' attribute is removed from the HTML element if applicable
          const html = document.querySelector("html");
          if (it !== 1 && html.hasAttribute("hidden")) {
            html.removeAttribute("hidden");
          }

          // Show terms page if required
          if (showTermsPage) setShowTermsPage(true);

          // Handle MPage frame height calculations
          if (isMPage() && it === 1) {
            window.CernerSmartEmbeddableLib.calcFrameHeight = function () {
              return mpt === 1 || mpt === 2 ? 500 : 400;
            };
          }

          // Set patient invitation status
          if (patientInvitationStatus || patientInvitationStatus === 0) {
            const status =
              patientInvitationStatus === 0 || patientInvitationStatus >= 6
                ? 0
                : patientInvitationStatus;
            setPatientInviteStatus(status);
            sessionStorage.setItem("invitationStatus", JSON.stringify(status));
          } else {
            setNoInviteStatus(true);
          }

          // Set patient ID
          if (patientId) {
            setPatientId(patientId);
            sessionStorage.setItem("patientId", JSON.stringify(patientId));
          } else {
            setNoPatientId(true);
          }

          // Set patient name
          if (patientName) {
            setPatientName(patientName);
            sessionStorage.setItem("patientName", JSON.stringify(patientName));
          }

          // Handle MPage setting
          const isMPageActive = mpt !== 1 && isMPage() && it === 1;
          setIsMPages(isMPageActive);
          localStorage.setItem("isMPage", JSON.stringify(isMPageActive));

          // Set page type and success flag
          setMPageType(mpt);
          setSuccess(true);
        }
      })
      .catch((e) => {
        if (e.error === "User is blocked") {
          sessionStorage.setItem(
            "user",
            JSON.stringify({ isSmartSession: true })
          );
          navigateRef.current("/smart/401");
        } else if (e.email && e.tenant) {
          navigateRef.current("/smart/request-access", { state: e });
        } else if (e.goLiveDate) {
          navigateRef.current("/smart/go-live");
        } else {
          navigateRef.current("/smart/403");
        }
      });
    return function cleanup() {
      mounted = false;
    };
  }, [
    location.search,
    setIsMPages,
    setMPageType,
    setPatientId,
    setPatientInviteStatus,
    setPatientName
  ]);

  useEffect(() => {
    if (
      patientId &&
      (patientInviteStatus === 1 ||
        patientInviteStatus === 2 ||
        patientInviteStatus === 3)
    ) {
      patientInviteService.getSmartMedications(patientId).catch((e) => {
        navigate("/smart/403");
      });
    }
  }, [navigate, patientId, patientInviteStatus]);

  useEffect(() => {
    if (success)
      accountService
        .getProfile()
        .then((user) => {
          const sessionUser = { ...user, isSmartSession: true };
          updateUser(sessionUser);
          sessionStorage.setItem(
            "user",
            JSON.stringify({ name: user.name, isSmartSession: true })
          );
        })
        .catch((e) => {
          console.log(e);
        });
  }, [navigate, success, updateUser]);

  useEffect(() => {
    if (success) {
      accountService
        .getPermissions()
        .then((permissions) => {
          updatePermissions(permissions);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [success, updatePermissions]);

  useEffect(() => {
    if (success) {
      accountService
        .getTenantFeatures()
        .then((tenantFeatures) => {
          updateTenantFeatures(tenantFeatures);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [success, updateTenantFeatures]);

  return (
    <div>
      {!showTermsPage && !success && (
        <div className="d-flex justify-content-center align-items-center h-80vh">
          <span className="spinner-border mr-1"></span>
        </div>
      )}
      {showTermsPage && <AcceptTerms setShowTermsPage={setShowTermsPage} />}
      {success && !showTermsPage && (
        <>
          {isMPages && mPageType === 0 && (
            <Navigate to={`/smart/403`} state={{ isMpage: true }} />
          )}
          {patientId && (
            <>
              {isMPages && patientInviteStatus === 2 && (
                <Navigate to={`/smart/workflow/${patientId}`} />
              )}
              {isMPages && patientInviteStatus !== 2 && (
                <Navigate
                  to={`/smart/mpages/${patientId}/${patientInviteStatus}`}
                />
              )}
              {(patientInviteStatus === 0 || patientInviteStatus >= 4) &&
                !isMPages && <Navigate to={`/smart/invite/${patientId}`} />}
              {(patientInviteStatus === 1 || patientInviteStatus === 3) &&
                !isMPages && (
                  <Navigate
                    to={`/smart/manage-invitations/manage/${patientId}`}
                  />
                )}

              {patientInviteStatus === 2 && !isMPages && (
                <Navigate to={`/smart/patients/${patientId}`} />
              )}
            </>
          )}
          {noPatientId && noInviteStatus && !isMPages && (
            <Navigate to={`/smart/admin/groups`} />
          )}
        </>
      )}
    </div>
  );
};

export { Smart };
