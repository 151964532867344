import React, { useEffect, useState } from "react";
import { ReactComponent as X } from "../images/circle-x.svg";
import { testError } from "../_helpers/fetch-wrapper";
import { useLocation } from "react-router-dom";
import { useAlertContext } from "../context/alertContext";
import PropTypes from "prop-types";

const Banner = ({ banner, showBanner }) => {
  const { alerts, alertMethods } = useAlertContext();
  const { clear } = alertMethods;
  const [bannerAlert, setBannerAlert] = useState(null);
  const location = useLocation();
  const toggle = () => showBanner((prevState) => !prevState);
  const refresh = () => {
    window.location.reload();
  };
  const mounted = React.useRef(false);
  React.useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => {
      mounted.current = false;
    }; // ... and to false on unmount
  }, []);

  useEffect(() => {
    let prevPathname = location.pathname;
    let alert;
    if (alerts && alerts.length > 0) {
      alert = alerts[alerts.length - 1];
    }

    if (alert && mounted.current) {
      setTimeout(() => {
        if (testError) {
          showBanner(true);
          setBannerAlert(alert);
        }
      }, 900);
    }

    const historyUnlisten = () => {
      // don't clear if pathname has trailing slash because this will be auto redirected again
      if (location.pathname?.endsWith("/")) return;
      if (prevPathname !== location.pathname) {
        prevPathname = location.pathname;
        clear(alert.id);
        showBanner(false);
      }
    };

    // clean up function that runs when the component unmounts
    return () => {
      // unsubscribe & unlisten to avoid memory leaks
      historyUnlisten();
    };
  }, [clear, alerts, location.pathname, showBanner]);
  return (
    <div>
      <div
        id="banner"
        className={
          banner
            ? "w-100 bg-danger text-white text-center py-md-3 py-2 d-flex justify-content-center align-items-center"
            : "d-none"
        }
      >
        <span>
          <X
            className="mr-md-3 mr-1 pointer ml-1"
            tabIndex="0"
            onClick={() => {
              toggle();
              clear(bannerAlert.id);
            }}
            aria-label="x"
          />
        </span>
        <span className="mr-1">
          &nbsp;&nbsp;It looks like there is an issue retrieving information.{" "}
          <button
            className="btn-link btn text-white underline"
            onClick={refresh}
          >
            Please refresh the page.
          </button>
        </span>
      </div>
    </div>
  );
};

Banner.propTypes = {
  banner: PropTypes.bool,
  showBanner: PropTypes.func
};

export { Banner };
