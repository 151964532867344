import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { clearLocalStorage } from "../_helpers";
import { accountService } from "../_services";
import { useAlertContext } from "../context/alertContext";
import { useUserContext } from "../context/userContext";
import { useNavigate } from "react-router-dom";

const Auth0Authentication = ({ setShowTermsPage }) => {
  const { isAuthenticated, getAccessTokenSilently, logout } = useAuth0();
  const { alertMethods } = useAlertContext();
  const { updateUser, updatePermissions, updateTenantFeatures } =
    useUserContext();
  const [userSecret, setUserSecret] = useState(null);
  const navigate = useNavigate();
  const navigateRef = useRef();

  useEffect(() => {
    navigateRef.current = navigate;
  }, [navigate]);
  useEffect(() => {
    let mounted = true;
    async function getUserSecret() {
      let token;
      try {
        token = await getAccessTokenSilently();
      } catch (e) {
        clearLocalStorage();
        logout({
          logoutParams: { returnTo: window.location.origin }
        });
        sessionStorage.setItem("failedLogin", "true");

        throw e;
      }

      accountService
        .getSecret(token)
        .then((res) => {
          if (mounted) setUserSecret(true);
          // if on go-live page, redirect to dashboard
          if (
            window.location.pathname === "/go-live" ||
            window.location.pathname === "/401"
          ) {
            navigateRef.current("/");
          }
        })
        .catch((e) => {
          if (e.error?.goLiveDate || e.error?.includes("GoLiveDate")) {
            navigateRef.current("/go-live");
          } else if (e.error?.includes("User is blocked")) {
            sessionStorage.setItem(
              "user",
              JSON.stringify({ isSmartSession: false })
            );

            navigateRef.current("/401");
          } else {
            clearLocalStorage();
            logout({
              logoutParams: { returnTo: window.location.origin }
            });
          }
        });
    }
    if (mounted && isAuthenticated) {
      getUserSecret();
    }

    return function cleanup() {
      mounted = false;
    };
  }, [alertMethods, getAccessTokenSilently, isAuthenticated, logout]);

  useEffect(() => {
    if (userSecret) {
      accountService
        .getProfile()
        .then((user) => {
          if (user.status === 1) {
            clearLocalStorage();
            logout({
              logoutParams: { returnTo: window.location.origin }
            });
            return;
          } else {
            const sessionUser = { ...user, isSmartSession: false };

            sessionStorage.setItem(
              "user",
              JSON.stringify({ name: user.name, isSmartSession: false })
            );
            updateUser(sessionUser);

            localStorage.setItem("LOGIN", "true");
          }
          if (user.showTermsPage) {
            setShowTermsPage(true);
          }
        })
        .catch((e) => {
          logout({
            logoutParams: { returnTo: window.location.origin }
          });
        });
    }
  }, [userSecret, updateUser, logout, setShowTermsPage]);

  useEffect(() => {
    if (userSecret) {
      accountService
        .getPermissions()
        .then((permissions) => {
          updatePermissions(permissions);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [updatePermissions, userSecret]);

  useEffect(() => {
    if (userSecret) {
      accountService
        .getTenantFeatures()
        .then((tenantFeatures) => {
          updateTenantFeatures(tenantFeatures);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [updateTenantFeatures, userSecret]);

  return null; // Return null or any other placeholder element
};

export { Auth0Authentication };

Auth0Authentication.propTypes = {
  setShowTermsPage: PropTypes.func
};
