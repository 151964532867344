import React from "react";
import PropTypes from "prop-types";

export const LastReading = ({
  sortedValues,
  selectedUnit,
  item,
  alternateUnitSelected,
  trackerInfo,
  version,
}) => {
  const createChartHeader = (type) => {
    // Sort the values by date because sometimes they are not sorted from the api
    // Then grab the last item in the list for the last reading value
    let selectedType = sortedValues[type];
    let sortedData = selectedType?.sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    );
    let text = "";
    if (type === "Total Cholesterol") {
      text = "Total";
    } else if (type === "Triglycerides") {
      text = "TG";
    } else {
      text = type;
    }

    if (sortedData) {
      let latestValue = sortedData[selectedType?.length - 1].value;
      if (alternateUnitSelected)
        latestValue = trackerInfo.conversion(latestValue);

      if (type === "Sleep Duration") {
        return (
          <>
            {Math.floor(latestValue / 60)} hrs, {latestValue % 60} mins
            &nbsp;&nbsp;
          </>
        );
      } else if (
        type === "Pain Severity" ||
        type === "Headache Severity" ||
        type === "Nausea"
      ) {
        return <>{latestValue}/10 </>;
      } else if (type === "Duration") {
        return null;
      } else if (type === "Systolic") {
        return <>{latestValue}/</>;
      } else if (trackerInfo.name === "Cholesterol") {
        return (
          <>
            {text}: {latestValue}&nbsp;&nbsp;
          </>
        );
      } else {
        return <>{latestValue}&nbsp;</>;
      }
    } else return "";
  };
  return (
    <div className="w-100">
      {version !== "summary" && <span> Last Reading:&nbsp;</span>}
      {Object.keys(sortedValues).map((type, idx) => {
        if (type !== "Duration")
          return <span key={type}> {createChartHeader(type)}</span>;
        else return null;
      })}
      {selectedUnit !== "Level" &&
        selectedUnit !== "Scale" &&
        selectedUnit !== "Time" && <span>{selectedUnit}</span>}
      <span className="text-secondary">
        &nbsp;&nbsp; ({/* Grab date from the most recent log */}
        {new Date(item.healthTrackersLog[0].logDate).toLocaleDateString()})
      </span>
    </div>
  );
};

LastReading.propTypes = {
  sortedValues: PropTypes.object,
  selectedUnit: PropTypes.string,
  item: PropTypes.object,
  alternateUnitSelected: PropTypes.bool,
  trackerInfo: PropTypes.object,
  version: PropTypes.string,
};
