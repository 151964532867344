import React, { useMemo } from "react";
import classnames from "classnames";
import { BackButton } from "../_components";
import { useNavigate, useLocation, useNavigationType } from "react-router-dom";
import { Use1480MediaQuery, UseMobileOrTabletMediaQuery } from "../_helpers";
import PropTypes from "prop-types";
import { useUserContext } from "../context/userContext";

const PageHeader = ({
  onBackClick,
  backText,
  margin,
  title,
  activation,
  info,
  subTitle,
  leftInfo,
  textWrap,
}) => {
  const navigate = useNavigate();
  const navigateType = useNavigationType();
  const is768Width = UseMobileOrTabletMediaQuery();
  const is1480Width = Use1480MediaQuery();
  const { userState } = useUserContext();
  const { user } = userState;
  const { pathname = "" } = useLocation();
  const isShrink = useMemo(
    () => !!["connections"].find((path) => pathname.includes(path)),
    [pathname]
  );

  return (
    <div>
      {backText && (navigateType !== "POP" || onBackClick) ? (
        <div>
          <BackButton
            onClick={() => {
              // send state when navigating to previous page
              onBackClick ? onBackClick() : navigate(-1);
            }}
            backText={backText}
          />
        </div>
      ) : (
        <div
          className={classnames(
            { "pt-6ish": is768Width && !user?.isSmartSession },
            { "pt-7": !is768Width && !user?.isSmartSession },
            { "pt-4": user?.isSmartSession && is768Width }
          )}
        ></div>
      )}
      <div
        className={classnames(
          { "margin-bottom  align-items-center": margin && !isShrink },
          { "flex-wrap align-items-end": !margin },
          { "pt-1": is768Width },
          "d-flex page-header justify-content-between zIndex-100",
          {
            "flex-column flex-shrink-0 align-items-start":
              isShrink && is1480Width,
          },
          {
            "flex-row gap-30px": isShrink && !is1480Width,
          }
        )}
      >
        <div className="flex-column">
          <h3
            className={classnames({
              "mr-auto": isShrink,
            })}
          >
            {title}
          </h3>
          {subTitle ? (
            <div
              className={classnames(
                {
                  "mr-auto": isShrink,
                },
                { "d-flex": textWrap }
              )}
            >
              <h6
                className={classnames({
                  "pl-0 pr-6": textWrap,
                  "col-xl-3 col-xxl-4": textWrap && user?.fontSize === 4,
                  "col-xl-4 col-xxl-5": textWrap && user?.fontSize === 3,
                })}
              >
                {subTitle}
              </h6>
            </div>
          ) : (
            <></>
          )}
        </div>
        {activation}
        {info}
        <div className="d-inline-block ml-auto">{leftInfo}</div>
      </div>
    </div>
  );
};

PageHeader.propTypes = {
  onBackClick: PropTypes.func,
  backText: PropTypes.string,
  margin: PropTypes.bool,
  title: PropTypes.string,
  activation: PropTypes.element,
  info: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  leftInfo: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  textWrap: PropTypes.bool,
};

export { PageHeader };
