import { fetchWrapper } from "../_helpers/index";

export const resourceService = {
  getLeaflets,
  getInteractions,
  getImages,
  createLeafletReport,
  createInteractionsReport,
  getImageFromNDC,
  getSavingsCardUrl,
  getSavingsCardImage,
  getSavingsCardDocument
};

const baseUrl = "/api/resource";

function getLeaflets(medsArray, language) {
  let url = `${baseUrl}/leaflets?language=${language}`;
  medsArray.forEach((med) => (url += `&medicationNames=${med}`));
  return fetchWrapper.get(url);
}

function getInteractions(clinical, medsArray) {
  let url = `${baseUrl}/interactions?clinical=${clinical}`;
  medsArray.forEach((med) => (url += `&medicationNames=${med}`));
  return fetchWrapper.get(url);
}

function getImages(body) {
  let url = `${baseUrl}/drugImages?`;
  for (const key in body) {
    if (body[key]) url += `&${key}=${body[key]}`;
  }
  return fetchWrapper.get(url);
}

function createLeafletReport(reportType, reportDocumentType, fields) {
  const url = `${baseUrl}/leaflets/report?reportType=${reportType}&reportDocumentType=${reportDocumentType}`;
  return fetchWrapper.post(url, fields);
}

function createInteractionsReport(reportType, reportDocumentType, fields) {
  const url = `${baseUrl}/interactions/report?reportType=${reportType}&reportDocumentType=${reportDocumentType}`;
  return fetchWrapper.post(url, fields);
}

function getImageFromNDC(ndc) {
  const url = `${baseUrl}/drugimage/${ndc}`;
  return fetchWrapper.get(url);
}

function getSavingsCardUrl() {
  return fetchWrapper.get(`${baseUrl}/savingscard/url`);
}

function getSavingsCardImage() {
  return fetchWrapper.get(`${baseUrl}/savingscard/image`);
}

function getSavingsCardDocument() {
  return fetchWrapper.get(`${baseUrl}/savingscard/document`);
}
