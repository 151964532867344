import React from "react";
import { useLocation } from "react-router";
import { smartService } from "../_services";
import { useAlertContext } from "../context/alertContext";
import logo from "../images/ED-Hortizontal_2-color_Logo.png";

const RequestAccess = () => {
  const location = useLocation();
  const { state } = location;
  const { alertMethods } = useAlertContext();
  const [submitting, setSubmitting] = React.useState(false);

  const requestAccess = () => {
    setSubmitting(true);
    smartService
      .requestSmartAccess(state)
      .then((response) => {
        alertMethods.success(
          "Your request has been sent to your organization's administrator. You will receive an email when your request has been approved."
        );
        setSubmitting(false);
      })
      .catch((e) => {
        alertMethods.error(
          "Something went wrong. Refresh the page and try again."
        );
        setSubmitting(false);
      });
  };
  return (
    <div className="d-flex flex-column align-items-center smart-container">
      <div className="card bg-white align-self-center invite">
        <div className="card-body text-center">
          <div className="d-flex justify-content-center pb-4">
            <img
              src={logo}
              alt="EveryDose"
              className="logo position-relative h-40px"
            />
          </div>

          <h3>Request Access to EveryDose</h3>

          <div className="py-5 mx-6">
            Your organization has a subscription to EveryDose, but <br /> you're
            not currently assigned a license. Click below to
            <br /> request access to EveryDose.
          </div>
          <button className="btn btn-primary" onClick={requestAccess}>
            {submitting && (
              <span
                className="spinner-border spinner-border-sm mr-1"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            Request Access
          </button>
        </div>
      </div>
    </div>
  );
};

export { RequestAccess };
