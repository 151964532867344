import React, { useContext, useEffect, useState } from "react";
import { EHRSync } from "../../_components";
import { allAccess, permissionsEnum } from "../../_helpers";
import { genderOptions } from "../../_helpers/constants";
import { PatientContext } from "../../context/patientContext";
import { useUserContext } from "../../context/userContext";
import { PatientRiskProfileModal } from "./PatientRiskProfileModal";
import { PatientPacket } from "./PatientPacket";
import { ActionPopover, QualityPopover } from "./QualityModal";
import { ContactCenter } from "./ContactCenter";
import dayjs from "dayjs";

const LeftInfo = ({ setDataChange, dataChange }) => {
  const { state } = useContext(PatientContext);
  const { patient } = state;
  const { userState } = useUserContext();
  const { tenantFeatures, user, userPermissions } = userState;
  const [ehrFeatureEnabled, setEhrFeatureEnabled] = useState(false);

  useEffect(() => {
    // if (!user?.isSmartSession) {
    if (
      tenantFeatures?.["Ehr"] &&
      (userPermissions?.indexOf(permissionsEnum.SyncMedication) > -1 ||
        allAccess(userPermissions))
    )
      setEhrFeatureEnabled(true);
    // } else {
    //   if (
    //     userPermissions?.indexOf(permissionsEnum.SmartMedicationsRead) > -1 ||
    //     allAccess(userPermissions)
    //   )
    //     setEhrFeatureEnabled(true);
    // }
  }, [
    tenantFeatures,
    // user?.isSmartSession,
    userPermissions
  ]);
  return (
    <div className="mb-1">
      <div className="d-flex align-items-center">
        Member Since: &nbsp;
        <span>{dayjs(patient.memberSince).format("M/D/YYYY")}</span>
        {ehrFeatureEnabled && (
          <EHRSync setDataChange={setDataChange} dataChange={dataChange} />
        )}
        <PatientPacket />
        {tenantFeatures?.PayorPages && <ContactCenter user={user} />}
      </div>
    </div>
  );
};

const PageInfo = ({ fetchData, patientId, loadingRiskProfiles }) => {
  const { state } = useContext(PatientContext);
  const { patient } = state;
  const { userState } = useUserContext();
  const { userPermissions, tenantFeatures } = userState;
  const [riskProfileFeatureEnabled, setRiskProfileFeatureEnabled] =
    useState(false);
  function findGender(pt) {
    let gender = genderOptions.find((elem) => elem.value === pt.gender);
    if (gender) return gender.label;
    else return "Unknown";
  }
  useEffect(() => {
    if (
      tenantFeatures?.["RiskProfiles"] &&
      (allAccess(userPermissions) ||
        userPermissions?.indexOf(
          permissionsEnum.PatientDetailsRiskProfilesRead
        ) > -1)
    )
      setRiskProfileFeatureEnabled(true);
  }, [tenantFeatures, userPermissions]);
  return (
    <div className="d-flex align-items-end mb-1 justify-content-between ml-5">
      <div className="info-val mb-1">
        ({findGender(patient)}, {patient.age})
      </div>
      {riskProfileFeatureEnabled && (
        <PatientRiskProfileModal
          fetchData={fetchData}
          patientId={patientId}
          loadingRiskProfiles={loadingRiskProfiles}
        />
      )}
      {tenantFeatures?.PayorPages && (
        <>
          <QualityPopover />
          <ActionPopover />
        </>
      )}
    </div>
  );
};

export { LeftInfo, PageInfo };
