import React, { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
import classnames from "classnames";
import { ReactComponent as Filter } from "../images/filter.svg";
import PropTypes from "prop-types";

const recordsOptions = [
  { label: "Last 10 Records", value: 10, type: "records" },
  { label: "Last 25 Records", value: 25, type: "records" },
  { label: "Last 50 Records", value: 50, type: "records" }
];

const lastTimeOptions = [
  { label: "Last 7 Days", value: 7, type: "time" },
  { label: "Last 30 Days", value: 30, type: "time" },
  { label: "Last 90 Days", value: 90, type: "time" },
  { label: "Last Year", value: 365, type: "time" }
];

const FilterDropdown = (props) => {
  const { setSelectedFilter, selectedFilter, activeTab } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filterTimeOptions, setFilterTimeOptions] = useState(
    activeTab > 0 ? lastTimeOptions : lastTimeOptions.slice(0, 3)
  );
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    if (activeTab === 0) {
      // if selected filter is Last Year, set selected filter to Last 25 records
      if (selectedFilter.value === 365) {
        setSelectedFilter({
          value: 25,
          label: "Last 25 records",
          type: "records"
        });
      }
      setFilterTimeOptions(lastTimeOptions.slice(0, 3));
    } else setFilterTimeOptions(lastTimeOptions);
  }, [activeTab, selectedFilter.value, setSelectedFilter]);
  // dropdown with records options and time options divided with divider
  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
        tag="span"
      >
        <button className="btn btn-link">
          <Filter
            className="mr-2 glyph-primary-fill h16-w16-style"
            aria-label="filter"
          />
          {selectedFilter.label}
        </button>
      </DropdownToggle>
      <DropdownMenu className="p-4 mt-2">
        {recordsOptions.map((option) => (
          <div key={option.value}>
            <DropdownItem
              className={classnames({
                "text-priamry": selectedFilter.value === option.value
              })}
              onClick={() => {
                // call api to get data with selected records option
                setSelectedFilter({ ...option, type: "records" });
              }}
            >
              {option.label}
            </DropdownItem>
          </div>
        ))}
        <div className="dropdown-divider"></div>
        {filterTimeOptions.map((option) => (
          <div key={option.value}>
            <DropdownItem
              className={classnames({
                "text-primary": selectedFilter.value === option.value
              })}
              onClick={() => {
                setSelectedFilter({ ...option, type: "time" });

                // call api to get data with selected time option
              }}
            >
              {option.label}
            </DropdownItem>
          </div>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

FilterDropdown.propTypes = {
  setSelectedFilter: PropTypes.func.isRequired,
  activeTab: PropTypes.number.isRequired,
  selectedFilter: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired
  }).isRequired
};

export { FilterDropdown, recordsOptions, lastTimeOptions };
