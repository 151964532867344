import React, { useState } from "react";
import { Popover, PopoverBody, PopoverHeader, Tooltip } from "reactstrap";
import { UseMobileOrTabletMediaQuery } from "../_helpers/media-queries";
import { CloseButton } from "../_components";
import PropTypes from "prop-types";

const MyTooltip = (props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { target, text } = props;
  const is_touch_device = "ontouchstart" in window;
  const isMobileOrTabletWidth = UseMobileOrTabletMediaQuery();
  return (
    <Popover
      trigger={is_touch_device ? "click" : "hover focus"}
      className={text === "Resend" ? "resend-tooltip" : "text-left"}
      placement={props.placement ? props.placement : "right"}
      autohide={false}
      isOpen={tooltipOpen}
      target={target}
      toggle={() => setTooltipOpen(!tooltipOpen)}
    >
      {isMobileOrTabletWidth && (
        <PopoverHeader>
          <CloseButton
            toggle={() => setTooltipOpen(!tooltipOpen)}
            color="glyph-white"
            popoverVersion={true}
          />
        </PopoverHeader>
      )}
      <PopoverBody>{text}</PopoverBody>
    </Popover>
  );
};
MyTooltip.propTypes = {
  target: PropTypes.string,
  text: PropTypes.string,
  placement: PropTypes.string
};

const MyBasicTooltip = (props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { target, text } = props;
  const is_touch_device = "ontouchstart" in window;

  return (
    <Tooltip
      trigger={is_touch_device ? "click" : "hover focus"}
      className="text-left"
      autohide={false}
      isOpen={tooltipOpen}
      target={target}
      toggle={() => setTooltipOpen(!tooltipOpen)}
    >
      {text}
    </Tooltip>
  );
};
MyBasicTooltip.propTypes = {
  target: PropTypes.string,
  text: PropTypes.string
};

export { MyTooltip, MyBasicTooltip };
