import {
  convertLbsToKgs,
  convertMgPerDlToMmolPerLiter,
  fahrenheitToCelsius
} from "./helper-functions";

export const colorOptions = [
  { PINK: 1 },
  { YELLOW: 2 },
  { BLUE: 3 },
  { LAVENDER: 4 },
  { PURPLE: 5 },
  { WHITE: 6 },
  { GOLD: 7 },
  { BEIGE: 8 },
  { GREEN: 9 },
  { BROWN: 10 },
  { ORANGE: 11 },
  { RED: 12 },
  { TURQUOISE: 13 },
  { PEACH: 14 },
  { LIGHT_BLUE: 15 },
  { GRAY: 16 },
  { TAN: 17 },
  { MAROON: 18 },
  { DARK_GREEN: 19 },
  { LIGHT_GREEN: 20 },
  { CLEAR: 21 },
  { BLACK: 22 },
  { DARK_BROWN: 23 },
  { LIGHT_BROWN: 24 },
  { TEAL: 25 },
  { DARK_BLUE: 26 },
  { MAUVE: 27 },
  { BUFF: 28 },
  { CARAMEL: 29 },
  { FLESH: 30 },
  { IVORY: 31 },
  { LILAC: 32 },
  { OLIVE: 33 },
  { ROSE: 34 },
  { NATURAL: 35 }
];

export const shapeOptions = [
  { CAPSULE: 2440 },
  {
    ROUND: 8782
  },
  {
    OVAL: 8783
  },
  {
    TRIANGULAR: 8784
  },
  {
    SQUARE: 8785
  },
  {
    PENTAGONAL: 8786
  },
  {
    HEXAGONAL: 8787
  },
  {
    OCTAGONAL: 8788
  },
  {
    SPHERICAL: 8789
  },
  {
    ELLIPTICAL: 8823
  },
  {
    OBLONG: 8831
  },
  {
    PEANUT: 8832
  },
  {
    RECTANGULAR: 8837
  },
  {
    EGG: 8859
  },
  {
    SHIELD: 8863
  },
  {
    DIAMOND: 8877
  },
  {
    HEART: 17640
  },
  {
    BOWTIE: 23899
  },
  {
    TRAPEZOID: 27927
  },
  {
    CLOVER: 34222
  }
];

export const healthTrackersEnum = [
  {
    id: 1,
    name: "A1C",
    type: "A1C",
    min: 0,
    max: 20.0,
    dataType: "Double",
    labelType: "n1",
    interval: 1,
    units: [{ value: "Percentage", label: "%" }]
  },
  {
    id: 2,
    name: "Blood Glucose",
    type: "bloodGlucose",
    units: [
      { value: "mg/dL", label: "mg/dL" },
      { value: "mmol/L", label: "mmol/L" }
    ],
    min: 0,
    max: 1000,
    dataType: "Double",
    labelType: "n0",
    interval: 10,
    altInterval: 1,
    conversion: (val) => {
      return convertMgPerDlToMmolPerLiter(val);
    },
    preferenceId: 1
  },
  {
    id: 3,
    name: "Blood Pressure",
    type: "bloodPressure",
    min: 0,
    max: 400,
    dataType: "Double",
    labelType: "n0",
    interval: 20,
    units: [{ value: "mmHg", label: "mmHg" }]
  },
  {
    id: 4,
    name: "Cholesterol",
    type: "cholesterol",
    min: 0,
    max: 5000,
    units: [
      { value: "mg/dL", label: "mg/dL" },
      { value: "mmol/L", label: "mmol/L" }
    ],
    dataType: "Double",
    labelType: "n0",
    interval: 20,
    altInterval: 1,
    conversion: (val) => {
      return convertMgPerDlToMmolPerLiter(val);
    },
    preferenceId: 2
  },
  {
    id: 5,
    name: "Headache",
    type: "headache",

    min: 1,
    max: 10,
    dataType: "Double",
    labelType: "n0",
    interval: 5,
    units: [{ value: "Level / Hours", label: "Level" }]
  },
  {
    id: 6,
    name: "Heart Rate",
    type: "heartRate",

    min: 0,
    max: 300,
    dataType: "Double",
    labelType: "n0",
    interval: 5,
    units: [{ value: "bpm", label: "bpm" }]
  },
  {
    id: 7,
    name: "INR",
    type: "inr",

    dataType: "Double",
    labelType: "n1",
    interval: 5,
    units: [{ value: "INR units", label: "INR units" }]
  },
  // {
  //   id: 8,
  //   name: "Mood",
  //   type: "mood",

  //   min: 1,
  //   max: 10,
  //   dataType: "Double",
  //   labelType: "n0",
  //   interval: 1,
  //   units: [{ value: "Level", label: "Level" }]
  // },
  {
    id: 9,
    name: "Nausea",
    type: "nausea",

    min: 1,
    max: 10,
    dataType: "Double",
    labelType: "n0",
    interval: 1,
    units: [{ value: "Level / Hours", label: "Level" }]
  },
  {
    id: 10,
    name: "Oxygen Saturation",
    type: "oxygenSaturation",

    min: 50,
    max: 100,
    dataType: "Double",
    labelType: "n1",
    interval: 10,
    units: [{ value: "Percentage", label: "%" }]
  },
  {
    id: 11,
    name: "Pain",
    type: "pain",
    min: 1,
    max: 10,
    dataType: "Double",
    labelType: "n0",
    interval: 5,
    units: [{ value: "Scale", label: "Scale" }]
  },
  {
    id: 12,
    name: "Sleep",
    type: "sleep",

    min: 0,
    max: 1440,
    dataType: "Double",
    labelType: "n1",
    interval: 0.5,
    units: [{ value: "Hours", label: "Time" }]
  },
  {
    id: 13,
    name: "Temperature",
    type: "temperature",

    min: 90,
    max: 110,
    units: [
      { value: "°F", label: "°F" },
      { value: "°C", label: "°C" }
    ],
    dataType: "Double",
    labelType: "n2",
    interval: 1,
    conversion: (temp) => {
      return fahrenheitToCelsius(temp);
    },
    preferenceId: 3
  },
  {
    id: 14,
    name: "Weight",
    type: "weight",
    units: [
      { value: "lbs", label: "lbs" },
      { value: "kgs", label: "kgs" }
    ],
    min: 0,
    max: 1000,
    dataType: "Double",
    labelType: "n1",
    interval: 50,
    altInterval: 20,

    conversion: (val) => {
      return convertLbsToKgs(val);
    },
    preferenceId: 4
  }
];

export const unitsEnum = {
  0: "Percentage",
  1: "mg/dL",
  2: "mmol/L",
  3: "mmHg",
  4: "Level",
  5: "bpm",
  6: "INR units",
  7: "Scale",
  8: "MINUTES",
  9: "°F",
  10: "°C",
  11: "lbs",
  12: "kgs"
};

export const permissionsEnum = {
  AccessAll: 32767,
  TenantCreate: 1000,
  TenantUpdate: 1001,
  AdvancedSettings: 0,
  AdminGroups: 1,
  AdminIntegrations: 2,
  AdminPatientsRead: 3,
  AdminRiskProfiles: 4,
  AdminTenantBranding: 5,
  // AdminUsers: 6,
  PatientDetailsRead: 7,
  PatientDetailsRiskProfilesRead: 8,
  PatientDetailsRiskProfilesUpdate: 9,
  PatientDetailsUpdate: 10,
  PatientInvitation: 11,
  PatientInvitationBulk: 12,
  PatientInvitationEhr: 13,
  PatientInvitationList: 14,
  Smart: 15,
  UserConnectionRequest: 16,
  UserIntegrations: 17,
  UserInvitation: 18,
  UserMedicationPlans: 19,
  UserMyProfile: 20,
  UserNotifications: 21,
  UserPatient: 22,
  UserPatientList: 23,
  UserPatientOutreach: 24,
  UserResource: 25,
  UserRiskProfiles: 26,
  UserSupport: 27,
  UserTasks: 28,
  SyncMedication: 29,
  Reports: 30,
  AdminMedicationPlans: 31,
  HealthTracker: 32,
  SmartInvitation: 33,
  PatientTasks: 34,
  SmartNotifications: 35,
  AdminPatientsUpdate: 36,
  AdminPatientsDelete: 37,
  AdminGroupUsers: 38,
  AdminGroupPatients: 39,
  ResourcesSavingsCard: 41,
  SmartMedicationsRead: 42,
  SmartMedicationsUpdateOnInvite: 43,
  UserPatientsDelete: 44,
  AdminTenantPreferencesMfa: 45,
  AdminUsersRead: 46,
  AdminUsersWrite: 47
};

// values 1-7, 11, 12 for Med List, Adherence, Health, Outreach, Tasks, Leaflets, DDIS, Food Interactions
export const reportTypes = [
  {
    value: 101,
    label: "Med List",
    fullName: "Medication List",
    types: ["PDF"],
    description: "report showing the patient’s EveryDose medication list.",
    fields: {
      medications: true
    }
  },
  {
    value: 102,
    label: "Adherence",
    fullName: "Adherence Summary",
    types: ["PDF", "XLSX"],
    description: "report showing dose-specific adherence data.",
    fields: {
      medications: true,
      dateRange: true,
      reportFormat: true
    }
  },
  {
    value: 103,
    label: "Health",
    fullName: "Health Metrics",
    types: ["PDF", "XLSX"],
    description: "report showing the patient’s logged health data.",
    fields: {
      metrics: true,
      dateRange: true,
      reportFormat: true
    }
  },
  {
    value: 104,
    label: "Outreach",
    fullName: "Outreach",
    types: ["PDF"],
    description: "report showing history of outreach to the patient.",
    fields: {
      dateRange: true
    }
  },
  {
    value: 105,
    label: "Tasks",
    fullName: "Tasks",
    types: ["PDF"],
    description: "report showing tasks connected to the patient.",
    fields: {
      dateRange: true
    }
  },
  {
    value: 106,
    label: "Leaflets",
    fullName: "Leaflets",
    types: ["PDF"],
    description: "of education leaflets for a patient’s medications.",
    fields: {
      medications: true,
      leafletType: true
    }
  },
  {
    value: 107,
    label: "Drug-Drug Interactions",
    fullName: "Drug-to-Drug Interactions",
    types: ["PDF"],
    description: "of drug-to-drug interactions for a patient’s medications.",
    fields: {
      medications: true,
      interactionType: true
    }
  },
  {
    value: 108,
    label: "Food Interactions",
    fullName: "Food Interactions",
    types: ["PDF"],
    description: "of food interactions for a patient’s medications.",
    fields: {
      medications: true,
      interactionType: true
    }
  }
];

export const populationReportTypes = [
  {
    value: 52,
    label: "Tasks",
    fullName: "Tasks",
    types: ["XLSX"],
    description: "showing Tasks data",
    fields: {
      assignedTo: true,
      dateCreatedRange: true,
      dateDueRange: true,
      taskStatus: true
    },
    permissionNum: 28
  },
  {
    value: 51,
    label: "Patient Lists",
    fullName: "Patient Lists",
    types: ["XLSX"],
    description: "showing Patient List data",
    fields: {
      filterBy: [
        {
          value: "atRisk",
          label: "At Risk",
          isClearable: true,
          options: [
            { value: true, label: "True" },
            { value: false, label: "False" }
          ]
        }
      ],
      searchBy: true
    },
    patientListIdRequired: true,
    sortBy: [
      { value: "sentAt", label: "Date Sent" },
      { value: "name", label: "Patient Name" },
      { value: "email", label: "Email" },
      { value: "phoneNumber", label: "Mobile Number" },
      { value: "medicationsAdded", label: "Medications Added" },
      { value: "statusDescription", label: "Status" }
    ],
    permissionNum: 23
  },
  {
    value: 53,
    label: "Connection Requests",
    fullName: "Connection Requests",
    types: ["XLSX"],
    description: "showing Connection Requests data",
    fields: {
      filterBy: [
        {
          value: "showAll",
          label: "Show All Connection Requests",
          isClearable: false,
          options: [
            { value: true, label: "True" },
            { value: false, label: "False" }
          ]
        }
      ],
      searchBy: true
    },
    sortBy: [
      { value: "sentAt", label: "Date Requested" },
      { value: "name", label: "Name" },
      { value: "email", label: "Email" }
    ],
    permissionNum: 16
  },
  {
    value: 50,
    label: "Patient Invitations",
    fullName: "Patient Invitations",
    types: ["XLSX"],
    description: "showing Patient Invitation data",
    fields: {
      filterBy: [
        {
          value: "invitationStatus",
          label: "Status",
          isMulti: true,
          options: [
            { value: 1, label: "Sent" },
            { value: 2, label: "Accepted" },
            { value: 3, label: "Resent" },
            { value: 4, label: "Withdrawn" },
            { value: 6, label: "Disconnected By Patient" },
            { value: 7, label: "Revoked By Admin" },
            { value: 8, label: "Revoked By User" }
          ]
        },
        {
          value: "showAll",
          label: "Show All Invitations",
          options: [
            { value: true, label: "True" },
            { value: false, label: "False" }
          ]
        }
      ],
      searchBy: true
    },
    sortBy: [
      { value: "sentAt", label: "Date Sent" },
      { value: "name", label: "Name" },
      { value: "phoneNumber", label: "Mobile Number" },
      { value: "medicationsAdded", label: "Medications Added" },
      { value: "statusDescription", label: "Status" }
    ],
    permissionNum: 11
  }
];

export const changeTypeArray = [
  { value: 0, description: "User Action Default", category: "No Change" },
  { value: 1, description: "User Action New Med", category: "Add" },
  { value: 2, description: "User Action Delete Med", category: "Remove" },
  { value: 3, description: "User Action Pause Med", category: "Pause" },
  { value: 4, description: "User Action Resume Med", category: "Resume" },
  {
    value: 5,
    description: "User Action Change Med Product Strength",
    category: "Change Strength"
  },
  {
    value: 6,
    description: "User Action Change Med Schedule",
    category: "Change Schedule"
  },
  {
    value: 7,
    description: "User Action Change Med Product Strength and Schedule",
    category: "Change Strength and Schedule"
  },
  {
    value: 8,
    description: "User Action Change Med Quantity Per Dose",
    category: "Change Quantity"
  },
  {
    value: 9,
    description: "User Action Change Medication Instructions",
    category: "Change Instructions"
  },
  {
    value: 10,
    description: "User Action Change Med Schedule Time",
    category: "Change Time(s)"
  },
  {
    value: 50,
    description: "User Action Base Time Zone Changed",
    category: "Time Zone Change"
  },
  {
    value: 51,
    description: "User Action Delete Account",
    category: "Account Deleted"
  },
  {
    value: 1003,
    description: "Scheduler Action Pause Medication",
    category: "Pause"
  },
  { value: 101, description: "Tenant Suggestion New Med", category: "Add" },
  {
    value: 102,
    description: "Tenant Suggestion Delete Med",
    category: "Remove"
  },
  { value: 103, description: "Tenant Suggestion Pause Med", category: "Pause" },
  {
    value: 104,
    description: "Tenant Suggestion Resume Med",
    category: "Resume"
  },
  {
    value: 110,
    description: "Tenant Suggestion Strength",
    category: "Change Strength"
  },
  {
    value: 111,
    description: "Tenant Suggestion Schedule",
    category: "Change Schedule"
  },
  {
    value: 112,
    description: "Tenant Suggestion Schedule Time",
    category: "Change Time(s)"
  },
  {
    value: 113,
    description: "Tenant Suggestion Quantity",
    category: "Change Quantity"
  },
  {
    value: 114,
    description: "Tenant Suggestion Instructions",
    category: "Change Instructions"
  },
  {
    value: 115,
    description: "Tenant Suggestion Strength Schedule",
    category: "Change Strength and Schedule"
  },
  {
    value: 116,
    description: "Tenant Suggestion Strength Schedule Time",
    category: "Change Strength and Time(s)"
  },
  {
    value: 117,
    description: "Tenant Suggestion Strength Quantity",
    category: "Change Strength and Quantity"
  },
  {
    value: 118,
    description: "Tenant Suggestion Strength Instructions",
    category: "Change Strength and Instructions"
  },
  {
    value: 119,
    description: "Tenant Suggestion Schedule Schedule Time",
    category: "Change Schedule and Time(s)"
  },
  {
    value: 120,
    description: "Tenant Suggestion Schedule Quantity",
    category: "Change Schedule and Quantity"
  },
  {
    value: 121,
    description: "Tenant Suggestion Schedule Instructions",
    category: "Change Schedule and Instructions"
  },
  {
    value: 122,
    description: "Tenant Suggestion Schedule Time Quantity",
    category: "Change Time(s), and Quantity"
  },
  {
    value: 123,
    description: "Tenant Suggestion Schedule Time Instructions",
    category: "Change Time(s), and Instructions"
  },
  {
    value: 124,
    description: "Tenant Suggestion Quantity Instructions",
    category: "Change Quantity and Instructions"
  },
  {
    value: 125,
    description: "Tenant Suggestion Strength Schedule Schedule Time",
    category: "Change Strength, Schedule, Time(s)"
  },
  {
    value: 126,
    description: "Tenant Suggestion Strength Schedule Quantity",
    category: "Change Strength, Schedule, and Quantity"
  },
  {
    value: 127,
    description: "Tenant Suggestion Strength Schedule Instructions",
    category: "Change Strength, Schedule, and Instructions"
  },
  {
    value: 128,
    description: "Tenant Suggestion Strength Schedule Time Quantity",
    category: "Change Strength, Time(s), and Quantity"
  },
  {
    value: 129,
    description: "Tenant Suggestion Strength Schedule Time Instructions",
    category: "Change Strength, Time(s), and Instructions"
  },
  {
    value: 130,
    description: "Tenant Suggestion Strength Quantity Instructions",
    category: "Change Strength, Quantity, and Instructions"
  },
  {
    value: 131,
    description: "Tenant Suggestion Schedule Schedule Time Quantity",
    category: "Change Schedule, Time(s), and Quantity"
  },
  {
    value: 132,
    description: "Tenant Suggestion Schedule Schedule Time Instructions",
    category: "Change Schedule, Time(s), and Instructions"
  },
  {
    value: 133,
    description: "Tenant Suggestion Schedule Quantity Instructions",
    category: "Change Schedule, Quantity, and Instructions"
  },
  {
    value: 134,
    description: "Tenant Suggestion Schedule Time Quantity Instructions",
    category: "Change Time(s), Quantity, and Instructions"
  },
  {
    value: 135,
    description: "Tenant Suggestion Strength Schedule Schedule Time Quantity",
    category: "Change Strength, Schedule, Time(s), and Quantity"
  },
  {
    value: 136,
    description:
      "Tenant Suggestion Strength Schedule Schedule Time Instructions",
    category: "Change Strength, Schedule, Time(s), and Instructions"
  },
  {
    value: 137,
    description: "Tenant Suggestion Strength Schedule Quantity Instructions",
    category: "Change Strength, Schedule, Quantity, and Instructions"
  },
  {
    value: 138,
    description:
      "Tenant Suggestion Strength Schedule Time Quantity Instructions",
    category: "Change Strength, Time(s), Quantity, and Instructions"
  },
  {
    value: 139,
    description:
      "Tenant Suggestion Schedule Schedule Time Quantity Instructions",
    category: "Change Schedule, Time(s), Quantity, and Instructions"
  },
  {
    value: 140,
    description:
      "Tenant Suggestion Strength Schedule Schedule Time Quantity Instructions",
    category: "Change Strength, Schedule, Time(s), Quantity, and Instructions"
  },

  {
    value: 150,
    description: "Tenant Suggestion Resume Strength",
    category: "Resume"
  },
  {
    value: 151,
    description: "Tenant Suggestion Resume Schedule",
    category: "Resume"
  },
  {
    value: 152,
    description: "Tenant Suggestion Resume Schedule Time",
    category: "Resume"
  },
  {
    value: 153,
    description: "Tenant Suggestion Resume Quantity",
    category: "Resume"
  },
  {
    value: 154,
    description: "Tenant Suggestion Resume Instructions",
    category: "Resume"
  },
  {
    value: 155,
    description: "Tenant Suggestion Resume Strength Schedule",
    category: "Resume"
  },
  {
    value: 156,
    description: "Tenant Suggestion Resume Strength Schedule Time",
    category: "Resume"
  },
  {
    value: 157,
    description: "Tenant Suggestion Resume Strength Quantity",
    category: "Resume"
  },
  {
    value: 158,
    description: "Tenant Suggestion Resume Strength Instructions",
    category: "Resume"
  },
  {
    value: 159,
    description: "Tenant Suggestion Resume Schedule Schedule Time",
    category: "Resume"
  },
  {
    value: 160,
    description: "Tenant Suggestion Resume Schedule Quantity",
    category: "Resume"
  },
  {
    value: 161,
    description: "Tenant Suggestion Resume Schedule Instructions",
    category: "Resume"
  },
  {
    value: 162,
    description: "Tenant Suggestion Resume Schedule Time Quantity",
    category: "Resume"
  },
  {
    value: 163,
    description: "Tenant Suggestion Resume Schedule Time Instructions",
    category: "Resume"
  },
  {
    value: 164,
    description: "Tenant Suggestion Resume Quantity Instructions",
    category: "Resume"
  },
  {
    value: 165,
    description: "Tenant Suggestion Resume Strength Schedule Schedule Time",
    category: "Resume"
  },
  {
    value: 166,
    description: "Tenant Suggestion Resume Strength Schedule Quantity",
    category: "Resume"
  },
  {
    value: 167,
    description: "Tenant Suggestion Resume Strength Schedule Instructions",
    category: "Resume"
  },
  {
    value: 168,
    description: "Tenant Suggestion Resume Strength Schedule Time Quantity",
    category: "Resume"
  },
  {
    value: 169,
    description: "Tenant Suggestion Resume Strength Schedule Time Instructions",
    category: "Resume"
  },
  {
    value: 170,
    description: "Tenant Suggestion Resume Strength Quantity Instructions",
    category: "Resume"
  },
  {
    value: 171,
    description: "Tenant Suggestion Resume Schedule Schedule Time Quantity",
    category: "Resume"
  },
  {
    value: 172,
    description: "Tenant Suggestion Resume Schedule Schedule Time Instructions",
    category: "Resume"
  },
  {
    value: 173,
    description: "Tenant Suggestion Resume Schedule Quantity Instructions",
    category: "Resume"
  },
  {
    value: 174,
    description: "Tenant Suggestion Resume Schedule Time Quantity Instructions",
    category: "Resume"
  },
  {
    value: 175,
    description:
      "Tenant Suggestion Resume Strength Schedule Schedule Time Quantity",
    category: "Resume"
  },
  {
    value: 176,
    description:
      "Tenant Suggestion Resume Strength Schedule Schedule Time Instructions",
    category: "Resume"
  },
  {
    value: 177,
    description:
      "Tenant Suggestion Resume Strength Schedule Quantity Instructions",
    category: "Resume"
  },
  {
    value: 178,
    description:
      "Tenant Suggestion Resume Strength Schedule Time Quantity Instructions",
    category: "Resume"
  },
  {
    value: 179,
    description:
      "Tenant Suggestion Resume Schedule Schedule Time Quantity Instructions",
    category: "Resume"
  },
  {
    value: 180,
    description:
      "Tenant Suggestion Resume Strength Schedule Schedule Time Quantity Instructions",
    category: "Resume"
  },
  {
    value: 201,
    description: "Tenant Suggestion Schedule End Date",
    category: "Change Schedule and End Date"
  },
  {
    value: 202,
    description: "Tenant Suggestion Schedule Start Date and End Date",
    category: "Change Schedule, Start and End Date"
  },
  {
    value: 203,
    description: "Tenant Suggestion Schedule Start Date",
    category: "Change Schedule and Start Date"
  },
  {
    value: 204,
    description: "Tenant Suggestion Schedule Time Start Date and End Date",
    category: "Change Time(s) and Start and End Date"
  },
  {
    value: 205,
    description: "Tenant Suggestion Schedule Time Start Date",
    category: "Change Time(s) and Start Date"
  },
  {
    value: 206,
    description: "Tenant Suggestion Schedule Time End Date",
    category: "Change Time(s) and End Date"
  },
  {
    value: 207,
    description: "Tenant Suggestion Start Date and End Date",
    category: "Change Start and End Date"
  },
  {
    value: 208,
    description: "Tenant Suggestion Start Date",
    category: "Change Start Date"
  },
  {
    value: 209,
    description: "Tenant Suggestion End Date",
    category: "Change End Date"
  },
  {
    value: 210,
    description:
      "Tenant Suggestion Strength Schedule Schedule Time Start Date and End Date",
    category: "Change Strength, Schedule, Time(s), Start and End Date"
  },
  {
    value: 211,
    description: "Tenant Suggestion Strength Schedule Schedule Time Start Date",
    category: "Change Strength, Schedule, Time(s) and Start Date"
  },
  {
    value: 212,
    description: "Tenant Suggestion Strength Schedule Schedule Time End Date",
    category: "Change Strength, Schedule, Time(s) and End Date"
  },
  {
    value: 213,
    description: "Tenant Suggestion Strength Schedule Start Date and End Date",
    category: "Change Strength, Schedule, Start and End Date"
  },
  {
    value: 214,
    description: "Tenant Suggestion Strength Schedule Start Date",
    category: "Change Strength, Schedule and Start Date"
  },
  {
    value: 215,
    description: "Tenant Suggestion Strength End Date",
    category: "Change Strength and End Date"
  },
  {
    value: 216,
    description:
      "Tenant Suggestion Strength Schedule Time Start Date and End Date",
    category: "Change Strength, Time(s), Start and End Date"
  },
  {
    value: 217,
    description: "Tenant Suggestion Strength Schedule Time Start Date",
    category: "Change Strength, Time(s) and Start Date"
  },
  {
    value: 218,
    description: "Tenant Suggestion Strength Schedule Time End Date",
    category: "Change Strength, Time(s) and End Date"
  },
  {
    value: 219,
    description: "Tenant Suggestion Strength Start Date and End Date",
    category: "Change Strength, Start and End Date"
  },
  {
    value: 220,
    description: "Tenant Suggestion Strength Start Date",
    category: "Change Strength and Start Date"
  },
  {
    value: 221,
    description:
      "Tenant Suggestion Resume Strength Schedule Schedule Time Start Date and End Date",
    category: "Resume"
  },
  {
    value: 222,
    description:
      "Tenant Suggestion Resume Strength Schedule Schedule Time Start Date",
    category: "Resume"
  },
  {
    value: 223,
    description:
      "Tenant Suggestion Resume Strength Schedule Schedule Time End Date",
    category: "Resume"
  },
  {
    value: 224,
    description:
      "Tenant Suggestion Resume Strength Schedule Start Date and End Date",
    category: "Resume"
  },
  {
    value: 225,
    description: "Tenant Suggestion Resume Strength Schedule Start Date",
    category: "Resume"
  },
  {
    value: 226,
    description: "Tenant Suggestion Resume Strength End Date",
    category: "Resume"
  },
  {
    value: 227,
    description:
      "Tenant Suggestion Resume Strength Schedule Time Start Date and End Date",
    category: "Resume"
  },
  {
    value: 228,
    description: "Tenant Suggestion Resume Strength Schedule Time Start Date",
    category: "Resume"
  },
  {
    value: 229,
    description: "Tenant Suggestion Resume Strength Schedule Time End Date",
    category: "Resume"
  },
  {
    value: 230,
    description: "Tenant Suggestion Resume Strength Start Date and End Date",
    category: "Resume"
  },
  {
    value: 231,
    description: "Tenant Suggestion Resume Strength Start Date",
    category: "Resume"
  },
  {
    value: 232,
    description: "Tenant Suggestion Resume Schedule Start Date and End Date",
    category: "Resume"
  },
  {
    value: 233,
    description: "Tenant Suggestion Resume Schedule Start Date",
    category: "Resume"
  },
  {
    value: 234,
    description: "Tenant Suggestion Resume Schedule End Date",
    category: "Resume"
  },
  {
    value: 235,
    description:
      "Tenant Suggestion Resume Schedule Time Start Date and End Date",
    category: "Resume"
  },
  {
    value: 236,
    description: "Tenant Suggestion Resume Schedule Time Start Date",
    category: "Resume"
  },
  {
    value: 237,
    description: "Tenant Suggestion Resume Schedule Time End Date",
    category: "Resume"
  },
  {
    value: 238,
    description: "Tenant Suggestion Resume Start Date and End Date",
    category: "Resume"
  },
  {
    value: 239,
    description: "Tenant Suggestion Resume Start Date",
    category: "Resume"
  },
  {
    value: 240,
    description: "Tenant Suggestion Resume End Date",
    category: "Resume"
  },
  {
    value: 241,
    description: "Tenant Suggestion Instructions Start Date and End Date",
    category: "Change Instructions, Start Date, and End Date"
  },
  {
    value: 242,
    description: "Tenant Suggestion Instructions State Date",
    category: "Change Instructions and Start Date"
  },
  {
    value: 243,
    description: "Tenant Suggestion Instructions End Date",
    category: "Change Instructions and End Date"
  },
  {
    value: 246,
    description:
      "Tenant Suggestion Resume Instructions Start Date and End Date",
    category: "Resume"
  },
  {
    value: 247,
    description: "Tenant Suggestion Resume Instructions Start Date",
    category: "Resume"
  },
  {
    value: 248,
    description: "Tenant Suggestion Resume Instructions End Date",
    category: "Resume"
  }
];
