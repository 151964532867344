import React from "react";
import { ReactComponent as EmptyState } from "../images/Illustration_user_mail_user.svg";
import PropTypes from "prop-types";

const EmptyTable = ({
  page,
  loading,
  emptyColSpan,
  emptyImg,
  otherEmptyMessage,
  emptyMsg,
  noPatientsMsg
}) => {
  return (
    <>
      {page.length === 0 && !loading && (
        <tr>
          <td
            colSpan={typeof emptyColSpan === "number" ? emptyColSpan : 7}
            className="text-center"
          >
            {emptyImg || (
              <EmptyState
                aria-label="no data in table"
                className="image-fluid glyph-grooveblue m-6"
                alt="Empty State"
              />
            )}
            <h4 className="mb-0">
              {otherEmptyMessage || emptyMsg || noPatientsMsg}
            </h4>
          </td>
        </tr>
      )}
    </>
  );
};

EmptyTable.propTypes = {
  page: PropTypes.array,
  loading: PropTypes.bool,
  emptyColSpan: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  emptyImg: PropTypes.object,
  otherEmptyMessage: PropTypes.string,
  emptyMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  noPatientsMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export { EmptyTable };
