import { createContext, useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";

// Create a new context
const AuthContext = createContext();

// Create a custom hook to access the AuthContext
const useAuthContext = () => useContext(AuthContext);

function AuthProvider({ children }) {
  const path = window.location.pathname;
  const [shouldUseAuth0, setShouldUseAuth0] = useState(
    !(path.includes("smart") || path === "/support")
  );

  // Function to update the shouldUseAuth0 value
  const updateShouldUseAuth0 = (value) => {
    setShouldUseAuth0(value);
  };

  // Provide the context value
  const contextValue = useMemo(
    () => ({
      shouldUseAuth0,
      updateShouldUseAuth0
    }),
    [shouldUseAuth0]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
}

export { AuthProvider, useAuthContext, AuthContext };

AuthProvider.propTypes = {
  children: PropTypes.node
};
