import React, { useRef, useState } from "react";
import { Popover, PopoverBody } from "reactstrap";
import { SimpleTable } from "../../_components";
import {
  dummyActionsData,
  dummyQualityMeasures
} from "../../components/patient";

const QualityPopover = (props) => {
  const [popover, setPopover] = useState(false);
  const is_touch_device = "ontouchstart" in window;

  const togglePopover = () => {
    setPopover(!popover);
  };
  const mounted = useRef(false);

  React.useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => {
      mounted.current = false;
    }; // ... and to false on unmount
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "Quality Measures",
        accessor: "name",
        disableSortBy: true
      },
      {
        Header: "PDC",
        accessor: "value",
        Cell: (props) => {
          if (props.value) return <div>{props.value}%</div>;
          else return <div>--</div>;
        }
      },
      {
        Header: "Proj.",
        accessor: "projection"
      }
    ],
    []
  );
  return (
    <>
      <div className="d-flex align-items-end mb-1">
        <div className="bg-secondary align-self-center mx-3 small-border"></div>
        {dummyQualityMeasures.length ? (
          <button className="btn btn-link py-0" id="qm-popover-link">
            {dummyQualityMeasures.length} Measures
          </button>
        ) : (
          <span>No Measures</span>
        )}
      </div>
      <Popover
        id="qm-popover"
        className="quality-measures-popover"
        toggle={togglePopover}
        target="qm-popover-link"
        trigger={is_touch_device ? "click" : "hover focus"}
        isOpen={popover}
        placement="bottom-start"
      >
        <PopoverBody className="py-0">
          <SimpleTable
            noSearch={true}
            data={dummyQualityMeasures}
            columns={columns}
            pageCount={1}
            totalCount={dummyQualityMeasures.length}
          />
        </PopoverBody>
      </Popover>
    </>
  );
};

const ActionPopover = (props) => {
  const [popover, setPopover] = useState(false);
  const is_touch_device = "ontouchstart" in window;

  const togglePopover = () => {
    setPopover(!popover);
  };
  const mounted = useRef(false);

  React.useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => {
      mounted.current = false;
    }; // ... and to false on unmount
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "Action Type",
        accessor: "type",
        Cell: (props) => {
          return (
            <div>
              {props.value}
              {props.row.original.count > 1 ? (
                <span> (x{props.row.original.count})</span>
              ) : (
                <></>
              )}
            </div>
          );
        }
      },
      {
        Header: "Quality Measure",
        accessor: "qualityMeasure"
      }
    ],
    []
  );
  return (
    <>
      <div className="d-flex align-items-end mb-1">
        <div className="bg-secondary align-self-center mx-3 small-border"></div>
        {dummyActionsData.length ? (
          <button className="btn btn-link py-0" id="action-items-popover-link">
            {dummyActionsData.length} action items
          </button>
        ) : (
          <span>No Action Items</span>
        )}
      </div>
      <Popover
        id="action-items-popover"
        toggle={togglePopover}
        target="action-items-popover-link"
        trigger={is_touch_device ? "click" : "hover focus"}
        isOpen={popover}
        placement="bottom-start"
        className="quality-measures-popover"
      >
        <PopoverBody className="py-0">
          <SimpleTable
            noSearch={true}
            data={dummyActionsData}
            columns={columns}
            pageCount={1}
            totalCount={dummyActionsData.length}
          />
        </PopoverBody>
      </Popover>
    </>
  );
};

export { ActionPopover, QualityPopover };
