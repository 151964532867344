import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import { Carousel, MySelect } from "../../_components";
import { allAccess, permissionsEnum } from "../../_helpers";
import {
  UseMobileMediaQuery,
  UseMobileOrSmallTabletMediaQuery
} from "../../_helpers/media-queries";
import { useUserContext } from "../../context/userContext";
import { DeletePatient } from "./DeletePatient";

const PatientNav = ({
  activeTab,
  toggleTab,
  patient,
  setPatientNotFound,
  items
}) => {
  const { userState } = useUserContext();
  const { user, userPermissions } = userState;

  const is992Width = UseMobileOrSmallTabletMediaQuery();
  const isMobileWidth = UseMobileMediaQuery();
  const [navItems, setNavItems] = useState(items);
  const [selectItems, setSelectItems] = useState([]);
  const [deletePatientEnabled, setDeletePatientEnabled] = useState(false);

  const [reportsVisible, setReportsVisible] = useState(true);
  useEffect(() => {
    if (
      userPermissions?.indexOf(permissionsEnum.UserPatientsDelete) > -1 ||
      allAccess(userPermissions)
    ) {
      setDeletePatientEnabled(true);
    }
  }, [userPermissions]);
  useEffect(() => {
    setSelectItems([]);
    setSelectItems((state) =>
      state.concat(
        navItems?.map((tab) => {
          return { label: tab.name, value: tab.idx };
        })
      )
    );
  }, [navItems]);

  useEffect(() => {
    // if (user?.isSmartSession || tenantFeatures?.Sso) {
    // remove id reports from navItems
    setNavItems(items.filter((item) => item.id !== "reports"));
    setReportsVisible(false);
    // }
  }, [
    items
    // tenantFeatures?.Sso, user?.isSmartSession
  ]);

  return (
    <>
      {isMobileWidth ? (
        <div className="px-4 my-4">
          <MySelect
            options={selectItems}
            value={selectItems.find((item) => item.value === activeTab)}
            onChange={(name, val) => {
              toggleTab(val.value);
            }}
            // position={document.body}
            name="patient-nav-dropdown"
          />
        </div>
      ) : (
        <Nav
          tabs
          className={classnames(
            { "col-12": !user?.isSmartSession },
            { "py-2 px-5": user?.isSmartSession },

            "patient-view-nav"
          )}
        >
          {is992Width ? (
            <div className="d-flex justify-content-between w-100">
              <Carousel
                numberToDisplay={5}
                items={navItems}
                activeTab={activeTab}
                toggleTab={toggleTab}
              />
              {patient && deletePatientEnabled && (
                <DeletePatient
                  patient={patient}
                  setPatientNotFound={setPatientNotFound}
                  reportsVisible={reportsVisible}
                />
              )}
            </div>
          ) : (
            <>
              {navItems.map((item, i) => (
                <NavItem
                  key={item.name}
                  className={classnames({ "ml-auto": item.id === "reports" })}
                >
                  <NavLink
                    className={classnames({ active: activeTab === i })}
                    tabIndex="0"
                    onKeyPress={() => {
                      toggleTab(i);
                    }}
                    onClick={() => {
                      toggleTab(i);
                    }}
                  >
                    {item.name}
                  </NavLink>
                </NavItem>
              ))}
              {patient && deletePatientEnabled && (
                <DeletePatient
                  patient={patient}
                  setPatientNotFound={setPatientNotFound}
                  reportsVisible={reportsVisible}
                />
              )}
              {/* {streaks ? (
            <div className=" mr-4 mb-2">
              <StreakBadge streaks={streaks} patient={patient} />
            </div>
          ) : (
            <></>
          )} */}
            </>
          )}
        </Nav>
      )}{" "}
    </>
  );
};

export { PatientNav };
