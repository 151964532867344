import React from "react";
import { MyStaticTable } from "./MyStaticTable";
import classnames from "classnames";
import { ReactComponent as ChevronDown } from "../images/chevron_down_small.svg";
import { ReactComponent as ChevronUp } from "../images/chevron_up_small.svg";
import PropTypes from "prop-types";

const DrugsCell = ({ drugName }) => (
  <div className="text-primary">{drugName}</div>
);
DrugsCell.propTypes = {
  drugName: PropTypes.string
};

const cellRenderer = (props, key = "") => {
  switch (key) {
    case "drugName":
      return <DrugsCell drugName={props.drugName} />;
    case "drugSeverityLevel":
      return <SeverityCell value={props.value} row={props.row} />;
    default:
      return null;
  }
};

const SeverityCell = ({ value, row }) => {
  return (
    // Same issue here
    <div className="d-flex justify-content-between text-nowrap">
      <div
        className={classnames(
          { "text-danger": value === "Major" },
          { "text-warning": value === "Moderate" }
        )}
      >
        {value}
      </div>{" "}
      <span {...row.getToggleRowExpandedProps()}>
        {row.isExpanded ? (
          <ChevronUp className="glyph-dark ml-2" aria-label="up" />
        ) : (
          <ChevronDown className="glyph-dark ml-2" aria-label="down" />
        )}
      </span>
    </div>
  );
};
SeverityCell.propTypes = {
  value: PropTypes.string,
  row: PropTypes.object
};

const DDITableExpandable = ({ isIE, clinicalInteractions, loading }) => {
  const ddiReviewColumns = React.useMemo(
    () => [
      {
        Header: "Drugs",
        accessor: (props) => cellRenderer(props, "drugName")
      },
      {
        Header: "Severity",
        accessor: "drugSeverityLevel",
        Cell: (props) => cellRenderer(props, "drugSeverityLevel")
      }
    ],
    []
  );

  const renderDrugDetails = React.useCallback(({ row }) => {
    return (
      <tr>
        <td className="border-0 pt-0 pb-6" colSpan={2}>
          {row.original.drugDescription ? (
            row.original.drugDescription
          ) : (
            <>No interaction information available</>
          )}
        </td>
      </tr>
    );
  }, []);
  renderDrugDetails.propTypes = {
    row: PropTypes.object
  };

  return (
    <div className={classnames({ "px-5": isIE }, "ddi-sidebar")}>
      <MyStaticTable
        columns={ddiReviewColumns}
        data={clinicalInteractions}
        loading={loading}
        renderRowSubComponent={renderDrugDetails}
      />
    </div>
  );
};

DDITableExpandable.propTypes = {
  isIE: PropTypes.bool,
  clinicalInteractions: PropTypes.array,
  loading: PropTypes.bool
};

export { DDITableExpandable };
