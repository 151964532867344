import classnames from "classnames";
import React from "react";
import { UseMobileMediaQuery } from "../_helpers/media-queries";
import PropTypes from "prop-types";

const TableSearch = ({
  searchMessage,
  searchFieldVal,
  setSearchFieldVal,
  handleSearchChange,
  leftSearch,
  mobileAction,
  noSearch,
}) => {
  const isMobileWidth = UseMobileMediaQuery();

  return (
    <>
      {!noSearch && (
        <div
          className={classnames(
            "d-flex flex-wrap",
            {
              "w-100": isMobileWidth,
              // "flex-shrink-0": isShrink
            }
            // { "w-50": is768Width && !isMobileWidth },
            // { "mr-md-6 mr-3 w-25": !is768Width },
            // { "flex-column": searchMessage === "Search Name" }
          )}
        >
          <span className="gylph-search form-control-feedback"></span>
          <input
            aria-label="search"
            name="role"
            type="text"
            placeholder={searchMessage || "Search"}
            value={searchFieldVal || ""}
            onChange={(e) => {
              setSearchFieldVal(e.target.value);
              handleSearchChange(e.target.value);
            }}
            className={classnames(
              { "left-search": leftSearch },
              "form-control form-control-sm search-input"
            )}
          />
          {/* Right now this is only for task table but I assume other tables could have something here as welll */}
          {mobileAction}
        </div>
      )}{" "}
    </>
  );
};

TableSearch.propTypes = {
  searchMessage: PropTypes.string,
  searchFieldVal: PropTypes.string,
  setSearchFieldVal: PropTypes.func,
  handleSearchChange: PropTypes.func,
  leftSearch: PropTypes.bool,
  mobileAction: PropTypes.object,
  noSearch: PropTypes.bool,
};

//arrow next to name on navbar
//look into search issue - broken on front or back end?
//shorter navbar all over
// flip colors on active tabs

export { TableSearch };
