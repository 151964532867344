import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { CloseButton } from "./CloseButton";
import PropTypes from "prop-types";

export const NoEditModal = ({ toggle, isOpen }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} className="center-modal">
      <ModalHeader toggle={toggle} close={<CloseButton toggle={toggle} />}>
        Add/Edit in EHR Med List Only
      </ModalHeader>
      <ModalBody>
        <p>
          Your organization has disabled the Add/Edit Medications feature in
          EveryDose Provider. Please navigate to the EHR medication list to make
          a change, then refresh this page.
        </p>
      </ModalBody>
    </Modal>
  );
};

NoEditModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool
}
