import React from "react";
import PropTypes from "prop-types";

const PdfViewer = ({ fileName, internalFile, id }) => {
  const pdfUrl = internalFile || URL.createObjectURL(fileName);
  return (
    <div>
      <iframe
        id={id || "pdf-viewer"}
        data-testid="pdf-viewer"
        src={pdfUrl}
        title="PDF Viewer"
        type="application/pdf"
        width="100%"
        height="600"
      >
        <p>Your browser does not support embedded PDFs.</p>
      </iframe>
    </div>
  );
};

PdfViewer.propTypes = {
  fileName: PropTypes.object,
  internalFile: PropTypes.string,
  id: PropTypes.string
};

export { PdfViewer };
