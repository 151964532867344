import React, { useState } from "react";
import { ReactComponent as SmartPhone } from "../../images/smartphone.svg";
import { ReviewSidebar } from "../../_components";
import { Use1600MediaQuery } from "../../_helpers";
import PropTypes from 'prop-types';

export const ContactCenter = ({ user }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(document.body.scrollTop);
  const is1600Width = Use1600MediaQuery();

  const toggleSidebar = React.useCallback(
    (arg) => {
      setScrollPosition(document.body.scrollTop);
      return setIsSidebarOpen(arg);
    },
    [setScrollPosition]
  );

  return (
    <>
      <button
        className="btn btn-small bg-white ml-3 btn-outline-graydark1 text-dark"
        onClick={toggleSidebar}
        id="create-patient-packet"
      >
        <SmartPhone
          className="glyph-dark h20-w20-style"
          aria-label="create-patient-packet"
        />{" "}
        Contact
      </button>
      <ReviewSidebar
        user={user}
        isSidebarOpen={isSidebarOpen}
        scrollPosition={scrollPosition}
        is1600Width={is1600Width}
        toggleSidebar={toggleSidebar}
        sidebarType="contact-center"
        testId="contact-sidebar"
      />
    </>
  );
};
ContactCenter.propTypes ={
  user: PropTypes.object,
}
