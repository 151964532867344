import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { MyToast } from "../_components";
import { Support } from "../support/Support";
import PropTypes from "prop-types";

const FeedbackText = () => {
  return (
    <div>
      <p>
        We encourage you to share your thoughts, ideas, and suggestions with us
        – both positive and critical. Whether you have a feature request,
        opportunity for improvement, or simply want to share your feedback, this
        is the place to do it. Your feedback matters, and together, we can shape
        the future of our product.
      </p>
      <p>
        Looking to create a support ticket or report a bug?{" "}
        <Link className="btn btn-link" to="/smart/support">
          Create a support ticket
        </Link>
      </p>
    </div>
  );
};

const SmartSupport = ({ version, publicVersion }) => {
  const navigate = useNavigate();
  const title = version
    ? "Provide Feedback/Suggest a Feature"
    : "Contact Support";
  const tag = version ? "new_feature" : "contact_support";

  const text = version ? (
    <FeedbackText />
  ) : (
    "Please fill out the form below with the nature of your request, and one of our representatives will be in touch shortly. Do not include Protected Health Information ('PHI') or sensitive patient data in your request."
  );

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center"
      // style={{ height: "90vh" }}
    >
      {/* <h3 className="text-center mb-5">Contact Support</h3> */}

      <div className="col-lg-5 col-md-7 col-10 mt-6">
        {/* <Card className="card align-self-center mx-4 pt-5">
        <CardBody> */}
        <Support
          title={publicVersion ? "Support" : title}
          text={text}
          tag={tag}
          publicVersion={publicVersion}
          toggle={publicVersion ? goBack : null}
        />
      </div>
      {publicVersion ? <MyToast /> : null}
      {/* </CardBody>
      </Card> */}
    </div>
  );
};

SmartSupport.propTypes = {
  version: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  publicVersion: PropTypes.bool,
};

export { SmartSupport };
