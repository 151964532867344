import { fetchWrapper } from "../_helpers/index";

export const taskService = {
  getTasks,
  createTask,
  getTaskById,
  deleteTask,
  editTask,
  getUsers,
  createTaskReport
};

const baseUrl = "/api/tasks";

function getTasks(filters, sortStr, pageSize = 50, page = 1) {
  if (!sortStr) sortStr = "dueDate.DESC";
  let url = `${baseUrl}?pageSize=${pageSize}&page=${page}&SortBy=${sortStr}`;
  if (filters) {
    for (const key in filters) {
      let urlFilters = "";
      filters[key].forEach((item) => (urlFilters += `&${key}=${item}`));
      url = `${url}${urlFilters}`;
    }
  }
  return fetchWrapper.get(url);
}

function createTask(fields) {
  return fetchWrapper.post(baseUrl, fields);
}

function getTaskById(id) {
  return fetchWrapper.get(`${baseUrl}/${id}`);
}

function deleteTask(id) {
  return fetchWrapper.delete(`${baseUrl}/${id}`);
}

function editTask(id, fields) {
  return fetchWrapper.put(`${baseUrl}/${id}`, fields);
}

function getUsers() {
  return fetchWrapper.get(`${baseUrl}/users`);
}

function createTaskReport(documentType, body) {
  return fetchWrapper.post(`${baseUrl}/report?reportDocumentType=${documentType}`, body);
}
