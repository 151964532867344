import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

const Loader = ({ selectVersion }) => {
  return (
    <div className={classnames(
      { "custom-loader": selectVersion },
      { "text-center p-6 d-flex align-items-center justify-content-center h-60vh": !selectVersion })}>
      <span data-testid="loading" className="spinner-border mr-1"></span>
    </div>
  );
};

Loader.propTypes = {
  selectVersion: PropTypes.bool
};

export { Loader };
