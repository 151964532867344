import React, { useState } from "react";
import classnames from "classnames";
import { Popover, PopoverBody, PopoverHeader } from "reactstrap";
import { ReactComponent as GlyphExclamation } from "../images/glyph-exclamation-filled.svg";
import { CloseButton } from "../_components";
import { UseMobileOrTabletMediaQuery } from "../_helpers/media-queries";
import PropTypes from "prop-types";

const NewPatientAlert = ({ id, isNewPatient, notRightAligned }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const is_touch_device = "ontouchstart" in window;
  const isTabletWidth = UseMobileOrTabletMediaQuery();
  return (
    <span>
      {isNewPatient && (
        <>
          <div
            className={classnames(
              { "right-neg-4px": isTabletWidth && !notRightAligned },
              { "right-neg-10px": !(isTabletWidth && !notRightAligned) },
              "position-absolute top-neg-10px"
            )}
            data-testid="new-patient-tooltip"
          >
            <button
              className="btn focusable-text"
              id={`${id}-tooltip`}
              aria-describedby="new patient"
            >
              <GlyphExclamation aria-label="exclamation point" />
            </button>
          </div>
          <Popover
            trigger={is_touch_device ? "click" : "hover focus"}
            className="text-left"
            placement="left"
            autohide={false}
            isOpen={tooltipOpen}
            target={`${id}-tooltip`}
            toggle={() => setTooltipOpen(!tooltipOpen)}
          >
            {isTabletWidth && (
              <PopoverHeader data-testid="alert-popover-header">
                <CloseButton
                  toggle={() => setTooltipOpen(!tooltipOpen)}
                  color="glyph-white"
                  popoverVersion={true}
                />
              </PopoverHeader>
            )}
            <PopoverBody>
              <div className="d-flex text-left primary-underline">
                <h6>Alert</h6>
              </div>
              <div className="text-left mt-2">
                This user joined less than a week ago. Some items may not be an
                accurate indicator of adherence yet.
              </div>
            </PopoverBody>
          </Popover>
        </>
      )}
    </span>
  );
};
NewPatientAlert.propTypes = {
  id: PropTypes.string,
  isNewPatient: PropTypes.bool,
  notRightAligned: PropTypes.bool
};

NewPatientAlert.propTypes = {
  id: PropTypes.string,
  isNewPatient: PropTypes.bool,
  notRightAligned: PropTypes.bool
};

export { NewPatientAlert };
