export const dummyQualityMeasures = [
  {
    name: "D08: Diabetes Adherence",
    value: 77,
    projection: "Cusp",
    status: "Active"
  },
  { name: "D12: SUPD", value: null, projection: "Miss", status: "Active" }
];

export const dummyClaimsData = [
  {
    type: "Medication",
    data: [
      { name: "Glipizide", daysOnHand: 0, pdcRatio: 77 },
      { name: "Metformin", daysOnHand: 0, pdcRatio: 77 },
      { name: "Warfarin", daysOnHand: 15, pdcRatio: 86 }
    ]
  },
  {
    type: "Measure",
    data: [{ name: "D08: Diabetes", daysOnHand: 0, pdcRatio: 77 }]
  }
];

export const claimsAlertData = [
  {
    type: "Late to Refill",
    severity: "major",
    medicationName: "Glipizide, 5 mg Tablet",
    description:
      "Patient’s diabetes medication refill is past due and has not been filled."
  },
  {
    type: "Late to Refill",
    severity: "major",
    medicationName: "Metformin, 500 mg Tablet",
    description:
      "Patient’s diabetes medication refill is past due and has not been filled."
  },
  {
    type: "Needs Statin Fill",
    severity: "major",
    medicationName: "Any statin from the SUPD Statin list",
    description:
      "Patient has not filled a statin in this Reporting Period. A statin must be filled to avoid missing the D14: SUPD measure."
  },
  {
    type: "Switch to 90-Day Fill",
    severity: "moderate",
    medicationName: "Glipizide, 5 mg Tablet",
    description:
      "Patient’s last fill of this medication is less than 90 days supply. Consider increasing days supply."
  },
  {
    type: "Low Polypharmacy Risk",
    severity: "low",
    medicationName: "",
    description: "No Opioids \nNo Benzodiazepines \nNo ACH\nNo CNSA"
  }
];

export const dummyMedClaims = [
  {
    medicationName: "Glipizide",
    medicationStrength: { strength: "5 mg", form: "tablet" },
    isInEhr: true,
    isCustomMedication: false
  },
  {
    medicationName: "Metformin",
    medicationStrength: { strength: "500 mg", form: "tablet" },
    isInEhr: true,
    isCustomMedication: false
  },
  {
    medicationName: "Warfarin",
    medicationStrength: { strength: "2 mg", form: "tablet" },
    isInEhr: true,
    isCustomMedication: false
  }
];

export const dummyActionsData = [
  {
    type: "Late to Refill",
    count: 2,
    qualityMeasure: "D08: Diabetes Adherence"
  },
  { type: "Needs Statin", count: 1, qualityMeasure: "D12: SUPD" },
  { type: "90 Day Switch", count: 1, qualityMeasure: "D08: Diabetes Adherence" }
];
