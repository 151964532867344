import debounce from "lodash.debounce";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { medicationsService } from "../_services";
import { useAlertContext } from "../context/alertContext";
import { MyAsyncSelect } from "./MySelect";

const MedLookupField = ({
  setMedList,
  label,
  name,
  setFieldValue,
  val,
  setStrengthOptions,
  setInitialValues,
  medList,
  isMulti,
  isDisabled,
  error,
  setFieldTouched
}) => {
  const [options, setOptions] = useState([]);
  const [numberOfChars, setNumberOfChars] = useState(0); // [value, setValue
  const [value, setValue] = useState(val || "");
  const { alertMethods } = useAlertContext();
  const mounted = React.useRef(false);

  React.useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => {
      mounted.current = false;
    }; // ... and to false on unmount
  }, []);

  useEffect(() => {
    if (!val?.value && !val?.length) {
      setValue("");
    }
  }, [val]);

  const filterMeds = React.useCallback(
    (inputValue) => {
      const medOptions = options.filter((op) => {
        return op.label?.toLowerCase().includes(inputValue?.toLowerCase());
      });
      return medOptions;
    },
    [options]
  );

  const debouncedSearchMedicationCall = React.useMemo(
    () =>
      debounce(async (inputValue, callback) => {
        if (inputValue.length < 3) {
          setOptions([]);
          callback([]);
        } else {
          setNumberOfChars(inputValue.length);
          // Your existing logic for API call
          try {
            const response = await medicationsService.searchMeds(inputValue);
            if (mounted.current) {
              const medOptions = response.medications.map((med) => ({
                value: med.drugId,
                label: med.name,
                strengths: setStrengthOptions
                  ? med.strengths
                    .filter((item) => item.strength !== null)
                    .map((strength) => ({
                      value: strength.id,
                      label: strength.strength + " " + strength.form,
                      strength: strength.strength
                    }))
                  : []
              }));
              setOptions(medOptions);
              callback(medOptions);
            }
          } catch (e) {
            if (mounted.current) {
              alertMethods.error(
                "Something went wrong. Refresh the page and try again."
              );
            }
          }
        }
      }, 500),
    [alertMethods, setStrengthOptions]
  );

  const loadSuggestions = (inputValue, callback) => {
    // Clear options if the input value is less than 3
    if (!options.length || inputValue.length < numberOfChars) {
      debouncedSearchMedicationCall(inputValue, callback);
    } else if (inputValue.length >= 3 && options.length > 0) {
      // If there are options available and the user types more than 3 characters, filter the options
      if (mounted.current) {
        callback(filterMeds(inputValue));
      }
    }
  };

  // When adding a medication as a criteria for a smart list or risk profile, we save only the medication name.
  // Therefore, we need to search for the name in the med database to prefill the form with id and name, on edit.
  // First result will be the result with the exact name, if multiple drugs match the search criteria.
  useEffect(() => {
    if (val && typeof val === "string" && !value.value) {
      medicationsService
        .searchMeds(val)
        .then((response) => {
          const med = {
            value: response.medications[0].drugId,
            label: response.medications[0].name
          };
          setValue(med);
          setInitialValues((current) => ({
            ...current,
            [name]: med
          }));
          return med;
        })
        .catch((e) => {
          alertMethods.error(
            "Something went wrong. Refresh the page and try again."
          );
        });
    }
  });

  return (
    <MyAsyncSelect
      id={name || "medName"}
      name={name || "medName"}
      error={error}
      isMulti={isMulti}
      noOptionsMessage={
        <>
          Please type at least three characters to search our medication
          database
        </>
      }
      label={
        label && (
          <h6 className="mb-0">
            Medication Name <span className="text-danger">*</span>
          </h6>
        )
      }
      placeholder={"Name of Med"}
      isDisabled={isDisabled}
      setFieldTouched={setFieldTouched}
      blurInputOnSelect={true} // actually allows for ^^ to work
      isClearable={true}
      className="basic-single"
      classNamePrefix="select"
      loadOptions={loadSuggestions}
      onChange={(val, input) => {
        if (setFieldValue) setFieldValue("strengthAndForm", "");
        setValue(input);
        if (input) {
          if (setMedList) {
            // if the med is not already in the list, add it
            if (!medList.find((med) => med.label === input.label)) {
              setMedList((current) => current.concat(input));
            } else alertMethods.error("This medication is already in the list");
            setValue("");
          } else {
            setFieldValue(val, input);
          }
        } else { // in case when there is no input or field is cleared
          setFieldValue(val, "");
        }
        if (input && setStrengthOptions) setStrengthOptions(input.strengths);
        setOptions([]);
        setNumberOfChars(0);
      }}
      value={value}
    />
  );
};

MedLookupField.propTypes = {
  setMedList: PropTypes.func,
  label: PropTypes.bool,
  name: PropTypes.string,
  setFieldValue: PropTypes.func,
  setStrengthOptions: PropTypes.func,
  val: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  setInitialValues: PropTypes.func,
  medList: PropTypes.array,
  isMulti: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  setFieldTouched: PropTypes.func
};

export { MedLookupField };
