import React from "react";
import { ReactComponent as GlyphX } from "../images/glyph_x.svg";
import { MedLookupField } from "../_components";
import PropTypes from "prop-types";

const MedLookup = ({ medList, setMedList, onSubmit, submitting, name }) => {
  const medListLength = name === "Interactions" ? 2 : 1;

  const onMedListItemClick = (med) => {
    setMedList(medList.filter((item) => item !== med));
  };

  return (
    <div className="d-flex justify-content-center">
      <div className="card bg-white align-self-center w-30rem">
        <div className="card-body">
          <MedLookupField
            setMedList={setMedList}
            label={true}
            name={name}
            medList={medList}
          />
          {medListLength === 2 ? (
            <p className="text-secondary small pt-1">
              To get started, add 2 or more medications
            </p>
          ) : (
            <p className="text-secondary small pt-1">
              To get started, add 1 or more medications
            </p>
          )}
          <div className="p-3 bg-gray w-100">
            <h6 className="mb-0">{name} Search List</h6>
          </div>
          {medList.map((med) => (
            <div
              className="pt-3 px-3 d-flex justify-content-between border-bottom"
              key={med.value}
            >
              <div>{med.label}</div>
              <button
                className="btn btn-link"
                onClick={() => onMedListItemClick(med)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") onMedListItemClick(med);
                }}
                tabIndex="0"
              >
                <GlyphX className="glyph-danger mb-2" aria-label="x" />
              </button>
            </div>
          ))}
          <div className="d-flex justify-content-end mt-4">
            <button
              className="btn btn-outline-secondary mr-4"
              onClick={() => setMedList([])}
            >
              Clear All
            </button>

            <button
              disabled={submitting || medList.length < medListLength}
              className="btn btn-primary"
              onClick={onSubmit}
            >
              {submitting && (
                <span
                  data-testid="submitting-circle"
                  className="spinner-border spinner-border-sm mr-1"
                ></span>
              )}
              Search {name}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

MedLookup.propTypes = {
  medList: PropTypes.array,
  setMedList: PropTypes.func,
  onSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  name: PropTypes.string
};

export { MedLookup };
