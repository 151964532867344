import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { CloseButton } from "../_components";
import { Support } from "./Support";
import PropTypes from "prop-types";

const SuggestAFeature = (props) => {
  const { isOpen, toggle } = props;
  const title = "Make a new Feature Suggestion";
  const tag = "new_feature";
  const text = "Do you have product ideas or feedback on how we can improve the EveryDose platform? We’d love to hear from you! Reach out to us by filling out the form below.​";

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle} close={<CloseButton toggle={toggle} />}>
          Suggest a Feature
        </ModalHeader>
        {/* <h3 className="text-light text-center mb-5">Contact Support</h3> */}
        <ModalBody>
          <div className=" align-self-center pt-3">
            {/* <Alert /> */}
            <div className="">
              <Support title={title} text={text} tag={tag} toggle={toggle} />
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

SuggestAFeature.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func
};

export { SuggestAFeature };
