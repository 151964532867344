import React from "react";

export const TrackingLoader = () => {
  return (
    <div className="ph-item bg-bg border-0">
      <div className="ph-col-12">
        <div className="ph-row">
          <div className="ph-col-half empty"></div>

          <div className="col-xl-5 col-md-12 col-12 extreme-tall"></div>
          <div className="ph-col-1 empty"></div>
          <div className="col-xl-5 col-md-12 col-12 extreme-tall"></div>
          <div className="ph-col-half empty"></div>
          <div className="ph-col-half empty"></div>

          <div className="col-xl-5 col-md-12 col-12 extreme-tall"></div>
          <div className="ph-col-1 empty"></div>
          <div className="col-xl-5 col-md-12 col-12 extreme-tall"></div>
        </div>
      </div>
    </div>
  );
};
