export const prnReasons = [
  {
    "label": "PRN Reason",
    "options": [
      {
        "value": -1,
        "label": "other"
      },
      {
        "value": 1,
        "label": "agitation"
      },
      {
        "value": 2,
        "label": "allergy symptoms"
      },
      {
        "value": 3,
        "label": "anaphylaxis"
      },
      {
        "value": 4,
        "label": "anxiety"
      },
      {
        "value": 5,
        "label": "arthritis"
      },
      {
        "value": 6,
        "label": "bleeding"
      },
      {
        "value": 7,
        "label": "blood glucose"
      },
      {
        "value": 8,
        "label": "chest pain"
      },
      {
        "value": 9,
        "label": "cluster headache"
      },
      {
        "value": 10,
        "label": "cold symptoms"
      },
      {
        "value": 11,
        "label": "congestion"
      },
      {
        "value": 12,
        "label": "constipation"
      },
      {
        "value": 13,
        "label": "control of stomach acid"
      },
      {
        "value": 14,
        "label": "cough"
      },
      {
        "value": 15,
        "label": "cough and congestion"
      },
      {
        "value": 16,
        "label": "dizziness"
      },
      {
        "value": 17,
        "label": "dry eyes"
      },
      {
        "value": 18,
        "label": "dry nasal passages"
      },
      {
        "value": 19,
        "label": "dry skin"
      },
      {
        "value": 20,
        "label": "dyspepsia"
      },
      {
        "value": 21,
        "label": "early awakening"
      },
      {
        "value": 22,
        "label": "erectile dysfunction"
      },
      {
        "value": 23,
        "label": "excessive menstrual bleeding"
      },
      {
        "value": 24,
        "label": "excessive sweating"
      },
      {
        "value": 25,
        "label": "fever"
      },
      {
        "value": 26,
        "label": "flu symptoms"
      },
      {
        "value": 27,
        "label": "gas"
      },
      {
        "value": 28,
        "label": "gastroparesis"
      },
      {
        "value": 29,
        "label": "gout pain"
      },
      {
        "value": 30,
        "label": "headache"
      },
      {
        "value": 31,
        "label": "heartburn"
      },
      {
        "value": 32,
        "label": "hereditary angioedema"
      },
      {
        "value": 33,
        "label": "hiccups"
      },
      {
        "value": 34,
        "label": "hypertension"
      },
      {
        "value": 35,
        "label": "indigestion"
      },
      {
        "value": 36,
        "label": "inflammation"
      },
      {
        "value": 37,
        "label": "insomnia"
      },
      {
        "value": 38,
        "label": "irritable bowel symptoms"
      },
      {
        "value": 39,
        "label": "itching"
      },
      {
        "value": 40,
        "label": "itching/rash"
      },
      {
        "value": 41,
        "label": "lactose intolerance"
      },
      {
        "value": 42,
        "label": "leg cramps"
      },
      {
        "value": 43,
        "label": "lip care"
      },
      {
        "value": 44,
        "label": "loose stool"
      },
      {
        "value": 45,
        "label": "low blood sugar"
      },
      {
        "value": 46,
        "label": "menopausal symptoms"
      },
      {
        "value": 47,
        "label": "menstrual pain"
      },
      {
        "value": 48,
        "label": "migraine headache"
      },
      {
        "value": 49,
        "label": "motion sickness"
      },
      {
        "value": 50,
        "label": "mouth sore pain"
      },
      {
        "value": 51,
        "label": "muscle pain"
      },
      {
        "value": 52,
        "label": "nasal congestion"
      },
      {
        "value": 53,
        "label": "nausea"
      },
      {
        "value": 54,
        "label": "nausea/vomiting"
      },
      {
        "value": 55,
        "label": "nicotine withdrawal"
      },
      {
        "value": 56,
        "label": "ocular congestion"
      },
      {
        "value": 57,
        "label": "opiate reversal"
      },
      {
        "value": 58,
        "label": "pain"
      },
      {
        "value": 59,
        "label": "pain/fever"
      },
      {
        "value": 60,
        "label": "pain, breakthrough"
      },
      {
        "value": 61,
        "label": "pain, mild"
      },
      {
        "value": 62,
        "label": "pain, moderate"
      },
      {
        "value": 63,
        "label": "pain, severe"
      },
      {
        "value": 64,
        "label": "premature ejaculation"
      },
      {
        "value": 65,
        "label": "psychosis"
      },
      {
        "value": 66,
        "label": "rash"
      },
      {
        "value": 67,
        "label": "reverse sedation"
      },
      {
        "value": 68,
        "label": "sedation"
      },
      {
        "value": 69,
        "label": "seizure activity"
      },
      {
        "value": 70,
        "label": "shortness of breath"
      },
      {
        "value": 71,
        "label": "shortness of breath or wheezing"
      },
      {
        "value": 72,
        "label": "sinus symptoms"
      },
      {
        "value": 73,
        "label": "skin irritation"
      },
      {
        "value": 74,
        "label": "sleep"
      },
      {
        "value": 75,
        "label": "smoking cessation"
      },
      {
        "value": 76,
        "label": "sore throat"
      },
      {
        "value": 77,
        "label": "spasm"
      },
      {
        "value": 78,
        "label": "symptoms of alcohol withdrawal"
      },
      {
        "value": 79,
        "label": "tension"
      },
      {
        "value": 80,
        "label": "urge to smoke"
      },
      {
        "value": 81,
        "label": "urinary discomfort"
      },
      {
        "value": 82,
        "label": "V fib or unstable V tach"
      },
      {
        "value": 83,
        "label": "wheezing"
      }
    ]
  }
]