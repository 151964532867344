import React from "react";
import PropTypes from "prop-types";

export const LoadingCard = ({ header }) => {
  return (
    <div className="card bg-white" data-testid="loading-med-tab">
      <div className="card-header">
        <h6>{header}</h6>
      </div>
      <div className="ph-item border-0 mt-5">
        <div className="ph-col-9">
          <div className="ph-row">
            <div className="ph-col-6 big"></div>
            <div className="ph-col-half empty"></div>
            <div className="ph-col-10 big"></div>
            <div className="ph-col-half empty"></div>
            <div className="ph-col-4 big"></div>
          </div>
        </div>
        <div className="ph-col-9">
          <div className="ph-row">
            <div className="ph-col-6 big"></div>
            <div className="ph-col-half empty"></div>
            <div className="ph-col-10 big"></div>
            <div className="ph-col-half empty"></div>
            <div className="ph-col-4 big"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

LoadingCard.propTypes = {
  header: PropTypes.string
};
