import React, { useEffect, useState, useContext } from "react";
import { Collapse } from "reactstrap";
import { ReactComponent as Caution } from "../../../images/caution.svg";
import { MyStaticTable, ReviewSidebar } from "../../../_components";
import { ReactComponent as ChevronDown } from "../../../images/chevron_down_small.svg";
import { ReactComponent as ChevronUp } from "../../../images/chevron_up_small.svg";
import { patientService } from "../../../_services";
import { Use1600MediaQuery, toTitleCase } from "../../../_helpers";
import { PatientContext } from "../../../context/patientContext";
import PropTypes from "prop-types";

const DrugsCell = ({ toggleSidebar, row, setReviewDrug }) => {
  return (
    <button
      tabIndex="0"
      className="btn btn-link mr-2"
      onClick={() => {
        setReviewDrug(row);
        toggleSidebar(true);
      }}
    >
      {row.drugName}
    </button>
  );
};
DrugsCell.propTypes = {
  toggleSidebar: PropTypes.func,
  row: PropTypes.object,
  setReviewDrug: PropTypes.func
};

const SeverityCell = ({ value }) => {
  return (
    <div className="text-nowrap">
      {value === "Major" ? (
        <span className="text-danger">{value}</span>
      ) : (
        <span>{value}</span>
      )}
    </div>
  );
};
SeverityCell.propTypes = {
  value: PropTypes.string
};

const DDIs = ({
  setLoadingInteractions,
  patientId,
  user,
  isIE,
  scrollPosition,
  setScrollPosition,
  loadingInteractions
}) => {
  const is1600Width = Use1600MediaQuery();
  const { state, setClinicalInteractions } = useContext(PatientContext);
  const { clinicalInteractions } = state;

  const [isDDIReviewOpen, setIsDDIReviewOpen] = useState(false);
  const [suggestion, setSuggestion] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [reviewDrug, setReviewDrug] = useState({});
  const toggleDDIReview = () => setIsDDIReviewOpen(!isDDIReviewOpen);
  const fetchIdRef = React.useRef(0);

  const toggleSidebar = React.useCallback(
    (arg) => {
      setScrollPosition(document.body.scrollTop);
      return setIsSidebarOpen(arg);
    },
    [setScrollPosition]
  );
  const mounted = React.useRef(false);

  useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => {
      mounted.current = false;
    }; // ... and to false on unmount
  }, []);

  const fetchDDIs = React.useCallback(() => {
    const fetchId = ++fetchIdRef.current;

    if (fetchId === fetchIdRef.current) {
      // Clear existing data
      setLoadingInteractions(true);
      setClinicalInteractions([]);

      patientService
        .getClinicalInteractions(patientId)
        .then((res) => {
          if (mounted.current) {
            setLoadingInteractions(false);

            setClinicalInteractions(res);
          }
        })
        .catch((e) => {
          setLoadingInteractions(false);

          console.log(e);
        });
    }
  }, [patientId, setLoadingInteractions, setClinicalInteractions]);

  useEffect(() => {
    fetchDDIs();
  }, [fetchDDIs]);

  useEffect(() => {
    if (isSidebarOpen) {
      document.body.style.height = "100%";
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "";
      document.body.style.height = "";
    }
  }, [isSidebarOpen]);

  useEffect(() => {
    if (reviewDrug?.drugDescription) {
      let string = reviewDrug.drugDescription;
      setSuggestion(toTitleCase(string.substring(0, string.indexOf(":"))));
    }
  }, [reviewDrug]);

  const ddiReviewColumns = React.useMemo(
    () => [
      {
        Header: "Drugs",
        accessor: (row) => (
          <DrugsCell
            toggleSidebar={toggleSidebar}
            row={row}
            setReviewDrug={setReviewDrug}
          />
        )
      },
      {
        Header: "Severity",
        accessor: "drugSeverityLevel",
        Cell: (data) => (
          <SeverityCell value={data.row.original.drugSeverityLevel} />
        )
      }
    ],
    [toggleSidebar]
  );

  return (
    <>
      {loadingInteractions && (
        <div className="card patient-alert-card">
          <div className="card-body px-0">
            <div className="ph-item border-0 px-5">
              <div className="ph-col-12">
                <div className="ph-row">
                  <div className="ph-col-6 big"></div>
                  <div className="ph-col-half empty"></div>
                  <div className="ph-col-10 big"></div>
                  <div className="ph-col-half empty"></div>
                  <div className="ph-col-4 big"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {clinicalInteractions.length > 0 && (
        <div className="card patient-alert-card">
          <div className="card-body px-0">
            <span className="card-title px-6 d-flex">
              <Caution
                className="glyph-danger mr-3 caution-style"
                aria-label="caution"
              />
              {clinicalInteractions.length} Severe DDIs
            </span>
            <p className="mt-2 px-6">
              We’ve detected {clinicalInteractions.length} severe Drug-to-Drug
              Interactions by scanning this patient’s mobile app medication
              list.
            </p>
            <Collapse isOpen={isDDIReviewOpen}>
              <MyStaticTable
                columns={ddiReviewColumns}
                data={clinicalInteractions}
                loading={false}
              />
            </Collapse>
            <button
              tabIndex="0"
              className="btn btn-link  px-6"
              onClick={toggleDDIReview}
            >
              {isDDIReviewOpen && (
                <span>
                  Close
                  <ChevronUp
                    aria-label="chevron up"
                    className="glyph-link-blue ml-1"
                  />
                </span>
              )}
              {!isDDIReviewOpen && (
                <span>
                  Review
                  <ChevronDown
                    aria-label="chevron down"
                    className="glyph-link-blue ml-1"
                  />
                </span>
              )}
            </button>
          </div>
        </div>
      )}
      <ReviewSidebar
        user={user}
        isSidebarOpen={isSidebarOpen}
        scrollPosition={scrollPosition}
        isIE={isIE}
        is1600Width={is1600Width}
        toggleSidebar={toggleSidebar}
        suggestion={suggestion}
        reviewDrug={reviewDrug}
        sidebarType="ddi"
        testId="review-sidebar"
      />
    </>
  );
};
DDIs.propTypes = {
  setLoadingInteractions: PropTypes.func,
  patientId: PropTypes.string,
  user: PropTypes.object,
  isIE: PropTypes.bool,
  scrollPosition: PropTypes.number,
  setScrollPosition: PropTypes.func,
  loadingInteractions: PropTypes.bool
};

export { DDIs };
