import React from "react";
import { ReactComponent as Exclamation } from "../images/circle-exclaimation.svg";
import PropTypes from "prop-types";
import { year } from "../_helpers";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true, error: error });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          id="access-denied"
          className="col-6 offset-3 col-lg-4 offset-lg-4 d-flex flex-column justify-content-between"
        >
          <div className="d-flex">
            <Exclamation className="mr-2 glyph-gray" aria-label="exclamation" />
            <div>
              <h4>Oops! Something went wrong</h4>
              <h6 className="mb-4">
                <button
                  className="btn btn-link font-weight-bold"
                  onClick={() => window.location.reload()}
                >
                  Please refresh the page
                </button>{" "}
                or try again later
              </h6>
            </div>
          </div>
          <div className="text-secondary text-center">
            <a href="/support" className="btn btn-link">
              Contact Support
            </a>
            <div className="pt-4">
              © {year} EveryDose. All rights reserved.{" "}
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.node
};
export { ErrorBoundary };
