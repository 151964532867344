import React, { useEffect, useState } from "react";
import { CustomInput, InputGroup } from "reactstrap";
import {
  convertLocalTimeToUTCTime,
  convertUTCTimeToLocalTime,
  specificTimeOptions,
  timeOptions
} from "../_helpers";
import { patientService } from "../_services";
import { useAlertContext } from "../context/alertContext";
import { InTableSelect } from "./MySelect";
import classnames from "classnames";
import PropTypes from "prop-types";

const AlertDropdowns = ({
  row,
  radio,
  scheduleType,
  setFormTouched,
  setRadio,
  setTime,
  time,
  bulkAlertType
}) => {
  return (
    <>
      <InputGroup className="d-flex flex-column">
        <CustomInput
          id={`${row.id}-missed`}
          name={`${row.id}-reminderType`}
          type="radio"
          value="missed"
          defaultChecked={radio === 1}
          label={
            scheduleType === 1 || bulkAlertType === "taken"
              ? "Send alert after med is taken"
              : "Send alert after med is missed"
          }
          onChange={() => {
            setFormTouched(true);
            setRadio(1);
            setTime("");
          }}
        />
        <CustomInput
          id={`${row.id}-specificTime`}
          name={`${row.id}-reminderType`}
          type="radio"
          value={2}
          defaultChecked={radio === 2}
          label="Send alert at a specific time"
          onChange={() => {
            setFormTouched(true);
            setRadio(2);
            setTime("");
          }}
        />
      </InputGroup>
      <div className="py-4 form-group">
        {radio !== 2 ? (
          <InTableSelect
            id="alert-time"
            value={time}
            isDisabled={radio !== 1}
            placeholder="Select alert time"
            options={timeOptions}
            name={`alert-time-${row.id}`}
            onChange={(val) => {
              setFormTouched(true);
              setTime(val);
            }}
          />
        ) : (
          <InTableSelect
            id="custom-alert-time"
            isDisabled={radio !== 2}
            value={time}
            placeholder="Set custom alert time"
            options={specificTimeOptions}
            name={`alert-time-${row.id}`}
            onChange={(val) => {
              setFormTouched(true);
              setTime(val);
            }}
          />
        )}
      </div>
    </>
  );
};

AlertDropdowns.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  radio: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  scheduleType: PropTypes.number,
  setFormTouched: PropTypes.func,
  setRadio: PropTypes.func,
  setTime: PropTypes.func,
  time: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  bulkAlertType: PropTypes.string
};

const FormInTable = ({
  row,
  selectedRows,
  fetchMedications,
  toggleAlertModal,
  bulkAlertType,
  setBulkUpdate,
  setMultiSelect
}) => {
  const { alertMethods } = useAlertContext();
  const { original } = row;

  const { alert, medicationSchedule } = original;
  const scheduleType = medicationSchedule?.scheduleType;
  const [submitting, setSubmitting] = React.useState(false);
  const [deleteSubmitting, setDeleteSubmitting] = React.useState(false);
  const [formTouched, setFormTouched] = useState(false);

  //Set time if alert is already set
  const [time, setTime] = useState(() => {
    if (alert && alert.alertScheduleType === 1) {
      return timeOptions.find((val) => val.value === alert.alertScheduleTime);
    } else if (alert && alert.alertScheduleType === 2) {
      const adjustedTime = convertUTCTimeToLocalTime(
        alert.alertScheduleTime,
        true
      );
      return specificTimeOptions.find((val) => val.value === adjustedTime);
    } else return {};
  });

  //Set radio if alert is already set
  const [radio, setRadio] = useState(
    alert?.alertScheduleTime ? alert.alertScheduleType : ""
  );
  const existingAlert = alert?.alertScheduleTime;

  useEffect(() => {
    if (alert?.alertScheduleTime) setRadio(alert.alertScheduleType);
    if (alert && alert.alertScheduleType === 1) {
      setTime(timeOptions.find((val) => val.value === alert.alertScheduleTime));
    } else if (alert && alert.alertScheduleType === 2) {
      const adjustedTime = convertUTCTimeToLocalTime(
        alert.alertScheduleTime,
        true
      );

      setTime(specificTimeOptions.find((val) => val.value === adjustedTime));
    }
  }, [alert]);

  const confirmAlert = (item, toDelete) => {
    toDelete ? setDeleteSubmitting(true) : setSubmitting(true);
    let fields;
    // handle bulk and single add alert scenarios
    if (item.original.alert) {
      if (toDelete) {
        fields = [
          {
            medicationStrengthId: item.original.medicationStrength.id,
            alertScheduleType: -1,
            alertScheduleTime: null
          }
        ];
      } else if (radio === 1) {
        fields = [
          {
            medicationStrengthId: item.original.medicationStrength.id,
            alertScheduleType: 1,
            alertScheduleTime: time.value
          }
        ];
      } else {
        fields = [
          {
            medicationStrengthId: item.original.medicationStrength.id,
            alertScheduleType: 2,
            alertScheduleTime: convertLocalTimeToUTCTime(time.value)
          }
        ];
      }
    } else {
      fields = selectedRows
        .filter((row) => {
          if (bulkAlertType === "taken") {
            return row.medicationSchedule.scheduleType === 1;
          } else {
            return row.medicationSchedule.scheduleType !== 1;
          }
        })
        .map((elem) => {
          if (toDelete) {
            return {
              medicationStrengthId: elem.medicationStrength.id,
              alertScheduleType: -1,
              alertScheduleTime: null
            };
          } else if (radio === 1) {
            return {
              medicationStrengthId: elem.medicationStrength.id,
              alertScheduleType: 1,
              alertScheduleTime: time.value
            };
          } else {
            return {
              medicationStrengthId: elem.medicationStrength.id,
              alertScheduleType: 2,
              alertScheduleTime: convertLocalTimeToUTCTime(time.value)
            };
          }
        });
    }

    patientService
      .addAlert(fields)
      .then((res) => {
        if (toDelete) {
          alertMethods.success(
            "<div class=''>Success!</div><div>Alert has been deleted</div>"
          );
        } else
          alertMethods.success(
            "<div class=''>Success!</div><div>Alert has been scheduled</div>"
          );
        if (toggleAlertModal) toggleAlertModal();
        if (setMultiSelect) setMultiSelect(false);
        if (setBulkUpdate) setBulkUpdate(false);

        fetchMedications();
      })
      .catch((e) => {
        if (toDelete) {
          alertMethods.error(
            "<div class=''>Something went wrong</div><div>Alert has not been deleted</div>"
          );
          setDeleteSubmitting(false);
        } else {
          alertMethods.error(
            "<div class=''>Something went wrong</div><div>Please try again</div>"
          );
          setSubmitting(false);
        }
      });
  };

  // Form has to be a little bit hacky because it is in a table and forms cannot be inside html tables
  return (
    <div className="form">
      <AlertDropdowns
        row={row}
        radio={radio}
        scheduleType={scheduleType}
        bulkAlertType={bulkAlertType}
        setFormTouched={setFormTouched}
        setRadio={setRadio}
        setTime={setTime}
        time={time}
      />
      {/* {updatePatientFeatureEnabled() && ( */}
      <div className="mt-5 d-flex flex-md-row flex-column">
        <button
          data-testid="button-primary"
          className="btn btn-primary mr-3 mb-md-0 mb-3"
          disabled={!time || !radio || !formTouched || submitting}
          onClick={() => confirmAlert(row)}
        >
          {submitting && (
            <span className="spinner-border spinner-border-sm mr-1"></span>
          )}
          Save
        </button>
        {existingAlert ? (
          <button
            data-testid="delete-alert"
            className="btn btn-outline-danger mr-3 mb-md-0 mb-3"
            onClick={() => confirmAlert(row, true)}
          >
            {deleteSubmitting && (
              <span className="spinner-border spinner-border-sm mr-1"></span>
            )}{" "}
            Delete Alert
          </button>
        ) : (
          <></>
        )}

        <button
          data-testid="close-row"
          className={classnames("btn btn-outline-secondary", {
            "ml-auto": existingAlert
          })}
          onClick={toggleAlertModal}
        >
          Cancel
        </button>
      </div>
      {/* )} */}
    </div>
  );
};

FormInTable.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    original: PropTypes.shape({
      alert: PropTypes.shape({
        alertScheduleTime: PropTypes.string,
        alertScheduleType: PropTypes.number
      }),
      medicationSchedule: PropTypes.shape({
        scheduleType: PropTypes.number
      }),
      medicationStrength: PropTypes.shape({
        id: PropTypes.string
      })
    }),
    medicationSchedule: PropTypes.shape({
      scheduleType: PropTypes.number
    })
  }),
  selectedRows: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  fetchMedications: PropTypes.func,
  toggleAlertModal: PropTypes.func,
  bulkAlertType: PropTypes.string,
  setBulkUpdate: PropTypes.func,
  setMultiSelect: PropTypes.func
};

export { FormInTable, specificTimeOptions, timeOptions };
