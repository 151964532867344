import React, { Suspense, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as ChevronDown } from "../images/chevron_down_small.svg";
import { ReactComponent as ChevronLeft } from "../images/chevron_left.svg";
import { ReactComponent as ChevronRight } from "../images/chevron_right.svg";
import PropTypes from "prop-types";
import { ReactComponent as Calendar } from "../images/glyph-calendar.svg";
import classNames from "classnames";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "reactstrap";
import { UseMobileMediaQuery } from "../_helpers";
import { OutsideAlerter } from "../components/patient/adhComponents";
import { DatePickerField } from "./DatePickerField";
dayjs.extend(utc);
const presets = [
  {
    label: "Yesterday",
    start: new Date(dayjs().utc().subtract(1, "days").startOf("day")),
    end: new Date(dayjs().utc().subtract(1, "days").endOf("day"))
  },
  {
    label: "Last 7 Days",
    start: new Date(dayjs().utc().subtract(7, "days").startOf("day")),
    end: new Date(dayjs().utc().startOf("day").add(5, "hours"))
  },
  {
    label: "Last 30 Days",
    start: new Date(dayjs().subtract(30, "days").startOf("day")),
    end: new Date(dayjs().utc().startOf("day").add(5, "hours"))
  },
  {
    label: "Last 3 Months",
    start: new Date(dayjs().subtract(3, "months").startOf("day")),
    end: new Date(dayjs().utc().startOf("day").add(5, "hours"))
  },
  {
    label: "Last 6 Months",
    start: new Date(dayjs().subtract(6, "months").startOf("day")),
    end: new Date(dayjs().utc().startOf("day").add(5, "hours"))
  },
  {
    label: "Last 1 Year",
    start: new Date(dayjs().subtract(1, "years").startOf("day")),
    end: new Date(dayjs().utc().startOf("day").add(5, "hours"))
  }
];

const CernerDateSelect = ({
  callback,
  range,
  id,
  activeOption,
  setActiveOption
}) => {
  const isMobileWidth = UseMobileMediaQuery();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);
  const toggleCalendar = () => setCalendarOpen(!calendarOpen);

  const [startDate, setStartDate] = useState(new Date(range.start));
  const [endDate, setEndDate] = useState(new Date(range.end));
  const onChange = (dates) => {
    const [start, end] = dates;
    let realEnd = new Date(dayjs(end).utc().endOf("day").format());
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      callback(start, realEnd);
      toggleCalendar();
    }
  };
  useEffect(() => {
    setStartDate(new Date(range.start));
    setEndDate(new Date(range.end));
  }, [range]);

  useEffect(() => {
    if (!calendarOpen && !endDate) {
      setStartDate(new Date(range.start));
      setEndDate(new Date(range.end));
    }
  }, [calendarOpen, endDate, range]);

  return (
    <div>
      <Dropdown
        isOpen={dropdownOpen && !calendarOpen}
        toggle={toggle}
        className="d-flex align-items-center primary-underline max-width-17"
      >
        <Calendar className="mb-1 mr-2 glyph-dark" aria-label="calendar" />
        <DropdownToggle
          nav
          className={classNames(
            "px-0 d-flex justify-content-between align-items-center w-100"
          )}
        >
          {calendarOpen ? (
            <OutsideAlerter setCalendarOpen={setCalendarOpen}>
              <div className="min-width-to-fit">
                <Suspense fallback={<div className=""></div>}>
                  <DatePickerField
                    id={id}
                    classnames="border-0"
                    renderCustomHeader={({
                      monthDate,
                      customHeaderCount,
                      decreaseMonth,
                      increaseMonth
                    }) => (
                      <div>
                        <button
                          aria-label="Previous Month"
                          className={
                            "react-datepicker__navigation react-datepicker__navigation--previous"
                          }
                          style={
                            customHeaderCount === 1
                              ? { visibility: "hidden" }
                              : null
                          }
                          onClick={decreaseMonth}
                        >
                          <span
                            className={
                              "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                            }
                          >
                            <ChevronLeft aria-label="chevron left" />
                          </span>
                        </button>
                        <span className="react-datepicker__current-month">
                          {monthDate.toLocaleString("en-US", {
                            month: "long",
                            year: "numeric"
                          })}
                        </span>
                        <button
                          aria-label="Next Month"
                          className={
                            "react-datepicker__navigation react-datepicker__navigation--next"
                          }
                          style={
                            customHeaderCount === 0 && !isMobileWidth
                              ? { visibility: "hidden" }
                              : null
                          }
                          onClick={increaseMonth}
                        >
                          <span
                            className={
                              "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                            }
                          >
                            <ChevronRight aria-label="chevron right" />
                          </span>
                        </button>
                      </div>
                    )}
                    // selected={startDate || new Date(range.start)}
                    onChange={onChange}
                    startDate={startDate}
                    endDate={endDate}
                    monthsShown={isMobileWidth ? 1 : 2}
                    selectsRange={true}
                    open={calendarOpen}
                    // disable dates after today
                    maxDate={new Date()}
                  />
                </Suspense>
              </div>
            </OutsideAlerter>
          ) : (
            <div className="dateselect">
              {activeOption === "Custom Range" ? (
                <>
                  {dayjs(startDate).format("MM/DD/YYYY")} -{" "}
                  {dayjs(endDate).format("MM/DD/YYYY")}
                </>
              ) : (
                <div className="w-100">{activeOption}</div>
              )}
            </div>
          )}

          <ChevronDown className="ml-3 glyph-dark" aria-label="chevron-down" />
        </DropdownToggle>
        <DropdownMenu className="zIndex-2000-important">
          {presets.map((preset) => (
            <DropdownItem
              className={classNames({
                "text-light-primary": activeOption === preset.label
              })}
              key={preset.label}
              onClick={() => {
                setActiveOption(preset.label);
                setStartDate(preset.start);
                setEndDate(preset.end);
                callback(preset.start, preset.end);
              }}
            >
              {preset.label}
            </DropdownItem>
          ))}
          <DropdownItem divider />
          <DropdownItem
            className={classNames({
              "text-light-primary": activeOption === "Custom Range"
            })}
            onClick={() => {
              toggleCalendar();
              setActiveOption("Custom Range");
            }}
          >
            Custom Range
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

CernerDateSelect.propTypes = {
  callback: PropTypes.func,
  range: PropTypes.object,
  id: PropTypes.string,
  activeOption: PropTypes.string,
  setActiveOption: PropTypes.func
};
export { CernerDateSelect };
