import React from "react";
import { ReactComponent as Exclamation } from "../images/circle-exclaimation.svg";
import { year } from "../_helpers";
import { useUserContext } from "../context/userContext";
import { Link } from "react-router-dom";
const SessionExpired = (props) => {
  const { userState } = useUserContext();
  const { user } = userState;
  return (
    <div
      id="access-denied"
      className="col-6 offset-3 col-lg-4 offset-lg-4 d-flex flex-column justify-content-between"
    >
      <div className="d-flex">
        <Exclamation
          className="mr-2 glyph-gray h-2rem w-4-5rem"
          aria-label="exclamation"
        />
        <div>
          <h4>Access Denied/Forbidden</h4>
          <h6 className="mb-4">Error 404: Not Found</h6>
          {!user || user?.isSmartSession ? (
            <p>
              Your session has expired. Please refresh the page to continue
              using the app.
            </p>
          ) : (
            <p>
              Your session has expired. Please start a new session to continue
              using the app.
            </p>
          )}
        </div>
      </div>
      <div className="text-secondary text-center">
        <Link to="/support" className="text-primary">
          Contact Support
        </Link>
        <div className="pt-4">© {year} EveryDose. All rights reserved. </div>
      </div>
    </div>
  );
};

export { SessionExpired };
