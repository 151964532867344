import { fetchWrapper } from "../_helpers/index";
export const adminService = {
  getGroups,
  createGroup,
  editGroup,
  deleteGroup,
  getGroupUsers,
  exportGroupUsers,
  getGroupPatients,
  exportGroupPatients,
  getNotGroupUsers,
  getNotGroupPatients,
  addUsersToGroup,
  addPatientsToGroup,
  removeUserFromGroup,
  removePatientFromGroup,
  getPatients,
  exportPatients,
  removePatient,
  editPatient,
  getUsers,
  exportUsers,
  removeUser,
  // getUser,
  editUser,

  blockUser,
  unblockUser,
  resetUserPassword,
  inviteUser,
  bulkInvite,
  getUsersInvitations,
  getUserInvitations,
  resendUserInvitation,
  withdrawUserInvitation,
  resendUsersInvitations,
  withdrawUsersInvitations,
  bulkInvitePatients,
  getIntegrations,
  updateIntegration,
  getTenantPreference,
  editTenantPreference,
  resetTenantPreference,
  uploadBrandImages,
  editTenantMFA,
  // createGroupUserReport,
  // createGroupPatientReport,
  getTenantImage,
  deleteTenantImage,
  updateTenantFeatures,
  // createUserInvitationReport,
  // createUserReport
};

const baseUrl = "/api/admin";

function getGroups(groupId) {
  let url = `${baseUrl}/groups`;
  if (groupId) {
    url = `${url}/${groupId}`;
  }
  return fetchWrapper.get(url);
}

function createGroup(fields) {
  return fetchWrapper.post(`${baseUrl}/groups/create`, fields);
}

function editGroup(groupId, fields) {
  return fetchWrapper.put(`${baseUrl}/groups/${groupId}`, fields);
}

function deleteGroup(groupId) {
  return fetchWrapper.delete(`${baseUrl}/groups/${groupId}`);
}

function getGroupUsers(
  groupId,
  searchText = "",
  pageSize = 50,
  page = 1,
  sortStr = "",
  filters = null
) {
  let url = `${baseUrl}/groups/${groupId}/users?searchText=${searchText}&pageSize=${pageSize}&page=${page}&SortBy=${
    sortStr || ""
  }`;
  if (filters) {
    for (const key in filters) {
      let urlFilters = "";
      filters[key].forEach((item) => (urlFilters += `&${key}=${item}`));
      url = `${url}${urlFilters}`;
    }
  }
  return fetchWrapper.get(url);
}

function exportGroupUsers(groupId, type) {
  const url = `${baseUrl}/groups/${groupId}/users/export?ExportTo=${type}`;
  return fetchWrapper.get(url);
}

function getNotGroupUsers(groupId, searchText, pageSize, page) {
  const url = `${baseUrl}/groups/${groupId}/users/not_in_group?searchText=${searchText}&pageSize=${pageSize}&page=${page}`;
  return fetchWrapper.get(url);
}

function getGroupPatients(groupId, searchText, pageSize, page, sortStr) {
  const url = `${baseUrl}/groups/${groupId}/patients?searchText=${searchText}&pageSize=${pageSize}&page=${page}&SortBy=${sortStr}`;
  return fetchWrapper.get(url);
}

function exportGroupPatients(groupId, type) {
  const url = `${baseUrl}/groups/${groupId}/patients/export?ExportTo=${type}`;
  return fetchWrapper.get(url);
}

function getNotGroupPatients(groupId, searchText, pageSize, page) {
  const url = `${baseUrl}/groups/${groupId}/patients/not_in_group?searchText=${searchText}&pageSize=${pageSize}&page=${page}`;
  return fetchWrapper.get(url);
}

function addUsersToGroup(groupId, fields) {
  return fetchWrapper.post(
    `${baseUrl}/groups/${groupId}/users/add_to_group`,
    fields
  );
}

function addPatientsToGroup(groupId, fields) {
  return fetchWrapper.post(
    `${baseUrl}/groups/${groupId}/patients/add_to_group`,
    fields
  );
}

function removeUserFromGroup(groupId, userId) {
  return fetchWrapper.post(
    `${baseUrl}/groups/${groupId}/users/remove_from_group/${userId}`,
    {}
  );
}

function removePatientFromGroup(groupId, patientId) {
  return fetchWrapper.post(
    `${baseUrl}/groups/${groupId}/patients/remove_from_group/${patientId}`,
    {}
  );
}

function getPatients(searchText, pageSize, page, sortStr, filters) {
  let url = `${baseUrl}/patients?searchText=${searchText}&pageSize=${pageSize}&page=${page}&SortBy=${sortStr}`;
  if (filters) {
    for (const key in filters) {
      url = `${url}&${key}=${filters[key]}`;
    }
  }

  return fetchWrapper.get(url);
}

function exportPatients(type) {
  const url = `${baseUrl}/patients/export?ExportTo=${type}`;
  return fetchWrapper.get(url);
}

function editPatient(patientId, fields) {
  return fetchWrapper.put(`${baseUrl}/patients?id=${patientId}`, fields);
}

function removePatient(patientId, deleteAll = false) {
  return fetchWrapper.delete(
    `${baseUrl}/patients?id=${patientId}&deleteAll=${deleteAll}`
  );
}

function getUsers(
  searchText = "",
  pageSize = 50,
  page = 1,
  sortStr = "",
  filters = null
) {
  let url = `${baseUrl}/users?searchText=${searchText}&pageSize=${pageSize}&page=${page}&SortBy=${sortStr}`;
  if (filters) {
    for (const key in filters) {
      let urlFilters = "";
      filters[key].forEach((item) => (urlFilters += `&${key}=${item}`));
      url = `${url}${urlFilters}`;
    }
  }
  return fetchWrapper.get(url);
}

function exportUsers(type) {
  const url = `${baseUrl}/users/export?ExportTo=${type}`;
  return fetchWrapper.get(url);
}

function editUser(userId, fields) {
  return fetchWrapper.put(`${baseUrl}/users/${userId}`, fields);
}

function resetUserPassword(userId, fields) {
  return fetchWrapper.post(`${baseUrl}/users/${userId}/reset_password`, fields);
}

function removeUser(userId) {
  return fetchWrapper.delete(`${baseUrl}/users/${userId}`);
}

function blockUser(userId) {
  return fetchWrapper.post(`${baseUrl}/users/${userId}/block`, {});
}

function unblockUser(userId) {
  return fetchWrapper.post(`${baseUrl}/users/${userId}/unblock`, {});
}

function getUsersInvitations(searchText, pageSize, page, sortStr, filters) {
  let url = `${baseUrl}/users/invitations?searchText=${searchText}&pageSize=${pageSize}&page=${page}&SortBy=${sortStr}`;
  if (filters) {
    for (const key in filters) {
      let urlFilters = "";
      filters[key].forEach((item) => (urlFilters += `&${key}=${item}`));
      url = `${url}${urlFilters}`;
    }
  }
  return fetchWrapper.get(url);
}

function getUserInvitations(userId) {
  const url = `${baseUrl}/users/${userId}/invitations`;
  return fetchWrapper.get(url);
}

function inviteUser(fields) {
  return fetchWrapper.post(`${baseUrl}/users/invitations/create`, fields);
}

function bulkInvite(fields) {
  return fetchWrapper.post(`${baseUrl}/users/invitations/create/bulk`, fields);
}
function bulkInvitePatients(fields) {
  return fetchWrapper.post(
    `${baseUrl}/patients/invitations/create/bulk`,
    fields
  );
}
function resendUserInvitation(userId) {
  return fetchWrapper.post(`${baseUrl}/users/${userId}/invitations/resend`, {});
}

function withdrawUserInvitation(userId) {
  return fetchWrapper.post(
    `${baseUrl}/users/${userId}/invitations/withdraw`,
    {}
  );
}

function resendUsersInvitations(userIds) {
  return fetchWrapper.post(`${baseUrl}/users/invitations/resend`, userIds);
}

function withdrawUsersInvitations(userIds) {
  return fetchWrapper.post(`${baseUrl}/users/invitations/withdraw`, userIds);
}

function getIntegrations() {
  return fetchWrapper.get(`${baseUrl}/integrations`);
}

function updateIntegration(fields) {
  return fetchWrapper.put(`${baseUrl}/integrations`, fields);
}

function getTenantPreference() {
  return fetchWrapper.get(`${baseUrl}/tenantpreference`);
}

function editTenantPreference(body) {
  let url = `${baseUrl}/tenantpreference/update`;

  return fetchWrapper.put(url, body);
}

function resetTenantPreference(id) {
  return fetchWrapper.put(`${baseUrl}/tenantpreference/reset`);
}

function editTenantMFA(value) {
  return fetchWrapper.put(`${baseUrl}/tenantpreference/mfa?mfa=${value}`);
}
function uploadBrandImages(file, type) {
  let formData = new FormData();

  formData.append("file", file, "file.blob");
  return fetchWrapper.post(
    `${baseUrl}/tenantpreference/image/${type}`,
    formData
  );
}

// function createGroupUserReport(reportType, docType, fields) {
//   const url = `${baseUrl}/groups/user/report?reportType=${reportType}&reportDocumentType=${docType}`;
//   return fetchWrapper.post(url, fields);
// }

// function createGroupPatientReport(reportType, docType, fields) {
//   const url = `${baseUrl}/groups/patients/report?reportType=${reportType}&reportDocumentType=${docType}`;
//   return fetchWrapper.post(url, fields);
// }

// function createUserInvitationReport(reportType, docType, fields) {
//   const url = `${baseUrl}/users/invitations/report?reportType=${reportType}&reportDocumentType=${docType}`;
//   return fetchWrapper.post(url, fields);
// }

// function createUserReport(reportType, docType, fields) {
//   const url = `${baseUrl}/users/report?reportType=${reportType}&reportDocumentType=${docType}`;
//   return fetchWrapper.post(url, fields);
// }

function getTenantImage(type) {
  return fetchWrapper.get(`${baseUrl}/tenantpreference/image/${type}`);
}

function deleteTenantImage(type) {
  return fetchWrapper.delete(`${baseUrl}/tenantpreference/image/${type}`);
}

function updateTenantFeatures(features) {
  return fetchWrapper.put(
    `${baseUrl}/tenantpreference/updatefeatures`,
    features
  );
}
