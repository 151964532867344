import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { clearLocalStorage } from "../_helpers";

const AuthenticationCheck = () => {
  const navigate = useNavigate();

  const { isAuthenticated, logout } = useAuth0();

  const goBack = () => {
    if (!isAuthenticated) {
      clearLocalStorage();
      logout({
        logoutParams: { returnTo: window.location.origin }
      });
    } else navigate(-1);
  };
  return (
    <button className="btn btn-link pl-0" onClick={goBack}>
      Back to Home
    </button>
  );
};

export { AuthenticationCheck };
