import React, { useEffect } from "react";
import { Toast, ToastBody } from "reactstrap";
import { ReactComponent as Caution } from "../images/caution.svg";
import { ReactComponent as Check } from "../images/circle-check.svg";
import { ReactComponent as Exclaim } from "../images/circle-exclaimation.svg";
import { ReactComponent as X } from "../images/circle-x.svg";
import { ReactComponent as Close } from "../images/glyph_x.svg";
import { useAlertContext } from "../context/alertContext";
import { useLocation } from "react-router-dom";

function MyToast() {
  const { alertMethods, alerts } = useAlertContext();
  const { clear } = alertMethods;
  const location = useLocation();
  const prevPathname = React.useRef(location.pathname);

  const timeoutRef = React.useRef(null);

  const mounted = React.useRef(false);
  React.useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => {
      mounted.current = false;
    }; // ... and to false on unmount
  }, []);

  const removeAlert = React.useCallback(
    (alert) => {
      if (mounted.current) {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = null;
        }
        const toasts = document.getElementsByClassName("toast");
        if (toasts.length === 1) clear(alert.id);
        else {
          setTimeout(function () {
            if (toasts.length > 1) {
              for (const element of toasts) {
                element.classList.add("moving");
              }
            }
          }, 100);
          setTimeout(function () {
            for (const element of toasts) {
              element.classList.remove("moving");
            }
          }, 500);
          // remove alert after faded out
          setTimeout(() => {
            clear(alert.id);
          }, 250);
        }
      }
    },
    [clear]
  );

  useEffect(() => {
    let alert;
    if (alerts.length > 0) {
      alert = alerts[alerts.length - 1];
    }

    if (alert && mounted.current) {
      // clear alerts when an empty alert is received
      // if (testError) return;
      if (!alert.message) {
        // filter out alerts without 'keepAfterRouteChange' flag
        const filteredAlerts = alerts.filter((x) => !x.keepAfterRouteChange);
        // remove 'keepAfterRouteChange' flag on the rest
        filteredAlerts.forEach((x) => {
          removeAlert(x);
        });

        // });
      } else {
        const toasts = document.getElementsByClassName("toast");
        setTimeout(function () {
          toasts[1]?.classList.add("out-of-viewport");
        }, 200);
        if (alert.autoClose) {
          timeoutRef.current = setTimeout(() => {
            removeAlert(alert);
          }, 10000);
        }
        // auto close alert if required
      }
    }

    // clear alerts on location change
    const historyUnlisten = () => {
      // don't clear if pathname has trailing slash because this will be auto redirected again
      if (location.pathname?.endsWith("/")) return;
      if (prevPathname.current !== location.pathname) {
        const filteredAlerts = alerts.filter((x) => !x.keepAfterRouteChange);
        filteredAlerts.forEach((x) => {
          clear(x.id);
        });
      }
    };

    // clean up function that runs when the component unmounts
    return () => {
      // unsubscribe & unlisten to avoid memory leaks
      // subscription.unsubscribe();
      historyUnlisten();
    };
  }, [alerts, clear, location.pathname, removeAlert]);

  function cssClasses(alert) {
    if (!alert) return;

    const classes = [];

    const alertTypeClass = {
      Success: "bg-success",
      Error: "bg-danger",
      Info: "bg-primary",
      Warning: "bg-warning"
    };

    classes.push(alertTypeClass[alert.type]);

    return classes.join(" ");
  }

  return (
    <div className="container alert-container">
      {alerts &&
        alerts
          .slice(0)
          .reverse()
          .map((alert, index) => (
            <Toast className="mb-5" key={index}>
              <div className="d-flex">
                <div
                  className={
                    cssClasses(alert) +
                    " d-flex justify-content-center align-items-center"
                  }
                >
                  {cssClasses(alert) === "bg-success" && (
                    <Check aria-label="check" />
                  )}
                  {cssClasses(alert) === "bg-danger" && <X aria-label="x" />}
                  {cssClasses(alert) === "bg-warning" && (
                    <Caution aria-label="caution" />
                  )}
                  {cssClasses(alert) === "bg-primary" && (
                    <Exclaim aria-label="exclamation point" />
                  )}
                </div>
                <ToastBody className="">
                  <button
                    className="close ml-auto"
                    onClick={() => removeAlert(alert)}
                  >
                    <Close
                      className="mb-2 pointer-events-auto"
                      aria-label="close"
                    />
                  </button>

                  <div
                    className="pr-6 mr-1"
                    dangerouslySetInnerHTML={{ __html: alert.message }}
                  ></div>
                </ToastBody>
              </div>
            </Toast>
          ))}
    </div>
  );
}

export { MyToast };
