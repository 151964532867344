import classnames from "classnames";
import React, { lazy, Suspense, useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { Collapse } from "reactstrap";
import { ReactComponent as Menu } from "../images/glyph_menu.svg";
import { ReactComponent as GlyphX } from "../images/glyph_x.svg";
import { ReactComponent as ChevronDown } from "../images/chevron_down_small.svg";
import { ReactComponent as ChevronUp } from "../images/chevron_up_small.svg";
import { useUserContext } from "../context/userContext";
import PropTypes from "prop-types";

const SidebarComponent = lazy(() =>
  import("@syncfusion/ej2-react-navigations").then((module) => ({
    default: module.SidebarComponent,
  }))
);

const DropdownCollapse = ({ item, name, toggleSidebar }) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => setOpen(!open);
  return (
    <>
      <button
        type="button"
        key={item.name}
        onClick={toggleOpen}
        className="dropdown-item"
      >
        <div className="row flex-no-wrap">
          {item.glyph && name !== "Account" && (
            <span className="col-2">{item.glyph}</span>
          )}
          <span className="col-10 d-flex">
            <span>{item.name}</span>
            <span className="">
              {open && (
                <span>
                  <ChevronUp aria-label="chevron up" className="ml-3 h-8px" />
                </span>
              )}
              {!open && (
                <span>
                  <ChevronDown
                    aria-label="chevron down"
                    className=" ml-3 h-8px"
                  />
                </span>
              )}
            </span>
          </span>
        </div>
      </button>
      <Collapse isOpen={open}>
        {item.items.map((item) => {
          return (
            <NavLink
              to={item.link}
              key={item.name}
              className={classnames(
                { disabled: item.disabled },
                "dropdown-item pl-6"
              )}
              onClick={toggleSidebar}
            >
              <div className="row flex-no-wrap">
                {item.glyph && name !== "Account" && (
                  <span className="col-2">{item.glyph}</span>
                )}

                <span className="col-10">{item.name}</span>
              </div>
            </NavLink>
          );
        })}
      </Collapse>
    </>
  );
};

DropdownCollapse.propTypes = {
  item: PropTypes.object,
  name: PropTypes.string,
  toggleSidebar: PropTypes.func,
};

function TemporaryDrawer({ details, position, isMobileWidth, logo }) {
  const { header, items, glyph, name, padding } = details;
  const [filteredItems, setFilteredItems] = useState(items);
  const { userState } = useUserContext();
  const { user } = userState;
  const [sidebar, setSidebar] = useState(false);

  const toggleSidebar = () => setSidebar(!sidebar);

  useEffect(() => {
    if (!user || user.userRole < 1) {
      setFilteredItems(items.filter((item) => item.name !== "Admin"));
    } else {
      setFilteredItems(items);
    }
  }, [items, user]);
  const sidebarRef = useRef(null);

  const onCreate = () => {
    // added if condition to prevent error when sidebarRef.current is null
    if (sidebarRef.current && sidebarRef.current !== null)
      sidebarRef.current.element.style.visibility = "";
  };

  // when sidebar opens, move focus to ${name}-0
  useEffect(() => {
    if (sidebar) {
      const firstItem = document.getElementById(`${name}-0`);
      firstItem?.focus();
    }
  }, [name, sidebar]);

  return (
    <div className="control-section">
      <Suspense fallback={glyph || <Menu aria-label="menu" />}>
        <SidebarComponent
          id={name}
          ref={sidebarRef}
          closeOnDocumentClick={false}
          type="Over"
          isOpen={sidebar}
          position={position}
          created={onCreate}
          style={{ visibility: "hidden" }}
          width="250px"
        >
          {padding && isMobileWidth && (
            <span>
              <button className="close p-2" onClick={toggleSidebar}>
                <GlyphX className="mb-2" aria-label="x" />
              </button>
            </span>
          )}
          {header && (
            <>
              <h4 className={classnames("px-5 pt-6 pb-3")}>{header}</h4>
              <div className="dropdown-divider"></div>
            </>
          )}
          {logo}
          <div>
            {filteredItems.map((item, index) => {
              if (item.onClick) {
                return (
                  <button
                    type="button"
                    id={`${name}-${index}`}
                    className="dropdown-item"
                    key={item.name}
                    onClick={() => {
                      item.onClick();
                      toggleSidebar();
                    }}
                  >
                    <span className={item.class}>{item.name}</span>
                  </button>
                );
              } else if (item.collapsible) {
                return (
                  <div key={item.name} id={`${name}-${index}`}>
                    <DropdownCollapse
                      item={item}
                      name={name}
                      toggleSidebar={toggleSidebar}
                    />
                  </div>
                );
              } else {
                return (
                  <NavLink
                    id={`${name}-${index}`}
                    to={item.link}
                    target={item?.openTabType || ""}
                    key={item.name}
                    className={classnames(
                      { disabled: item.disabled },
                      "dropdown-item"
                    )}
                    onClick={toggleSidebar}
                  >
                    <div className="row flex-no-wrap">
                      {item.glyph && name !== "Account" && (
                        <span className="col-2">{item.glyph}</span>
                      )}

                      <span className="col-10">{item.name}</span>
                    </div>
                  </NavLink>
                );
              }
            })}
          </div>
        </SidebarComponent>
        <div className="center-align">
          <button
            type="button"
            onClick={() => {
              toggleSidebar();
            }}
            // when clicking enter on the button, it will trigger the toggleSidebar function
            className="text-secondary btn btn-link"
            tabIndex={0}
            id={`${name}-toggle`}
          >
            {glyph || <Menu className="glyph-dark w-18px" aria-label="menu" />}
          </button>
        </div>
      </Suspense>
    </div>
  );
}

TemporaryDrawer.propTypes = {
  details: PropTypes.object,
  position: PropTypes.string,
  isMobileWidth: PropTypes.bool,
  logo: PropTypes.element,
};

export { TemporaryDrawer };
