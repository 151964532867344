export const formEnum = [
  {
    "value": 1,
    "abbr": "ELIX",
    "label": "elixir",
    "preferredUnitId": 2,
    "commonUnitIds": null
  },
  {
    "value": 2,
    "abbr": "SUSP",
    "label": "suspension",
    "preferredUnitId": 2,
    "commonUnitIds": null
  },
  {
    "value": 3,
    "abbr": "SYR",
    "label": "syrup",
    "preferredUnitId": 2,
    "commonUnitIds": null
  },
  {
    "value": 4,
    "abbr": "OINT",
    "label": "ointment",
    "preferredUnitId": 58,
    "commonUnitIds": null
  },
  {
    "value": 5,
    "abbr": "CREA",
    "label": "cream",
    "preferredUnitId": 58,
    "commonUnitIds": null
  },
  {
    "value": 6,
    "abbr": "PWD",
    "label": "powder",
    "preferredUnitId": 58,
    "commonUnitIds": null
  },
  {
    "value": 7,
    "abbr": "SPR",
    "label": "spray",
    "preferredUnitId": 29,
    "commonUnitIds": null
  },
  {
    "value": 8,
    "abbr": "SOLN",
    "label": "solution",
    "preferredUnitId": 2,
    "commonUnitIds": null
  },
  {
    "value": 9,
    "abbr": "SHAM",
    "label": "shampoo",
    "preferredUnitId": 58,
    "commonUnitIds": null
  },
  {
    "value": 10,
    "abbr": "GUM",
    "label": "gum",
    "preferredUnitId": 17,
    "commonUnitIds": null
  },
  {
    "value": 11,
    "abbr": "GEL",
    "label": "gel",
    "preferredUnitId": 58,
    "commonUnitIds": null
  },
  {
    "value": 12,
    "abbr": "LOT",
    "label": "lotion",
    "preferredUnitId": 58,
    "commonUnitIds": null
  },
  {
    "value": 13,
    "abbr": "LOZ",
    "label": "lozenge",
    "preferredUnitId": 20,
    "commonUnitIds": null
  },
  {
    "value": 14,
    "abbr": "IMP",
    "label": "implant",
    "preferredUnitId": 7,
    "commonUnitIds": null
  },
  {
    "value": 15,
    "abbr": "ENE",
    "label": "enema",
    "preferredUnitId": 2,
    "commonUnitIds": null
  },
  {
    "value": 16,
    "abbr": "INJ",
    "label": "injection",
    "preferredUnitId": 2,
    "commonUnitIds": null
  },
  {
    "value": 17,
    "abbr": "AERO",
    "label": "aerosol",
    "preferredUnitId": 28,
    "commonUnitIds": null
  },
  {
    "value": 18,
    "abbr": "AERA",
    "label": "aerosol with adapter",
    "preferredUnitId": 28,
    "commonUnitIds": null
  },
  {
    "value": 19,
    "abbr": "BAR",
    "label": "bar",
    "preferredUnitId": 58,
    "commonUnitIds": null
  },
  {
    "value": 20,
    "abbr": "CAP",
    "label": "capsule",
    "preferredUnitId": 25,
    "commonUnitIds": null
  },
  {
    "value": 21,
    "abbr": "ERC",
    "label": "capsule, extended release",
    "preferredUnitId": 25,
    "commonUnitIds": null
  },
  {
    "value": 22,
    "abbr": "CONC",
    "label": "concentrate",
    "preferredUnitId": 2,
    "commonUnitIds": null
  },
  {
    "value": 23,
    "abbr": "CRE",
    "label": "cream with applicator",
    "preferredUnitId": 11,
    "commonUnitIds": null
  },
  {
    "value": 24,
    "abbr": "CRY",
    "label": "crystal",
    "preferredUnitId": 7,
    "commonUnitIds": null
  },
  {
    "value": 25,
    "abbr": "DEV",
    "label": "device",
    "preferredUnitId": 7,
    "commonUnitIds": null
  },
  {
    "value": 26,
    "abbr": "DROPS",
    "label": "drops",
    "preferredUnitId": 10,
    "commonUnitIds": null
  },
  {
    "value": 27,
    "abbr": "DRC",
    "label": "delayed release capsule",
    "preferredUnitId": 25,
    "commonUnitIds": null
  },
  {
    "value": 28,
    "abbr": "DRT",
    "label": "delayed release tablet",
    "preferredUnitId": 27,
    "commonUnitIds": null
  },
  {
    "value": 29,
    "abbr": "FILM",
    "label": "film",
    "preferredUnitId": 18,
    "commonUnitIds": null
  },
  {
    "value": 30,
    "abbr": "TERF",
    "label": "film, extended release",
    "preferredUnitId": 18,
    "commonUnitIds": null
  },
  {
    "value": 31,
    "abbr": "FOAM",
    "label": "foam",
    "preferredUnitId": 58,
    "commonUnitIds": null
  },
  {
    "value": 32,
    "abbr": "FOA",
    "label": "foam with applicator",
    "preferredUnitId": 11,
    "commonUnitIds": null
  },
  {
    "value": 33,
    "abbr": "GELS",
    "label": "gel forming solution",
    "preferredUnitId": 10,
    "commonUnitIds": null
  },
  {
    "value": 34,
    "abbr": "JEL",
    "label": "gel with applicator",
    "preferredUnitId": 11,
    "commonUnitIds": null
  },
  {
    "value": 35,
    "abbr": "GRAN",
    "label": "granule",
    "preferredUnitId": 7,
    "commonUnitIds": null
  },
  {
    "value": 36,
    "abbr": "GRAR",
    "label": "granule for reconstitution",
    "preferredUnitId": 7,
    "commonUnitIds": null
  },
  {
    "value": 37,
    "abbr": "EFGR",
    "label": "granule, effervescent",
    "preferredUnitId": 50,
    "commonUnitIds": null
  },
  {
    "value": 38,
    "abbr": "ERG",
    "label": "granule, extended release",
    "preferredUnitId": 7,
    "commonUnitIds": null
  },
  {
    "value": 39,
    "abbr": "INS",
    "label": "insert",
    "preferredUnitId": 51,
    "commonUnitIds": null
  },
  {
    "value": 40,
    "abbr": "KIT",
    "label": "kit",
    "preferredUnitId": 52,
    "commonUnitIds": null
  },
  {
    "value": 41,
    "abbr": "LIQ",
    "label": "liquid",
    "preferredUnitId": 2,
    "commonUnitIds": null
  },
  {
    "value": 42,
    "abbr": "OIL",
    "label": "oil",
    "preferredUnitId": 58,
    "commonUnitIds": null
  },
  {
    "value": 43,
    "abbr": "OINTA",
    "label": "ointment w/applicator",
    "preferredUnitId": 11,
    "commonUnitIds": null
  },
  {
    "value": 44,
    "abbr": "PAD",
    "label": "pad",
    "preferredUnitId": 81,
    "commonUnitIds": null
  },
  {
    "value": 45,
    "abbr": "PAS",
    "label": "paste",
    "preferredUnitId": 58,
    "commonUnitIds": null
  },
  {
    "value": 46,
    "abbr": "PWDI",
    "label": "powder for injection",
    "preferredUnitId": 1,
    "commonUnitIds": null
  },
  {
    "value": 47,
    "abbr": "PWDR",
    "label": "powder for reconstitution",
    "preferredUnitId": 1,
    "commonUnitIds": null
  },
  {
    "value": 48,
    "abbr": "RING",
    "label": "ring",
    "preferredUnitId": 7,
    "commonUnitIds": null
  },
  {
    "value": 49,
    "abbr": "SOA",
    "label": "soap",
    "preferredUnitId": 58,
    "commonUnitIds": null
  },
  {
    "value": 50,
    "abbr": "SPG",
    "label": "sponge",
    "preferredUnitId": 7,
    "commonUnitIds": null
  },
  {
    "value": 51,
    "abbr": "STK",
    "label": "stick",
    "preferredUnitId": 58,
    "commonUnitIds": null
  },
  {
    "value": 52,
    "abbr": "SUPP",
    "label": "suppository",
    "preferredUnitId": 16,
    "commonUnitIds": null
  },
  {
    "value": 53,
    "abbr": "ERSUS",
    "label": "suspension, extended release",
    "preferredUnitId": 2,
    "commonUnitIds": null
  },
  {
    "value": 54,
    "abbr": "SWAB",
    "label": "swab",
    "preferredUnitId": 58,
    "commonUnitIds": null
  },
  {
    "value": 55,
    "abbr": "TAB",
    "label": "tablet",
    "preferredUnitId": 27,
    "commonUnitIds": null
  },
  {
    "value": 56,
    "abbr": "TC",
    "label": "tablet, chewable",
    "preferredUnitId": 27,
    "commonUnitIds": null
  },
  {
    "value": 57,
    "abbr": "CPT",
    "label": "tablet, coated particles",
    "preferredUnitId": 27,
    "commonUnitIds": null
  },
  {
    "value": 58,
    "abbr": "EFT",
    "label": "tablet, effervescent",
    "preferredUnitId": 27,
    "commonUnitIds": null
  },
  {
    "value": 59,
    "abbr": "ERT",
    "label": "tablet, extended release",
    "preferredUnitId": 27,
    "commonUnitIds": null
  },
  {
    "value": 60,
    "abbr": "TMPN",
    "label": "tampon",
    "preferredUnitId": 7,
    "commonUnitIds": null
  },
  {
    "value": 61,
    "abbr": "TAPE",
    "label": "tape",
    "preferredUnitId": 58,
    "commonUnitIds": null
  },
  {
    "value": 62,
    "abbr": "TEST",
    "label": "test",
    "preferredUnitId": 7,
    "commonUnitIds": null
  },
  {
    "value": 63,
    "abbr": "TINC",
    "label": "tincture",
    "preferredUnitId": 58,
    "commonUnitIds": null
  },
  {
    "value": 64,
    "abbr": "WAF",
    "label": "wafer",
    "preferredUnitId": 61,
    "commonUnitIds": null
  },
  {
    "value": 65,
    "abbr": "DT",
    "label": "tablet, disintegrating",
    "preferredUnitId": 27,
    "commonUnitIds": null
  },
  {
    "value": 66,
    "abbr": "DRES",
    "label": "dressing",
    "preferredUnitId": 18,
    "commonUnitIds": null
  },
  {
    "value": 67,
    "abbr": "EMULS",
    "label": "emulsion",
    "preferredUnitId": 10,
    "commonUnitIds": null
  },
  {
    "value": 68,
    "abbr": "AEROPWD",
    "label": "aerosol powder",
    "preferredUnitId": 7,
    "commonUnitIds": null
  },
  {
    "value": 69,
    "abbr": "DSPR",
    "label": "dispersion",
    "preferredUnitId": 7,
    "commonUnitIds": null
  },
  {
    "value": 70,
    "abbr": "GAS",
    "label": "gas",
    "preferredUnitId": 7,
    "commonUnitIds": null
  },
  {
    "value": 71,
    "abbr": "ERTC",
    "label": "tablet, chewable, extended release",
    "preferredUnitId": 27,
    "commonUnitIds": null
  },
  {
    "value": 72,
    "abbr": "DSPT",
    "label": "tablet, dispersible",
    "preferredUnitId": 27,
    "commonUnitIds": null
  },
  {
    "value": 73,
    "abbr": "ECGR",
    "label": "granule, enteric coated",
    "preferredUnitId": 7,
    "commonUnitIds": null
  },
  {
    "value": 74,
    "abbr": "SOLTAB",
    "label": "tablet, soluble",
    "preferredUnitId": 27,
    "commonUnitIds": null
  },
  {
    "value": 75,
    "abbr": "DSTRIP",
    "label": "disintegrating strip",
    "preferredUnitId": 86,
    "commonUnitIds": null
  },
  {
    "value": 76,
    "abbr": "PWDRER",
    "label": "powder for reconstitution, extended release",
    "preferredUnitId": 7,
    "commonUnitIds": null
  },
  {
    "value": 77,
    "abbr": "ERL",
    "label": "liquid, extended release",
    "preferredUnitId": 2,
    "commonUnitIds": null
  },
  {
    "value": 78,
    "abbr": "PWDIER",
    "label": "powder for injection, extended release",
    "preferredUnitId": 7,
    "commonUnitIds": null
  },
  {
    "value": 79,
    "abbr": "ERSOL",
    "label": "solution, extended release",
    "preferredUnitId": 2,
    "commonUnitIds": null
  },
  {
    "value": 80,
    "abbr": "PWDRDR",
    "label": "powder for reconstitution, delayed release",
    "preferredUnitId": 7,
    "commonUnitIds": null
  },
  {
    "value": 81,
    "abbr": "PWDEFF",
    "label": "powder for reconstitution, effervescent",
    "preferredUnitId": 7,
    "commonUnitIds": null
  },
  {
    "value": 82,
    "abbr": "ERDT",
    "label": "tablet, disintegrating, extended release",
    "preferredUnitId": 27,
    "commonUnitIds": null
  },
  {
    "value": 83,
    "abbr": "SENTAB",
    "label": "tablet with sensor",
    "preferredUnitId": 27,
    "commonUnitIds": null
  },
  {
    "value": 84,
    "abbr": "pllt",
    "label": "pellet",
    "preferredUnitId": 7,
    "commonUnitIds": null
  }
]