import React, { useEffect } from "react";
import { useRowSelect, useTable } from "react-table";
import { Table } from "reactstrap";
import PropTypes from "prop-types";

const SimpleTable = (props) => {
  const {
    columns,
    data,
    initialState,
    loading,
    emptyMsg,
    id,
    setSelectedPatient,
    isResponsive = false,
  } = props;
  const tableInstance = useTable({ columns, data, initialState }, useRowSelect);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
  } = tableInstance;
  useEffect(() => {
    const selected = selectedFlatRows;

    if (setSelectedPatient) setSelectedPatient(selected);
  }, [selectedFlatRows, setSelectedPatient]);
  return (
    <div className="table-container">
      <Table
        {...getTableProps()}
        id={id}
        className="g-table"
        responsive={isResponsive}
      >
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th {...column.getHeaderProps()}>
                      <span>
                        {
                          // Render the header
                          column.render("Header")
                        }
                      </span>
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        {/* Apply the table body props */}

        <tbody {...getTableBodyProps()}>
          {
            // Loop over the table rows
            rows.map((row) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr {...row.getRowProps()}>
                  {
                    // Loop over the rows cells
                    row.cells.map((cell) => {
                      // Apply the cell props
                      return (
                        <td {...cell.getCellProps()}>
                          {
                            // Render the cell contents
                            cell.render("Cell")
                          }
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })
          }
          {rows.length === 0 && !loading ? (
            <tr>
              <td colSpan="6" className="pt-6 pb-0 text-center">
                {/* <img className="image-fluid glyph-grooveblue p-6" src={emptyState} /> */}
                <h4>{emptyMsg}</h4>
              </td>
            </tr>
          ) : (
            <tr></tr>
          )}
          {loading ? (
            <tr className="hover-none">
              <td colSpan="6" className="pb-0 border-0 text-center">
                {[...Array(5)].map((e, i) => {
                  if (i % 2 === 0) {
                    return (
                      <div className="ph-item" key={i}>
                        <div className="ph-col-12">
                          <div className="ph-row">
                            <div className="ph-col-2 big"></div>
                            <div className="ph-col-1 empty"></div>
                            <div className="ph-col-2 big"></div>
                            <div className="ph-col-1 empty"></div>
                            <div className="ph-col-2 big"></div>
                            <div className="ph-col-1 empty"></div>
                            <div className="ph-col-real3 big"></div>
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div className="ph-item" key={i}>
                        <div className="ph-col-12">
                          <div className="ph-row">
                            <div className="ph-col-15 big"></div>{" "}
                            <div className="ph-col-1 empty"></div>
                            <div className="ph-col-real3 big"></div>
                            <div className="ph-col-half empty"></div>
                            <div className="ph-col-real3 big"></div>
                            <div className="ph-col-half empty"></div>
                            <div className="ph-col-2 big"></div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </td>
            </tr>
          ) : (
            <tr></tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

SimpleTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  initialState: PropTypes.object,
  loading: PropTypes.bool,
  emptyMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  emptyImg: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  id: PropTypes.string,
  setSelectedPatient: PropTypes.func,
  isResponsive: PropTypes.bool,
};

export { SimpleTable };
