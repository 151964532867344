import classnames from "classnames";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Popover,
  PopoverBody,
  PopoverHeader,
  Table
} from "reactstrap";
import { CloseButton } from "../../../_components";
import { UseMobileMediaQuery } from "../../../_helpers/media-queries";
import { ReactComponent as Left } from "../../../images/chevron_left.svg";
import { ReactComponent as Right } from "../../../images/chevron_right.svg";
import { ReactComponent as GlyphSkip } from "../../../images/glyph-skip.svg";
import { ReactComponent as GlyphAfternoon } from "../../../images/glyph_afternoon.svg";
import { ReactComponent as GlyphCheck } from "../../../images/glyph_check.svg";
import { ReactComponent as GlyphEvening } from "../../../images/glyph_evening.svg";
import { ReactComponent as GlyphMorning } from "../../../images/glyph_morning.svg";
import { ReactComponent as GlyphNight } from "../../../images/glyph_night.svg";
import { ReactComponent as GlyphX } from "../../../images/glyph_x.svg";
import { ReactComponent as GlyphPerson } from "../../../images/nav-user.svg";
import { ReactComponent as PillIcon } from "../../../images/pill_bottle_plus.svg";
import { OutsideAlerter } from "./index";

const MedHistoryTable = ({ rows }) => {
  function compareDateParts(dateStr1, dateStr2) {
    const datePart1 = dateStr1?.split("T")[0];
    const datePart2 = dateStr2?.split("T")[0];
    return datePart1 === datePart2;
  }

  return (
    <Table borderless>
      <tbody className="font-weight-normal overflow-anywhere">
        {rows.map((row, idx) => {
          return (
            <tr
              key={`${row.date}-${row.medicationId}-${idx}`}
              className={classnames(
                { "text-warning": row.data.isTaken === 2 },
                { "text-danger": !row.data.isTaken || row.data.isTaken > 2 }
              )}
            >
              <td className="w-28">
                {row.data.scheduledAtString ? (
                  <span>{dayjs(row.data.scheduledAt).format("h:mm A")}</span>
                ) : (
                  "N/A"
                )}
              </td>
              <td className="w-44">
                {row.medicationName}
                {row.data.strength ? ", " + row.data.strength : ""}
              </td>
              <td className="w-28">
                {row.data.isTaken === 1 && (
                  <span>
                    <GlyphCheck
                      aria-label="check"
                      className="glyph-gray mr-1 w-12px"
                    />
                    {!row.data.scheduledAt ||
                    compareDateParts(row.data.scheduledAt, row.data.takenAt) ? (
                      <span>{dayjs(row.data.takenAt).format("h:mm A")}</span>
                    ) : (
                      <>
                        {dayjs(row.data.takenAt).format("M/D/YY")}
                        <br />
                        <div className="no-wrap">
                          at&nbsp;
                          {dayjs(row.data.takenAt).format("h:mm A")}
                        </div>
                      </>
                    )}
                  </span>
                )}
                {row.data.isTaken === 2 && (
                  <span>
                    <GlyphSkip
                      aria-label="x"
                      className="glyph-warning-stroke mr-1 w-12px"
                    />
                    Skipped
                  </span>
                )}
                {!row.data.isTaken && (
                  <span>
                    <GlyphX
                      aria-label="x"
                      className="glyph-danger mr-1 w-12px"
                    />
                    Missed
                  </span>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
MedHistoryTable.propTypes = {
  rows: PropTypes.array
};

const BodyInfo = ({
  info,
  morningMeds,
  noonMeds,
  nightMeds,
  eveningMeds,
  prnMeds,
  extraMeds
}) => {
  return (
    <>
      {info._context.viewApi.type === "dayGridMonth" && (
        <p className="pb-2 border-bottom">{info.title}</p>
      )}
      {morningMeds.length > 0 && (
        <div className="pt-2">
          <p className="mb-1 border-bottom">
            <GlyphMorning
              aria-label="morning"
              className="mr-2 mb-2 glyph-dark w-1rem "
            />
            Morning
          </p>
          <MedHistoryTable rows={morningMeds} />
        </div>
      )}
      {noonMeds.length > 0 && (
        <div className="pt-2">
          <p className="mb-1 border-bottom">
            <GlyphAfternoon
              aria-label="afternoon"
              className="mr-2 mb-2 glyph-dark w-1rem "
            />
            Afternoon
          </p>
          <MedHistoryTable rows={noonMeds} />
        </div>
      )}
      {eveningMeds.length > 0 && (
        <div className="pt-2">
          <p className="mb-1 border-bottom">
            <GlyphEvening
              aria-label="evening"
              className="mr-2 mb-2 glyph-dark w-1rem "
            />
            Evening
          </p>
          <MedHistoryTable rows={eveningMeds} />
        </div>
      )}
      {nightMeds.length > 0 && (
        <div className="pt-2">
          <p className="mb-1 border-bottom">
            <GlyphNight
              aria-label="night"
              className="mr-2 mb-2 glyph-dark w-1rem "
            />
            Night
          </p>
          <MedHistoryTable rows={nightMeds} />
        </div>
      )}
      {extraMeds.length > 0 && (
        <div className="pt-2">
          <p className="mb-1 border-bottom">
            <PillIcon
              aria-label="person"
              className="mr-2 mb-2 glyph-dark w-1rem h-1rem"
            />
            Extra Doses
          </p>
          <MedHistoryTable rows={extraMeds} />
        </div>
      )}
      {prnMeds.length > 0 && (
        <div className="pt-2">
          <p className="mb-1 border-bottom">
            <GlyphPerson
              aria-label="person"
              className="mr-2 mb-2 glyph-dark w-1rem h-1rem"
            />
            PRN
          </p>
          <MedHistoryTable rows={prnMeds} />
        </div>
      )}
    </>
  );
};
BodyInfo.propTypes = {
  info: PropTypes.object,
  morningMeds: PropTypes.array,
  noonMeds: PropTypes.array,
  nightMeds: PropTypes.array,
  eveningMeds: PropTypes.array,
  prnMeds: PropTypes.array,
  extraMeds: PropTypes.array
};

export const MedsHistoryEvent = ({
  adhState,
  adhDispatch,
  handlePreviousClick,
  handleNextClick
}) => {
  const [popoverOpen, setPopoverOpen] = useState(true);
  const [morningMeds, setMorningMeds] = useState([]);
  const [noonMeds, setNoonMeds] = useState([]);
  const [eveningMeds, setEveningMeds] = useState([]);
  const [nightMeds, setNightMeds] = useState([]);
  const [prnMeds, setPrnMeds] = useState([]);
  const [extraMeds, setExtraMeds] = useState([]);
  const isMobileWidth = UseMobileMediaQuery();
  const { selectedEvent, eventInfo } = adhState;
  const info = selectedEvent;
  const toggle = () => setPopoverOpen(!popoverOpen);

  React.useEffect(() => {
    setMorningMeds([]);
    setNoonMeds([]);
    setEveningMeds([]);
    setNightMeds([]);
    setPrnMeds([]);
    setExtraMeds([]);
    if (info.extendedProps.history) {
      const sortedData = info.extendedProps.history.sort((a, b) => {
        const aDate = a.data.takenAt || a.data.scheduledAt;
        const bDate = b.data.takenAt || b.data.scheduledAt;

        return new Date(aDate) - new Date(bDate);
      });
      sortedData.forEach((element) => {
        let hour;
        // New Logic based on medicationHistoryType
        if (element.data.medicationHistoryType === 0) {
          hour = dayjs(element.data.scheduledAt).hour();
          if (hour >= 12 && hour < 17) {
            setNoonMeds((state) => [...state, element]);
          } else if (hour >= 17 && hour < 21) {
            setEveningMeds((state) => [...state, element]);
          } else if (hour >= 21 && hour < 24) {
            setNightMeds((state) => [...state, element]);
          } else {
            setMorningMeds((state) => [...state, element]);
          }
        } else if (element.data.medicationHistoryType === 1) {
          setPrnMeds((state) => [...state, element]);
        } else if (element.data.medicationHistoryType === 2) {
          setExtraMeds((state) => [...state, element]);
        }
      });
    }
  }, [info]);

  return (
    <>
      {isMobileWidth ? (
        <Modal
          isOpen={popoverOpen}
          toggle={toggle}
          className="center-modal med-event-modal"
        >
          <OutsideAlerter
            adhDispatch={adhDispatch}
            selectedEvent={selectedEvent}
          >
            <ModalHeader className="py-1 bg-light">
              <div className="mb-0 mt-2">
                <button
                  className="btn btn-link pl-0 text-secondary"
                  onClick={handlePreviousClick}
                >
                  <Left className="mr-1 width-8" aria-label="previous" />
                </button>
                <span>{dayjs(info.start).format("dddd, MMMM D")}</span>

                <button
                  className="btn btn-link pr-0 float-right text-secondary"
                  onClick={handleNextClick}
                >
                  <Right className="ml-1 width-8" aria-label="next" />
                </button>
              </div>
            </ModalHeader>

            <ModalBody className="px-5">
              <BodyInfo
                info={info}
                morningMeds={morningMeds}
                noonMeds={noonMeds}
                nightMeds={nightMeds}
                eveningMeds={eveningMeds}
                prnMeds={prnMeds}
                extraMeds={extraMeds}
              />
            </ModalBody>
          </OutsideAlerter>
        </Modal>
      ) : (
        <Popover
          trigger="click"
          container={document.getElementById("calendar")}
          boundariesElement={document.getElementById("calendar")}
          placement="bottom"
          isOpen={popoverOpen}
          target={eventInfo.el}
          toggle={toggle}
          className="width-275"
        >
          <PopoverHeader className="d-flex align-items-end">
            <span>{dayjs(info.start).format("dddd, MMMM D")}</span>

            <CloseButton toggle={toggle} color="glyph-white mb-0" />
          </PopoverHeader>{" "}
          <OutsideAlerter adhDispatch={adhDispatch}>
            <PopoverBody>
              <BodyInfo
                info={info}
                morningMeds={morningMeds}
                noonMeds={noonMeds}
                nightMeds={nightMeds}
                eveningMeds={eveningMeds}
                prnMeds={prnMeds}
                extraMeds={extraMeds}
              />
            </PopoverBody>
          </OutsideAlerter>
        </Popover>
      )}
    </>
  );
};
MedsHistoryEvent.propTypes = {
  adhState: PropTypes.object,
  adhDispatch: PropTypes.func,
  handlePreviousClick: PropTypes.func,
  handleNextClick: PropTypes.func
};
