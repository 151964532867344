export const quantityUnitsEnum = [
  {
    "value": 1,
    "abbr": "mg",
    "label": "milligram(s)"
  },
  {
    "value": 2,
    "abbr": "mL",
    "label": "milliliter(s)"
  },
  {
    "value": 3,
    "abbr": "g",
    "label": "gram(s)"
  },
  {
    "value": 4,
    "abbr": "units",
    "label": "unit(s)"
  },
  {
    "value": 5,
    "abbr": "intl units",
    "label": "international unit(s)"
  },
  {
    "value": 6,
    "abbr": "mcg",
    "label": "microgram(s)"
  },
  {
    "value": 7,
    "abbr": "ea",
    "label": "each"
  },
  {
    "value": 8,
    "abbr": "inch",
    "label": "inch(es)"
  },
  {
    "value": 9,
    "abbr": "mEq",
    "label": "milliequivalent(s)"
  },
  {
    "value": 10,
    "abbr": "gtt",
    "label": "drop(s)"
  },
  {
    "value": 11,
    "abbr": "appful",
    "label": "applicatorful"
  },
  {
    "value": 12,
    "abbr": "scp",
    "label": "scoop(s)"
  },
  {
    "value": 13,
    "abbr": "day",
    "label": "day(s)"
  },
  {
    "value": 14,
    "abbr": "hr",
    "label": "hour(s)"
  },
  {
    "value": 15,
    "abbr": "min",
    "label": "minute(s)"
  },
  {
    "value": 16,
    "abbr": "SUPP(s)",
    "label": "suppository(ies)"
  },
  {
    "value": 17,
    "abbr": "GUM",
    "label": "gum"
  },
  {
    "value": 18,
    "abbr": "PATCH",
    "label": "patch"
  },
  {
    "value": 19,
    "abbr": "GEL",
    "label": "gel"
  },
  {
    "value": 20,
    "abbr": "LOZ",
    "label": "lozenge"
  },
  {
    "value": 21,
    "abbr": "GRAN(S)",
    "label": "granules"
  },
  {
    "value": 22,
    "abbr": "MU",
    "label": "million unit(s)"
  },
  {
    "value": 23,
    "abbr": "cm",
    "label": "centimeter"
  },
  {
    "value": 24,
    "abbr": "mmol",
    "label": "millimole(s)"
  },
  {
    "value": 25,
    "abbr": "cap(s)",
    "label": "cap(s)"
  },
  {
    "value": 26,
    "abbr": "L",
    "label": "liter(s)"
  },
  {
    "value": 27,
    "abbr": "tab(s)",
    "label": "tab(s)"
  },
  {
    "value": 28,
    "abbr": "puff(s)",
    "label": "puff(s)"
  },
  {
    "value": 29,
    "abbr": "spray(s)",
    "label": "spray(s)"
  },
  {
    "value": 30,
    "abbr": "g/kg",
    "label": "g/kg"
  },
  {
    "value": 31,
    "abbr": "intl units/kg",
    "label": "international units/kg"
  },
  {
    "value": 32,
    "abbr": "mcg/kg",
    "label": "mcg/kg"
  },
  {
    "value": 33,
    "abbr": "mcg/m2",
    "label": "mcg/m2"
  },
  {
    "value": 34,
    "abbr": "mEq/kg",
    "label": "mEq/kg"
  },
  {
    "value": 35,
    "abbr": "mg/kg",
    "label": "mg/kg"
  },
  {
    "value": 36,
    "abbr": "mg/kg/day",
    "label": "mg/kg/day"
  },
  {
    "value": 37,
    "abbr": "mg/m2",
    "label": "mg/m2"
  },
  {
    "value": 38,
    "abbr": "mg/m2/day",
    "label": "mg/m2/day"
  },
  {
    "value": 39,
    "abbr": "mL/kg",
    "label": "mL/kg"
  },
  {
    "value": 40,
    "abbr": "mmol/kg",
    "label": "mmol/kg"
  },
  {
    "value": 41,
    "abbr": "units/kg",
    "label": "units/kg"
  },
  {
    "value": 42,
    "abbr": "units/kg/day",
    "label": "units/kg/day"
  },
  {
    "value": 43,
    "abbr": "units/m2",
    "label": "units/m2"
  },
  {
    "value": 44,
    "abbr": "mg/24h",
    "label": "mg/24h"
  },
  {
    "value": 45,
    "abbr": "wk",
    "label": "week(s)"
  },
  {
    "value": 46,
    "abbr": "dose",
    "label": "dose(s)"
  },
  {
    "value": 47,
    "abbr": "mL/min",
    "label": "mL/min"
  },
  {
    "value": 48,
    "abbr": "cm2",
    "label": "square centimeter"
  },
  {
    "value": 49,
    "abbr": "INH",
    "label": "inhalation"
  },
  {
    "value": 50,
    "abbr": "PKT(S)",
    "label": "packet(s)"
  },
  {
    "value": 51,
    "abbr": "INS",
    "label": "insert"
  },
  {
    "value": 52,
    "abbr": "KIT",
    "label": "kit"
  },
  {
    "value": 53,
    "abbr": "-",
    "label": "-"
  },
  {
    "value": 54,
    "abbr": "g/m2",
    "label": "gram/m2"
  },
  {
    "value": 55,
    "abbr": "month",
    "label": "month(s)"
  },
  {
    "value": 56,
    "abbr": "intl units/m2",
    "label": "international units/m2"
  },
  {
    "value": 57,
    "abbr": "mL/m2",
    "label": "mL/m2"
  },
  {
    "value": 58,
    "abbr": "app",
    "label": "application"
  },
  {
    "value": 59,
    "abbr": "ng",
    "label": "nanogram(s)"
  },
  {
    "value": 60,
    "abbr": "ng/kg",
    "label": "ng/kg"
  },
  {
    "value": 61,
    "abbr": "wafer(s)",
    "label": "wafer(s)"
  },
  {
    "value": 62,
    "abbr": "ppm",
    "label": "parts per million"
  },
  {
    "value": 63,
    "abbr": "pn unit",
    "label": "protein nitrogen unit"
  },
  {
    "value": 64,
    "abbr": "INHL",
    "label": "inhaler(s)"
  },
  {
    "value": 65,
    "abbr": "MBq",
    "label": "megabecquerel(s)"
  },
  {
    "value": 66,
    "abbr": "mCi",
    "label": "millicurie"
  },
  {
    "value": 67,
    "abbr": "uCi",
    "label": "microcurie"
  },
  {
    "value": 68,
    "abbr": "mg/hr",
    "label": "mg/hr"
  },
  {
    "value": 69,
    "abbr": "mcg/hr",
    "label": "mcg/hr"
  },
  {
    "value": 70,
    "abbr": "pump(s)",
    "label": "pump(s)"
  },
  {
    "value": 71,
    "abbr": "mcg/day",
    "label": "mcg/day"
  },
  {
    "value": 72,
    "abbr": "mgPE",
    "label": "mgPE"
  },
  {
    "value": 73,
    "abbr": "mgPE/kg",
    "label": "mgPE/kg"
  },
  {
    "value": 74,
    "abbr": "mpfu",
    "label": "million plaque-forming units"
  },
  {
    "value": 75,
    "abbr": "film(s)",
    "label": "film(s)"
  },
  {
    "value": 76,
    "abbr": "mu/m2",
    "label": "million units/m2"
  },
  {
    "value": 77,
    "abbr": "blist",
    "label": "blister(s)"
  },
  {
    "value": 78,
    "abbr": "caplt",
    "label": "caplet(s)"
  },
  {
    "value": 79,
    "abbr": "implat",
    "label": "implant(s)"
  },
  {
    "value": 80,
    "abbr": "lanct",
    "label": "lancet(s)"
  },
  {
    "value": 81,
    "abbr": "pad(s)",
    "label": "pad(s)"
  },
  {
    "value": 82,
    "abbr": "pn ndl",
    "label": "pen needle(s)"
  },
  {
    "value": 83,
    "abbr": "ring(s)",
    "label": "ring(s)"
  },
  {
    "value": 84,
    "abbr": "spng(s)",
    "label": "sponge(s)"
  },
  {
    "value": 85,
    "abbr": "stck",
    "label": "stick(s)"
  },
  {
    "value": 86,
    "abbr": "strip(s)",
    "label": "strip(s)"
  },
  {
    "value": 87,
    "abbr": "swab(s)",
    "label": "swab(s)"
  },
  {
    "value": 88,
    "abbr": "trch",
    "label": "troche(s)"
  },
  {
    "value": 89,
    "abbr": "unspcfd",
    "label": "unspecified"
  },
  {
    "value": 90,
    "abbr": "applctr",
    "label": "applicator"
  },
  {
    "value": 91,
    "abbr": "MBq/kg",
    "label": "MBq/kg"
  },
  {
    "value": 92,
    "abbr": "mm",
    "label": "millimeter"
  },
  {
    "value": 93,
    "abbr": "mm/kg",
    "label": "mm/kg"
  },
  {
    "value": 94,
    "abbr": "trill vg",
    "label": "trillion vector genomes"
  },
  {
    "value": 95,
    "abbr": "bill vg",
    "label": "billion vector genomes"
  },
  {
    "value": 96,
    "abbr": "bill vg/kg",
    "label": "billion vector genomes/kilogram"
  },
  {
    "value": 97,
    "abbr": "trill vg/kg",
    "label": "trillion vector genomes/kilogram"
  }
]