import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { CloseButton, TaskForm, TaskTable } from "../../_components";
import { UseMobileOrTabletMediaQuery } from "../../_helpers/media-queries";
import { patientService } from "../../_services";
import { usePatientContext } from "../../context/patientContext";
import { useUserContext } from "../../context/userContext";
import { useTableStateContext } from "../../context/stateContext";
import { useAlertContext } from "../../context/alertContext";

const headerTabs = [
  { name: "All Tasks", id: "all" },
  { name: "My Tasks", id: "my" },
  { name: "Completed", id: "completed" }
];
const TabTask = ({
  patient,
  patientId,
  patientGroups,
  users,
  patientExists,
  setIncompleteTasks
}) => {
  const { setTasks, state, updateTask, cancelTask, setLoadingTasks } =
    usePatientContext();
  const { alertMethods } = useAlertContext();
  const { userState } = useUserContext();
  const { user } = userState;
  const { tasks, loadingTasks } = state;
  const is768Width = UseMobileOrTabletMediaQuery();
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [addModal, setAddModal] = useState(false);
  const { tableState, updatePatientTaskTableState } = useTableStateContext();

  const toggleAdd = () => {
    setAddModal(!addModal);
  };
  const fetchIdRef = React.useRef(0);
  const mounted = React.useRef(false);
  useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => {
      mounted.current = false;
    }; // ... and to false on unmount
  }, []);

  const fetchData = React.useCallback(
    ({ filtersArr, sortStr, pageSize, pageIndex, activeTab }) => {
      let filterArr = {};

      let sort = {};
      if (sortStr) {
        sort.id = sortStr.slice(0, sortStr.indexOf("."));
        sort.desc = sortStr.slice(sortStr.indexOf(".") + 1) === "DESC";
      }
      if (headerTabs[activeTab].id === "my") {
        filterArr["myTasks"] = [true];
      } else {
        delete filterArr.myTasks;
      }
      if (headerTabs[activeTab].id === "completed") {
        filterArr["status"] = [1];
      } else {
        filterArr["status"] = [0];
      }

      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;

      // Set the loading state
      setTasks([]);
      setLoadingTasks(true);

      if (fetchId === fetchIdRef.current && patientExists) {
        patientService
          .getTasks(patientId, filterArr, sortStr, pageSize, pageIndex)
          .then((res) => {
            if (mounted.current) {
              setTasks(res.data);
              setTotalPages(res.totalPages);
              setTotalCount(res.count);
              setLoadingTasks(false);

              const filterKeys = Object.keys(filterArr);
              if (
                filterKeys?.length === 1 &&
                filterArr?.status[0] === 0 &&
                setIncompleteTasks
              ) {
                setIncompleteTasks(res.data);
              }
            }
          })
          .catch((e) => {
            setLoadingTasks(false);
            alertMethods.error("Something went wrong. Please try again.");
          });
      }

      // Clear existing data
    },
    [
      alertMethods,
      patientExists,
      patientId,
      setIncompleteTasks,
      setLoadingTasks,
      setTasks
    ]
  );

  return (
    <Row>
      {!is768Width ? (
        <Col xl="3" lg="4" md="12">
          <div className="card bg-white">
            <div className="card-header">Create a Task</div>
            <div className="card-body">
              <TaskForm
                patient={patient}
                fetchData={fetchData}
                version="patient"
                users={users}
                patientId={patientId}
                groups={patientGroups?.map((group) => {
                  return { value: group.id, label: group.name };
                })}
                tableState={tableState.patientTaskTableState}
              />
            </div>
          </div>
        </Col>
      ) : (
        <></>
      )}
      <Col xl="9" lg="8" sm="12">
        <TaskTable
          toggleAdd={toggleAdd}
          fetchData={fetchData}
          tasks={tasks}
          totalPages={totalPages}
          totalCount={totalCount}
          updatePatientTask={updateTask}
          cancelPatientTask={cancelTask}
          patient={patient}
          users={users}
          showGroupsColumn={!user?.isSmartSession}
          headerTabs={headerTabs}
          setLoading={setLoadingTasks}
          loading={loadingTasks}
          version="patient"
          patientId={patientId}
          groups={patientGroups?.map((group) => {
            return { value: group.id, label: group.name };
          })}
          tableState={tableState.patientTaskTableState}
          updateTableState={updatePatientTaskTableState}
        />
      </Col>
      <Modal
        isOpen={addModal}
        toggle={toggleAdd}
        className="center-modal scroll-modal"
      >
        <ModalHeader
          toggle={toggleAdd}
          close={<CloseButton toggle={toggleAdd} />}
        >
          Create A Task
        </ModalHeader>
        <ModalBody>
          <TaskForm
            version="patient"
            patient={patient}
            toggleAdd={toggleAdd}
            fetchData={fetchData}
            users={users}
            patientId={patientId}
            groups={patientGroups?.map((group) => {
              return { value: group.id, label: group.name };
            })}
            tableState={tableState.patientTaskTableState}
          />
        </ModalBody>
      </Modal>
    </Row>
  );
};

TabTask.propTypes = {
  patient: PropTypes.object,
  patientId: PropTypes.string,
  patientGroups: PropTypes.array,
  users: PropTypes.array,
  patientExists: PropTypes.bool,
  setIncompleteTasks: PropTypes.func
};

export { TabTask };
