import React, { useEffect, useRef, useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Form,
  FormGroup,
  CustomInput
} from "reactstrap";
import { unitsEnum } from "../../../_helpers";
import { accountService } from "../../../_services";
import { useAlertContext } from "../../../context/alertContext";
import { useUserContext } from "../../../context/userContext";
import PropTypes from "prop-types";

export const UnitsDropdown = ({
  trackerInfo,
  setSelectedUnit,
  selectedUnit,
  setAlternateUnitSelected
}) => {
  const { alertMethods } = useAlertContext();
  const { userState, updateUser } = useUserContext();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const checkedRadioRef = useRef(null);

  const mounted = React.useRef(false);
  React.useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => {
      mounted.current = false;
    }; // ... and to false on unmount
  }, []);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    // Focus the checked radio button when the dropdown is open
    if (dropdownOpen && checkedRadioRef.current) {
      checkedRadioRef.current.focus();
    }
  }, [dropdownOpen]);

  const handleRadioChange = (e) => {
    let index = e.target.id.indexOf("-");
    let val = e.target.id.substring(index + 1);
    // Grab the unit type from the id of the radio
    setSelectedUnit(val);
    if (val !== trackerInfo.units[0].label) {
      // If it's not the default, set alternative unit selected to trigger conversion on chart
      setAlternateUnitSelected(true);
    } else setAlternateUnitSelected(false);
  };

  // To get the integer associated with the selected unit for api call
  function findKeyByValue(obj, value) {
    for (let key in obj) {
      if (obj[key] === value) {
        return key;
      }
    }
  }

  const handleDefaultChange = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    accountService
      .updateHealthTrackerPreference(
        findKeyByValue(unitsEnum, selectedUnit),
        trackerInfo.preferenceId
      )
      .then(() => {
        if (mounted.current) {
          // update user preferences in context
          updateUser({
            ...userState.user,
            userPreference: {
              ...userState.user.userPreference,
              [trackerInfo.type]: findKeyByValue(unitsEnum, selectedUnit)
            }
          });

          alertMethods.success("Your preferences have been updated");
          setIsSubmitting(false);
          setChecked(false);
          toggle();
        }
      })
      .catch((e) => {
        if (mounted.current) {
          setIsSubmitting(false);

          alertMethods.error(
            "Something went wrong updating your preferences. Please refresh the page and try again."
          );
        }
      });
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="right">
      <DropdownToggle
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
        tag="span"
      >
        <button
          className="btn btn-link"
          onKeyDown={(e) => {
            if (
              e.key === "Enter" ||
              e.key === "ArrowDown" ||
              e.key === "ArrowUp"
            ) {
              toggle();
              e.preventDefault();
            }
          }}
        >
          Units: {selectedUnit}
        </button>
      </DropdownToggle>
      <DropdownMenu className="p-4 mt-2">
        <Form>
          <FormGroup className="mb-2">
            <label htmlFor={`${trackerInfo.name}`} className="text-secondary">
              Units
            </label>
            {trackerInfo.units.map((unit, idx) => (
              <div key={unit.label}>
                <CustomInput
                  type="radio"
                  label={unit.label}
                  id={`${trackerInfo.name}-${unit.label}`}
                  onChange={handleRadioChange}
                  defaultChecked={selectedUnit === unit.label}
                  name="unit-radio"
                  // Assign ref to the radio button that's checked
                  innerRef={(el) => {
                    if (selectedUnit === unit.label) {
                      checkedRadioRef.current = el;
                    }
                  }}
                ></CustomInput>
              </div>
            ))}
          </FormGroup>
          <FormGroup className="my-3 text-secondary">
            <CustomInput
              type="checkbox"
              className="gray-checkmark"
              id={`${trackerInfo.name}-default-checkmark`}
              name={`${trackerInfo.name}-default-checkmark`}
              label="Set as Default"
              value={checked}
              onChange={() => setChecked(!checked)}
            ></CustomInput>
          </FormGroup>
          <button
            className="btn btn-primary btn-small"
            onClick={handleDefaultChange}
            disabled={!checked || isSubmitting}
            onKeyDown={(e) => {

              e.key === "Enter" && handleDefaultChange(e)

            }}
          >
            {isSubmitting && (
              <span className="spinner-border spinner-border-sm mr-1"></span>
            )}
            Save
          </button>
        </Form>
      </DropdownMenu>
    </Dropdown>
  );
};
UnitsDropdown.propTypes = {
  trackerInfo: PropTypes.object,
  setSelectedUnit: PropTypes.func,
  selectedUnit: PropTypes.string,
  setAlternateUnitSelected: PropTypes.func
};
