import React from "react";
import { ReactComponent as Exclamation } from "../images/glyph-exclamation.svg";
import PropTypes from "prop-types";

const FrontEndAlert = (props) => {
  return (
    <div className="w-100">
      <div className="mb-3">
        <div className="alert alert-danger alert-dismissable">
          <Exclamation className="mr-3 glyph-danger" aria-label="exclamation" />
          <span className="mx-3">{props.children}</span>
        </div>
      </div>
    </div>
  );
};
FrontEndAlert.propTypes = {
  children: PropTypes.node
};

export { FrontEndAlert };
